import { useState } from "react";
import { AxiosRequest } from "_services/api";
import { HttpError } from "_services/api/HttpError";
import { HttpResponse } from "_services/api/HttpResponse";
import {
    IData,
    IHttpError,
    IHttpResponse,
} from "_services/api/interface/HttpResponse";

export function useServicoModelo() {
    const [servicosModelo, setServicosModelo] = useState<any[]>([]);
    const [servicosModeloOptions, setServicosModeloOptions] = useState<any[]>([]);
    const [error, setError] = useState<IHttpResponse<IData | any>>();
    const [totalRows, setTotalRows] = useState<number>(0);

    const api = AxiosRequest.makeApi();

    interface IListarServicosModelo {
        page?: number;
        limit?: number;
        idServicoModelo?: string;
        uf?: string;
        atualizarOptions?: boolean;
        atualizarListagem?: boolean;
    }

    async function listarServicosModelo({
        page,
        limit,
        idServicoModelo,
        uf,
        atualizarOptions = false,
        atualizarListagem = false,
    }: IListarServicosModelo) {
        try {
            let params = "limit=-1";
            if (atualizarListagem) {
                params = "";
                params += page ? `&page=${page}` : "";
                params += limit ? `&limit=${limit}` : "";
                params += idServicoModelo ? `&idServicoModelo=${idServicoModelo}` : "";
                params += uf ? `&uf=${uf}` : "";
            }

            const { status, data } = await api.get(`/empresa/servico_modelo/empresa${params ? `?${params}` : ""}`);
            const rersponse = new HttpResponse(false, status, data);
            setError(rersponse);
            if (atualizarListagem) {
                setServicosModelo(data.dados);
                setTotalRows(data.totalRegistros);
            }
            if (atualizarOptions) {
                criarOptionsServicosModelo(data);
            }
            return rersponse;
        } catch (error: IHttpError<IData, any> | any) {
            const response = new HttpError<IData>(true, error);
            setError(response);
            if (atualizarListagem) {
                setServicosModelo([]);
                setTotalRows(0);
            }
            if (atualizarOptions) {
                criarOptionsServicosModelo([]);
            }
            return response;
        }
    }

    function criarOptionsServicosModelo(servicos: any[]) {
        const optionsServicos = servicos.map((item) => ({
            value: item.id,
            label: item.nome,
        }));

        setServicosModeloOptions(optionsServicos);
    }

    return {
        listarServicosModelo,
        error,
        servicosModeloOptions,
        servicosModelo,
        totalRows,
    };
}
