import { Tab, Tabs } from "react-bootstrap";
import { Container } from "./style";
import { FormularioDespachante } from "../FormularioDespachante";
import { ListaServicos } from "../ListaServicos";
import { useDespachantes } from "features/core/pages/Cadastro/Hooks/useDespachantes";

interface IFormularioCadastroProps {
    id: string;
    setId: (e: string) => void;
}

export function FormularioTabs({
    id,
    setId,
}: IFormularioCadastroProps) {
    const {
        buscarServicosDespachante,
        servicosDespachante,
        buscarEtapasServicoDespachante,
        etapasServicoDespachante,
        setHonorarioServicoDespachante,
        honorarioServicoDespachante,
        atualizarHonorarioServicoDespachante,
        buscarDespachante,
        dadosFormulario,
        dadosTabelaAreasAtuacao,
        setDadosFormulario,
        setDadosTabelaAreasAtuacao,
        cadastrarDespachante,
        atualizarDespachante,
        setLoading,
        loading,
        setListaUfs,
        listaUfs,
        setPageServicos,
        setLimitServicos,
        pageServicos,
        limitServicos,
    } = useDespachantes();

    const tabServicosDisabled = !id.length;

    function handleLimitChange(limit: number) {
        setLimitServicos(limit);
    }

    function handlePageChange(page: number) {
        setPageServicos(page);
    }

    return (
        <Container>
            <Tabs defaultActiveKey="tab1" id="tabs-example">
                <Tab eventKey="tab1" title={<strong style={{ color: "black", fontWeight: 400 }}>Despachante</strong>}>
                    <FormularioDespachante
                        id={id}
                        setId={setId}
                        buscarDespachante={buscarDespachante}
                        dadosFormulario={dadosFormulario}
                        dadosTabelaAreasAtuacao={dadosTabelaAreasAtuacao}
                        setDadosFormulario={setDadosFormulario}
                        setDadosTabelaAreasAtuacao={setDadosTabelaAreasAtuacao}
                        cadastrarDespachante={cadastrarDespachante}
                        atualizarDespachante={atualizarDespachante}
                        setLoading={setLoading}
                        loading={loading}
                        setListaUfs={setListaUfs}
                    ></FormularioDespachante>
                </Tab>
                <Tab disabled={tabServicosDisabled} eventKey="tab2" title={<strong style={{ color: "black", fontWeight: 400 }}>Serviços</strong>}>
                    <ListaServicos
                        id={id}
                        buscarServicosDespachante={buscarServicosDespachante}
                        buscarEtapasServicoDespachante={buscarEtapasServicoDespachante}
                        atualizarHonorarioServicoDespachante={atualizarHonorarioServicoDespachante}
                        loading={loading}
                        honorarioServicoDespachante={honorarioServicoDespachante}
                        etapasServicoDespachante={etapasServicoDespachante}
                        servicosDespachante={servicosDespachante}
                        setHonorarioServicoDespachante={setHonorarioServicoDespachante}
                        listaUfs={listaUfs}
                        handleLimitChange={handleLimitChange}
                        handlePageChange={handlePageChange}
                        limit={limitServicos}
                        page={pageServicos}
                    ></ListaServicos>
                </Tab>
            </Tabs>
        </Container >
    );
}