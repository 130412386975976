import { ModalCustom } from "components/ModalCustom";
import { Container, IconeContainer, TextoContainer } from "./style";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  show: boolean;
  handleClose: () => void;
  mensagemErro: string;
}

export function ModalErroPlanilha({ handleClose, show, mensagemErro }: Props) {
  return (
    <ModalCustom centered handleClose={handleClose} show={show} title="Erro">
      <Container>
        <IconeContainer>
          <FontAwesomeIcon icon={faXmark} />
        </IconeContainer>
        <TextoContainer>
          <p>Ocorreu um erro ao processar planilha:</p>
          <span>{mensagemErro}</span>
        </TextoContainer>
      </Container>
    </ModalCustom>
  );
}
