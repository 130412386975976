import styled from "styled-components";

export const Title = styled.h2`
  color: ${({ theme }) => theme.colors.neutral.neutral90};
  font-weight: bold;
  font-size: 1rem;
  padding: 0;
  margin: 0;
`;

