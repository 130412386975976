import { ModalCustom } from "components/ModalCustom";
import { Body } from "./style";
import { Col, Form, Row } from "react-bootstrap";
import { CustomInput } from "components/CustomInput";
import { Informacao } from "components/Informacao";

interface IModalEditarServicoProps {
    show: boolean;
    handleClose: () => void;
    onConfirm: () => void;
    onChangeNome: (e: any) => void;
    onChangeUf: (e: any) => void;
    nome: string;
    uf: string;
}

export function ModalEditarServico({
    show,
    handleClose,
    onConfirm,
    onChangeNome,
    onChangeUf,
    nome,
    uf,
}: IModalEditarServicoProps) {

    const mensagem = "Esta ação afetará todos os despachantes e processos vinculados a este serviço, mantendo apenas as informações já cadastradas em custo e valor."

    return (
        <ModalCustom
            show={show}
            handleClose={handleClose}
            title="Editar serviço"
            centered
            footer
            footerConfirmButton
            confirmButtonText="Salvar"
            onConfirm={onConfirm}
            size={"lg"}
        >
            <Body>
                <Row style={{ marginTop: "12px", marginBottom: "24px" }}>
                    <Col lg="8">
                        <Form.Label style={{ marginBottom: "0.3rem" }}>
                            Serviço
                        </Form.Label>
                        <CustomInput
                            onChange={onChangeNome}
                            id="nome"
                            type="text"
                            value={nome}
                        />
                    </Col>
                    <Col lg="4">
                        <Form.Label style={{ marginBottom: "0.3rem" }}>
                            UF
                        </Form.Label>
                        <CustomInput
                            onChange={onChangeUf}
                            id="uf"
                            type="text"
                            value={uf}
                            disabled
                        />
                    </Col>
                </Row>
                <Informacao
                    mensagem={mensagem}
                    type="atencao"
                />
            </Body>
        </ModalCustom>
    );
}