import { ModalCustom } from "components/ModalCustom";
import { Body } from "./style";

interface IModalConfirmarExcluir {
    show: boolean;
    handleClose: () => void;
    onConfirm: () => void;
}


export function ModalConfirmarExcluir({
    show,
    handleClose,
    onConfirm,
}: IModalConfirmarExcluir) {

    return (
        <ModalCustom
            show={show}
            handleClose={handleClose}
            title="Exclusão Motivo de Pendência"
            centered={true}
            footer={true}
            footerConfirmButton={true}
            onConfirm={onConfirm}
        >
            <Body>
                Deseja confirmar a exclusão?
            </Body>
        </ModalCustom>
    );
}