import { OverlayTrigger } from "react-bootstrap";
import { Container } from "./style";
import { Placement } from "react-bootstrap/esm/types";

interface ITooltip {
  children: any;
  childrenToolTip: any;
  show?: boolean;
  placement?: Placement;
}

export function TooltipBootstrap({ children, childrenToolTip, show = true, placement = "auto" }: ITooltip) {
  return (
    <OverlayTrigger placement={placement} overlay={show ? <Container>{childrenToolTip}</Container> : <></>}>
      {children}
    </OverlayTrigger>
  );
}
