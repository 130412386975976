import { FaDownload } from "react-icons/fa";
import { ExportExcelContainer } from "./style";

interface IExportExcel {
  onClick?: () => void;
  variant?: "primary" | "secondary";
}

function ExportExcel({ onClick, variant = "primary" }: IExportExcel) {
  return (
    <ExportExcelContainer variant={variant} onClick={onClick}>
      Exportar
      <FaDownload />
    </ExportExcelContainer>
  );
}
export { ExportExcel };
