import { useState } from "react";
import { AxiosRequest } from "_services/api";
import { HttpError } from "_services/api/HttpError";
import { HttpResponse } from "_services/api/HttpResponse";
import {
    IData,
    IHttpError,
    IHttpResponse,
} from "_services/api/interface/HttpResponse";

export default function useScripts() {
    const api = AxiosRequest.makeApi();

    async function clonarServicosModelo(arquivo: File) {
        try {
            const formData = new FormData();
            formData.append('arquivo', arquivo);

            const { status, data } = await api.post('/script/scriptClonarServicosModelo', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            const rersponse = new HttpResponse(false, status, data);
            return rersponse;
        } catch (error: IHttpError<IData, any> | any) {
            const response = new HttpError<IData>(true, error);
            return response;
        }
    }

    async function migrarServicos(arquivo: File) {
        try {
            const formData = new FormData();
            formData.append('arquivo', arquivo);

            const { status, data } = await api.post('/script/scriptMigrarServicos', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            const rersponse = new HttpResponse(false, status, data);
            return rersponse;
        } catch (error: IHttpError<IData, any> | any) {
            const response = new HttpError<IData>(true, error);
            return response;
        }
    }

    return { clonarServicosModelo, migrarServicos };
}
