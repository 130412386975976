import { Col, Row } from "react-bootstrap";
import styled from "styled-components";

export const Container = styled.div`
  text-align: center;
`;

export const Divider = styled.hr`
  border: solid 1px #000000;
  height: 2px;
  background-color: #000000;
`;

export const TotalLinhas = styled.p`
  text-align: left;
  font-size: 14px;
`;

export const Titulo = styled.p`
  font-size: 18px;
`;

export const TotaisContainer = styled(Row)`
  padding: 1.5rem;
  align-items: center;
  justify-content: space-between;

  .col {
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      margin: 0;
    }
  }
`;

export const TableHeader = styled(Row)`
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
`;

export const ContagemDebitos = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
`;

