import { usePaginationSortSearch } from "containers/Table/hooks/usePaginationSortSearch";
import { useCallback, useState } from "react";
import { AxiosRequest } from "_services/api";
import { HttpError } from "_services/api/HttpError";
import { HttpResponse } from "_services/api/HttpResponse";
import { IData, IHttpError, IHttpResponse } from "_services/api/interface/HttpResponse";
import { IPagarIpvaLote } from "../Interfaces/IPagarIpvaLote";

interface IListarProcessosIpva {
    empresa?: number | null | undefined;
    divisao?: number | null | undefined;
    inicioDataCadastroProcesso?: Date;
    fimDataCadastroProcesso?: Date;
    inicioDataVencimento?: Date;
    fimDataVencimento?: Date;
    statusPagamento: string | null | undefined;
    coluna?: string | undefined;
    direcao?: string | undefined
}

export function useListar() {
    const api = AxiosRequest.makeApi();
    const { totalRows, setTotalRows } = usePaginationSortSearch();
    const [loading, setLoading] = useState(false);
    const [processosIpvaLista, setProcessosIpvaLista] = useState<IPagarIpvaLote[]>([]);
    const [error, setError] = useState<IHttpResponse<IData | any>>();
    const [page, setPage] = useState<number>(1);
    const [limit, setLimit] = useState<number>(10);
    const [orderBy, setOrderBy] = useState<string | undefined>("id");
    const [orderByDirecao, setOrderByDirecao] = useState<string | undefined>("desc");

    const listarProcesssIpva = useCallback(async ({
        empresa,
        divisao,
        inicioDataCadastroProcesso,
        fimDataCadastroProcesso,
        inicioDataVencimento,
        fimDataVencimento,
        statusPagamento,
        coluna,
        direcao
    }: IListarProcessosIpva) => {
        setLoading(true);
        try {
            let params = "";
            params += empresa ? `&idEmpresa=${empresa}` : "";
            params += divisao ? `&idDivisao=${divisao}` : "";
            params += inicioDataCadastroProcesso ? `&inicioDataCadastroProcesso=${inicioDataCadastroProcesso}` : "";
            params += fimDataCadastroProcesso ? `&fimDataCadastroProcesso=${fimDataCadastroProcesso}` : "";
            params += inicioDataVencimento ? `&inicioDataVencimento=${inicioDataVencimento}` : "";
            params += fimDataVencimento ? `&fimDataVencimento=${fimDataVencimento}` : "";
            params += statusPagamento ? `&statusPagamento=${statusPagamento}` : "";
            params += coluna && direcao ? `&orderBy=${coluna}&orderDirecao=${direcao}` : "";
            const { status, data } = await api.get(`/processos/detran/ipva?page=${page}&limit=${limit}${params}`);
            setProcessosIpvaLista(data.items);
            setTotalRows(data.meta.totalItems);
            const response = new HttpResponse(false, status, data);
            setError(response);
            return response;
        } catch (error: IHttpError<IData, any> | any) {
            const response = new HttpError<IData>(true, error);
            setError(response);
            return response;
        } finally {
            setLoading(false);
        }
    }, [page, limit, setTotalRows]);

    return {
        error,
        loading,
        processosIpvaLista,
        setPage,
        setLimit,
        listarProcesssIpva,
        totalRows,
        setOrderBy,
        setOrderByDirecao,
        orderBy,
        orderByDirecao
    };
}
