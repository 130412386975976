import { Alert, Button, Form, Spinner } from "react-bootstrap";
import styled from "styled-components";
import { FaInfoCircle, FaUserAlt } from "react-icons/fa";
import { CustomInput } from "../../../../components/CustomInput";
import { dominiosConfig } from "_config/dominios";
import { MdEmail } from "react-icons/md";
import { PrimaryButton } from "components/Button/style";

export const ContainerConfirmarRecuperacao = styled.h3``;

export const FeedbackError = styled(Alert).attrs({
  variant: "danger",
})`
  margin-top: 1rem;
  display: flex;
  align-items: center;
`;

export const IconeAlerta = styled(FaInfoCircle)`
  color: #dc3545;
  vertical-align: initial;
  margin-right: 8px;
  min-height: 15px;
  min-width: 15px;
`;

export const Carregamento = styled(Spinner).attrs({
  animation: "border",
})`
  color: ${({ theme }) => theme.colors.primary};
  align-self: center;
  margin-top: 8.5rem;
  margin-bottom: 1rem;
`;

export const ContainerForm = styled(Form)`
  margin-top: 2rem;
`;

export const FormGroup = styled(Form.Group)``;

export const IconeEmail = styled(FaUserAlt)`
  color: ${({ theme }) => theme.colors.neutral.neutral40};
`;

export const InputEmail = styled(CustomInput).attrs({
  type: "email",
  placeholder: "Insira seu email",
  size: "lg",
})`
  width: 100%;
  border-left: 0;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
   &::placeholder {
    font-size: 14px;
  }
`;

export const CustomButton = styled(PrimaryButton)`
  width: 100%;
  height: 50px;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

export const Texto = styled.p`
  padding: 0;
  margin: 0;
  color: ${({ theme }) => theme.colors.neutral.neutral50};
`;

export const TextoContainer = styled.div`
  width: 100%;
  display: grid;
  gap: 1rem;
  margin-bottom: 56px;
`;

export const TituloVerifiqueEmail = styled.div`
  color: ${(props) => props.color};
  font-size: 32px;
  font-weight: 700;
  padding:0;
  margin: 0;
`;

export const TitleEsqueceu = styled.h1`
  color: ${(props) => props.color};
  font-size: 32px;
  font-weight: 700;
  padding:0;
  margin: 0;
`;

export const Label = styled.div`
  margin-bottom: 8px;
  font-size: 16px;
`;

export const Title = styled.h1`
  color: ${(props) => props.color};
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
`;

export const TextButton = styled.button`
  background: none !important;
  border: none;
  padding: 0 !important;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary.main};

  :hover {
    text-decoration: underline;
  }
`;

export const TextoChequeEmail = styled.div`
  text-align: center;
`;

export const ContainerChequeEmail = styled.div`
  text-align: center;
`;

export const EmailIcon = styled(MdEmail)`
  color: ${({ theme }) => theme.colors.primary.main};
`;

export const VoltarButton = styled.button`
  background: none !important;
  border: none;
  padding: 0 !important;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.project.main};
`;
