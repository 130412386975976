import * as yup from "yup";
import { portugues } from "_utils/validador";

yup.setLocale(portugues);

export const motivoPendenciaSchema = yup.object().shape({
  descricao: yup
    .string()
    .max(80, "Descrição precisa ter até 80 caracteres")
    .min(1, "Descrição é um campo obrigatório"),
  id: yup.string().optional(),
});

export const scriptGerais = yup.object().shape({
  tipoScript: yup.string().required("Tipo de script é obrigatório."),
  periodo: yup.date().optional().nullable(),
  idEmpresa: yup.number().optional().nullable(),
});
