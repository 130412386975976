import { Col } from "react-bootstrap";
import styled from "styled-components";

export const UploadContainer = styled.div`
  display: grid;
  gap: 0.35rem;

  span {
    font-size: 14px;
  }
`;

export const ButtonsContainer = styled(Col)`
  height: 100%;
  display: flex;
  gap: 1rem;
  align-items: center;
  padding-right: 0;
  
  @media (max-width: 1400px) {
    gap: 0.5rem;
    padding: 0;
    
    button {
      font-size: 12px;
    }
  }
`;
