import moment, { DurationInputArg2 } from "moment";
import "moment/locale/pt-br";
moment.locale("pt-br");

export function converterData(dataFormatar: string) {
  return dataFormatar.split("-").reverse().join("/");
}

export function formataData(data: string) {
  if (!data) {
    return null;
  }

  return data.split("T")[0];
}

export function formataDataISOParaBR(data: string) {
  return data.split("T")[0].split("-").reverse().join("/");
}
export class Data {
  public date: Date;

  constructor(date?: Date | any) {
    this.date = date ? date : new Date();
    return this;
  }

  public ehValida() {
    return moment(this.date).isValid();
  }

  public getDatePrimeiroDiaDoMes() {
    const date = this.date;
    const primeiroDia = new Date(date.getFullYear(), date.getMonth(), 1);
    this.date = primeiroDia;
    return this;
  }

  public getDataUltimoDiaDoMes() {
    const date = this.date;
    const ultimoDia = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    this.date = ultimoDia;
    return this;
  }

  public validarDataEhMaior(data1: Date | string, data2: Date | string) {
    if (!data1 || !data2) return false;

    data1 = moment(data1).format("YYYY-MM-DD");
    data2 = moment(data2).format("YYYY-MM-DD");

    if (data1 > data2) {
      return true;
    } else {
      return false;
    }
  }

  public addDias(numeroDias: number) {
    const adicionar = moment(this.date)
      .add(numeroDias, "days")
      .format("YYYY-MM-DD hh:mm:ss");
    this.date = new Date(adicionar);
    return this;
  }

  public getYear() {
    return moment(this.date).year();
  }

  public getMonth() {
    return moment(this.date).format("M");
  }

  public getDate() {
    return moment(this.date).date();
  }

  public getDataBr() {
    return moment(this.date).format("DD/MM/YYYY");
  }

  public getDataEua() {
    return moment(this.date).format("YYYY-MM-DD");
  }

  public getDataHoraBr() {
    return moment(this.date).format("DD/MM/YYYY HH:mm:ss");
  }

  public getDataHoraEua() {
    return moment(this.date).format("YYYY-MM-DD HH:mm:ss");
  }
  public setDataBr(data: Date | string) {
    data = data.toString();
    data = data.split("/").reverse().join("-");

    this.date = new Date(data);
    return this;
  }

  public getHora() {
    return moment(this.date).format("HH:mm:ss");
  }

  public getDiferenca(data: string) {
    const diferenca = moment(this.date).diff(moment(data));
    return Math.round(moment.duration(diferenca).asDays());
  }
  public getDataHoraEuaBarra() {
    return moment(this.date).format("MM/DD/YYYY HH:mm:ss");
  }
}

const ehValida = (data: Date | string) => moment(data).isValid();

const converterStringDataBrParaEua = (data: string) =>
  data.split("/").reverse().join("-");

const converterDataEua = (data: Date | string) =>
  ehValida(data) ? moment(data).format("YYYY-MM-DD") : null;

const converterDataBr = (data: Date | string) =>
  ehValida(data) ? moment(data).format("DD/MM/YYYY") : null;

const converterDataHoraBr = (data: Date | string) =>
  ehValida(data) ? moment(data).format("DD/MM/YYYY HH:mm:ss") : null;

const converterDataHoraEua = (data: Date) =>
  ehValida(data) ? moment(data).format("YYYY-MM-DD HH:mm:ss") : null;

const dataInicialMaiorDatafim = (dataInicial: Date, dataFinal: Date) =>
  moment(converterDataEua(dataFinal)).isBefore(converterDataEua(dataInicial));

const converterDataHora = (data: Date | string) =>
  ehValida(data) ? moment(data).format("DD/MM/YYYY") : null;

const adicionarHora = (
  data: Date,
  tempoAdicional: number,
  tipo: DurationInputArg2,
  formato: string = "YYYY-MM-DD HH:mm:ss"
) =>
  ehValida(data)
    ? moment(data).add(tempoAdicional, tipo).format(formato)
    : null;

export {
  ehValida,
  converterDataEua,
  converterDataBr,
  converterDataHoraEua,
  converterDataHoraBr,
  dataInicialMaiorDatafim,
  converterStringDataBrParaEua,
  converterDataHora,
  adicionarHora,
};
