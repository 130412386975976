import { Modal, ModalBody } from "react-bootstrap";
import { ButtonClose, Footer, Header, ModalButton, Title } from "./style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { PrimaryButton } from "components/Button/style";

interface ModalProps {
  handleClose: () => void;
  show: boolean;
  title?: string;
  subTitle?: string;
  children: any;
  size?: "lg" | "sm" | "xl";
  centered?: boolean;
  footer?: boolean;
  footerTools?: any;
  footerButtonOk?: boolean;
  footerConfirmButton?: boolean;
  disabledConfirmButton?: boolean;
  onConfirm?: () => void;
  footerCloseButton?: boolean;
  confirmButtonText?: string;
  ConfirmButtonIcone?: any;
  footerConfirmButtonInvert?: boolean;
}

export function ModalCustom({
  handleClose,
  show,
  title,
  subTitle,
  children,
  size,
  centered,
  footer = false,
  footerTools,
  footerButtonOk,
  footerConfirmButton,
  disabledConfirmButton = false,
  onConfirm,
  footerCloseButton = false,
  confirmButtonText,
  ConfirmButtonIcone,
  footerConfirmButtonInvert,
}: ModalProps) {
  return (
    <Modal
      onHide={handleClose}
      show={show}
      size={size}
      aria-labelledby="contained-modal-title-vcenter"
      centered={centered}
    >
      {title && (
        <Header>
          <Title>{title}</Title>
          <ButtonClose onClick={handleClose}>
            <FontAwesomeIcon icon={faXmark} />
          </ButtonClose>
        </Header>
      )}
      <ModalBody>
        {subTitle && <p>{subTitle}</p>}
        {children}
      </ModalBody>
      {footer && (
        <Footer>
          {footerButtonOk && (
            <PrimaryButton onClick={handleClose}>
              OK
            </PrimaryButton>
          )}

          {footerConfirmButton && (
            <>
              <ModalButton onClick={handleClose} outline={true} variante="primary">
                Cancelar
              </ModalButton>
              <ModalButton
                type="submit"
                onClick={onConfirm}
                disabled={disabledConfirmButton}
              >
                {confirmButtonText || "Confirmar"}
                {ConfirmButtonIcone}
              </ModalButton>
            </>
          )}

          {footerConfirmButtonInvert && (
            <>
              <ModalButton
                type="submit"
                onClick={onConfirm}
                disabled={disabledConfirmButton}
              >
                {confirmButtonText || "Confirmar"}
                {ConfirmButtonIcone}
              </ModalButton>
              <ModalButton onClick={handleClose} outline={true} variante="primary">
                Cancelar
              </ModalButton>
            </>
          )}

          {footerCloseButton && (
            <ModalButton onClick={handleClose} outline={true} variante="primary">
              Fechar
            </ModalButton>
          )}

          {footerTools}
        </Footer>
      )}
    </Modal>
  );
}
