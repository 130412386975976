import React from "react";
import { ToolsActionContainer } from "./style";

interface ToolsActionProps {
  children?: any;
}
function ToolsAction({ children }: ToolsActionProps) {
  return <ToolsActionContainer>{children}</ToolsActionContainer>;
}
export { ToolsAction };

