import { IMenu } from "interfaces/Menu";
import { useCallback, useEffect, useMemo, useState } from "react";
import { HttpError } from "_services/api/HttpError";
import { IData } from "_services/api/interface/HttpResponse";
import { getIdUsuario, removeToken } from "_services/auth";
import { AxiosRequest } from "_services/api";

export default function useMenu() {
  const [itens, setItem] = useState<IMenu[]>([]);

  const buscarMenus = useCallback(async () => {
    try {
      const idUsuario = getIdUsuario();
      const baseUrlMsUsuario = process.env.REACT_APP_MS_USUARIO_BASE_URL;
      const despachanteBaseUrl = process.env.REACT_APP_DESPACHANTE_BASE_URL;

      const api = AxiosRequest.makeApi(baseUrlMsUsuario);
      const { data } = await api.get(`/usuario/menus`);

      const menus = data.map((menu: any) => ({
        nome: menu.descricao_funcionalidade,
        icone: <i aria-hidden="true" style={{ fontFamily: 'FontAwesome' }} className={`fa ${menu.menu_icone}`}></i>,
        link: false,
        url: "",
        subMenu: menu.sub_menus.map((subMenu: any) => ({
          nome: subMenu.descricao_funcionalidade,
          icone: "",
          link: true,
          url: `${despachanteBaseUrl}/${subMenu.url}`,
          subMenu: [],
        })),
      }));

      setItem(menus);
    } catch (error: any) {
      const response = new HttpError<IData>(true, error);

      if (response.status === 401) {
        removeToken();
      }
    }
  }, []);

  useEffect(() => {
    buscarMenus();
  }, [buscarMenus]);

  return useMemo(() => [itens], [itens]);
}
