import { Row } from "react-bootstrap";
import styled from "styled-components";

export const RadioContainer = styled(Row)`
  div {
    align-items: center;
  }

  input {
    accent-color: ${({ theme }) => theme.colors.project.main};
    margin-right: 1rem;
    width: 1rem;
    height: 1rem;
  }
`;