import { Page } from "containers/Page";
import { Filtros } from "./containers/Filtros";
import { ModalPreencherATPV } from "./containers/ModalPreencherATPV";
import { useContext, useEffect, useState } from "react";
import { ListarSolicitacoes } from "./containers/Listar";
import { usePreencherATPV } from "./hooks/usePreencherATPV";
import { ModalSucessoPreencherATPV } from "./containers/ModalSucessoPreencherATPV";
import { LoadingScreen } from "components/LoadingScreen";
import { useSocket } from "hooks/useSocket";
import { IPreencherATPVLote } from "./interfaces/IPreencherATPVLote";
import { UploadContext } from "contexts/UploadContext";
import { alertaErro } from "components/Alerta";

export function PreenchimentoATPV() {
    const [nomeEmpresa, setNomeEmpresa] = useState<string>("");
    const [nomeDivisao, setNomeDivisao] = useState<string>("");
    const {
        adicionarArquivo,
        atualizarProgressoUpload,
        showBox,
        toggleShowBox,
        setErrosUpload,
        setSucessoUpload,
        setPlanilhaCorrompida,
    } = useContext(UploadContext);
    const { socket } = useSocket();
    const {
        buscarDados,
        dados,
        loading,
        setLimit,
        setPage,
        limit,
        page,
        queryParams,
        gerarQueryParams,
        totalRows,
        idSolicitacao,
        showModalSucesso,
        setShowModalSucesso,
        showModalPreencherATPV,
        setShowModalPreencherATPV,
        preencherATPVUnitario,
        downloadSolicitacaoATPV,
        preencherATPVLote,
        forceUpdate,
    } = usePreencherATPV();
    useEffect(() => {
        buscarDados();
    }, [limit, page, queryParams, forceUpdate, buscarDados]);

    const onClickModalPreencherATPV = () => {
        setShowModalPreencherATPV(true)
    }
    const closeModalPreencherATPV = () => {
        setShowModalPreencherATPV(false)
    }
    const handlePageChange = (page: number) => {
        setPage(page);
    }

    const handleLimitChange = (limit: number) => {
        setLimit(limit);
    }
    async function handleSubmitFiltros(data: IFiltro) {
        gerarQueryParams(data);
    }
    const closeModalSucesso = () => {
        setShowModalSucesso(false);
    }
    const handleDownloadATPV = (id: number) => {
        downloadSolicitacaoATPV(id);
    }
    let validacaoFinalizada = false;
    const handleDisponibilidadeSocket = (tempoAnterior: number, limiteEspera: number, nomeArquivo: string) => {
        if ((Date.now() - tempoAnterior) > limiteEspera && !validacaoFinalizada) {
            alertaErro("Ocorreu um erro", "Não foi possível monitorar o status de processamento da planilha. Recarregue a página para verificar o processamento do Lote.");
        }
    }
    const handleSubmitFormLote = async (dados: IPreencherATPVLote) => {
        const socketId = `${Date.now()}`;
        const nomeArquivo = `${dados.arquivo.name}:${socketId}`;
        const limiteEspera = 30000;
        let tempoUltimaAtualizacaoProcessamentoPlanilha = Date.now();

        socket?.on(`${socketId}:corrompida`, () => {
            tempoUltimaAtualizacaoProcessamentoPlanilha = Date.now();
            validacaoFinalizada = true;
            atualizarProgressoUpload({
                nomeArquivo: `${nomeArquivo}`,
                progressoAtual: 100,
                temErro: true,
            });
            setPlanilhaCorrompida(nomeArquivo);
        });

        socket?.on(`${socketId}:progresso_validacao`, (data) => {
            tempoUltimaAtualizacaoProcessamentoPlanilha = Date.now();
            setTimeout(() => {
                handleDisponibilidadeSocket(
                    tempoUltimaAtualizacaoProcessamentoPlanilha,
                    limiteEspera,
                    nomeArquivo,
                )
            }, limiteEspera)
            atualizarProgressoUpload({
                nomeArquivo: `${nomeArquivo}`,
                progressoAtual: ((data.atual / data.total) * 100) / 2,
                temErro: false,
            });
        });

        socket?.on(`${socketId}:progresso_insercao`, (data) => {
            tempoUltimaAtualizacaoProcessamentoPlanilha = Date.now();
            setTimeout(() => {
                handleDisponibilidadeSocket(
                    tempoUltimaAtualizacaoProcessamentoPlanilha,
                    limiteEspera,
                    nomeArquivo,
                )
            }, limiteEspera)
            atualizarProgressoUpload({
                nomeArquivo: `${nomeArquivo}`,
                progressoAtual: 50 + ((data.atual / data.total) * 100) / 2,
                temErro: false,
            });
        });

        socket?.on(`${socketId}:concluida`, (data) => {
            tempoUltimaAtualizacaoProcessamentoPlanilha = Date.now();
            validacaoFinalizada = true;
            console.log(data)
            setSucessoUpload(nomeArquivo, `${process.env.PUBLIC_URL}/backoffice/preenchimentoatpv/detalhes?id=${data.id}`);
            atualizarProgressoUpload({
                nomeArquivo: nomeArquivo,
                progressoAtual: 100,
                temErro: false,
            });
            buscarDados();
        });

        socket?.on(`${socketId}:erros`, (data) => {
            tempoUltimaAtualizacaoProcessamentoPlanilha = Date.now();
            validacaoFinalizada = true;
            setErrosUpload(nomeArquivo, data);
            atualizarProgressoUpload({
                nomeArquivo: `${nomeArquivo}`,
                progressoAtual: 100,
                temErro: true,
            });
        });

        setShowModalPreencherATPV(false);
        const { hasErro } = await preencherATPVLote({
            ...dados,
            nomeEmpresa,
            nomeDivisao,
            nomeArquivo,
            socketId
        });

        setTimeout(() => {
            handleDisponibilidadeSocket(
                tempoUltimaAtualizacaoProcessamentoPlanilha,
                limiteEspera,
                nomeArquivo,
            )
        }, limiteEspera)

        if (hasErro) {
            return;
        }
        adicionarArquivo([
            {
                nomeArquivo: `${nomeArquivo}`,
                progressoUpload: 0,
                temErro: false,
                uploadFinalizado: false,
            },
        ]);

        if (!showBox) {
            toggleShowBox();
        }
    }

    return (
        <Page title="Preenchimento ATPV">
            <LoadingScreen mostrar={loading} />
            <Filtros
                submitForm={handleSubmitFiltros}
                onClickPreencherATPV={onClickModalPreencherATPV}
            ></Filtros>
            <ListarSolicitacoes
                dados={dados}
                handleLimitChange={handleLimitChange}
                handlePageChange={handlePageChange}
                totalRows={totalRows}
                handleDownload={handleDownloadATPV}
            ></ListarSolicitacoes>
            <ModalPreencherATPV
                show={showModalPreencherATPV}
                handleClose={closeModalPreencherATPV}
                submitFormUnitario={preencherATPVUnitario}
                submitFormLote={handleSubmitFormLote}
                setNomeEmpresa={setNomeEmpresa}
                setNomeDivisao={setNomeDivisao}
            ></ModalPreencherATPV>
            <ModalSucessoPreencherATPV
                show={showModalSucesso}
                handleClose={closeModalSucesso}
                idSolicitacao={idSolicitacao}
            ></ModalSucessoPreencherATPV>
        </Page>
    );
}