import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    padding: 20px;
    background-color: ${({ theme }) => theme.colors.white};
`;

export const Titulo = styled.div`
    margin: 20px 0;
    font-weight: bolder;
    font-size: 0.9rem;
`;

export const TituloAreaAtuacao = styled.div`
    margin-top: 60px;
    margin-bottom: 6px;
    font-weight: bolder;
    font-size: 0.9rem;
`;

export const Icone = styled.span`
    font-size: 14px;
    padding: 1px;
    border-radius: 30px;
    color: ${({ theme }) => theme.colors.white};
    background-color: #10AC85;
    cursor: pointer;
    margin-left: 1.5rem;

    svg {
        width: 15px;
        height: 15px;
        margin-bottom: 2px;
    }
`

export const ContainerBotao = styled.div`
    padding: 12px;
`