import { PrimaryButton } from "components/Button/style";
import { ModalFooter, ModalHeader, ModalTitle } from "react-bootstrap";
import styled from "styled-components";

export const Header = styled(ModalHeader)`
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.primary.main};
  height: 3rem;
`
export const Title = styled(ModalTitle)`
  font-size: 1.35rem;
`

export const Footer = styled(ModalFooter)`
  display: flex;
  justify-content: center;
`

export const ModalButton = styled(PrimaryButton)`
  width: 200px;
  margin: 0 8px;
  svg {
    color: ${({ theme }) => theme.colors.white} !important;
  }
`

export const ButtonClose = styled.button`
  border: none;
  background: none;
  color: ${({ theme }) => theme.colors.primary.main};
  display: flex;
  align-items: center;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;