import { utils, writeFile } from "xlsx";
import { CadastrarProcessoEmLoteMapper } from "features/processo/utils/mappers/CadastrarProcessoEmLoteMapper";

export function handleExportProcessos(
  processosComErro: any[],
  showModalCallback: any
) {
  if (!processosComErro.length) {
    showModalCallback(false);
    window.location.reload();
    return;
  }

  const headers = [
    "Placa",
    "Chassi",
    "Renavam",
    "Odometro",
    "UF",
    "Municipio",
    "Marca_Modelo",
    "Ano_Modelo",
    "Ano_Fabricacao",
    "Cor",
    "Tipo",
    "Especie",
    "Potencia",
    "Combustivel",
    "CPF_CNPJ",
    "Nome",
    "Email",
    "CEP",
    "Endereco",
    "Numero",
    "Complemento",
    "Bairro",
    "Cidade",
    "UF_Cliente",
    "Valor_De_Venda",
    "UF_Venda",
    "Municipio_De_Venda",
    "Data_De_Venda",
    "Escolha_De_Placa",
    "Local_De_Emplacamento",
    "Placa_Vermelha",
    "Alienacao",
    "NF",
    "Emissao",
    "Erro",
  ];

  const dados = processosComErro.map((item) =>
    CadastrarProcessoEmLoteMapper.makeObjetoComErro(item, item.mensagem)
  );

  const dadosPlanilha = [
    ["Dados Obrigatórios"],
    headers,
    ...dados.map((dado: any) => headers.map((header) => dado[header])),
  ];

  const worksheet = utils.aoa_to_sheet(dadosPlanilha);
  const workbook = utils.book_new();
  
  utils.book_append_sheet(workbook, worksheet, "Planilha");
  writeFile(workbook, "Erros_Cadastrar_Processos_Lote.xlsx");
  showModalCallback(false);
}
