import { LoginBoxContainer } from "features/Login/container/LoginBoxContainer";
import { Container, ImgLogoDoc } from "./styles";

export function Login() {
  return (
    <Container>
      <ImgLogoDoc />
      <LoginBoxContainer />
    </Container>
  );
}
