import { Page } from "containers/Page";
import { TabsStyled } from "features/Login/components/LoginBox/style";
import { useContext } from "react";
import { Tab } from "react-bootstrap";
import { Historico } from "./containers/historico";
import { SolicitarAutorizacao } from "./containers/solicitarAutorizacao";
import { AutorizacaoPlacaContext } from "./context/AutorizacaoPlacaContext";

export function AutorizacaoPlaca() {
  const { loading, tab, setTab } = useContext(AutorizacaoPlacaContext);

  return (
    <Page
      lastPage="Home / Backoffice"
      title="Autorização de placa"
      loading={loading}
    >
      <TabsStyled
        host=""
        id="controlled-tab-example"
        className="mt-4"
        activeKey={tab}
        onSelect={(k) => {
          setTab(k as string);
        }}
      >
        <Tab eventKey="solicitar" title="Solicitar autorização">
          <SolicitarAutorizacao />
        </Tab>
        <Tab eventKey="historico" title="Histórico">
          <Historico />
        </Tab>
      </TabsStyled>
    </Page>
  );
}
