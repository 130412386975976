import { useCallback, useEffect, useMemo, useState } from "react";
import { AxiosRequest } from "_services/api";
import { HttpError } from "_services/api/HttpError";
import { HttpResponse } from "_services/api/HttpResponse";
import {
  IData,
  IHttpError,
  IHttpResponse,
} from "_services/api/interface/HttpResponse";

export function useDespachantes(empresa: any) {
  const [despachantes, setDespachantes] = useState<any[]>([]);
  const [despachantesOptions, setDespachantesOptions] = useState<any[]>([]);
  const [error, setError] = useState<IHttpResponse<IData | any>>();

  const api = useMemo(() => AxiosRequest.makeApi(), []);

  const listarTodosDespachantes = useCallback(
    async () => {
      try {
        const { status, data } = await api.get(
          `/empresa/despachante/`
        );
        const rersponse = new HttpResponse(false, status, data);
        setError(rersponse);
        setDespachantes(data.dados);
        criarOptionsDivisoes(data.dados);
        return rersponse;
      } catch (error: IHttpError<IData, any> | any) {
        criarOptionsDivisoes([]);
        const response = new HttpError<IData>(true, error);
        setError(response);
        return response;
      }
    },
    [api]
  );

  useEffect(() => {
    if (!empresa) {
      setDespachantes([]);
      setDespachantesOptions([]);
      return;
    }

    listarTodosDespachantes();
  }, [empresa, listarTodosDespachantes]);

  function criarOptionsDivisoes(divisoes: any[]) {
    const optionsDivisoes = divisoes.map((item) => ({
      value: item.id,
      label: item.nome_fantasia,
    }));

    setDespachantesOptions(optionsDivisoes);
  }

  return {
    despachantes,
    error,
    despachantesOptions,
    listarTodosDespachantes,
  };
}

