import { ReactNode, createContext, useCallback, useMemo } from "react";
import { getEmpresa, getToken, parseJwt } from "_services/auth";
import { AxiosRequest } from "_services/api";

export interface ContextPremissaoProps {
  verificarPermissao: () => boolean;
  verificarEmpresa: () => boolean;
}

export const ContextPermissao = createContext<ContextPremissaoProps>({
  verificarPermissao: () => false,
  verificarEmpresa: () => false,
});

interface IContextPermissaoProvider {
  children: ReactNode;
}

function ContextPermisaoProvider({ children }: IContextPermissaoProvider) {
  const { permissoes } = parseJwt();

  const permissaoUrl = useCallback(() => {
    if (permissoes.length <= 0) {
      return false;
    }

    const urlAtual = window.location.pathname.substring(1);

    if (
      permissoes.length > 0 &&
      !permissoes.some((url: string) => url === urlAtual)
    ) {
      return false;
    }
    return true;
  }, [permissoes]);

  const verificarPermissao = useCallback(() => {
    const token = getToken();

    if (!token || token === null || token === "undefined") {
      return false;
    }

    return permissaoUrl();
  }, [permissaoUrl]);

  const verificarEmpresa = useCallback(() => {
    const ID_LW = "91";

    const empresaUsuario = getEmpresa();

    return empresaUsuario === ID_LW;
  }, []);

  const validarToken = useCallback(async () => {
    try {
      const api = AxiosRequest.makeApi(process.env.REACT_APP_MS_USUARIO_BASE_URL)
      const token = getToken();
  
      const { data } = await api.post("/usuario/validade", { token });
  
      if (!data.id) {
        return false;
      }
  
      return true;
    } catch (err) {
      return false;
    }
  }, [])

  const contextValue = useMemo(
    () => ({
      verificarPermissao,
      verificarEmpresa,
    }),
    [verificarPermissao, verificarEmpresa]
  );
  const baseUrlDespachante = process.env.REACT_APP_DESPACHANTE_BASE_URL;

  if (!(async () => await validarToken())) {
    window.location.assign(
      `${baseUrlDespachante}/index.php?m=idNull`
    );
    return;
  }

  if (!permissaoUrl()) {
    window.location.assign(
      `${baseUrlDespachante}/pages/core/error/permissao/401.php`
    );
    return;
  }

    return (
      <ContextPermissao.Provider value={contextValue}>
        {children}
      </ContextPermissao.Provider>
    );
}

export default ContextPermisaoProvider;

