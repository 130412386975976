import { Button, ButtonsContainer, Container, DatePickerContainer, FormContainer, InputContainer, Title } from "./style";
import { Col, Form, Row } from "react-bootstrap";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { DropdownSelect } from "components/DropdownSelect";
import { yupResolver } from "@hookform/resolvers/yup";
import { CustomDatePicker } from "components/DatePicker";
import { MaisFiltros } from "components/ButtonModal";
import { IFiltrosPagarIpvaLote } from "features/core/pages/Financeiro/Interfaces/IFiltrosPagarIpvaLote";
import { filtrosPagarIpvaLote } from "features/core/pages/Financeiro/Validate/validator";

interface IPagarIpvaLoteFiltrosProps {
    empresasOptions: any[];
    empresaSelecionada: number | undefined;
    onChangeEmpresa: (e: any) => void;
    divisoesOptions: any[];
    onChangeDataVencimento: (e: any) => void;
    inicioDataVencimento: Date | undefined;
    fimDataVencimento: Date | undefined;
    onChangeDataCadastroProcesso: (e: any) => void;
    inicioDataCadastroProcesso: Date | undefined;
    fimDataCadastroProcesso: Date | undefined;
    formSubmit: SubmitHandler<IFiltrosPagarIpvaLote>;
    onChangeDivisao: (e: any) => void;
    divisaoSelecionada: number | undefined;
    onChangeStatusPagamento: (e: any) => void;
    statusPagamentoSelecionado: string | undefined;
    showMaisFiltros: boolean;
    aplicarOnClick: () => void;
    cancelarOnClick: () => void;
    limparTudoOnClick: () => void;
}

export function PagarIpvaLoteFiltros({
    empresasOptions,
    empresaSelecionada,
    onChangeEmpresa,
    divisoesOptions,
    onChangeDataVencimento,
    inicioDataVencimento,
    fimDataVencimento,
    onChangeDataCadastroProcesso,
    inicioDataCadastroProcesso,
    fimDataCadastroProcesso,
    formSubmit,
    onChangeDivisao,
    divisaoSelecionada,
    onChangeStatusPagamento,
    statusPagamentoSelecionado,
    showMaisFiltros,
    aplicarOnClick,
    cancelarOnClick,
    limparTudoOnClick
}: IPagarIpvaLoteFiltrosProps) {

    const {
        handleSubmit,
        control,
        reset,
    } = useForm<IFiltrosPagarIpvaLote>({
        resolver: yupResolver(filtrosPagarIpvaLote),
    });

    return (
        <Container>
            <Title>Filtros</Title>
            <hr/>
            <FormContainer>
                <Form noValidate onSubmit={handleSubmit(formSubmit)}>
                    <Row>
                        <Col lg="3">
                            <Form.Group>
                                <Controller
                                    control={control}
                                    name="empresa"
                                    render={() => (
                                        <DropdownSelect
                                            options={empresasOptions}
                                            title="Empresa"
                                            selectedValue={empresaSelecionada}
                                            onSelect={onChangeEmpresa}
                                            searchable
                                        />
                                    )}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg="3">
                            <Form.Group>
                                <Controller
                                    control={control}
                                    name="divisao"
                                    render={() => (
                                        <DropdownSelect
                                            options={divisoesOptions}
                                            title="Divisão"
                                            selectedValue={divisaoSelecionada}
                                            onSelect={onChangeDivisao}
                                            searchable
                                        />
                                    )}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg="3">
                            <Form.Group>
                                <Controller
                                    control={control}
                                    name="dataVencimento"
                                    render={() => (
                                        <DatePickerContainer>
                                            <span>Data de vencimento</span>
                                            <CustomDatePicker
                                                selected={inicioDataVencimento}
                                                onChange={onChangeDataVencimento}
                                                startDate={inicioDataVencimento}
                                                endDate={fimDataVencimento}
                                                rangeSelect={true}
                                            />
                                        </DatePickerContainer>
                                    )}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg="3">
                            <ButtonsContainer>
                                <Form.Group>
                                    <Button type="submit">Filtrar</Button>
                                </Form.Group>
                                <Form.Group>
                                    <MaisFiltros
                                        limparTudoOnClick={limparTudoOnClick}
                                        cancelarOnClick={cancelarOnClick}
                                        widthModal="md"
                                        showMaisFiltrosDefault={showMaisFiltros}
                                        handleClickAplicarFiltros={aplicarOnClick}
                                    >
                                        <InputContainer>
                                            <Form.Group>
                                                <Controller
                                                    control={control}
                                                    name="statusPagamento"
                                                    render={() => (
                                                        <DropdownSelect
                                                            options={[
                                                                { value: 1, label: "Pendente" },
                                                                { value: 2, label: "Processando" },
                                                                { value: 3, label: "Pago" },
                                                                { value: 4, label: "Baixa manual" },
                                                                { value: 5, label: "Erro" }
                                                            ]}
                                                            title="Status de pagamento"
                                                            selectedValue={statusPagamentoSelecionado}
                                                            onSelect={onChangeStatusPagamento}
                                                        />
                                                    )}
                                                />
                                            </Form.Group>
                                        </InputContainer>
                                        <InputContainer>
                                            <Form.Group>
                                                <Controller
                                                    control={control}
                                                    name="dataCadastroProcesso"
                                                    render={() => (
                                                        <DatePickerContainer>
                                                            <span>Data de cadastro do processo</span>
                                                            <CustomDatePicker
                                                                selected={inicioDataCadastroProcesso}
                                                                onChange={onChangeDataCadastroProcesso}
                                                                startDate={inicioDataCadastroProcesso}
                                                                endDate={fimDataCadastroProcesso}
                                                                rangeSelect={true}
                                                            />
                                                        </DatePickerContainer>
                                                    )}
                                                />
                                            </Form.Group>
                                        </InputContainer>
                                    </MaisFiltros>
                                </Form.Group>
                            </ButtonsContainer>
                        </Col>
                    </Row>
                </Form>
            </FormContainer>
        </Container>
    );
}