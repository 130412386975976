import { PrimaryButton } from "components/Button/style";
import { CompositeContainer } from "components/CompositeContainer";
import { DropdownSelect } from "components/DropdownSelect";
import FileUpload from "components/UploadArquivos";
import { Col, Form, Row } from "react-bootstrap";
import { FaDownload } from "react-icons/fa";
import { ButtonsContainer, UploadContainer } from "./style";
import { useEmpresas } from "hooks/useEmpresas";
import { useDivisoes } from "hooks/useDivisoes";
import { useEffect, useMemo, useState } from "react";
import { useDespachantes } from "hooks/useDespachantes";
import { useServicos } from "hooks/useServicos";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { getToken, parseJwt } from "_services/auth";
import { Cookies } from "_utils/Cookies";
import { makeWorker } from "features/processo/_workers";
import Swal from "sweetalert2";
import { cadastrarProcessoLoteSchema } from "features/processo/validator";

export interface ICadastrarProcessoLote {
  empresa: NullableNumber;
  divisao: NullableNumber;
  despachante: NullableNumber;
  servico: NullableNumber;
  arquivo: any;
}

export function ImportarExcelProcessos({ setLoadingPage }: any) {
  const { idEmpresa, idDespachante } = parseJwt();

  const [empresaSelecionada, setEmpresaSelecionada] = useState(null);
  const [despachanteSelecionado, setDespachanteSelecionado] = useState(null);
  const { empresasOptions } = useEmpresas();
  const { divisoesOptions } = useDivisoes(empresaSelecionada);
  const { despachantesOptions } = useDespachantes(empresaSelecionada);
  const { servicosOptions } = useServicos(empresaSelecionada, despachanteSelecionado);

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitted },
    reset,
  } = useForm<ICadastrarProcessoLote>({
    resolver: yupResolver<ICadastrarProcessoLote>(cadastrarProcessoLoteSchema),
  });

  useEffect(() => {
    if (empresasOptions.length && idEmpresa) {
      setEmpresaSelecionada(idEmpresa);
      reset({
        empresa: idEmpresa,
      })
    }

    if (
      despachantesOptions.length &&
      idDespachante
    ) {
      setDespachanteSelecionado(idDespachante);
    }
  }, [
    idEmpresa,
    idDespachante,
    empresasOptions,
    despachantesOptions,
    reset,
  ]);

  const worker = useMemo(makeWorker, []);

  function handleConsultarPlanilha(dados: ICadastrarProcessoLote) {
    Swal.fire(
      "Solicitar Processo em lote",
      "<span>Isso pode demorar um pouco</span>",
      "info"
    )
      .then(() => {
        setLoadingPage(true);
        const baseURL = process.env.REACT_APP_API_DESPACHANTE_BASE_URL

        const apiHandler = {
          token: getToken(),
          baseURL,
        };

        worker.postMessage({
          type: "consulta",
          dados,
          apiHandler,
        });
      })
      .catch(() => {});
  }

  function handleBaixarModelo() {
    const link = document.createElement("a");
    link.download = "Modelo_Cadastrar_Processo_Lote.xlsx";
    link.href = "/app/files/Modelo_Cadastrar_Processo_Lote.xlsx";
    link.click();
  }

  return (
    <CompositeContainer.Root>
      <CompositeContainer.Body>
        <Form noValidate onSubmit={handleSubmit(handleConsultarPlanilha)}>
          <Row style={{ height: "90px" }}>
            <Col lg="2">
              <Form.Group>
                <Controller
                  control={control}
                  name="empresa"
                  render={({ field: { onChange, value } }) => (
                    <DropdownSelect
                      searchable
                      onSelect={(value) => {
                        if (value === null) {
                          reset();
                        }
                        setEmpresaSelecionada(value);
                        onChange(value);
                      }}
                      options={empresasOptions}
                      selectedValue={empresaSelecionada}
                      title="Empresa"
                      mensagemErro={
                        isSubmitted ? errors.empresa?.message : null
                      }
                    />
                  )}
                />
              </Form.Group>
            </Col>
            <Col lg="2">
              <Form.Group>
                <Controller
                  control={control}
                  name="divisao"
                  render={({ field: { onChange, value } }) => (
                    <DropdownSelect
                      searchable
                      onSelect={onChange}
                      options={divisoesOptions}
                      selectedValue={value}
                      title="Divisão"
                      disabled={!empresaSelecionada}
                      mensagemErro={
                        isSubmitted ? errors.divisao?.message : null
                      }
                    />
                  )}
                />
              </Form.Group>
            </Col>
            <Col lg="2">
              <Form.Group>
                <Controller
                  control={control}
                  name="despachante"
                  render={({ field: { onChange } }) => (
                    <DropdownSelect
                      searchable
                      onSelect={(value) => {
                        setDespachanteSelecionado(value);
                        onChange(value);
                      }}
                      options={despachantesOptions}
                      selectedValue={despachanteSelecionado}
                      title="Despachante"
                      disabled={!empresaSelecionada}
                      mensagemErro={
                        isSubmitted ? errors.despachante?.message : null
                      }
                    />
                  )}
                />
              </Form.Group>
            </Col>
            <Col lg="2">
              <Form.Group>
                <Controller
                  control={control}
                  name="servico"
                  render={({ field: { onChange, value } }) => (
                    <DropdownSelect
                      searchable
                      onSelect={onChange}
                      options={servicosOptions}
                      selectedValue={value}
                      title="Serviço"
                      disabled={!empresaSelecionada || !despachanteSelecionado}
                      mensagemErro={
                        isSubmitted ? errors.servico?.message : null
                      }
                    />
                  )}
                />
              </Form.Group>
            </Col>
            <Col lg="2">
              <Form.Group>
                <UploadContainer>
                  <span>Arquivo (Excel)</span>
                  <Controller
                    name="arquivo"
                    control={control}
                    render={({ field: { onChange } }) => (
                      <FileUpload
                        onFileUpload={onChange}
                        mensagemErro={
                          isSubmitted ? (errors.arquivo?.message as any) : null
                        }
                      />
                    )}
                  />
                </UploadContainer>
              </Form.Group>
            </Col>
            <ButtonsContainer lg="2">
              <PrimaryButton buttonWidth="none" type="submit">
                Consultar
              </PrimaryButton>
              <PrimaryButton
                buttonWidth="none"
                type="button"
                onClick={handleBaixarModelo}
                variante="primary"
                outline
              >
                Modelo <FaDownload />
              </PrimaryButton>
            </ButtonsContainer>
          </Row>
        </Form>
      </CompositeContainer.Body>
    </CompositeContainer.Root>
  );
}

