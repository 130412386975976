import React, { useState } from "react";
import {
  AreaClicavel,
  FileInput,
  FileUploadContainer,
  SpanMensagemErro,
  UploadButton,
  UploadIcon,
  UploadTextContainer,
} from "./style";

interface FileUploadProps {
  onFileUpload: (file: File) => void;
  mensagemErro?: NullableString;
  id?: string;
  comMaxWidth?: boolean;
  gapContainer?: string;
  inputAccept?: string;
}

function FileUpload({
  onFileUpload,
  mensagemErro,
  id = "fileInput",
  comMaxWidth = true,
  gapContainer = "0.5rem",
  inputAccept = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
}: FileUploadProps) {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      setSelectedFile(file);
      onFileUpload(file);
    }
  };

  return (
    <FileUploadContainer gap={gapContainer}>
      <FileInput
        accept={inputAccept}
        id={id}
        type="file"
        onChange={handleFileChange}
      />
      <AreaClicavel htmlFor={id}>
        <UploadTextContainer comErro={!!mensagemErro} comMaxWidth={comMaxWidth}>
          {selectedFile ? (
            <span>{selectedFile?.name}</span>
          ) : (
            <span>Selecione o arquivo</span>
          )}
        </UploadTextContainer>
        <UploadButton comErro={!!mensagemErro}>
          <UploadIcon />
          Upload
        </UploadButton>
      </AreaClicavel>
      {mensagemErro && <SpanMensagemErro>{mensagemErro}</SpanMensagemErro>}
    </FileUploadContainer>
  );
}

export default FileUpload;
