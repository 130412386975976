import { Page } from "containers/Page";
import { FormularioTabs } from "./containers/FormularioTabs";
import { useState } from "react";

export function DespachanteCadastro() {
    let idDespachante = "";
    const params = window.location.href.split("?");
    if (params[1] && params[1].includes("id=")) {
        idDespachante = params[1].split("&").filter((item) => item.includes("id="))[0].split("=")[1]
    }

    const [id, setId] = useState<string>(idDespachante);

    return (
        <Page title="Despachantes">
            <FormularioTabs
                setId={setId}
                id={id}
            ></FormularioTabs>
        </Page >
    );
}