
import { Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { DropdownSelect } from "components/DropdownSelect";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, ButtonsContainer, Container, FormContainer, InputContainer, Title } from "./style";
import { ContainerHeader } from "components/Ibox/style";
import { PrimaryButton } from "components/Button/style";
import { FaFileSignature, FaFilter } from "react-icons/fa";
import { CustomInput } from "components/CustomInput";
import { CustomDatePicker } from "components/DatePicker";
import { preenchimentoAtpvFiltrosSchema } from "../../Validate/validator";
import { useEmpresas } from "hooks/useEmpresas";
import { useState } from "react";
import { useMaskInput } from "hooks/useMaskInput";

interface IFiltrosProps {
    submitForm: (e: IFiltro) => void;
    onClickPreencherATPV: () => void;
}

export function Filtros({
    submitForm,
    onClickPreencherATPV
}: IFiltrosProps) {

    const {
        register,
        handleSubmit,
        reset,
        control,
    } = useForm({
        resolver: yupResolver(preenchimentoAtpvFiltrosSchema),
    });

    const { empresasOptions } = useEmpresas();
    const [dataInicio, setDataInicio] = useState<Date>();
    const [dataFim, setDataFim] = useState<Date>();
    const onChangeData = (dates: any) => {
        const [start, end] = dates;
        setDataInicio(start);
        setDataFim(end);
    };

    const {
        mInputPlaca,
        mInputNum,
    } = useMaskInput();

    return (
        <Container>
            <ContainerHeader>
                <Title>Filtros</Title>
                <PrimaryButton buttonWidth="xl" buttonWidthTipo="px" onClick={onClickPreencherATPV}>Preencher ATPV <FaFileSignature style={{ fontSize: "12px", marginBottom: "3px" }}></FaFileSignature></PrimaryButton>
            </ContainerHeader>
            <hr />
            <FormContainer>
                <Form noValidate onSubmit={handleSubmit((dados: IFiltro) =>
                    submitForm(dados)
                )}>
                    <Row>
                        <InputContainer>
                            <Form.Group>
                                <Form.Label>
                                    ID Solicitação
                                </Form.Label>
                                <CustomInput
                                    type="text"
                                    placeholder="Busque por ID"
                                    {...register("idSolicitacao")}
                                    style={{ height: "2.2rem" }}
                                    onChange={mInputNum}
                                />
                            </Form.Group>
                        </InputContainer>
                        <InputContainer>
                            <Form.Group>
                                <Form.Label>
                                    Placa
                                </Form.Label>
                                <CustomInput
                                    type="text"
                                    placeholder="Busque por placa"
                                    {...register("placa")}
                                    style={{ height: "2.2rem" }}
                                    onChange={mInputPlaca}
                                />
                            </Form.Group>
                        </InputContainer>
                        <InputContainer>
                            <Form.Group>
                                <Form.Label>
                                    Empresa
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name="empresa"
                                    render={({ field: { onChange, value } }) => (
                                        <DropdownSelect
                                            options={empresasOptions}
                                            title=""
                                            selectedValue={value}
                                            onSelect={onChange}
                                            searchable
                                        />
                                    )}
                                />
                            </Form.Group>
                        </InputContainer>
                        <InputContainer>
                            <Form.Group>
                                <Form.Label>
                                    Período de solicitação
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name="periodoSolicitacao"
                                    render={({ field: { onChange } }) => (
                                        <CustomDatePicker
                                            onChange={(update) => {
                                                onChange(update);
                                                onChangeData(update);
                                            }}
                                            rangeSelect={true}
                                            startDate={dataInicio}
                                            endDate={dataFim}
                                        />
                                    )}
                                />
                            </Form.Group>
                        </InputContainer>
                        <InputContainer>
                            <Form.Group>
                                <Form.Label>
                                    Status da solicitação
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name="statusSolicitacao"
                                    render={({ field: { onChange, value } }) => (
                                        <DropdownSelect
                                            options={[
                                                { value: "1", label: "Processando" },
                                                { value: "2", label: "Finalizada" },
                                                { value: "3", label: "Falha" },
                                            ]}
                                            title=""
                                            selectedValue={value}
                                            onSelect={onChange}
                                            searchable
                                        />
                                    )}
                                />
                            </Form.Group>
                        </InputContainer>
                        <InputContainer>
                            <Form.Group>
                                <ButtonsContainer>
                                    <Button type="submit"><FaFilter style={{ fontSize: "12px", marginBottom: "3px", marginRight: "5px" }} /> Filtrar</Button>
                                    <Button
                                        type="reset"
                                        outline
                                        variante="primary"
                                        onClick={() => {
                                            onChangeData([null, null])
                                            reset({
                                                idSolicitacao: null,
                                                placa: null,
                                                empresa: null,
                                                statusSolicitacao: null,
                                            });
                                        }}
                                    >Limpar</Button>
                                </ButtonsContainer>
                            </Form.Group>
                        </InputContainer>
                    </Row>
                </Form>
            </FormContainer>
        </Container>
    );
}