import { PrimaryButton } from "components/Button/style";
import { Row } from "react-bootstrap";
import styled from "styled-components";

export const Body = styled.div`
  padding: 0px 24px;
`

export const InputsContainer = styled(Row)`
  div {
    align-items: center;
  }

  input {
    accent-color: ${({ theme }) => theme.colors.project.main};
    margin-right: 1rem;
    width: 1rem;
    height: 1rem;
  }
`;

export const FormContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.neutral.neutral00};
  padding: 24px;
  border-radius: 8px;
`;

export const TextoSecundario = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.neutral.neutral40};
`;

export const Button = styled(PrimaryButton)`
  width: 100%;
`;

export const ButtonsContainer = styled.div`
  position: relative;
  padding-top: 2rem;
  display: flex;
  justify-content: space-between;
`;