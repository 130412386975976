import { Page } from "containers/Page";
import { useEffect, useState } from "react";
import { Filtros } from "./containers/Filtros";
import { usePreencherDetalhesATPV } from "./hooks/usePreencherDetalhesATPV";
import { ListarDetalhes } from "./containers/Listar";
import { LoadingScreen } from "components/LoadingScreen";
import { ModalSucessoConsultarNovamente } from "./containers/ModalSucessoConsultarNovamente";
import { alertaErro } from "components/Alerta";

export function PreenchimentoATPVDetalhes() {
    const {
        buscarDados,
        dados,
        loading,
        setLimit,
        setPage,
        limit,
        page,
        queryParams,
        gerarQueryParams,
        totalRows,
        downloadATPV,
        buscarDadosResumo,
        dadosReumo,
        consultarNovamente,
        showModalConsultarNovamenteSucesso,
        setShowModalConsultarNovamenteSucesso,
        onClickProcesso,
        setId,
        id,
        forceUpdate,
        buscarDadosExcel,
        dadosExcel,
    } = usePreencherDetalhesATPV();
    useEffect(() => {
        window.location.href.split("?")[1].split("&").forEach((item) => {
            if (item.includes("id")) {
                setId(item.split("=")[1]);
            }
        })
    }, [setId]);
    useEffect(() => {
        if (id) {
            buscarDados();
            buscarDadosResumo();
        }
    }, [id, limit, page, queryParams, forceUpdate, buscarDados, buscarDadosResumo]);
    async function handleSubmitFiltros(data: IFiltro) {
        gerarQueryParams(data);
    }

    const handlePageChange = (page: number) => {
        setPage(page);
    }

    const handleLimitChange = (limit: number) => {
        setLimit(limit);
    }

    const handleDownloadATPV = (id: string | number, placa: string) => {
        downloadATPV(id, placa);
    }

    const [nenhumaConsultaComFalha, setNenhumaConsultaComFalha] = useState<boolean>(false);
    const handleConsultarNovamente = async () => {
        if (id) {
            const { data, hasErro } = await consultarNovamente(id);
            if (hasErro) {
                if (data.message === "ATPVs não encontrados") {
                    setNenhumaConsultaComFalha(true);
                    setShowModalConsultarNovamenteSucesso(true);
                    return;
                }
                setNenhumaConsultaComFalha(false);
                alertaErro("Ocorreu um erro", data.message);
                return;
            }
            setShowModalConsultarNovamenteSucesso(true);
            buscarDados();
        }
    }
    const handleCloseModalSucesso = () => {
        setShowModalConsultarNovamenteSucesso(false);
    }
    const handleExport = () => {
        buscarDadosExcel();
    }
    return (
        <Page title="Preenchimento ATPV" redirectDoIcone="/backoffice/preenchimentoatpv">
            <LoadingScreen mostrar={loading} />
            <Filtros
                submitForm={handleSubmitFiltros}
            ></Filtros>
            <ListarDetalhes
                loading={loading}
                dados={dados}
                handleLimitChange={handleLimitChange}
                handlePageChange={handlePageChange}
                totalRows={totalRows}
                handleDownload={handleDownloadATPV}
                dadosResumo={dadosReumo}
                idSolicitacao={id}
                handleConsultarNovamente={handleConsultarNovamente}
                onClickProcesso={onClickProcesso}
                handleExport={handleExport}
                dadosExcel={dadosExcel}
            ></ListarDetalhes>
            <ModalSucessoConsultarNovamente
                show={showModalConsultarNovamenteSucesso}
                handleClose={handleCloseModalSucesso}
                nenhumaConsultaComFalha={nenhumaConsultaComFalha}
            ></ModalSucessoConsultarNovamente>
        </Page>
    );
}