import styled from "styled-components";

const SearchContainer = styled.div`
  input[type="search"] {
    background: #fff;
    color: #222;
  }

  
  input[type="search"]:focus::-webkit-search-cancel-button {
    opacity: 0.3;
    pointer-events: all;
  }

  input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
    height: 1em;
    width: 1em;
    border-radius: 50em;
    background: url(https://pro.fontawesome.com/releases/v5.10.0/svgs/solid/times-circle.svg)
      no-repeat 50% 50%;
    background-size: contain;
    opacity: 0;
    pointer-events: none;
  }
`;
export { SearchContainer };
