import styled from "styled-components";

export const Body = styled.div`
  padding: 0px 24px;
`

export const TextoContainer = styled.div`
    text-align: center;
    margin-top: 12px;
    margin-bottom: 24px;
`