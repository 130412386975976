import { Col, Row } from "react-bootstrap";
import { BodyDadoResumo, ContainerBackground, TituloDadoResumo } from "./style";
import { IDadosResumoDetalhesSolicitacaoATPV } from "../../interfaces/IDadosResumoDetalhesSolicitacaoATPV";
import { converterDataHoraBr } from "_utils/Data";

interface IResumoDetalhesSolicitacaoATPV {
    dadosResumo: IDadosResumoDetalhesSolicitacaoATPV;
}

export function ResumoDetalhesSolicitacaoATPV({
    dadosResumo,
}: IResumoDetalhesSolicitacaoATPV) {

    return (
        <ContainerBackground>
            <Row>
                <Col>
                    <TituloDadoResumo>Empresa</TituloDadoResumo>
                    <BodyDadoResumo>{dadosResumo.empresa ? dadosResumo.empresa : "-"}</BodyDadoResumo>
                </Col>
                <Col>
                    <TituloDadoResumo>Divisão</TituloDadoResumo>
                    <BodyDadoResumo>{dadosResumo.divisao ? dadosResumo.divisao : "-"}</BodyDadoResumo>
                </Col>
                <Col>
                    <TituloDadoResumo>Usuário</TituloDadoResumo>
                    <BodyDadoResumo>{dadosResumo.usuario ? dadosResumo.usuario : "-"}</BodyDadoResumo>
                </Col>
                <Col>
                    <TituloDadoResumo>ID Solicitação</TituloDadoResumo>
                    <BodyDadoResumo>{dadosResumo.idSolicitacao ? dadosResumo.idSolicitacao : "-"}</BodyDadoResumo>
                </Col>
                <Col>
                    <TituloDadoResumo>Data/hora solicitação</TituloDadoResumo>
                    <BodyDadoResumo>{dadosResumo.dataHoraSolicitacao ? converterDataHoraBr(dadosResumo.dataHoraSolicitacao) : "-"}</BodyDadoResumo>
                </Col>
                <Col>
                    <TituloDadoResumo>Qtd de placas</TituloDadoResumo>
                    <BodyDadoResumo>{dadosResumo.qtdPlacas ? dadosResumo.qtdPlacas : "-"}</BodyDadoResumo>
                </Col>
                <Col>
                    <TituloDadoResumo>Qtd emitida</TituloDadoResumo>
                    <BodyDadoResumo>{dadosResumo.qtdEmitidas ? dadosResumo.qtdEmitidas : "-"}</BodyDadoResumo>
                </Col>
            </Row>
        </ContainerBackground>
    );
}
