import * as yup from "yup";

export const cadastrarProcessoLoteSchema = yup.object().shape({
  empresa: yup.number().required("Selecione a empresa"),
  divisao: yup.number().required("Selecione a divisão"),
  despachante: yup.number().required("Selecione o despachante"),
  servico: yup.number().required("Selecione o serviço"),
  arquivo: yup
    .mixed()
    .test("file-selected", "Selecione um arquivo", (value) => {
      return value instanceof File && value.size > 0;
    })
    .required("Envie o arquivo"),
});

