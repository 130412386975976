
import { Form, Row } from "react-bootstrap";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { DropdownSelect } from "components/DropdownSelect";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, ButtonsContainer, Container, FormContainer, InputContainer, Title } from "./style";
import { ContainerHeader } from "components/Ibox/style";
import { PrimaryButton } from "components/Button/style";
import { FaPlus, FaSearch } from "react-icons/fa";
import { CustomInput } from "components/CustomInput";
import { despachanteFiltrosSchema } from "features/core/pages/Cadastro/Validate/validator";
import { IOptions } from "interfaces/IOptions";
import { Link } from "react-router-dom";
import { useMaskInput } from "hooks/useMaskInput";

interface IDespachanteFiltrosProps {
    onChangeUf: (e: any) => void;
    uf: string;
    optionsMunicipios: IOptions[];
    onChangeMunicipio: (e: any) => void;
    municipio: string;
    submitForm: (e: any) => void;
    disabledMunicipio: boolean;
}

export function DespachanteFiltros({
    onChangeUf,
    uf,
    optionsMunicipios,
    onChangeMunicipio,
    municipio,
    submitForm,
    disabledMunicipio,
}: IDespachanteFiltrosProps) {

    const {
        register,
        handleSubmit,
        reset,
        setError,
        control,
        formState: { errors, isSubmitted },
    } = useForm({
        resolver: yupResolver(despachanteFiltrosSchema),
    });

    const { mInputCNPJ } = useMaskInput();

    return (
        <Container>
            <ContainerHeader>
                <Title>Filtros</Title>
                <Link to="/cadastro/despachante/cadastro">
                    <PrimaryButton buttonWidth="xl" buttonWidthTipo="px">Cadastrar Despachante <FaPlus></FaPlus></PrimaryButton>
                </Link>
            </ContainerHeader>
            <hr />
            <FormContainer>
                <Form noValidate onSubmit={handleSubmit(submitForm)}>
                    <Row>
                        <InputContainer>
                            <Form.Group>
                                <Form.Label style={{ marginBottom: "0.8rem" }}>
                                    Despachante
                                </Form.Label>
                                <CustomInput
                                    type="text"
                                    placeholder="Busque por despachante"
                                    {...register("despachante")}
                                    style={{ height: "2.2rem" }}
                                    iconeDireito={<FaSearch></FaSearch>}
                                />
                            </Form.Group>
                        </InputContainer>
                        <InputContainer>
                            <Form.Group>
                                <Form.Label style={{ marginBottom: "0.8rem" }}>
                                    CNPJ
                                </Form.Label>
                                <CustomInput
                                    type="text"
                                    placeholder="Busque por CNPJ"
                                    {...register("cnpj")}
                                    style={{ height: "2.2rem" }}
                                    iconeDireito={<FaSearch></FaSearch>}
                                    onChange={(event) => mInputCNPJ(event)}
                                />
                            </Form.Group>
                        </InputContainer>
                        <InputContainer>
                            <Form.Group>
                                <Form.Label>
                                    UF de atuação
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name="uf"
                                    render={() => (
                                        <DropdownSelect
                                            options={[
                                                { value: "AC", label: "AC" },
                                                { value: "AL", label: "AL" },
                                                { value: "AP", label: "AP" },
                                                { value: "AM", label: "AM" },
                                                { value: "BA", label: "BA" },
                                                { value: "CE", label: "CE" },
                                                { value: "DF", label: "DF" },
                                                { value: "ES", label: "ES" },
                                                { value: "GO", label: "GO" },
                                                { value: "MA", label: "MA" },
                                                { value: "MT", label: "MT" },
                                                { value: "MS", label: "MS" },
                                                { value: "MG", label: "MG" },
                                                { value: "PA", label: "PA" },
                                                { value: "PB", label: "PB" },
                                                { value: "PR", label: "PR" },
                                                { value: "PE", label: "PE" },
                                                { value: "PI", label: "PI" },
                                                { value: "RJ", label: "RJ" },
                                                { value: "RN", label: "RN" },
                                                { value: "RS", label: "RS" },
                                                { value: "RO", label: "RO" },
                                                { value: "RR", label: "RR" },
                                                { value: "SC", label: "SC" },
                                                { value: "SP", label: "SP" },
                                                { value: "SE", label: "SE" },
                                                { value: "TO", label: "TO" }
                                            ]}
                                            title=""
                                            selectedValue={uf}
                                            onSelect={onChangeUf}
                                            searchable
                                        />
                                    )}
                                />

                            </Form.Group>
                        </InputContainer>
                        <InputContainer>
                            <Form.Group>
                                <Form.Label>
                                    Município de atuação
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name="municipio"
                                    render={() => (
                                        <DropdownSelect
                                            options={optionsMunicipios}
                                            title=""
                                            selectedValue={municipio}
                                            onSelect={onChangeMunicipio}
                                            searchable
                                            disabled={disabledMunicipio}
                                        />
                                    )}
                                />
                            </Form.Group>
                        </InputContainer>
                        <InputContainer>
                            <Form.Group>
                                <ButtonsContainer>
                                    <Button type="submit">Buscar</Button>
                                </ButtonsContainer>
                            </Form.Group>
                        </InputContainer>
                    </Row>
                </Form>
            </FormContainer>
        </Container>
    );
}