import styled from "styled-components";

export const MenuSpan = styled.span`
  height: 60px;
  margin-left: 1rem;
`

export const ItemCollapse = styled.a`
  padding: 22px 20px 22px 25px !important;
  height: 60px;
`

export const SubMenu = styled.ul`
`

export const Arrow = styled.span`
  float: right;
  line-height: 1.42857;
`

export const LinkMenu = styled.a`
  height: 60px;
  display: flex !important;
  align-items: center !important;
  gap: 1rem;
  font-size: 1rem;
`;