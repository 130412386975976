import styled from "styled-components";
import backgroundImage from "../../../../_assets/images/bg_login2.jpg"
import logoGrupoLw from "../../../../_assets/images/logo_grupo_lw_branco.png";
import logoDoc from "../../../../_assets/images/logo_lw_vermelho.png";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 64px 128px 64px 5%;
  margin: 0;
  width: 100%;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.white};
  background-image: url(${backgroundImage});
  background-size: 54% 100vh;
  background-repeat: no-repeat;
  background-position: right;
`;

export const ImgLogoDoc = styled.img.attrs({
  src: logoDoc,
})`
  height: 40px;
  width: 117.04px;
  margin-bottom: 48px;
`;

export const LoginBoxForm = styled.div`
  width: 28%;
`;

export const LoginContainer = styled.div`
  font-size: 1rem;
`;

export const ImgLogoGrupoLw = styled.img.attrs({
    src: logoGrupoLw,
})`
  position: absolute;
  bottom: 64px;
  right: 80px;
`;
