import { AxiosRequest } from "_services/api";
import { HttpError } from "_services/api/HttpError";
import { HttpResponse } from "_services/api/HttpResponse";
import { IData, IHttpError, IHttpResponse } from "_services/api/interface/HttpResponse";
import { IEtapaServicoModelo } from "features/core/interfaces/IEtapaServicoModelo";

interface ICadastarServicoModeloDespachante {
    servicoModelo: string;
    etapas: IEtapaServicoModelo[];
    ufs: string[];
}

interface IAtualizarServicoModeloDespachante {
    idServicoModelo: string;
    nome: string;
    uf: string;
}

interface IAtualizarEtapasServicoModeloDespachante {
    idServicoModelo: string;
    etapas: IEtapaServicoModelo[]
}

export function useServicoModeloDespachante() {
    const api = AxiosRequest.makeApi();

    const cadastrarServicoDespachante = async ({
        servicoModelo,
        etapas,
        ufs
    }: ICadastarServicoModeloDespachante) => {
        try {
            const { status, data } = await api.post(`/empresa/servico_modelo/despachantes`, {
                nome: servicoModelo,
                etapas,
                ufs,
            });
            const response = new HttpResponse(false, status, data);
            return response;
        } catch (error: IHttpError<IData, any> | any) {
            const response = new HttpError<IData>(true, error);
            return response;
        }
    };

    async function buscarServicoModelo(idServicoModelo: string) {
        try {
            const { status, data } = await api.get(`/empresa/servico_modelo/empresa?idServicoModelo=${idServicoModelo}`);
            const rersponse = new HttpResponse(false, status, data);
            return rersponse;
        } catch (error: IHttpError<IData, any> | any) {
            const response = new HttpError<IData>(true, error);
            return response;
        }
    }

    async function buscarEtapasServicoModelo(idServicoModelo: string) {
        try {
            const { status, data } = await api.get(`/empresa/servico_modelo/${idServicoModelo}`);
            const rersponse = new HttpResponse(false, status, data);
            return rersponse;
        } catch (error: IHttpError<IData, any> | any) {
            const response = new HttpError<IData>(true, error);
            return response;
        }
    }

    async function atualizarServicoModelo({
        idServicoModelo,
        nome,
        uf
    }: IAtualizarServicoModeloDespachante) {
        try {
            const { status, data } = await api.patch(`/empresa/servico_modelo/${idServicoModelo}/${nome}`, { uf });
            const rersponse = new HttpResponse(false, status, data);
            return rersponse;
        } catch (error: IHttpError<IData, any> | any) {
            const response = new HttpError<IData>(true, error);
            return response;
        }
    }

    async function excluirServicoModelo(idServicoModelo: string) {
        try {
            const { status, data } = await api.delete(`/empresa/servico_modelo/${idServicoModelo}`);
            const rersponse = new HttpResponse(false, status, data);
            return rersponse;
        } catch (error: IHttpError<IData, any> | any) {
            const response = new HttpError<IData>(true, error);
            return response;
        }
    }

    async function atualizarEtapasServicoModelo({
        idServicoModelo,
        etapas,
    }: IAtualizarEtapasServicoModeloDespachante) {
        try {
            const { status, data } = await api.patch(`/empresa/etapa_modelo/servico_modelo/${idServicoModelo}`, { etapas });
            const rersponse = new HttpResponse(false, status, data);
            return rersponse;
        } catch (error: IHttpError<IData, any> | any) {
            const response = new HttpError<IData>(true, error);
            return response;
        }
    }

    return {
        cadastrarServicoDespachante,
        buscarServicoModelo,
        buscarEtapasServicoModelo,
        atualizarServicoModelo,
        excluirServicoModelo,
        atualizarEtapasServicoModelo,
    };
}
