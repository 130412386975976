import { Page } from "containers/Page";
import { DespachanteFiltros } from "./containers/DespachanteFiltros";
import { DespachanteListar } from "./containers/DespachanteListar";
import { useMunicipios } from "hooks/useMunicipios";
import { useEffect, useState } from "react";
import { ModalExcluirDespachante } from "./containers/ModalExcluirDespachante";
import { useDespachantes } from "../../Hooks/useDespachantes";
import { alertaErro, alertaSucesso } from "components/Alerta";
import { LoadingScreen } from "components/LoadingScreen";

export function Despachante() {
    const {
        totalRows,
        listarDespachantes,
        listaDespachantes,
        excluirDespachante,
        loading,
        ultimoFiltro,
        setPage,
        setLimit,
        page,
        limit,
    } = useDespachantes();

    useEffect(() => {
        listarDespachantes({
            pageDespachante: page,
            limitDespachante: limit,
            cnpj: ultimoFiltro?.cnpj,
            uf: ultimoFiltro?.uf,
            municipio: ultimoFiltro?.municipio,
            despachante: ultimoFiltro?.despachante
        });
    }, [, page, limit]);

    const submitForm = (dados: any) => {
        listarDespachantes({
            uf,
            municipio,
            pageDespachante: page,
            limitDespachante: limit,
            ...dados
        })
    }

    const { municipios, listarMunicipios, criarOptionsMunicipios, optionsMunicipios } = useMunicipios();
    const [uf, setUf] = useState<string>("");
    const onChangeUf = (uf: string) => {
        setUf(uf);
    }
    useEffect(() => {
        if (uf) {
            setDisabledMunicipio(false);
            listarMunicipios(uf);
            return;
        }
        setDisabledMunicipio(true);
        listarMunicipios("")
    }, [uf]);

    useEffect(() => {
        criarOptionsMunicipios(municipios)
    }, [municipios]);

    const [municipio, setMunicipio] = useState<string>("");
    const onChangeMunicipio = (municipio: string) => {
        setMunicipio(municipio);
    }

    const [despachanteExcluir, setDespachanteExcluir] = useState<string>("");
    const [showModalDespachanteExcluir, setShowModalDespachanteExcluir] = useState<boolean>(false);
    const handleExcluir = (id: any, qtdProcessos: any) => {
        if (qtdProcessos) {
            alertaErro("Exclusão de Despachante", "Não foi possível realizar a exclusão. Existem processos cadastrados para este despachante.");
            return;
        }

        if (id == "0") {
            alertaErro("Exclusão de Despachante", "Não é possível excluir este despachante.");
            return;
        }
        setDespachanteExcluir(id)
        setShowModalDespachanteExcluir(true)
    }
    const handleCloseModalDespachanteExcluir = () => {
        setDespachanteExcluir("")
        setShowModalDespachanteExcluir(false)
    }
    const onConfirmModalDespachanteExcluir = async () => {
        if (despachanteExcluir) {
            const { hasErro } = await excluirDespachante(despachanteExcluir);
            if (hasErro) {
                alertaErro("Ocorreu um erro", "Erro ao tentar excluir despachante.");
                setDespachanteExcluir("")
                setShowModalDespachanteExcluir(false)
                return;
            }

            alertaSucesso("Sucesso", "Despachante excluído com sucesso!");
        }
        setDespachanteExcluir("")
        setShowModalDespachanteExcluir(false)
        if (ultimoFiltro) {
            listarDespachantes(ultimoFiltro);
            return;
        }
        listarDespachantes({ pageDespachante: page, limitDespachante: limit });
    }

    const [disabledMunicipio, setDisabledMunicipio] = useState<boolean>(false);

    function handleLimitChange(limit: number) {
        setLimit(limit);
    }

    function handlePageChange(page: number) {
        setPage(page);
    }

    return (
        <Page title="Despachantes">
            <LoadingScreen mostrar={loading} />
            <DespachanteFiltros
                onChangeUf={onChangeUf}
                uf={uf}
                optionsMunicipios={optionsMunicipios}
                onChangeMunicipio={onChangeMunicipio}
                municipio={municipio}
                submitForm={submitForm}
                disabledMunicipio={disabledMunicipio}
            ></DespachanteFiltros>
            <DespachanteListar
                dados={listaDespachantes}
                loading={false}
                totalRows={totalRows}
                onChangeRowsPerPage={handleLimitChange}
                onChangePage={handlePageChange}
                show={true}
                handleExcluir={handleExcluir}
            ></DespachanteListar>
            <ModalExcluirDespachante
                show={showModalDespachanteExcluir}
                handleClose={handleCloseModalDespachanteExcluir}
                onConfirm={onConfirmModalDespachanteExcluir}
            ></ModalExcluirDespachante>
        </Page>
    );
}