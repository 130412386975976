import { Page } from "containers/Page";
import { useMunicipios } from "hooks/useMunicipios";
import { useEffect, useState } from "react";
import { alertaErro, alertaSucesso } from "components/Alerta";
import { LoadingScreen } from "components/LoadingScreen";
import { Formulario } from "./containers/Formulario";
import useScripts from "../../Hooks/useScripts";
import { FormularioMigrar } from "./containers/FormularioMigrar";

export function ClonarServicoModelo() {

    const [loading, setLoading] = useState<boolean>(false);
    const [file, setFile] = useState<File>();
    const [fileMigrar, setFileMigrar] = useState<File>();
    const { clonarServicosModelo, migrarServicos } = useScripts();

    const onChangeArquivo = (file: any) => {
        setFile(file);
    }

    const onClickExecutar = async () => {
        if (!file) {
            alertaErro("Erro", "Arquivo é necessário")
            return;
        }

        setLoading(true);
        const { hasErro, data } = await clonarServicosModelo(file)
        setLoading(false);
        if (hasErro) {
            alertaErro("Erro", data.message)
            return;
        }
        alertaSucesso("Sucesso", "Serviços modelo clonados com sucesso!")
    }

    const onChangeArquivoMigrar = (file: any) => {
        setFileMigrar(file);
    }

    const onClickMigrar = async () => {
        if (!fileMigrar) {
            alertaErro("Erro", "Arquivo é necessário")
            return;
        }

        setLoading(true);
        const { hasErro, data } = await migrarServicos(fileMigrar)
        setLoading(false);
        if (hasErro) {
            alertaErro("Erro", data.message)
            return;
        }
        alertaSucesso("Sucesso", "Serviços migrados com sucesso!")
    }

    return (
        <Page title="Clonar Serviços Modelo">
            <LoadingScreen mostrar={loading} />
            <Formulario
                onChangeArquivo={onChangeArquivo}
                onClickExecutar={onClickExecutar}
            />
            <FormularioMigrar
                onChangeArquivo={onChangeArquivoMigrar}
                onClickMigrar={onClickMigrar}
            />
        </Page>
    );
}