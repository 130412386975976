import { Controller, useForm } from "react-hook-form";
import { Background, ButtonContainer, FormContainer, TituloSecao } from "./style";
import { Button, Col, Form, Row } from "react-bootstrap";
import { DropdownSelect } from "components/DropdownSelect";
import { useEmpresas } from "hooks/useEmpresas";
import { yupResolver } from "@hookform/resolvers/yup";
import { modalPreencherATPVFormularioUnitarioSchema } from "../../Validate/validator";
import { useEffect, useState } from "react";
import { Required } from "components/Required";
import { CustomInput } from "components/CustomInput";
import { useDivisoes } from "hooks/useDivisoes";
import { useMaskInput } from "hooks/useMaskInput";
import { LoadingScreen } from "components/LoadingScreen";
import { useCep } from "hooks/useCep";
import { Tooltip } from "components/Tooltip";
import { Question } from "components/Icons/Question";
import { Informacao } from "components/Informacao";
import { PrimaryButton } from "components/Button/style";

interface IModalPreencherATPVFormularioUnitarioProps {
    submitForm: (dados: IPreencherATPVUnitario) => void;
}

export function ModalPreencherATPVFormularioUnitario({
    submitForm
}: IModalPreencherATPVFormularioUnitarioProps) {

    const { empresasOptions } = useEmpresas();
    const [idEmpresaEscolhida, setIdEmpresaEscolhida] = useState<number>(0);
    const [desabilitarDivisao, setDesabilitarDivisao] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const { divisoesOptions } = useDivisoes(idEmpresaEscolhida);
    const onChangeEmpresaEscolhida = (empresa: number | null) => {
        if (empresa) {
            setIdEmpresaEscolhida(empresa);
            setDesabilitarDivisao(false);
            return;
        }
        setDesabilitarDivisao(true);
    }
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        control,
        formState: { errors, isSubmitted },
    } = useForm({
        resolver: yupResolver(modalPreencherATPVFormularioUnitarioSchema),
    });
    const { obterDadosCep, dadosLocal } = useCep();
    const onChangeCep = async (element: any) => {
        mInputCEP(element)
        let cep = element.target.value
        const qtdCarateresCep = 9;
        if (cep.length < qtdCarateresCep) {
            return;
        }
        setLoading(true)
        await obterDadosCep(cep);
        setLoading(false)
    }

    useEffect(() => {
        if (!dadosLocal) {
            return;
        }
        if (dadosLocal.logradouro) {
            setValue("logradouroComprador", dadosLocal.logradouro)
            setError("logradouroComprador", { type: "", message: "" })
        }

        if (dadosLocal.localidade) {
            setValue("cidadeComprador", dadosLocal.localidade)
            setError("cidadeComprador", { type: "", message: "" })
        }
        if (dadosLocal.uf) {
            setValue("ufComprador", dadosLocal.uf)
            setError("ufComprador", { type: "", message: "" })
        }
        if (dadosLocal.bairro) {
            setValue("bairroComprador", dadosLocal.bairro)
            setError("bairroComprador", { type: "", message: "" })
        }
    }, [dadosLocal, setValue, setError]);

    const {
        mInputCEP,
        mInputPlaca,
        mInputCPFCNPJ,
        mInputRenavam,
        mInputCoin2,
        mInputNum,
        mInputChassi,
    } = useMaskInput();

    return (
        <FormContainer>
            <LoadingScreen mostrar={loading} />
            <Form noValidate onSubmit={handleSubmit((dados: IPreencherATPVUnitario) => submitForm(dados))}>
                <Row style={{ height: "6rem" }}>
                    <Col>
                        <Form.Group>
                            <Form.Label>
                                Empresa <Required />
                            </Form.Label>
                            <Controller
                                control={control}
                                name="empresa"
                                render={({ field: { onChange, value } }) => (
                                    <DropdownSelect
                                        options={empresasOptions}
                                        title=""
                                        selectedValue={value}
                                        onSelect={(value) => {
                                            onChange(value)
                                            onChangeEmpresaEscolhida(value)
                                        }}
                                        searchable
                                        mensagemErro={
                                            isSubmitted ? (errors.empresa?.message as any) : null
                                        }
                                        gapDropdownContainer="0"
                                    />
                                )}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label>
                                Divisão <Required />
                            </Form.Label>
                            <Controller
                                control={control}
                                name="divisao"
                                render={({ field: { onChange, value } }) => (
                                    <DropdownSelect
                                        options={divisoesOptions}
                                        title=""
                                        selectedValue={value}
                                        onSelect={onChange}
                                        searchable
                                        disabled={desabilitarDivisao}
                                        mensagemErro={
                                            isSubmitted ? (errors.divisao?.message as any) : null
                                        }
                                        gapDropdownContainer="0"
                                    />
                                )}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg="2">
                        <Form.Group>
                            <Form.Label>
                                UF <Required />{" "}
                                <Tooltip trigger={<Question />}>
                                    <Informacao
                                        type="preto"
                                        typeTexto="preto"
                                        title={true}
                                        showBotaoFechar={false}
                                        mensagem={"UF de emplacamento"}
                                        style={{
                                            width: "150px",
                                            fontSize: "10px",
                                            position: "absolute",
                                            left: "34rem",
                                            top: "-2rem",
                                        }}
                                    />
                                </Tooltip>
                            </Form.Label>
                            <Controller
                                control={control}
                                name="uf"
                                render={({ field: { onChange, value } }) => (
                                    <DropdownSelect
                                        options={[
                                            { value: "PR", label: "PR" },
                                        ]}
                                        title=""
                                        selectedValue={value}
                                        onSelect={onChange}
                                        searchable
                                        mensagemErro={
                                            isSubmitted ? (errors.uf?.message as any) : null
                                        }
                                        gapDropdownContainer="0"
                                    />
                                )}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Background>
                    <TituloSecao>Dados do veículo</TituloSecao>
                    <Row style={{ height: "4.4rem" }}>
                        <Col>
                            <Form.Group>
                                <Form.Label>
                                    Placa <Required />
                                </Form.Label>
                                <CustomInput
                                    type="text"
                                    placeholder="Informe a placa"
                                    {...register("placa")}
                                    style={{ height: "2.2rem" }}
                                    onChange={(element) => { mInputPlaca(element) }}
                                    mensagemErro={
                                        isSubmitted ? (errors.placa?.message as any) : null
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>
                                    Chassi <Required />
                                </Form.Label>
                                <CustomInput
                                    type="text"
                                    placeholder="Informe o chassi"
                                    {...register("chassi")}
                                    style={{ height: "2.2rem" }}
                                    onChange={(element) => { mInputChassi(element) }}
                                    mensagemErro={
                                        isSubmitted ? (errors.chassi?.message as any) : null
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>
                                    Renavam <Required />
                                </Form.Label>
                                <CustomInput
                                    type="text"
                                    placeholder="Informe o renavam"
                                    {...register("renavam")}
                                    style={{ height: "2.2rem" }}
                                    onChange={(element) => { mInputRenavam(element) }}
                                    mensagemErro={
                                        isSubmitted ? (errors.renavam?.message as any) : null
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>
                                    Odômetro <Required />
                                </Form.Label>
                                <CustomInput
                                    type="text"
                                    placeholder="Informe o odômetro"
                                    {...register("odometro")}
                                    style={{ height: "2.2rem" }}
                                    onChange={(element) => { mInputNum(element) }}
                                    mensagemErro={
                                        isSubmitted ? (errors.odometro?.message as any) : null
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>
                                    Valor de venda <Required />
                                </Form.Label>
                                <CustomInput
                                    type="text"
                                    placeholder="R$ 00,00"
                                    {...register("valorVenda")}
                                    style={{ height: "2.2rem" }}
                                    onChange={(element) => { mInputCoin2(element) }}
                                    mensagemErro={
                                        isSubmitted ? (errors.valorVenda?.message as any) : null
                                    }
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Background>
                <Background>
                    <TituloSecao>Dados do vendedor</TituloSecao>
                    <Row style={{ height: "4.4rem" }}>
                        <Col>
                            <Form.Group>
                                <Form.Label>
                                    CPF / CNPJ <Required />
                                </Form.Label>
                                <CustomInput
                                    type="text"
                                    placeholder="Informe o CPF ou CNPJ"
                                    {...register("cpfCnpjVendedor")}
                                    style={{ height: "2.2rem" }}
                                    onChange={(element) => { mInputCPFCNPJ(element) }}
                                    mensagemErro={
                                        isSubmitted ? (errors.cpfCnpjVendedor?.message as any) : null
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>
                                    E-mail <Required />
                                </Form.Label>
                                <CustomInput
                                    type="text"
                                    placeholder="Informe o e-mail"
                                    {...register("emailVendedor")}
                                    style={{ height: "2.2rem" }}
                                    onChange={() => { }}
                                    mensagemErro={
                                        isSubmitted ? (errors.emailVendedor?.message as any) : null
                                    }
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Background>
                <Background>
                    <TituloSecao>Dados do comprador</TituloSecao>
                    <Row style={{ height: "5.5rem" }}>
                        <Col>
                            <Form.Group>
                                <Form.Label>
                                    CPF / CNPJ <Required />
                                </Form.Label>
                                <CustomInput
                                    type="text"
                                    placeholder="Informe o CPF ou CNPJ"
                                    {...register("cpfCnpjComprador")}
                                    style={{ height: "2.2rem" }}
                                    onChange={(element) => { mInputCPFCNPJ(element) }}
                                    mensagemErro={
                                        isSubmitted ? (errors.cpfCnpjComprador?.message as any) : null
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>
                                    Nome <Required />
                                </Form.Label>
                                <CustomInput
                                    type="text"
                                    placeholder="Informe o nome"
                                    {...register("nomeComprador")}
                                    style={{ height: "2.2rem" }}
                                    onChange={() => { }}
                                    mensagemErro={
                                        isSubmitted ? (errors.nomeComprador?.message as any) : null
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>
                                    E-mail <Required />
                                </Form.Label>
                                <CustomInput
                                    type="text"
                                    placeholder="Informe o e-mail"
                                    {...register("emailComprador")}
                                    style={{ height: "2.2rem" }}
                                    onChange={() => { }}
                                    mensagemErro={
                                        isSubmitted ? (errors.emailComprador?.message as any) : null
                                    }
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <div style={{ marginBottom: "16px" }}>Endereço</div>
                    <Row style={{ height: "5.5rem" }}>
                        <Col lg="3">
                            <Form.Group>
                                <Form.Label>
                                    CEP <Required />
                                </Form.Label>
                                <CustomInput
                                    type="text"
                                    placeholder="informe o CEP"
                                    {...register("cepComprador")}
                                    style={{ height: "2.2rem" }}
                                    onChange={onChangeCep}
                                    mensagemErro={
                                        isSubmitted ? (errors.cepComprador?.message as any) : null
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>
                                    Logradouro <Required />
                                </Form.Label>
                                <CustomInput
                                    type="text"
                                    placeholder="Informe o logradouro"
                                    {...register("logradouroComprador")}
                                    style={{ height: "2.2rem" }}
                                    onChange={() => { }}
                                    mensagemErro={
                                        isSubmitted ? (errors.logradouroComprador?.message as any) : null
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col lg="3">
                            <Form.Group>
                                <Form.Label>
                                    Número <Required />
                                </Form.Label>
                                <CustomInput
                                    type="text"
                                    placeholder="Informe o número"
                                    {...register("numeroComprador")}
                                    style={{ height: "2.2rem" }}
                                    onChange={() => { }}
                                    mensagemErro={
                                        isSubmitted ? (errors.numeroComprador?.message as any) : null
                                    }
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row style={{ height: "4.4rem" }}>
                        <Col>
                            <Form.Group>
                                <Form.Label>
                                    Bairro <Required />
                                </Form.Label>
                                <CustomInput
                                    type="text"
                                    placeholder="Informe o bairro"
                                    {...register("bairroComprador")}
                                    style={{ height: "2.2rem" }}
                                    onChange={() => { }}
                                    mensagemErro={
                                        isSubmitted ? (errors.bairroComprador?.message as any) : null
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>
                                    Complemento
                                </Form.Label>
                                <CustomInput
                                    type="text"
                                    placeholder="Informe o complemento"
                                    {...register("complementoComprador")}
                                    style={{ height: "2.2rem" }}
                                    onChange={() => { }}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>
                                    Cidade <Required />
                                </Form.Label>
                                <CustomInput
                                    type="text"
                                    placeholder="Informe a cidade"
                                    {...register("cidadeComprador")}
                                    style={{ height: "2.2rem" }}
                                    onChange={() => { }}
                                    mensagemErro={
                                        isSubmitted ? (errors.cidadeComprador?.message as any) : null
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>
                                    UF <Required />
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name="ufComprador"
                                    render={({ field: { onChange, value } }) => (
                                        <DropdownSelect
                                            options={[
                                                { value: "AC", label: "AC" },
                                                { value: "AL", label: "AL" },
                                                { value: "AP", label: "AP" },
                                                { value: "AM", label: "AM" },
                                                { value: "BA", label: "BA" },
                                                { value: "CE", label: "CE" },
                                                { value: "DF", label: "DF" },
                                                { value: "ES", label: "ES" },
                                                { value: "GO", label: "GO" },
                                                { value: "MA", label: "MA" },
                                                { value: "MT", label: "MT" },
                                                { value: "MS", label: "MS" },
                                                { value: "MG", label: "MG" },
                                                { value: "PA", label: "PA" },
                                                { value: "PB", label: "PB" },
                                                { value: "PR", label: "PR" },
                                                { value: "PE", label: "PE" },
                                                { value: "PI", label: "PI" },
                                                { value: "RJ", label: "RJ" },
                                                { value: "RN", label: "RN" },
                                                { value: "RS", label: "RS" },
                                                { value: "RO", label: "RO" },
                                                { value: "RR", label: "RR" },
                                                { value: "SC", label: "SC" },
                                                { value: "SP", label: "SP" },
                                                { value: "SE", label: "SE" },
                                                { value: "TO", label: "TO" }
                                            ]}
                                            title=""
                                            selectedValue={value}
                                            onSelect={onChange}
                                            searchable
                                            mensagemErro={
                                                isSubmitted ? (errors.ufComprador?.message as any) : null
                                            }
                                            gapDropdownContainer="0"
                                        />
                                    )}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Background>
                <ButtonContainer>
                    <PrimaryButton style={{ width: "200px" }} type="submit">Preencher ATPV</PrimaryButton>
                </ButtonContainer>
            </Form>
        </FormContainer>
    )
}