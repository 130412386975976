import { PrimaryButton } from "components/Button/style";
import { Body, ButtonContainer, Buttons, Container, Header, Tag, TextoHeader } from "./style";
import { Table } from "containers/Table";
import { IColum } from "containers/Table/interfaces";
import { IPagarIpvaLote } from "features/core/pages/Financeiro/Interfaces/IPagarIpvaLote";
import { TableColumn } from "react-data-table-component";
import { utils, writeFileXLSX } from "xlsx";
import { useCallback } from "react";
import { formatarDinheiro } from "_utils/Money";

interface IPagarIpvaLoteProps {
    dados: IPagarIpvaLote[];
    loading: boolean;
    totalRows?: number;
    onChangeRowsPerPage?: (limit: number) => void;
    onChangePage?: (page: number) => void;
    onClickBaixarManualmente: (e: any) => void;
    setSelectedRows: (e: any) => void;
    clearSelectedRows: boolean,
    onClickRealizarPagamento: (e: any) => void;
    onSort: (selectedColumn: TableColumn<any>, sortDirection: string) => void;
    show: boolean;
    handleTentarNovamente: (processo: any, erro: any) => void;
}

export function PagarIpvaLoteLista({
    dados,
    loading,
    totalRows,
    onChangeRowsPerPage,
    onChangePage,
    onClickBaixarManualmente,
    setSelectedRows,
    clearSelectedRows,
    onClickRealizarPagamento,
    onSort,
    show,
    handleTentarNovamente
}: IPagarIpvaLoteProps) {

    const statusPagamentoStrategy = {
        1: "Pendente",
        2: "Processando",
        3: "Pago",
        4: "Baixa manual",
        5: "Erro"
    }

    const columns: IColum<IPagarIpvaLote>[] = [
        {
            name: <span>ID</span>,
            selector: (processo: IPagarIpvaLote) => <a href={`${process.env.REACT_APP_DESPACHANTE_BASE_URL}/pages/processo/Processo.php?param=${processo.param}`} target="_blank">{processo.id}</a>,
            sortable: true,
            sortField: "id",
            width: "90px",
        },
        {
            name: <span>Exercício</span>,
            selector: (processo: IPagarIpvaLote) => processo.exercicio,
            sortable: true,
            sortField: "exercicio",
        },
        {
            name: <span>Placa</span>,
            selector: (processo: IPagarIpvaLote) => processo.placa,
            sortable: true,
            sortField: "placa",
        },
        {
            name: <span>Renavam</span>,
            selector: (processo: IPagarIpvaLote) => processo.renavam,
            sortable: true,
            sortField: "renavam",
            width: "200px",
        },
        {
            name: <span>Chassi</span>,
            selector: (processo: IPagarIpvaLote) => processo.chassi,
            sortable: true,
            sortField: "chassi",
            width: "200px",
        },
        {
            name: <span>Dt. Cadastro Processo</span>,
            selector: (processo: IPagarIpvaLote) => processo.dataCadastroProcesso,
            sortable: true,
            sortField: "dataCadastroProcesso",
        },
        {
            name: <span>Data Vencimento</span>,
            selector: (processo: IPagarIpvaLote) => processo.dataVencimento,
            sortable: true,
            sortField: "dataVencimento",
            width: "200px",
        },
        {
            name: <span>Valor</span>,
            selector: (processo: IPagarIpvaLote) => (formatarDinheiro(processo.valor || 0)),
            sortable: true,
            sortField: "valor",
            width: "200px",
        },
        {
            name: <span>Status Pagamento</span>,
            selector: (processo: IPagarIpvaLote) => {
                return (
                    <Tag tipo={processo.statusPagamento} onClick={() => handleTentarNovamente(processo.id, processo.motivoErro)}>
                        {statusPagamentoStrategy[processo.statusPagamento]}
                    </Tag>
                )
            },
            sortable: true,
            sortField: "statusPagamento",
            width: "200px",
        }
    ];

    const dadosPlanilha = dados.map((item: IPagarIpvaLote) => ({
        ID: item.id,
        EXERCÍCIO: item.exercicio,
        PLACA: item.placa,
        RENAVAM: item.renavam,
        CHASSI: item.chassi,
        "DT. CADASTRO PROCESSO": item.dataCadastroProcesso,
        "DATA VENCIMENTO": item.dataVencimento,
        VALOR: item.valor || 0,
        "STATUS PAGAMENTO": statusPagamentoStrategy[item.statusPagamento]
    }));

    const handleExport = useCallback(() => {
        const planilha = utils.json_to_sheet(dadosPlanilha);
        planilha['!cols'] = [{ wch: 5 }, { wch: 12 }, { wch: 10 }, { wch: 12 }, { wch: 12 }, { wch: 25 }, { wch: 20 }, { wch: 10 }, { wch: 20 }];
        const workBook = utils.book_new();
        utils.book_append_sheet(workBook, planilha, "IPVA");
        writeFileXLSX(workBook, "pagar-ipva.xlsx");
    }, [dadosPlanilha]);


    return (
        <Container show={show}>
            <Header>
                <TextoHeader>Listagem - Débitos</TextoHeader>
            </Header>
            <hr/>
            <Body>
                <Table
                    toolsHeader={
                        <Buttons>
                            <ButtonContainer>
                                <PrimaryButton outline={true} variante={"primary"} buttonWidth="lg" onClick={onClickBaixarManualmente}>Baixar Manualmente</PrimaryButton>
                            </ButtonContainer>
                            <ButtonContainer>
                                <PrimaryButton buttonWidth="lg" onClick={onClickRealizarPagamento}>Realizar Pagamento</PrimaryButton>
                            </ButtonContainer>
                        </Buttons>
                    }
                    keyField="id"
                    clearSelectedRows={clearSelectedRows}
                    setRowsSelected={(row) => setSelectedRows(row)}
                    selectableRows={true}
                    totalRows={totalRows}
                    pagination={true}
                    columns={columns}
                    data={dados}
                    paginationRowsPerPageOptions={[10, 20, 50]}
                    loading={loading}
                    onChangeRowsPerPage={onChangeRowsPerPage}
                    onChangePage={onChangePage}
                    handleSortFunction={onSort}
                    exportExcel={true}
                    handleExport={handleExport}
                    buttonExportVariant="secondary"
                />
            </Body>
        </Container >
    );
}