import { toastErro } from "components/Toast";
import {
  CadastrarProcessoEmLoteMapper,
  IClienteProcessoLote,
  IServicoEmplacamentoProcessoLote,
  IServicoTransferenciaProcessoLote,
  IVeiculoProcessoLote,
} from "../utils/mappers/CadastrarProcessoEmLoteMapper";
import { AxiosRequest } from "_services/api";
import { converterDataBr } from "_utils/Data";
import { useState } from "react";
import { HttpResponse } from "_services/api/HttpResponse";

interface IServicoProcesso {
  empresa: number;
  divisao: number;
  despachante: number;
  servico: number;
}

interface ICadastrarProcesso {
  servico: IServicoProcesso;
  servicoEmplacamento: IServicoEmplacamentoProcessoLote;
  servicoTransferencia: IServicoTransferenciaProcessoLote;
  idVeiculo: number;
  idCliente: number;
  veiculo: IVeiculoProcessoLote;
}

export function useCadastrarProcesso() {
  const [processosComErro, setProcessosComErro] = useState<any[]>([]);

  function handleLimparProcessosComErro() {
    setProcessosComErro([])
  }

  async function cadastrarProcessos(
    processos: CadastrarProcessoEmLoteMapper[],
    servico: IServicoProcesso
  ) {
    const idsProcessos = [];
    for (const processo of processos) {
      const { cliente, veiculo, servicoEmplacamento, servicoTransferencia } =
        processo;
      const resultadoVeiculo = await cadastrarAtualizarVeiculo(
        veiculo,
        servico
      );

      if (resultadoVeiculo.hasErro) {
        setProcessosComErro((prev) => [...prev, {
          ...processo,
          mensagem: resultadoVeiculo.data,
        }]);
        continue;
      }

      let resultadoCliente = null;

      if (cliente.cpfCnpj) {
        resultadoCliente = await cadastrarAtualizarCliente(
          cliente,
          servico.empresa,
          servico.despachante
        );
      }

      const resultadoProcesso = await cadastrarProcesso({
        servico,
        servicoEmplacamento,
        servicoTransferencia,
        idVeiculo: resultadoVeiculo?.data?.id,
        idCliente: resultadoCliente?.id ?? 0,
        veiculo: {
          ...resultadoVeiculo.data,
          odometro: veiculo.odometro,
        },
      });

      if (resultadoProcesso.hasErro) {
        setProcessosComErro((prev) => [...prev, {
          ...processo,
          mensagem: resultadoProcesso.data,
        }]);
        continue;
      }

      if (!resultadoProcesso.data.id) {
        setProcessosComErro((prev) => [...prev, {
          ...processo,
          mensagem: "Entre em contato com a equipe de TI",
        }]);
        continue;
      }

      idsProcessos.push(resultadoProcesso.data.id);
    }
    return idsProcessos;
  }

  async function cadastrarProcesso({
    servico,
    servicoEmplacamento,
    servicoTransferencia,
    idVeiculo,
    idCliente,
    veiculo,
  }: ICadastrarProcesso) {
    try {
      const axios = AxiosRequest.makeApi();
      const { data, status } = await axios.post("/processos/", {
        id_servico_modelo: servico.servico,
        id_empresa: servico.empresa,
        id_despachante: servico.despachante,
        id_divisao: servico.divisao,
        id_cliente: idCliente,
        id_veiculo: idVeiculo,
        odometro: veiculo.odometro,
        placa_vermelha: servicoEmplacamento.placaVermelha,
        valor_venda: servicoTransferencia.valorDeVenda,
        uf_venda: servicoTransferencia.ufVenda,
        municipio_venda: servicoTransferencia.municipioDeVenda,
        data_venda: servicoTransferencia.dataDeVenda
          ? converterDataBr(servicoTransferencia.dataDeVenda)
          : null,
        escolha_placa: servicoEmplacamento.escolhaDePlaca,
        local_lacre: servicoEmplacamento.localDeEmplacamento,
        nf: servicoEmplacamento.nf,
        alienacao: servicoEmplacamento.alienacao,
      });

      return new HttpResponse(false, status, data);
    } catch (error: any) {
      const { status, data } = error.response;
      return new HttpResponse(true, status, data.message);
    }
  }

  async function cadastrarAtualizarVeiculo(
    veiculo: IVeiculoProcessoLote,
    dadosServico: IServicoProcesso
  ) {
    if (veiculo.idVeiculo) {
      return atualizarVeiculo(veiculo, dadosServico);
    }

    return cadastrarVeiculo(veiculo, dadosServico);
  }

  async function cadastrarVeiculo(
    veiculo: IVeiculoProcessoLote,
    dadosServico: IServicoProcesso
  ) {
    try {
      const axios = AxiosRequest.makeApi();
      const { data, status } = await axios.post("/veiculos/", {
        placa: formatarPlaca(veiculo.placa!),
        renavam: veiculo.renavam,
        chassi: veiculo.chassi,
        marca_modelo: veiculo.marcaModelo,
        especie: veiculo.especie,
        tipo: veiculo.tipo,
        ano_fabricacao: veiculo.anoFabricacao,
        ano_modelo: veiculo.anoModelo,
        cor: veiculo.cor,
        combustivel: veiculo.combustivel,
        potencia: veiculo.potencia,
        municipio: veiculo.municipio,
        uf: veiculo.uf,
        id_empresa: dadosServico.empresa,
        id_divisao: dadosServico.divisao,
      });

      return new HttpResponse(false, status, data);
    } catch (error: any) {
      const { status, data } = error.response;
      return new HttpResponse(true, status, data.message);
    }
  }

  async function atualizarVeiculo(
    veiculo: IVeiculoProcessoLote,
    dadosServico: IServicoProcesso
  ) {
    try {
      const axios = AxiosRequest.makeApi();
      const { data, status } = await axios.patch(`/veiculos/${veiculo.idVeiculo}`, {
        placa: formatarPlaca(veiculo.placa!),
        renavam: veiculo.renavam,
        chassi: veiculo.chassi,
        marca_modelo: veiculo.marcaModelo,
        especie: veiculo.especie,
        tipo: veiculo.tipo,
        ano_fabricacao: veiculo.anoFabricacao,
        ano_modelo: veiculo.anoModelo,
        cor: veiculo.cor,
        combustivel: veiculo.combustivel,
        potencia: veiculo.potencia,
        municipio: veiculo.municipio,
        uf: veiculo.uf,
        id_empresa: dadosServico.empresa,
        id_divisao: dadosServico.divisao,
      });

      return new HttpResponse(false, status, data);
    } catch (error: any) {
      const { status, data } = error.response;
      return new HttpResponse(true, status, data.message);
    }
  }

  async function cadastrarAtualizarCliente(
    cliente: IClienteProcessoLote,
    idEmpresa: number,
    idDespachante: number
  ) {
    cliente.cpfCnpj = formatarCpfCpnj(cliente.cpfCnpj!);
    const resultadoCliente = await buscarClientePorCpfCnpjIdEmpresa(idEmpresa, cliente.cpfCnpj!);

    if (!resultadoCliente) {
      return await cadastrarCliente(cliente, idEmpresa, idDespachante);
    }

    return await atualizarCliente(
      resultadoCliente.id,
      cliente,
      idEmpresa,
      idDespachante
    );
  }

  async function buscarClientePorCpfCnpjIdEmpresa(idEmpresa: number, cpfCnpj: string) {
    try {
      const axios = AxiosRequest.makeApi();
      const { data } = await axios.get(`/empresa/${idEmpresa}/clientes/${cpfCnpj}`);
      return data;
    } catch (error) {
      return null;
    }
  }

  async function cadastrarCliente(
    cliente: IClienteProcessoLote,
    idEmpresa: number,
    idDespachante: number
  ) {
    try {
      const axios = AxiosRequest.makeApi();
      const { data } = await axios.post(`/processos/cliente/`, {
        cpf_cnpj: cliente.cpfCnpj,
        nome_razao_social: cliente.nome,
        email: cliente.email,
        cep: cliente.cep,
        endereco: cliente.endereco,
        numero: cliente.numero,
        bairro: cliente.bairro,
        complemento: cliente.complemento,
        cidade: cliente.cidade,
        estado: cliente.ufCliente,
        id_empresa: idEmpresa,
        id_despachante: idDespachante,
      });
      return data;
    } catch (error) {
      return null;
    }
  }

  async function atualizarCliente(
    idCliente: number,
    cliente: IClienteProcessoLote,
    idEmpresa: number,
    idDespachante: number
  ) {
    try {
      const axios = AxiosRequest.makeApi();
      const { data } = await axios.put(`/processos/cliente/${idCliente}`, {
        cpf_cnpj: cliente.cpfCnpj,
        nome_razao_social: cliente.nome,
        email: cliente.email,
        cep: cliente.cep,
        endereco: cliente.endereco,
        numero: cliente.numero,
        bairro: cliente.bairro,
        complemento: cliente.complemento,
        cidade: cliente.cidade,
        estado: cliente.ufCliente,
        id_empresa: idEmpresa,
        id_despachante: idDespachante,
      });
      return data;
    } catch (error) {
      return null;
    }
  }

  function formatarCpfCpnj(cpfCnpj?: string) {
    if (!cpfCnpj) {
      return null;
    }

    return cpfCnpj.toString().replace(/[.\-/]/g, "");
  }

  function formatarPlaca(placa?: string) {
    if (!placa) {
      return null;
    }

    return placa.replace(/-/g, "").toUpperCase();
  }

  return {
    cadastrarProcessos,
    processosComErro,
    handleLimparProcessosComErro,
  };
}

