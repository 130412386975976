import { CompositeContainerActionButton } from "./CompositeContainerActionButton";
import { CompositeContainerBody } from "./CompositeContainerBody";
import { CompositeContainerHeader } from "./CompositeContainerHeader";
import { CompositeContainerRoot } from "./CompositeContainerRoot";
import { CompositeContainerTitulo } from "./CompositeContainerTitulo";

export const CompositeContainer = {
  Root: CompositeContainerRoot,
  Header: CompositeContainerHeader,
  Titulo: CompositeContainerTitulo,
  ActionButton: CompositeContainerActionButton,
  Body: CompositeContainerBody,
}