import React from "react";

import { Container } from "./estilo";
interface Props {
  activeMiniNavbar: boolean;
  children: any;
}

const Main: React.FC<Props> = ({ activeMiniNavbar, children }) => {
  return (
    <Container>
      <main
        className={`pace-done body-small ${
          activeMiniNavbar ? "mini-navbar" : ""
        }`}
      >
        {children}
      </main>
    </Container>
  );
};

export default Main;
