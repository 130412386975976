import { useCallback, useEffect, useState } from "react";

export function useTablePaginationSortSearch() {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState<number>(1);
  const [sort, setSort] = useState<string | null>(null);
  const [order, setOrder] = useState<string | null>(null);
  const [search, setSearch] = useState<string | null>(null);
  const [paginationSortSearch, setPaginationSortSearch] = useState<string>("");

  const handlePaginationSortSearch = useCallback(() => {
    const ajuste = [];
    if (page) ajuste.push(`page=${page}`);
    if (limit) ajuste.push(`limit=${limit}`);
    if (sort) ajuste.push(`sort=${sort}`);
    if (order) ajuste.push(`order=${order}`);
    if (search) ajuste.push(`search=${search}`);
    const ajustePaginationSortSearch = ajuste.join("&");

    setPaginationSortSearch(ajustePaginationSortSearch);
  }, [limit, page, sort, order, search])

  useEffect(() => {
    handlePaginationSortSearch();
  }, [handlePaginationSortSearch]);
  
  function handlePageChange(page: number) {
    setPage(page);
  }

  function handleLimitChange(limit: number) {
    setLimit(limit);
  }

  return {
    limit,
    setLimit,
    setPage,
    setSort,
    setOrder,
    setSearch,
    paginationSortSearch,
    handlePageChange,
    handleLimitChange,
  };
}
