import { Body, Container, Header, TextoHeader } from "./style";
import { Table } from "containers/Table";
import { IColum } from "containers/Table/interfaces";
import { utils, writeFileXLSX } from "xlsx";
import { useCallback } from "react";
import { IDespachanteListar } from "features/core/pages/Cadastro/Interfaces/IDespachanteListar";
import { FaFolder, FaPen, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";

interface IDespachanteListarProps {
    dados: IDespachanteListar[];
    loading: boolean;
    totalRows?: number;
    onChangeRowsPerPage?: (limit: number) => void;
    onChangePage?: (page: number) => void;
    show: boolean;
    handleExcluir: (id: any, processos: any) => void
}

export function DespachanteListar({
    dados,
    loading,
    totalRows,
    onChangeRowsPerPage,
    onChangePage,
    show,
    handleExcluir,
}: IDespachanteListarProps) {

    const columns: IColum<IDespachanteListar>[] = [
        {
            name: <span>ID</span>,
            selector: (despachante: IDespachanteListar) => despachante.id,
            width: "90px",
        },
        {
            name: <span>Empresa</span>,
            selector: (despachante: IDespachanteListar) => despachante.empresa,
        },
        {
            name: <span>Razão Social</span>,
            selector: (despachante: IDespachanteListar) => despachante.razao_social,
        },
        {
            name: <span>CNPJ</span>,
            selector: (despachante: IDespachanteListar) => despachante.cnpj,
            width: "200px",
        },
        {
            name: <span>UF de Atuação</span>,
            selector: (despachante: IDespachanteListar) => <span>{despachante.estado}</span>,
            width: "200px",
        },
        {
            name: <span>Município de Atuação</span>,
            selector: (despachante: IDespachanteListar) => <span>{despachante.cidade}</span>,
            width: "300px",
        },
        {
            name: <span>Processos</span>,
            selector: (despachante: IDespachanteListar) => despachante.processos,
            width: "150px",
        },
        {
            name: <span>Ações</span>,
            selector: (despachante: IDespachanteListar) => <span>
                <FaFolder onClick={() => {
                    window.location.href = `${process.env.PUBLIC_URL.replace("/app", "")}/pages/processo/Processo_Buscar.php?id_despachante=${despachante.id}`
                }} style={{ marginRight: "20px", cursor: "pointer" }} />
                <Link to={`/cadastro/despachante/cadastro?id=${despachante.id}`}>
                    <FaPen style={{ marginRight: "20px", cursor: "pointer", color: "#000" }} />
                </Link>
                <FaTimes onClick={() => { handleExcluir(despachante.id, despachante.processos) }} style={{ color: "red", cursor: "pointer" }} />
            </span>,
            width: "200px",
        },
    ];

    const dadosPlanilha = dados.map((item: IDespachanteListar) => ({
        ID: item.id,
        EMPRESA: item.empresa,
        "RAZÃO SOCIAL": item.razao_social,
        CNPJ: item.cnpj,
        "UF DE ATUAÇÃO": item.estado,
        "MUNICÍPIO DE ATUAÇÃO": item.cidade,
        PROCESSOS: item.processos
    }));

    const handleExport = useCallback(() => {
        const planilha = utils.json_to_sheet(dadosPlanilha);
        planilha['!cols'] = [{ wch: 5 }, { wch: 12 }, { wch: 10 }, { wch: 12 }, { wch: 12 }, { wch: 25 }, { wch: 20 }];
        const workBook = utils.book_new();
        utils.book_append_sheet(workBook, planilha, "Despachantes");
        writeFileXLSX(workBook, "lista_despachantes.xlsx");
    }, [dadosPlanilha]);


    return (
        <Container show={show}>
            <Header>
                <TextoHeader>Listagem - Despachantes</TextoHeader>
            </Header>
            <hr style={{ marginTop: "1.5rem" }} />
            <Body>
                <Table
                    keyField="id"
                    totalRows={totalRows}
                    pagination={true}
                    columns={columns}
                    data={dados}
                    paginationRowsPerPageOptions={[10, 20, 50]}
                    loading={loading}
                    onChangeRowsPerPage={onChangeRowsPerPage}
                    onChangePage={onChangePage}
                    exportExcel={true}
                    handleExport={handleExport}
                    buttonExportVariant="secondary"
                />
            </Body>
        </Container >
    );
}