import { ReactNode } from "react";

interface Props {
  children: ReactNode;
}

function PageWrapper({ children }: Props) {
  return (
    <div id="page-wrapper" className="gray-bg">
      {children}
    </div>
  );
};

export default PageWrapper;
