import { useEffect, useState } from "react";

export function useCount() {
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [valor, setValor] = useState<number>(0);
  const [desconto, setDesconto] = useState<number>(0);
  const [acrecimos, setAcrecimos] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);

  useEffect(() => {
    setValor(0);
    setDesconto(0);
    setAcrecimos(0);
    setTotal(0);

    if (selectedRows.length > 0) {
      setValor(
        selectedRows.map((item) => item.total).reduce((item, i) => item + i)
      );
      setDesconto(
        selectedRows.map((item) => item.desconto).reduce((item, i) => item + i)
      );
      setAcrecimos(
        selectedRows.map((item) => item.acrescimo).reduce((item, i) => item + i)
      );
      setTotal(
        selectedRows.map((item) => item.total).reduce((item, i) => item + i)
      )
    }
  }, [selectedRows]);

  const limparSelectedRows = () => setSelectedRows([]);

  return {
    total,
    desconto,
    valor,
    acrecimos,
    selectedRows,
    setSelectedRows,
    limparSelectedRows,
  };
}
