import * as yup from "yup";
import { portugues } from "_utils/validador";

yup.setLocale(portugues);

export const preenchimentoAtpvDetalhesFiltrosSchema = yup.object().shape({
    placa: yup.string().optional().nullable(),
    renavam: yup.string().optional().nullable(),
    cpfCnpj: yup.string().optional().nullable(),
    idProcesso: yup.string().optional().nullable(),
    statusATPV: yup.string().optional().nullable(),
});