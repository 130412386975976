import { DropdownSelect } from "components/DropdownSelect";
import { ModalCustom } from "components/ModalCustom";
import { DropdownContainer } from "./style";

interface IModalRealizarPagamento {
    show: boolean;
    handleClose: () => void;
    onConfirm: () => void;
    dropdownReciboOnSelect: (e: any) => void;
    dropdownReciboValor: number | undefined;
    dropdownReciboOptions: any[];
}


export function ModalRealizarPagamento({
    show,
    handleClose,
    onConfirm,
    dropdownReciboOnSelect,
    dropdownReciboValor,
    dropdownReciboOptions
}: IModalRealizarPagamento) {



    return (
        <ModalCustom
            show={show}
            handleClose={handleClose}
            title="Selecionar Recibo"
            centered={true}
            footer={true}
            footerConfirmButton={true}
            onConfirm={onConfirm}
        >
            <DropdownContainer>
                <DropdownSelect
                    options={dropdownReciboOptions}
                    title="Selecione um recibo para vincular os pagamentos."
                    selectedValue={dropdownReciboValor}
                    onSelect={dropdownReciboOnSelect}
                />
            </DropdownContainer>
        </ModalCustom>
    );
}