import { ModalCustom } from "components/ModalCustom";
import { Informacao } from "components/Informacao";

interface IModalProcessosSemIpva {
    show: boolean;
    handleClose: () => void;
    onConfirm: () => void;
    idsProcessosSemIpva: any[];
}


export function ModalProcessosSemIpva({
    show,
    handleClose,
    onConfirm,
    idsProcessosSemIpva
}: IModalProcessosSemIpva) {

    const MENSAGEM = `Os processos listados abaixo não possuem custo de IPVA para serem vinculados ao recibo.`;

    return (
        <ModalCustom
            show={show}
            handleClose={handleClose}
            title="Baixar pagamento de IPVA"
            centered={true}
            footer={true}
            footerConfirmButton={true}
            onConfirm={onConfirm}
        >
            <Informacao
                mensagem={MENSAGEM}
                type="atencao"
            />
            <div style={{ marginBottom: "16px" }}><strong>Processos sem custo de IPVA</strong></div>
            {
                idsProcessosSemIpva.map((idProcesso: any) => {
                    return <div style={{ marginBottom: "8px" }} key={idProcesso}>{idProcesso}</div>;
                })
            }
            <div style={{ marginTop: "24px" }}>Deseja realizar a baixa do IPVA do restante dos processos?</div>
        </ModalCustom>
    );
}