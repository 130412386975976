import { Page } from "containers/Page";
import {
  Container,
  ContainerWrapper,
} from "./style";

function Dashboard() {
  return (
    <Page title="Dashboard">
      <Container>
        <ContainerWrapper>
          <h1>Exemplo de página</h1>
        </ContainerWrapper>
        <ContainerWrapper>
          
        </ContainerWrapper>
      </Container>
    </Page>
  );
}

export { Dashboard };
