export const TOKEN_KEY = "@AWDOC_TOKEN";
export const NOME_KEY = "@nome_usuario";
export const EMAIL_KEY = "@email_usuario";
export const EMPRESA_KEY = "@nome_empresa_usuario";
export const USUARIO_ADMIN_KEY = "@usuario_admin";
export const ID_USUARIO_KEY = "@id_usuario";

function getIdUsuario() {
  return localStorage.getItem(ID_USUARIO_KEY);
}

function isAuthenticated() {
  return getToken() !== null ? true : false;
}

function getToken(): string | null {
  return localStorage.getItem(TOKEN_KEY);
}

function setToken(token: string) {
  return localStorage.setItem(TOKEN_KEY, token);
}
function removeToken(manter: boolean = false) {
  const storageManter = [
    "config_buscar_processos",
  ];

  const storageRecriar = storageManter.map((item) => {
    if (localStorage.getItem(item)) {
      return { id: item, valor: localStorage.getItem(item), restaurar: true }
    }
    return { id: item, valor: null, restaurar: false }
  })

  localStorage.clear();
  if (manter) {
    storageRecriar.forEach((item) => {
      if (item.restaurar && item.valor) {
        localStorage.setItem(item.id, item.valor)
      }
    })
  }
  return true;
}

function getNome() {
  const nomeArray = localStorage.getItem(NOME_KEY)?.split(" ");

  if (nomeArray && nomeArray?.length >= 2) {
    const primeiroNome = nomeArray?.shift();
    const sobrenome = nomeArray?.pop();
    return `${primeiroNome} ${sobrenome}`;
  }

  return localStorage.getItem(NOME_KEY);
}

function setNome(nome: string) {
  return localStorage.setItem(NOME_KEY, nome);
}

function getEmail() {
  return localStorage.getItem(EMAIL_KEY);
}

function setEmail(email: string) {
  return localStorage.setItem(EMAIL_KEY, email);
}

function setEmpresa(empresa: string) {
  return localStorage.setItem(EMPRESA_KEY, empresa);
}

function getEmpresa() {
  return localStorage.getItem(EMPRESA_KEY);
}

function setAdmin(admin: string) {
  return localStorage.setItem(EMPRESA_KEY, admin);
}

function getAdmin() {
  return localStorage.getItem(USUARIO_ADMIN_KEY);
}

function setLocalStore(
  token: string,
  nome: string,
  email: string,
  empresa: string
) {
  setToken(token);
  setNome(nome);
  setEmail(email);
  setEmpresa(empresa);
}

function parseJwt() {
  try {
    const token = getToken();

    if (!token) {
      const baseUrlDespachante = process.env.REACT_APP_DESPACHANTE_BASE_URL;
      window.location.assign(`${baseUrlDespachante}/index.php?m=err`);
    }

    const base64Url = token?.split(".")[1];
    const base64 = base64Url?.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64!)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  } catch (e: any) {
    const baseUrlDespachante = process.env.REACT_APP_DESPACHANTE_BASE_URL;
    window.location.assign(`/app/login?m=err`);
  }
}

function verificarPermissao(permissao: string) {
  try {
    const token = parseJwt();

    if (!token) {
      return false;
    }

    return true;
  } catch (e: any) {
    console.log(e.message);
  }
}

export {
  isAuthenticated,
  getToken,
  getNome,
  getEmail,
  setLocalStore,
  removeToken,
  getEmpresa,
  setEmpresa,
  getAdmin,
  parseJwt,
  verificarPermissao,
  getIdUsuario,
};
