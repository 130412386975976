import { useEffect, useState } from "react";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import {
  ChangeRows,
  ChangeRowsButton,
  Container,
  SelectPagination,
  SelectPaginationContainter,
  TotalRows,
} from "./style";

export const PaginationComponent = ({
  rowsPerPage,
  rowCount,
  onChangePage,
  onChangeRowsPerPage,
  currentPage,
  paginationRowsPerPageOptions
}: any) => {
  const [pageNumbers, setPageNumbers] = useState<number[]>([]);
  const [totalPages, setTotalPages] = useState<number>(1);

  useEffect(() => {
    let pages = Array.from(Array(Math.ceil(rowCount / rowsPerPage) + 1).keys());
    pages.shift();
    setTotalPages(Math.max(...pages));
    setPageNumbers(pages);
  }, [rowCount, rowsPerPage]);

  const handleBackButtonClick = () => {
    onChangePage(currentPage >= 2 ? currentPage - 1 : currentPage);
  };

  const handleNextButtonClick = () => {
    onChangePage(currentPage === totalPages ? currentPage : currentPage + 1);
  };

  const handlePageNumber = (e: any) => {
    onChangePage(Number(e.target.innerText));
  };

  const handleRowsPerPage = (e: any) => {
    onChangeRowsPerPage(Number(e.target.value));
  };

  const renderPageNumbers = (pageNumbers: number[]) => {
    return (
      <>
        <ChangeRowsButton onClick={handleBackButtonClick}>
          <FaAngleLeft />
        </ChangeRowsButton>
        {pageNumbers.map((number) => (
          number >= currentPage - 3 && number <= currentPage + 3 && (
            <ChangeRowsButton
              key={number}
              active={number === currentPage}
              onClick={handlePageNumber}
            >
              {number}
            </ChangeRowsButton>
          ))
        )}
        <ChangeRowsButton onClick={handleNextButtonClick}>
          <FaAngleRight />
        </ChangeRowsButton>
      </>
    );
  };

  return (
    <Container>
      <SelectPaginationContainter>
        <SelectPagination value={rowsPerPage} onChange={handleRowsPerPage}>
          {paginationRowsPerPageOptions.map((rpp: number) => (
            <option value={rpp} key={rpp}>{`${rpp} por página`}</option>
          ))}
        </SelectPagination>
        <TotalRows>{`${rowCount} resultados`}</TotalRows>
      </SelectPaginationContainter>
      <ChangeRows>{renderPageNumbers(pageNumbers)}</ChangeRows>
    </Container>
  );
};
