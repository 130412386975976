import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;

export const IconeContainer = styled.div`
  padding: 0.75rem;
  border-radius: 0.75rem;
  background-color: ${({ theme }) => theme.colors.danger.bgColor};

  svg {
    width: 1.75rem;
    height: 1.75rem;
    color: ${({ theme }) => theme.colors.danger.main};
  }
`;

export const TextoContainer = styled.div`
  font-size: 16px;
  text-align: center;
`;
