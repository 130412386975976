import { ModalCustom } from "components/ModalCustom";
import { FaCheck, FaFileExcel } from "react-icons/fa";
import { IconeContainer, InfoSucessoProcessos, ListaChassis } from "./style";
import { Informacao } from "components/Informacao";

interface Props {
  processosComErro: any[];
  show: boolean;
  soTeveErro: boolean;
  handleClose: () => void;
  onConfirm: () => void;
}

export function ModalProcessosCadastrados({
  processosComErro,
  soTeveErro,
  show,
  handleClose,
  onConfirm,
}: Props) {
  return (
    <ModalCustom
      handleClose={handleClose}
      show={show}
      title="Cadastro de processos"
      footer
      footerConfirmButton
      confirmButtonText={processosComErro.length ? "Exportar" : "OK"}
      ConfirmButtonIcone={processosComErro.length ? <FaFileExcel /> : null}
      onConfirm={onConfirm}
      centered
      size="lg"
    >
      {soTeveErro ? (
        <></>
      ) : (
        <InfoSucessoProcessos>
          <IconeContainer>
            <FaCheck />
          </IconeContainer>
          <span>Processos cadastrados com sucesso</span>
        </InfoSucessoProcessos>
      )}
      {processosComErro.length > 0 && (
        <>
          <Informacao
            mensagem="Alguns processos não foram cadastrados, verifique o chassi na listagem abaixo"
            type="atencao"
            showBotaoFechar={false}
            showLabel={false}
          />
          <strong>Processos não cadastrados - Chassis</strong>
          <ListaChassis>
            {processosComErro.map((item) => (
              <p key={item.veiculo.chassi}>{item.veiculo.chassi}</p>
            ))}
          </ListaChassis>
        </>
      )}
    </ModalCustom>
  );
}

