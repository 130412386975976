import axios, { InternalAxiosRequestConfig, AxiosHeaders } from "axios";
import { getToken } from "../auth";
import { HttpStatusCode } from "_utils/HttpStatusCode";

const frontPhp = axios.create({
  baseURL: process.env.REACT_APP_DESPACHANTE_BASE_URL,
});

frontPhp.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
  const token = getToken();
  if (token) {
    const headers = {
      Authorization: `Bearer ${token}`,
      ...config.headers,
    } as unknown as AxiosHeaders;
    config.headers = headers;
  }

  return config;
});

frontPhp.interceptors.response.use(
  (response) => response,
  (err) => {
    const statusCode = err.response.status;

    if ([HttpStatusCode.FORBIDDEN].includes(statusCode)) {
      window.location.href = "/app/?m=idNull";
      return;
    }

    throw err;
  }
);

export default frontPhp;
