import { LoadingScreen } from "components/LoadingScreen";
import { Page } from "containers/Page";
import { Filtros } from "./containers/Filtros";
import { Listar } from "./containers/Listar";
import { useServicoModelo } from "hooks/useServicoModelo";
import { useEffect, useState } from "react";
import { alertaErro, alertaSucesso } from "components/Alerta";
import { ModalCadastrarServico } from "./containers/ModalCadastrarServico";
import { ModalServicoEtapas } from "./containers/ModalServicoEtapas";
import { IEtapaServicoModelo } from "features/core/interfaces/IEtapaServicoModelo";
import { useServicoModeloDespachante } from "../../Hooks/useServicoModeloDespachante";
import { ModalEditarServico } from "./ModalEditarServico";
import { ModalExcluirServico } from "./ModalExcluirServico";
import { ModalEditarEtapasServico } from "./containers/ModalEditarEtapasServico";
import { useMaskInput } from "hooks/useMaskInput";
import { ModalConfirmarAlterarEtapas } from "./containers/ModalConfirmarAlterarEtapas";

interface IServicoValido {
    valido: boolean;
    mensagem: string;
}

export function ServicosDespachantes() {
    const { mInputNum } = useMaskInput();
    const [page, setPage] = useState<number>(1);
    const [limit, setLimit] = useState<number>(10);
    const [uf, setUf] = useState<string>("");
    const [idServicoModelo, setIdServicoModelo] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [loaded, setLoaded] = useState<boolean>(false);

    const {
        servicosModeloOptions,
        listarServicosModelo,
        servicosModelo,
        totalRows,
    } = useServicoModelo();

    const {
        cadastrarServicoDespachante,
        buscarServicoModelo,
        buscarEtapasServicoModelo,
        atualizarServicoModelo,
        excluirServicoModelo,
        atualizarEtapasServicoModelo,
    } = useServicoModeloDespachante();

    useEffect(() => {
        setLoaded(true);
    }, []);

    useEffect(() => {
        (async () => {
            if (loaded) {
                setLoading(true);
                listarServicosModelo({
                    limit,
                    page,
                    atualizarListagem: true,
                });
                await listarServicosModelo({
                    atualizarOptions: true,
                });
                setLoading(false);
            }
        })();
    }, [loaded]);

    useEffect(() => {
        (async () => {
            if (loaded) {
                setLoading(true);
                const { hasErro, data } = await listarServicosModelo({
                    limit,
                    page,
                    atualizarListagem: true,
                    idServicoModelo,
                    uf,
                });
                setLoading(false);
                if (hasErro) {
                    alertaErro("Erro ao carregar lista do filtro de serviços", data.message)
                }
            }
        })();
    }, [page, limit]);

    const onClickBuscar = async () => {
        setLoading(true);
        const { hasErro, data } = await listarServicosModelo({
            limit,
            page: 1,
            idServicoModelo,
            uf,
            atualizarOptions: false,
            atualizarListagem: true,
        });
        setLoading(false);
        if (hasErro) {
            alertaErro("Erro ao buscar serviços", data.message)
        }
    }

    const onChangeUf = (uf: string) => {
        setUf(uf);
    }

    const onChangeIdServicoModelo = (idServicoModelo: string) => {
        setIdServicoModelo(idServicoModelo);
    }

    function handleLimitChange(limit: number) {
        setLimit(limit);
    }

    function handlePageChange(page: number) {
        setPage(page);
    }

    const [etapas, setEtapas] = useState<IEtapaServicoModelo[]>([]);
    const [showModalCadastrarServico, setShowModalCadastrarServico] = useState<boolean>(false);
    const [descricaoServico, setDescricaoServico] = useState<string>("");
    const handleCloseModalCadastrarServico = () => {
        setShowModalCadastrarServico(false);
        limparModalCadastrarServico();
    }
    const onChangeDescricaoServico = (e: any) => {
        setDescricaoServico(e.target.value)
    }
    const onConfirmCadastrarServico = async () => {
        const servicoValido = validarNovoServico(descricaoServico, listaUfsModal);
        if (!servicoValido.valido) {
            alertaErro("Erro ao cadastrar novo serviço", servicoValido.mensagem)
            return;
        }

        setLoading(true);
        setShowModalCadastrarServico(false);
        const { hasErro, data } = await cadastrarServicoDespachante({
            servicoModelo: descricaoServico,
            etapas,
            ufs: listaUfsModal
        });

        if (hasErro) {
            setLoading(false);
            setShowModalCadastrarServico(true);
            alertaErro("Erro ao cadastrar novo serviço", data.message)
            return;
        }

        limparModalCadastrarServico();
        await listarServicosModelo({
            limit,
            page,
            atualizarListagem: true,
        });
        await listarServicosModelo({
            atualizarOptions: true,
        });
        setLoading(false);
        alertaSucesso("Sucesso", "Serviço cadastrado com sucesso!")
    }

    const onClickCadastrar = () => {
        setShowModalCadastrarServico(true);
    }

    const [listaUfsModal, setListaUfsModal] = useState<string[]>([]);
    const onChangeUfModal = (ufs: string[]) => {
        setListaUfsModal(ufs);
    }

    function limparModalCadastrarServico() {
        setListaUfsModal([]);
        setDescricaoServico("");
        setEtapas([]);
    }

    const [dadosAntigosEtapaModalEditarEtapasServico, setDadosAntigosEtapaModalEditarEtapasServico] = useState<IEtapaServicoModelo>({ nome: "", ordem: "", exclusao: "" });
    const handleEditarEtapaModalEditarEtapasServico = (nomeEtapa: string, ordemEtapa: string) => {
        setSelectedModalEditarEtapasServico("cadastrar");
        setEtapaServicoEtapaModalEditarEtapasServico(nomeEtapa);
        setOrdemServicoEtapaModalEditarEtapasServico(ordemEtapa);
        setDadosAntigosEtapaModalEditarEtapasServico({ nome: nomeEtapa, ordem: ordemEtapa });
    }
    const [selectedModalEditarEtapasServico, setSelectedModalEditarEtapasServico] = useState<"cadastrar" | "copiar">("cadastrar");
    const [ordemServicoEtapaModalEditarEtapasServico, setOrdemServicoEtapaModalEditarEtapasServico] = useState<string>("");
    const onChangeOrdemModalEditarEtapasServico = (e: any) => {
        setOrdemServicoEtapaModalEditarEtapasServico(mInputNum(e));
    }

    const [etapaServicoEtapaModalEditarEtapasServico, setEtapaServicoEtapaModalEditarEtapasServico] = useState<string>("");
    const onChangeEtapaModalEditarEtapasServico = (e: any) => {
        setEtapaServicoEtapaModalEditarEtapasServico(e.target.value);
    }
    const [showAlterarEtapas, setShowAlterarEtapas] = useState<boolean>(false);
    const [showModalServicoEtapas, setShowModalServicoEtapas] = useState<boolean>(false);
    const [showModalEditarEtapasServico, setShowModalEditarEtapasServico] = useState<boolean>(false);
    const [idServicoEditarEtapas, setIdServicoEditarEtapas] = useState<string>("");
    const [idServicoModeloModalEditarEtapasServico, setIdServicoModeloModalEditarEtapasServico] = useState<string>("");
    const handleShowModalEditarEtapas = async (idServico: string) => {
        setSelectedModalEditarEtapasServico("cadastrar");
        setIdServicoEditarEtapas(idServico);
        setLoading(true);
        const { hasErro, data } = await buscarEtapasServicoModelo(idServico);
        if (hasErro) {
            setLoading(false);
            setShowModalEditarEtapasServico(true);
            return;
        }

        const naoExcluido = "0";
        const listaEtapas = etapas;
        if (Array.isArray(data)) {
            data.forEach((obj: any) => {
                listaEtapas.push({ id: obj.id, nome: obj.nome, ordem: obj.ordem, exclusao: naoExcluido })
            })
            setEtapas(listaEtapas);
        }
        setLoading(false);
        setShowModalEditarEtapasServico(true);
    }
    const onConfirmModalEditarEtapasServico = () => {
        setShowAlterarEtapas(true);
        setShowModalEditarEtapasServico(false);
    }
    const handleCloseAlterarEtapas = () => {
        setShowAlterarEtapas(false);
        setShowModalEditarEtapasServico(true);
    };
    const onConfirmAlterarEtapas = async () => {
        setShowModalEditarEtapasServico(false);
        setShowAlterarEtapas(false);
        setLoading(true);
        const { hasErro, data } = await atualizarEtapasServicoModelo({
            idServicoModelo: idServicoEditarEtapas,
            etapas,
        });

        limparModalEditarEtapasServico();
        setLoading(false);
        if (hasErro) {
            alertaErro("Erro", data.message);
            return;
        }

        alertaSucesso("Sucesso", "Etapas do serviço atualizadas com sucesso!")
        return;
    };
    const onChangeServicoModalEditarEtapasServico = (id: string) => {
        setIdServicoModeloModalEditarEtapasServico(id);
    };
    const handleExcluirEtapaModalEditarEtapasServico = (nome: string, ordemEtapa: string) => {
        const etapasEditadas = etapas.map(obj => {
            if (obj.nome == nome && obj.ordem == ordemEtapa) {
                const excluido = "1";
                obj.exclusao = excluido;
            }
            return obj;
        });
        setEtapas(etapasEditadas);
    }
    const onClickCopiarEtapaModalEditarEtapasServico = async () => {
        if (idServicoModeloModalEditarEtapasServico) {
            setLoading(true);
            const { hasErro, data } = await buscarEtapasServicoModelo(idServicoModeloModalEditarEtapasServico);
            if (hasErro) {
                setLoading(false);
                alertaErro("Erro", data.message)
                return;
            }

            const naoExcluido = "0";
            const listaEtapas = etapas;
            if (Array.isArray(data)) {
                data.forEach((obj: any) => {
                    listaEtapas.push({ nome: obj.nome, ordem: obj.ordem, exclusao: naoExcluido })
                })
                setEtapas(listaEtapas);
            }

            setIdServicoModeloModalEditarEtapasServico("");
            setLoading(false);
        }
    };
    const onClickCadastrarEtapaModalEditarEtapasServico = () => {
        if (!etapaServicoEtapaModalEditarEtapasServico) {
            alertaErro("Atenção!", "O nome da etapa é obrigatório.")
            return;
        }

        if (!ordemServicoEtapaModalEditarEtapasServico) {
            alertaErro("Atenção!", "A ordem da etapa é obrigatória.")
            return;
        }

        const listaEtapas = etapas;
        if (dadosAntigosEtapaModalEditarEtapasServico.nome) {
            listaEtapas.forEach(obj => {
                const excluido = "1";
                if (obj.exclusao != excluido && obj.nome == dadosAntigosEtapaModalEditarEtapasServico.nome && obj.ordem == dadosAntigosEtapaModalEditarEtapasServico.ordem) {
                    obj.nome = etapaServicoEtapaModalEditarEtapasServico;
                    obj.ordem = ordemServicoEtapaModalEditarEtapasServico
                }
            })
            setEtapas(listaEtapas);
            setEtapaServicoEtapaModalEditarEtapasServico("");
            setOrdemServicoEtapaModalEditarEtapasServico("");
            setDadosAntigosEtapaModalEditarEtapasServico({ nome: "", ordem: "", exclusao: "" });
            return;
        }
        if (listaEtapas.filter(obj => obj.nome.toUpperCase() == etapaServicoEtapaModalEditarEtapasServico.toUpperCase() && obj.exclusao != "1").length) {
            alertaErro("Atenção!", "Já existe uma etapa com este nome.")
            return;
        }

        if (listaEtapas.filter(obj => obj.ordem == ordemServicoEtapaModalEditarEtapasServico && obj.exclusao != "1").length) {
            alertaErro("Atenção!", "Já existe uma etapa com esta ordem.")
            return;
        }

        const naoExcluido = "0";
        listaEtapas.push({ nome: etapaServicoEtapaModalEditarEtapasServico, ordem: ordemServicoEtapaModalEditarEtapasServico, exclusao: naoExcluido })
        setEtapas(listaEtapas);
        setEtapaServicoEtapaModalEditarEtapasServico("");
        setOrdemServicoEtapaModalEditarEtapasServico("");
    };
    const handleCloseModalEditarEtapasServico = () => {
        setShowModalEditarEtapasServico(false);
        limparModalEditarEtapasServico();
    }
    function limparModalEditarEtapasServico() {
        setIdServicoModeloModalEditarEtapasServico("");
        setEtapaServicoEtapaModalEditarEtapasServico("");
        setOrdemServicoEtapaModalEditarEtapasServico("");
        setEtapas([]);
        setIdServicoEditarEtapas("");
    }

    const [etapasEditando, setEtapasEditando] = useState<IEtapaServicoModelo[]>([]);
    const onCadastrarEtapas = () => {
        const servicoValido = validarNovoServico(descricaoServico, listaUfsModal);
        if (!servicoValido.valido) {
            alertaErro("Atenção!", servicoValido.mensagem)
            return;
        }

        const etapasEditar = etapas.map(obj => obj);
        setEtapasEditando(etapasEditar);
        setShowModalServicoEtapas(true);
        setShowModalCadastrarServico(false);
    }
    const handleCloseModalServicoEtapas = () => {
        setShowModalServicoEtapas(false);
        setShowModalCadastrarServico(true);
        limparModalServicoEtapas();
    }
    const onConfirmCadastrarServicoEtapas = async () => {
        const etapasEditadas = etapasEditando.map(obj => obj);
        setEtapas(etapasEditadas);
        setShowModalCadastrarServico(true);
        setShowModalServicoEtapas(false);
    }

    function validarNovoServico(descricao: string, ufs: string[]): IServicoValido {
        if (descricao == "") {
            return { valido: false, mensagem: "Necessário fornecer uma descrição do serviço." }
        }

        if (!ufs.length) {
            return { valido: false, mensagem: "Necessário escolher algum estado para o serviço." }
        }

        return { valido: true, mensagem: "" }
    }

    function limparModalServicoEtapas() {
        setIdServicoModeloModalServicoEtapas("");
        setEtapaServicoEtapa("");
        setOrdemServicoEtapa("");
        setIdServicoEditarEtapas("");
        setEtapasEditando([]);
    }

    const [idServicoModeloModalServicoEtapas, setIdServicoModeloModalServicoEtapas] = useState<string>("");
    const onChangeServicoModalServicoEtapas = (id: string) => {
        setIdServicoModeloModalServicoEtapas(id);
    }

    const [ordemServicoEtapa, setOrdemServicoEtapa] = useState<string>("");
    const onChangeOrdem = (e: any) => {
        setOrdemServicoEtapa(mInputNum(e));
    }

    const [etapaServicoEtapa, setEtapaServicoEtapa] = useState<string>("");
    const onChangeEtapa = (e: any) => {
        setEtapaServicoEtapa(e.target.value);
    }

    const [selectedModalServicoEtapas, setSelectedModalServicoEtapas] = useState<"cadastrar" | "copiar">("cadastrar");

    const onClickCadastrarEtapa = () => {
        if (!etapaServicoEtapa) {
            alertaErro("Atenção!", "O nome da etapa é obrigatório.")
            return;
        }

        if (!ordemServicoEtapa) {
            alertaErro("Atenção!", "A ordem da etapa é obrigatória.")
            return;
        }

        const listaEtapasOnClick = etapasEditando.map(obj => obj);
        if (dadosAntigosEtapa.nome) {
            setEtapasEditando(listaEtapasOnClick.map(obj => {
                const retorno = { nome: obj.nome, ordem: obj.ordem }
                if (obj.nome == dadosAntigosEtapa.nome && obj.ordem == dadosAntigosEtapa.ordem) {
                    retorno.nome = etapaServicoEtapa;
                    retorno.ordem = ordemServicoEtapa
                }
                return retorno;
            }));
            setEtapaServicoEtapa("");
            setOrdemServicoEtapa("");
            setDadosAntigosEtapa({ nome: "", ordem: "" });
            return;
        }
        if (listaEtapasOnClick.filter(obj => obj.nome.toUpperCase() == etapaServicoEtapa.toUpperCase()).length) {
            alertaErro("Atenção!", "Já existe uma etapa com este nome.")
            return;
        }

        if (listaEtapasOnClick.filter(obj => obj.ordem == ordemServicoEtapa).length) {
            alertaErro("Atenção!", "Já existe uma etapa com esta ordem.")
            return;
        }

        listaEtapasOnClick.push({ nome: etapaServicoEtapa, ordem: ordemServicoEtapa })
        setEtapasEditando(listaEtapasOnClick);
        setEtapaServicoEtapa("");
        setOrdemServicoEtapa("");
    };

    const onClickCopiarEtapa = async () => {
        if (idServicoModeloModalServicoEtapas) {
            setLoading(true);
            const { hasErro, data } = await buscarEtapasServicoModelo(idServicoModeloModalServicoEtapas);
            if (hasErro) {
                setLoading(false);
                alertaErro("Erro", data.message)
                return;
            }

            const listaEtapas = etapasEditando.map(obj => obj);
            if (Array.isArray(data)) {
                data.forEach((obj: any) => {
                    listaEtapas.push({ nome: obj.nome, ordem: obj.ordem })
                })
                setEtapasEditando(listaEtapas);
            }

            setIdServicoModeloModalServicoEtapas("");
            setLoading(false);
        }
    };

    const [showModalEditarServico, setShowModalEditarServico] = useState<boolean>(false);
    const [idServicoEditar, setIdServicoEditar] = useState<string>("");
    const [nomeModalEditarServico, setNomeModalEditarServico] = useState<string>("");
    const [ufModalEditarServico, setUfModalEditarServico] = useState<string>("");
    const onChangeNomeModalEditar = (e: any) => {
        setNomeModalEditarServico(e.target.value);
    }
    const onChangeUfModalEditar = (e: any) => {
        setUfModalEditarServico(e.target.value);
    }
    const handleShowModalEditarServico = async (id: string) => {
        if (id) {
            setIdServicoEditar(id);
            setLoading(true);
            const { hasErro, data } = await buscarServicoModelo(id);
            setLoading(false);
            if (hasErro) {
                alertaErro("Erro", data.message)
                setIdServicoEditar("");
                return;
            }
            setNomeModalEditarServico(data.dados[0].nome);
            setUfModalEditarServico(data.dados[0].uf);
            setShowModalEditarServico(true);
        }
    };
    const handleCloseModalEditarServico = () => {
        setShowModalEditarServico(false);
        setIdServicoEditar("");
        setNomeModalEditarServico("");
        setUfModalEditarServico("");
    }
    const onConfirmModalEditarServico = async () => {
        const servicoValido = validarAtualizarServico(
            idServicoEditar,
            ufModalEditarServico,
            nomeModalEditarServico
        );

        if (!servicoValido.valido) {
            alertaErro("Erro ao atualizar serviço", servicoValido.mensagem)
            return;
        }

        setShowModalEditarServico(false);
        setLoading(true);
        const { hasErro, data } = await atualizarServicoModelo({
            idServicoModelo: idServicoEditar,
            nome: nomeModalEditarServico,
            uf: ufModalEditarServico,
        });

        setIdServicoEditar("");
        setNomeModalEditarServico("");
        setUfModalEditarServico("");

        if (hasErro) {
            alertaErro("Erro", data.message);
            setLoading(false);
            return;
        }

        await listarServicosModelo({
            limit,
            page,
            atualizarListagem: true,
        });
        await listarServicosModelo({
            atualizarOptions: true,
        });
        setLoading(false);
        alertaSucesso("Sucesso", "Serviço atualizado com sucesso!")
    };

    function validarAtualizarServico(id: string, uf: string, nome: string): IServicoValido {
        if (!id) {
            return { valido: false, mensagem: "Serviço desconhecido." }
        }

        if (!uf) {
            return { valido: false, mensagem: "Necessário escolher um estado para o serviço." }
        }

        if (!nome) {
            return { valido: false, mensagem: "Necessário digitar um nome para o serviço." }
        }

        return { valido: true, mensagem: "" }
    }

    const [showModalExcluirServico, setShowModalExcluirServico] = useState<boolean>(false);
    const [idServicoExcluir, setIdServicoExcluir] = useState<string>("");
    const handleShowModalExcluirServico = async (id: string) => {
        if (id) {
            setIdServicoExcluir(id);
            setShowModalExcluirServico(true);
        }
    };
    const handleCloseModalExcluirServico = () => {
        setShowModalExcluirServico(false);
        setIdServicoExcluir("");
    }
    const onConfirmModalExcluirServico = async () => {
        if (idServicoExcluir) {
            setLoading(true);
            setShowModalExcluirServico(false);

            const { hasErro, data } = await excluirServicoModelo(idServicoExcluir);

            setIdServicoExcluir("");

            if (hasErro) {
                alertaErro("Erro", data.message);
                setLoading(false);
                return;
            }

            await listarServicosModelo({
                limit,
                page,
                atualizarListagem: true,
            });
            await listarServicosModelo({
                atualizarOptions: true,
            });
            setLoading(false);
            alertaSucesso("Sucesso", "Serviço excluído com sucesso!")

        }
    };

    const handleExcluirEtapa = (nomeEtapa: string) => {
        const etapasEditadas = etapasEditando.filter(obj => obj.nome != nomeEtapa);
        setEtapasEditando(etapasEditadas);
    }

    const [dadosAntigosEtapa, setDadosAntigosEtapa] = useState<IEtapaServicoModelo>({ nome: "", ordem: "" });
    const handleEditarEtapa = (nomeEtapa: string, ordemEtapa: string) => {
        setSelectedModalServicoEtapas("cadastrar");
        setEtapaServicoEtapa(nomeEtapa);
        setOrdemServicoEtapa(ordemEtapa);
        setDadosAntigosEtapa({ nome: nomeEtapa, ordem: ordemEtapa });
    }

    return (
        <Page title="Serviços">
            <LoadingScreen mostrar={loading} />
            <Filtros
                onClickCadastrar={onClickCadastrar}
                servicosModeloOptions={servicosModeloOptions}
                onChangeUf={onChangeUf}
                uf={uf}
                onChangeIdServicoModelo={onChangeIdServicoModelo}
                idServicoModelo={idServicoModelo}
                onClickBuscar={onClickBuscar}
            />
            <Listar
                dados={servicosModelo}
                handleLimitChange={handleLimitChange}
                handlePageChange={handlePageChange}
                totalRows={totalRows}
                handleShowModalEditarServico={handleShowModalEditarServico}
                handleShowModalExcluirServico={handleShowModalExcluirServico}
                handleShowModalEditarEtapas={handleShowModalEditarEtapas}
            />
            <ModalCadastrarServico
                show={showModalCadastrarServico}
                handleClose={handleCloseModalCadastrarServico}
                onChangeDescricaoServico={onChangeDescricaoServico}
                descricao={descricaoServico}
                onConfirm={onConfirmCadastrarServico}
                onChangeUf={onChangeUfModal}
                listaUfs={listaUfsModal}
                onCadastrarEtapas={onCadastrarEtapas}
                dadosEtapas={etapas}
            />
            <ModalServicoEtapas
                show={showModalServicoEtapas}
                handleClose={handleCloseModalServicoEtapas}
                onConfirm={onConfirmCadastrarServicoEtapas}
                selected={selectedModalServicoEtapas}
                setSelected={setSelectedModalServicoEtapas}
                servicosModeloOptions={servicosModeloOptions}
                onChangeIdServicoModelo={onChangeServicoModalServicoEtapas}
                idServicoModelo={idServicoModeloModalServicoEtapas}
                onChangeOrdem={onChangeOrdem}
                onChangeEtapa={onChangeEtapa}
                ordem={ordemServicoEtapa}
                etapa={etapaServicoEtapa}
                dadosEtapas={etapasEditando}
                onClickCadastrarEtapa={onClickCadastrarEtapa}
                onClickCopiarEtapa={onClickCopiarEtapa}
                handleExcluirEtapa={handleExcluirEtapa}
                handleEditarEtapa={handleEditarEtapa}
            />
            <ModalEditarServico
                show={showModalEditarServico}
                onConfirm={onConfirmModalEditarServico}
                handleClose={handleCloseModalEditarServico}
                onChangeNome={onChangeNomeModalEditar}
                onChangeUf={onChangeUfModalEditar}
                nome={nomeModalEditarServico}
                uf={ufModalEditarServico}
            />
            <ModalExcluirServico
                show={showModalExcluirServico}
                handleClose={handleCloseModalExcluirServico}
                onConfirm={onConfirmModalExcluirServico}
            />
            <ModalEditarEtapasServico
                show={showModalEditarEtapasServico}
                handleClose={handleCloseModalEditarEtapasServico}
                onConfirm={onConfirmModalEditarEtapasServico}
                selected={selectedModalEditarEtapasServico}
                setSelected={setSelectedModalEditarEtapasServico}
                servicosModeloOptions={servicosModeloOptions}
                onChangeIdServicoModelo={onChangeServicoModalEditarEtapasServico}
                idServicoModelo={idServicoModeloModalEditarEtapasServico}
                onChangeOrdem={onChangeOrdemModalEditarEtapasServico}
                onChangeEtapa={onChangeEtapaModalEditarEtapasServico}
                ordem={ordemServicoEtapaModalEditarEtapasServico}
                etapa={etapaServicoEtapaModalEditarEtapasServico}
                dadosEtapas={etapas.filter(obj => obj.exclusao != "1")}
                onClickCadastrarEtapa={onClickCadastrarEtapaModalEditarEtapasServico}
                onClickCopiarEtapa={onClickCopiarEtapaModalEditarEtapasServico}
                handleExcluirEtapa={handleExcluirEtapaModalEditarEtapasServico}
                handleEditarEtapa={handleEditarEtapaModalEditarEtapasServico}
            />
            <ModalConfirmarAlterarEtapas
                show={showAlterarEtapas}
                handleClose={handleCloseAlterarEtapas}
                onConfirm={onConfirmAlterarEtapas}
            />
        </Page>
    );
}