import styled from "styled-components";

export const Container = styled.div`
  .react-datepicker-popper {
    z-index: 1000;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker__day {
    width: 32px;
    height: 32px;
    padding: 3px 0;
    border-radius: 10px;
  }
  .react-datepicker__day--today {
    background-color: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.project.main};
    color: ${({ theme }) => theme.colors.neutral.neutral90};
  }
  .react-datepicker__day--in-selecting-range{
    background-color: ${({ theme }) => theme.colors.project.bgColor};
    color: ${({ theme }) => theme.colors.neutral.neutral90};
  }
  .react-datepicker__day--keyboard-selected {
    background-color: ${({ theme }) => theme.colors.project.main};
    color: ${({ theme }) => theme.colors.white};
  }
  .react-datepicker__day--selected {
    background-color: ${({ theme }) => theme.colors.project.main};
    color: ${({ theme }) => theme.colors.white};
  }
  .react-datepicker__day--range-start {
    background-color: ${({ theme }) => theme.colors.project.main} !important;
    color: ${({ theme }) => theme.colors.white} !important;
  }
  .react-datepicker__day--range-end {
    background-color: ${({ theme }) => theme.colors.project.main} !important;
    color: ${({ theme }) => theme.colors.white} !important;
  }
  .react-datepicker__day--in-range {
    background-color: ${({ theme }) => theme.colors.project.bgColor20};
    color: ${({ theme }) => theme.colors.white};
  }
  button.react-datepicker__close-icon::after {
    background-color: ${({ theme }) => theme.colors.project.main};
  }
`;

export const CustomButton = styled.button`
  width: 100%;
  height: 2.375rem;
  border: 1px solid ${({ theme }) => theme.colors.neutral.neutral20};
  border-radius: 5px;
  padding: 0.3rem 1rem;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    color: ${({ theme }) => theme.colors.neutral.neutral40};
  }
`;

interface PlaceholderProps {
  selected: boolean;
}

export const Placeholder = styled.span<PlaceholderProps>`
  color: ${({ selected, theme }) => selected ? theme.colors.neutral.neutral80 : theme.colors.neutral.neutral40};

`;