import { Row } from "react-bootstrap";
import styled from "styled-components";

export const HeaderContainer = styled(Row)`
  background-color: ${({ theme }) => theme.colors.background};
  padding: 20px 20px 0px 12px;
  display: grid;
  gap: 10px;

  .reply {
    margin-right: 10px;
    margin-bottom: 6px;
  }

  .breadcrumb-item {
    padding-right: 0.2em;
    padding-left: 0.2em;
  }
`;

export const Title = styled.h2`
  color: ${({ theme }) => theme.colors.neutral.neutral80};
  font-weight: 400;
  font-size: 24px;
  padding: 0;
  margin: 0;
`;

export const Breadcrumb = styled.ol`
  display: flex;
  align-items: center;
  margin-bottom: 0;
  list-style: none;
  padding: 0;

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.neutral.neutral80};
  }
`;

export const ListItem = styled.li`
  font-weight: 400;
  font-size: 16px;
  height: 14px;
`;

export const StrongText = styled.strong`
  color: ${({ theme }) => theme.colors.neutral.neutral80};
  font-weight: 600;
  font-size: 16px;
  line-height: 1rem;
`;

export const BackButton = styled.button`
  width: auto;
  height: auto;
  border: none;
  background: transparent;

  svg {
    color: ${({ theme }) => theme.colors.neutral.neutral60};
    font-size:14px;
  }
`;