import styled from "styled-components";

interface IContainerProps {
  type: "atencao" | "erro" | "info" | "sucesso" | "preto";
  mostrar?: boolean;
  showLabel?: boolean;
}

interface ITextoProps {
  type?: "atencao" | "erro" | "info" | "sucesso" | "preto";
}

const bgColorStrategy = {
  atencao: "#FFEFCC",
  sucesso: "#DBFFF6",
  erro: "#FFE4E4",
  info: "#E9E9FE",
  preto: "#333333",
};

const textColorStrategy = {
  atencao: "#FF9F42",
  sucesso: "#10AC85",
  erro: "#EE5353",
  info: "#2529F4",
  preto: "#FFFFFF",
};

export const Container = styled.div<IContainerProps>`
  background-color: ${({ type }) => bgColorStrategy[type]};
  width: 100%;
  padding: 10px 20px;
  border-radius: 0.5rem;
  display: ${({ mostrar }) => !mostrar && "none !important"};
  display: ${({ showLabel }) => !showLabel && "flex"};
  align-items: center;

  .btnFechar:hover {
    cursor: pointer;
  }
`;

export const TitleContainer = styled.div<IContainerProps>`
  width: ${({ showLabel }) => !showLabel && "5%"};
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    font-weight: bold;
    color: ${({ theme }) => theme.colors.neutral.neutral90};
    display: flex;
    align-items: center;
    padding: 6px 0;

    svg {
      width: 1.2rem;
      height: 1.2rem;
      color: ${({ type }) => textColorStrategy[type]};
      margin-right: 10px;
    }
  }
`;

export const TextoContainer = styled.div<ITextoProps>`
  width: 100%;
  color: ${({ theme, type }) => type ? textColorStrategy[type] : theme.colors.neutral.neutral70};
`;

TitleContainer.defaultProps = {
  showLabel: true,
};
