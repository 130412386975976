import { Route, Routes } from "react-router-dom";
import { Despachante } from "../Pages/Despachante";
import { DespachanteCadastro } from "../Pages/DespachanteCadastro";
import { ServicosDespachantes } from "../Pages/ServicosDespachantes";

function CadastroRoutes() {
    return (
        <Routes>
            <Route path="/despachante" element={<Despachante />} />
            <Route path="/despachante/cadastro" element={<DespachanteCadastro />} />
            <Route path="/servico" element={<ServicosDespachantes />} />
        </Routes>
    );
}
export { CadastroRoutes };