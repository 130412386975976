import { ModalCustom } from "components/ModalCustom";
import { FaCheck } from "react-icons/fa";
import { Container } from "./style";

interface IModalSucessoPreencherATPVProps {
    show: boolean;
    handleClose: () => void;
    idSolicitacao: number | NullableString;
}

export function ModalSucessoPreencherATPV({
    show,
    handleClose,
    idSolicitacao,
}: IModalSucessoPreencherATPVProps) {

    return (
        <ModalCustom
            show={show}
            handleClose={handleClose}
            title="Preenchimento ATPV"
            centered
        >
            <Container><FaCheck style={{
                color: "#2529F4",
                backgroundColor: "#E9E9FE",
                fontSize: "40px",
                padding: "8px",
                borderRadius: "10px",
            }} /></Container>
            <Container>Solicitação efetuada com sucesso! <strong>O preenchimento da ATPV está sendo processo.</strong></Container>
            <Container>ID de solicitação: {idSolicitacao}</Container>
        </ModalCustom>
    );
}