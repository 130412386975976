import styled from "styled-components";

export const Container = styled.div`
  /* MAIN */
  main:not(.fixed-sidebar):not(.canvas-menu).mini-navbar
    .nav
    li:hover
    > .nav-second-level,
  .mini-navbar .nav li:focus > .nav-second-level {
    display: block;
    border-radius: 0 2px 2px 0;
    min-width: 160px;
    height: auto;
  }
  main.mini-navbar .navbar-default .nav > li > .nav-second-level li a {
    font-size: 12px;
    border-radius: 3px;
  }
  main.fixed-nav #wrapper .navbar-static-side,
  main.fixed-nav #wrapper #page-wrapper {
    margin-top: 60px;
  }
  main.top-navigation.fixed-nav #wrapper #page-wrapper {
    margin-top: 0;
  }
  main.fixed-nav.fixed-nav-basic .navbar-fixed-top {
    left: 220px;
  }
  main.fixed-nav.fixed-nav-basic.mini-navbar .navbar-fixed-top {
    left: 70px;
  }
  main.fixed-nav.fixed-nav-basic.fixed-sidebar.mini-navbar .navbar-fixed-top {
    left: 0;
  }
  main.fixed-nav.fixed-nav-basic #wrapper .navbar-static-side {
    margin-top: 0;
  }
  main.fixed-nav.fixed-nav-basic.body-small .navbar-fixed-top {
    left: 0;
  }
  main.fixed-nav.fixed-nav-basic.fixed-sidebar.mini-navbar.body-small
    .navbar-fixed-top {
    left: 220px;
  }
  main.mini-navbar .navbar-static-side {
    width: 70px;
  }
  main.mini-navbar .profile-element,
  main.mini-navbar .nav-label,
  main.mini-navbar .navbar-default .nav li a span {
    display: none;
  }
  main.canvas-menu .profile-element {
    display: block;
  }
  main:not(.fixed-sidebar):not(.canvas-menu).mini-navbar .nav-second-level {
    display: none;
  }
  main.mini-navbar .navbar-default .nav > li > a {
    font-size: 16px;
  }
  main.mini-navbar .logo-element {
    display: block;
  }
  main.canvas-menu .logo-element {
    display: none;
  }
  main.mini-navbar .nav-header {
    padding: 0;
    background-color: ${(props) => props.theme.colors.primary};
  }
  main.canvas-menu .nav-header {
    padding: 33px 25px;
  }
  main.canvas-menu .sidebar-collapse li {
    width: 100%;
  }
  main.mini-navbar #page-wrapper {
    width: calc(100% - 70px);
  }
  main.canvas-menu.mini-navbar #page-wrapper,
  main.canvas-menu.mini-navbar .footer {
    margin: 0;
    width: 100%;
  }
  main.fixed-sidebar .navbar-static-side,
  main.canvas-menu .navbar-static-side {
    width: 220px;
    z-index: 2001;
    height: 100vh;
    position: fixed;
  }
  main.fixed-sidebar.mini-navbar .navbar-static-side {
    width: 0;
  }
  main.fixed-sidebar #page-wrapper {
    margin: 0 0 0 220px;
  }
  main.fixed-sidebar.body-small #page-wrapper {
    margin: 0;
  }
  main.fixed-sidebar.mini-navbar #page-wrapper {
    margin: 0 0 0 0;
    width: 100%;
  }
  main.body-small.fixed-sidebar.mini-navbar #page-wrapper {
    margin: 0 0 0 220px;
  }
  main.body-small.fixed-sidebar.mini-navbar .navbar-static-side {
    width: 220px;
  }
  main.fixed-sidebar.mini-navbar
    .navbar-default
    .nav
    > li
    > .nav-second-level
    li
    a {
    font-size: 12px;
    border-radius: 3px;
  }
  main.canvas-menu.mini-navbar
    .navbar-default
    .nav
    > li
    > .nav-second-level
    li
    a {
    font-size: 13px;
    border-radius: 3px;
  }
  main.fixed-sidebar.mini-navbar .navbar-default .nav > li > a,
  main.canvas-menu.mini-navbar .navbar-default .nav > li > a {
    font-size: 13px;
  }
  main.fixed-sidebar.mini-navbar .nav-label,
  main.fixed-sidebar.mini-navbar .navbar-default .nav li a span,
  main.canvas-menu.mini-navbar .nav-label,
  main.canvas-menu.mini-navbar .navbar-default .nav li a span {
    display: inline;
  }
  main.canvas-menu.mini-navbar .navbar-default .nav li .profile-element a span {
    display: block;
  }
  main.canvas-menu nav.navbar-static-side {
    z-index: 2001;
    background:  ${(props) => props.theme.colors.colorMenuPrimary};
    height: 100%;
    position: fixed;
    display: none;
  }
  main.canvas-menu.mini-navbar nav.navbar-static-side {
    display: block;
    width: 220px;
  }
  main.tour-open .animated {
    animation-fill-mode: initial;
  }
  main.full-height-layout #wrapper,
  main.full-height-layout #page-wrapper {
    height: 100%;
  }
  main.boxed-layout {
    background: url("patterns/shattered.png");
  }
  main.boxed-layout #wrapper {
    background-color:  ${(props) => props.theme.colors.darkLight};
    max-width: 1200px;
    margin: 0 auto;
    -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.75);
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.75);
  }
  main.mini-navbar .branding {
    display: none;
  }
  main.fullscreen-ibox-mode {
    overflow-y: hidden;
  }
  main.modal-open {
    padding-right: inherit !important;
  }
  /* Show profile dropdown on fixed sidebar */
  main.mini-navbar.fixed-sidebar .profile-element,
  .block {
    display: block !important;
  }
  main.mini-navbar.fixed-sidebar .nav-header {
    padding: 33px 25px;
  }
  main.mini-navbar.fixed-sidebar .logo-element {
    display: none;
  }
  main.body-small .vote-icon {
    display: none;
  }
  body {
    overflow: visible !important;
  }

  @media (max-width: 768px) {
    main:not(.mini-navbar) {
      -webkit-transition: background-color 500ms linear;
      -moz-transition: background-color 500ms linear;
      -o-transition: background-color 500ms linear;
      -ms-transition: background-color 500ms linear;
      transition: background-color 500ms linear;
      background-color: ${(props) => props.theme.colors.light};
    }
  }

  /*PACE_DONE*/

  .pace-done .nav-header {
    transition: all 0.4s;
  }
  .pace-done .navbar-static-side,
  .pace-done .nav-header,
  .pace-done li.active,
  .pace-done #page-wrapper,
  .pace-done .footer {
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
  }

  /* BODY-SMALL */

  .body-small .navbar-top-links li:last-child {
    margin-right: 0;
  }
  .body-small .nav-second-level.collapsing li a,
  .mini-navbar .nav-second-level.collapsing li a {
    min-width: 140px;
  }
  .body-small .navbar-fixed-top {
    margin-left: 0;
  }
  .body-small .sidebar-content .wrapper,
  .body-small .wrapper.sidebar-content {
    padding-right: 20px !important;
  }
  .body-small .small-chat-box {
    bottom: 70px;
    right: 20px;
  }
  .body-small.rtls.top-navigation .navbar-header {
    float: none;
  }
  .body-small.rtls .navbar-fixed-top {
    margin-right: 0;
  }
  .body-small.rtls .navbar-header {
    float: right;
  }
  @media (max-width: 768px) {
    .body-small.rtls #page-wrapper {
      position: inherit;
      margin: 0 0 0 0;
      min-height: 1000px;
    }
  }
  @media (max-width: 768px) {
    .body-small .navbar-static-side {
      display: block;
      z-index: 2001;
      width: 0;
      overflow: hidden;
    }
    .body-small.mini-navbar .navbar-static-side {
      display: block;
      overflow: visible;
    }
  }

  /* MINI-BAR */

  .mini-navbar .nav-second-level li a {
    padding: 10px 10px 10px 15px;
  }
  .mini-navbar .nav .nav-second-level {
    position: absolute;
    left: 70px;
    top: 0;
    background-color:  ${(props) => props.theme.colors.colorMenuPrimary};
    padding: 10px 10px 10px 10px;
    font-size: 12px;
  }
  .mini-navbar li.active .nav-second-level {
    left: 65px;
  }
  .mini-navbar .metismenu .collapse {
    opacity: 0;
  }
  .mini-navbar .metismenu .collapse.in {
    opacity: 1;
  }
  .mini-navbar .metismenu .collapse a {
    display: none;
  }
  .mini-navbar .metismenu .collapse.in a {
    display: block;
  }
`;
