import { Form, Row } from "react-bootstrap";
import { Button, ButtonsContainer, Container, FormContainer, InputContainer, Title } from "./style";
import { ContainerHeader } from "components/Ibox/style";
import FileUpload from "components/UploadArquivos";

interface IFormularioProps {
    onChangeArquivo: (file: any) => void;
    onClickExecutar: () => void;
}
export function Formulario({
    onChangeArquivo,
    onClickExecutar,
}: IFormularioProps) {

    return (
        <Container>
            <ContainerHeader>
                <Title>Clonar Serviços Modelo para os Estados</Title>
            </ContainerHeader>
            <hr />
            <FormContainer>
                <Row>
                    <InputContainer>
                        <Form.Group>
                            <Form.Label style={{ marginBottom: "0.8rem" }}>
                                Arquivo (Excel)
                            </Form.Label>
                            <FileUpload
                                onFileUpload={(file: any) => { onChangeArquivo(file) }}
                            />
                        </Form.Group>
                    </InputContainer>


                    <InputContainer>
                        <Form.Group>
                            <ButtonsContainer>
                                <Button onClick={onClickExecutar}>Executar</Button>
                            </ButtonsContainer>
                        </Form.Group>
                    </InputContainer>
                </Row>
            </FormContainer>
        </Container>
    );
}