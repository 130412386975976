import { FaInfoCircle } from "react-icons/fa";
import styled from "styled-components";

export const ButtonReenviar = styled.button`
  background: none !important;
  border: none;
  padding: 0 !important;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary.main};
`;

export const TextoEtapa = styled.p`
  color: #767f85;
  font-weight: 400;
  font-size: 16px;
  margin: 0;
`;

export const TextoCodigo = styled.strong`
  font-size: 14px;
`;

export const Id = styled.p`
  margin-top: 1rem;
  margin-bottom: 3rem;
`;

export const ContainerDescricao = styled.div`
  text-align: center;
`;

export const NaoRecebido = styled.span`
  color: #767f85;
  font-size: 14px;
`;

export const ErroCodigo = styled.div`
  margin-top: 1rem;
  padding: 1rem;
  background-color: ${({ theme }) => theme.colors.danger.bgColor};
  color: ${({ theme }) => theme.colors.neutral.neutral60};
  border-radius: 6px;
`;

export const IconeErro = styled(FaInfoCircle)`
  margin-right: 8px;
  color: ${({ theme }) => theme.colors.danger.main} !important;
`;
