import { useEffect, useState } from "react";

export function useSelectedRows() {
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggledClearRows, setToggleClearRows] = useState(false);

  return {
    selectedRows,
    setSelectedRows,
    toggledClearRows,
    setToggleClearRows,
  };
}
