import { Page } from "containers/Page";
import { PagarIpvaLoteFiltros } from "./containers/FiltrosIpvaLote";
import { PagarIpvaLoteLista } from "./containers/ListarIpvaLote";
import { useListar } from "../../../Hooks/useListar";
import { useEffect, useState } from "react";
import { useEmpresas } from "hooks/useEmpresas";
import { useDivisoes } from "hooks/useDivisoes";
import { useCount } from "containers/CountMoney/hook/useCount";
import { ModalBaixarManualmente } from "./containers/ModalBaixarManualmente";
import { useRecibos } from "../../../Hooks/useRecibos";
import { alertaErro, alertaSucesso } from "components/Alerta";
import { ModalProcessosSemIpva } from "./containers/ModalProcessosSemIpva";
import { ModalRealizarPagamento } from "./containers/ModalRealizarPagamento";
import { LoadingScreen } from "components/LoadingScreen";
import { TableColumn } from "react-data-table-component";
import { ModalTentarNovamente } from "./containers/ModalTentarNovamente";

export function PagarIpvaLote() {
    const [carregando, setCarregando] = useState<boolean>(false)
    const {
        error,
        processosIpvaLista,
        listarProcesssIpva,
        loading,
        setLimit,
        setPage,
        totalRows,
        setOrderBy,
        setOrderByDirecao,
        orderBy,
        orderByDirecao
    } = useListar();
    const {
        listarRecibosDaEmpresa,
        baixarManualmente,
        reciboEmpresaLista,
        processosComIpva,
        processosSemIpva,
        baixarManualmenteProcessosComIpva,
        realizarPagamento
    } = useRecibos()
    useEffect(() => {
        if (!error) return;
    }, [error]);

    const [showDataTable, setShowDataTable] = useState<boolean>(false);

    async function submitForm() {
        if (empresaSelecionada) {
            setCarregando(true);
            await listarProcesssIpva({
                empresa: empresaSelecionada,
                divisao: divisaoSelecionada,
                inicioDataCadastroProcesso,
                fimDataCadastroProcesso,
                inicioDataVencimento,
                fimDataVencimento,
                statusPagamento: statusPagamentoSelecionado,
                coluna: orderBy,
                direcao: orderByDirecao
            });
            setShowDataTable(true);
            setCarregando(false);
            return;
        }
        alertaErro("Ocorreu um erro", "O campo empresa é obrigatório.")
    }

    function handleLimitChange(limit: number) {
        setLimit(limit);
    }

    function handlePageChange(page: number) {
        setPage(page);
    }
    function handleOnSort(coluna: TableColumn<any>, direcao: string) {
        setOrderBy(coluna.sortField);
        setOrderByDirecao(direcao);
    }
    useEffect(() => {
        if (empresaSelecionada) {
            submitForm()
        }
    }, [orderBy, orderByDirecao]);

    const { empresasOptions } = useEmpresas();
    const [empresaSelecionada, setEmpresaSelecionada] = useState<number>();
    const { divisoesOptions, listarTodasDivisoes } = useDivisoes("");
    const [divisaoSelecionada, setDivisaoSelecionada] = useState<number>();
    const onChangeEmpresa = (empresa: any) => {
        setEmpresaSelecionada(empresa);
    }
    const onChangeDivisao = (divisao: any) => {
        setDivisaoSelecionada(divisao)
    }
    useEffect(() => {
        listarTodasDivisoes(empresaSelecionada);
        listarRecibosDaEmpresa(empresaSelecionada);
    }, [empresaSelecionada])


    const [inicioDataVencimento, setInicioDataVencimento] = useState<Date>();
    const [fimDataVencimento, setFimDataVencimento] = useState<Date>();
    const OnChangeDataVencimento = (dates: any) => {
        const [start, end] = dates;
        setInicioDataVencimento(start);
        setFimDataVencimento(end);
    };

    const [inicioDataCadastroProcesso, setInicioDataCadastroProcesso] = useState<Date>();
    const [fimDataCadastroProcesso, setFimDataCadastroProcesso] = useState<Date>();
    const onChangeDataCadastroProcesso = (dates: any) => {
        const [start, end] = dates;
        setInicioDataCadastroProcesso(start);
        setFimDataCadastroProcesso(end);
    };

    const [statusPagamentoSelecionado, setStatusPagamentoSlecionado] = useState<string>();
    const OnChangeStatusPagamentoSelecionado = (status: any) => {
        setStatusPagamentoSlecionado(status)
    };

    const [showModalRealizarPagamento, setShowModalRealizarPagamento] = useState<boolean>(false);
    const [dropdownReciboValorRealizarPagamento, setDropdownReciboValorRealizarPagamento] = useState<number>();
    function dropdownReciboOnSelectRealizarPagamento(recibo: any) {
        setDropdownReciboValorRealizarPagamento(recibo);
    }
    const handleCloseModalRealizarPagamento = () => {
        setShowModalRealizarPagamento(false);
        setDropdownReciboValorRealizarPagamento(undefined)
    };
    const onClickRealizarPagamento = () => {
        if (!empresaSelecionada) {
            alertaErro("Ocorreu um erro", "O campo empresa é obrigatório.");
            return;
        }
        if (!idsProcessos || idsProcessos.length == 0) {
            alertaErro("Ocorreu um erro", "É necessário selecionar algum processo.");
            return;
        }
        setShowModalRealizarPagamento(true);
    }
    const onConfirmRealizarPagamento = async () => {
        if (!dropdownReciboValorRealizarPagamento) {
            alertaErro("Ocorreu um erro", "É necessário escolher um recibo.");
            return;
        }
        setShowModalRealizarPagamento(false);
        setCarregando(true);
        const { data, hasErro } = await realizarPagamento(dropdownReciboValorRealizarPagamento, idsProcessos);
        handleClearTable();
        await submitForm();
        setDropdownReciboValorRealizarPagamento(undefined);
        if (hasErro) {
            alertaErro("Ocorreu um erro", data.message);
            return;
        }
        alertaSucesso("Pagamento Realizado", "Pagamento realizado com sucesso!");
    }

    const [showModalBaixarManualmente, setShowModalBaixarManualmente] = useState<boolean>(false);
    const handleCloseModalBaixarManualmente = () => {
        setShowModalBaixarManualmente(false);
        setDropdownReciboValorBaixarManualmente(undefined)
    };
    const onClickBaixarManualmente = () => {
        if (!empresaSelecionada) {
            alertaErro("Ocorreu um erro", "O campo empresa é obrigatório.");
            return;
        }
        if (!idsProcessos || idsProcessos.length == 0) {
            alertaErro("Ocorreu um erro", "É necessário selecionar algum processo.");
            return;
        }
        setShowModalBaixarManualmente(true);
    }

    const [showMaisFiltros, setShowMaisFiltros] = useState(false);
    const aplicarOnClick = () => {
        setShowMaisFiltros(false);
    }
    const cancelarOnClick = () => {
        setStatusPagamentoSlecionado("");
        setInicioDataCadastroProcesso(undefined);
        setFimDataCadastroProcesso(undefined);
        setShowMaisFiltros(false);
    }
    const limparTudoOnClick = () => {
        setStatusPagamentoSlecionado("");
        setInicioDataCadastroProcesso(undefined);
        setFimDataCadastroProcesso(undefined);
    }

    const [dropdownReciboValorBaixarManualmente, setDropdownReciboValorBaixarManualmente] = useState<number>();
    function dropdownReciboOnSelectBaixarManualmente(recibo: any) {
        setDropdownReciboValorBaixarManualmente(recibo);
    }
    const onConfirmBaixarManualmente = async () => {
        if (!dropdownReciboValorBaixarManualmente) {
            alertaErro("Ocorreu um erro", "É necessário escolher um recibo.");
            return;
        }
        setShowModalBaixarManualmente(false);
        setCarregando(true);
        const { data, hasErro } = await baixarManualmente(idsProcessos)
        setCarregando(false)
        if (hasErro) {
            alertaErro("Ocorreu um erro", data.message);
            return;
        }
    }
    useEffect(() => {
        if (idsProcessos.length > 0) {
            if (processosComIpva.length > 0) {
                if (processosSemIpva.length > 0) {
                    setShowModalProcessosSemIpva(true)
                    return;
                }

                handleOkModalProcessosSemIpva();
                return;
            }
            if (processosComIpva.length == 0) {
                alertaErro("Ocorreu um erro", "Nenhum processo selecionado possui custo de IPVA para vincular ao recibo.");
            }
        }
    }, [processosComIpva, processosSemIpva]);
    const [showModalProcessosSemIpva, setShowModalProcessosSemIpva] = useState<boolean>(false);
    const handleCloseModalProcessosSemIpva = () => {
        setShowModalProcessosSemIpva(false);
        setDropdownReciboValorBaixarManualmente(undefined)
    };
    async function handleOkModalProcessosSemIpva() {
        setShowModalProcessosSemIpva(false);
        setCarregando(true);
        const { data, hasErro } = await baixarManualmenteProcessosComIpva(dropdownReciboValorBaixarManualmente, processosComIpva);
        handleClearTable();
        await submitForm();
        setDropdownReciboValorBaixarManualmente(undefined)

        if (hasErro) {
            alertaErro("Ocorreu um erro", data.message);
            return;
        }
        alertaSucesso("Baixa Realizada", "Pagamento baixado com sucesso!");
    }

    const [idProcessoTentarNovamente, setIdProcessoTentarNovamente] = useState<number>(0);
    const [motivoErro, setMotivoErro] = useState<string>("");
    function handleTentarNovamente(idProcesso: any, erroDescricao: any) {
        if (!erroDescricao || erroDescricao.length <= 0) {
            return;
        }
        if (!empresaSelecionada) {
            alertaErro("Ocorreu um erro", "O campo empresa é obrigatório.");
            return;
        }
        setIdProcessoTentarNovamente(idProcesso);
        setMotivoErro(erroDescricao)
    }
    useEffect(() => {
        if (idProcessoTentarNovamente) {
            setShowModalTentarNovamente(true);
        }
    }, [idProcessoTentarNovamente])
    const [showModalTentarNovamente, setShowModalTentarNovamente] = useState<boolean>(false);
    const handleCloseModalTentarNovamente = () => {
        setIdProcessoTentarNovamente(0);
        setMotivoErro("");
        setShowModalTentarNovamente(false);
    };
    const handleOkModalTentarNovamente = () => {
        setShowModalTentarNovamente(false);
        setIdsProcessos([idProcessoTentarNovamente])
        setShowModalRealizarPagamento(true);
        setIdProcessoTentarNovamente(0);
        setMotivoErro("");
    };

    const [clearRows, setClearRows] = useState<boolean>(false);
    function handleClearTable() {
        setClearRows(true);
    }

    const [idsProcessos, setIdsProcessos] = useState<number[]>([]);
    const {
        setSelectedRows
    } = useCount();
    const getLinhas = (linhas: any[]) => {
        setClearRows(false);
        setSelectedRows(linhas);
        handleIdsProcessos(linhas);
    };
    function handleIdsProcessos(processos: any[]) {
        setIdsProcessos(processos.map((item) => item.id));
    }

    return (
        <Page title="Pagar IPVA">
            <LoadingScreen mostrar={carregando} />
            <PagarIpvaLoteFiltros
                empresasOptions={empresasOptions}
                empresaSelecionada={empresaSelecionada}
                onChangeEmpresa={onChangeEmpresa}
                divisoesOptions={divisoesOptions}
                onChangeDataVencimento={OnChangeDataVencimento}
                inicioDataVencimento={inicioDataVencimento}
                fimDataVencimento={fimDataVencimento}
                onChangeDataCadastroProcesso={onChangeDataCadastroProcesso}
                inicioDataCadastroProcesso={inicioDataCadastroProcesso}
                fimDataCadastroProcesso={fimDataCadastroProcesso}
                formSubmit={submitForm}
                onChangeDivisao={onChangeDivisao}
                divisaoSelecionada={divisaoSelecionada}
                onChangeStatusPagamento={OnChangeStatusPagamentoSelecionado}
                statusPagamentoSelecionado={statusPagamentoSelecionado}
                showMaisFiltros={showMaisFiltros}
                aplicarOnClick={aplicarOnClick}
                limparTudoOnClick={limparTudoOnClick}
                cancelarOnClick={cancelarOnClick}
            ></PagarIpvaLoteFiltros>
            <PagarIpvaLoteLista
                show={showDataTable}
                dados={processosIpvaLista}
                loading={loading}
                totalRows={totalRows}
                onChangeRowsPerPage={handleLimitChange}
                onChangePage={handlePageChange}
                setSelectedRows={getLinhas}
                clearSelectedRows={clearRows}
                onClickBaixarManualmente={onClickBaixarManualmente}
                onClickRealizarPagamento={onClickRealizarPagamento}
                onSort={handleOnSort}
                handleTentarNovamente={handleTentarNovamente}
            ></PagarIpvaLoteLista>
            <ModalBaixarManualmente
                show={showModalBaixarManualmente}
                handleClose={handleCloseModalBaixarManualmente}
                onConfirm={onConfirmBaixarManualmente}
                dropdownReciboOnSelect={dropdownReciboOnSelectBaixarManualmente}
                dropdownReciboValor={dropdownReciboValorBaixarManualmente}
                dropdownReciboOptions={reciboEmpresaLista}
            ></ModalBaixarManualmente>
            <ModalProcessosSemIpva
                show={showModalProcessosSemIpva}
                handleClose={handleCloseModalProcessosSemIpva}
                onConfirm={handleOkModalProcessosSemIpva}
                idsProcessosSemIpva={processosSemIpva}
            ></ModalProcessosSemIpva>
            <ModalRealizarPagamento
                show={showModalRealizarPagamento}
                handleClose={handleCloseModalRealizarPagamento}
                onConfirm={onConfirmRealizarPagamento}
                dropdownReciboOnSelect={dropdownReciboOnSelectRealizarPagamento}
                dropdownReciboValor={dropdownReciboValorRealizarPagamento}
                dropdownReciboOptions={reciboEmpresaLista}
            ></ModalRealizarPagamento>
            <ModalTentarNovamente
                show={showModalTentarNovamente}
                handleClose={handleCloseModalTentarNovamente}
                onConfirm={handleOkModalTentarNovamente}
                motivoErro={motivoErro}
            ></ModalTentarNovamente>
        </Page>
    );
}