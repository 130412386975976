import * as yup from "yup";
import { portugues } from "_utils/validador";

yup.setLocale(portugues);

export const preenchimentoAtpvFiltrosSchema = yup.object().shape({
    idSolicitacao: yup.string().optional().nullable(),
    placa: yup.string().optional().nullable(),
    empresa: yup.number().optional().nullable(),
    periodoSolicitacao: yup.array().optional().nullable(),
    statusSolicitacao: yup.string().optional().nullable(),
});

export const modalPreencherATPVFormularioUnitarioSchema = yup.object().shape({
    uf: yup.string().required("Escolha uma UF"),
    placa: yup.string().required("Placa obrigatória"),
    chassi: yup.string().required("Chassi obrigatório"),
    renavam: yup.string().required("Renavam obrigatório"),
    odometro: yup.string().required("Odômetro obrigatório"),
    empresa: yup.number().required("Escolha uma empresa"),
    divisao: yup.number().required("Escolha uma divisão"),
    valorVenda: yup.string().required("Valor obrigatório"),
    cpfCnpjVendedor: yup.string().required("CPF/CNPJ obrigatório"),
    emailVendedor: yup.string().required("E-mail obrigatório"),
    cpfCnpjComprador: yup.string().required("CPF/CNPJ obrigatório"),
    emailComprador: yup.string().required("E-mail obrigatório"),
    nomeComprador: yup.string().required("Nome obrigatório"),
    cepComprador: yup.string().required("CEP obrigatório"),
    logradouroComprador: yup.string().required("Logradouro obrigatório"),
    bairroComprador: yup.string().required("Bairro obrigatório"),
    numeroComprador: yup.string().required("Número obrigatório"),
    complementoComprador: yup.string().optional(),
    cidadeComprador: yup.string().required("Cidade obrigatória"),
    ufComprador: yup.string().required("UF obrigatória"),
});

export const modalPreencherATPVFormularioLoteSchema = yup.object().shape({
    uf: yup.string().required("Escolha uma UF"),
    empresa: yup.number().required("Escolha uma empresa"),
    divisao: yup.number().required("Escolha uma divisão"),
    arquivo: yup
        .mixed()
        .test("file-selected", "Selecione um arquivo", (value) => {
            return value instanceof File && value.size > 0;
        })
        .required("Selecione um arquivo")
        .nonNullable(),
});