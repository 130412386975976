import { ModalCustom } from "components/ModalCustom";
import { Body, InputContainer } from "./style";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Form, Row } from "react-bootstrap";
import { CustomInput } from "components/CustomInput";
import { honorarioServicoDespachanteSchema } from "features/core/pages/Cadastro/Validate/validator";
import { useMaskInput } from "hooks/useMaskInput";

interface IModalHonorarioServicoDespachanteProps {
    show: boolean;
    handleClose: () => void;
    valorHonorarioServico: string;
    submitForm: () => void;
    onChangeValor: (event: any) => void;
    disabledInput: boolean;
}

export function ModalHonorarioServicoDespachante({
    show,
    handleClose,
    valorHonorarioServico,
    submitForm,
    onChangeValor,
    disabledInput,
}: IModalHonorarioServicoDespachanteProps) {



    return (
        <ModalCustom
            show={show}
            handleClose={handleClose}
            title="Honorário"
            centered
            footer
            footerConfirmButton
            confirmButtonText="Salvar"
            onConfirm={submitForm}
        >
            <Body>
                <Form >
                    <Row>
                        <InputContainer>
                            <Form.Group>
                                <Form.Label style={{ marginBottom: "0.3rem" }}>
                                    Descrição
                                </Form.Label>
                                <CustomInput
                                    disabled
                                    type="text"
                                    style={{ height: "2.2rem" }}
                                    value="Honorário"
                                />
                            </Form.Group>
                        </InputContainer>
                        <InputContainer>
                            <Form.Group>
                                <Form.Label style={{ marginBottom: "0.3rem" }}>
                                    Valor
                                </Form.Label>
                                <CustomInput
                                    type="text"
                                    placeholder="Informe o valor do honorário"
                                    style={{ height: "2.2rem" }}
                                    onChange={onChangeValor}
                                    value={valorHonorarioServico}
                                    disabled={disabledInput}
                                />
                            </Form.Group>
                        </InputContainer>
                    </Row>
                </Form>
            </Body>
        </ModalCustom>
    );
}