export function useMask() {
  function mTel(valor: string): string {
    valor = valor.replace(/\D/g, "");
    valor = valor.replace(/^(\d\d)(\d)/g, "($1) $2");
    if (valor.length <= 13) {
      valor = valor.replace(/(\d{4})(\d)/, "$1-$2");
    } else {
      valor = valor.replace(/(\d{5})(\d)/, "$1-$2");
    }
    return valor;
  }

  function mCNPJ(cnpj: string): string {
    cnpj = cnpj.replace(/\D/g, "");
    cnpj = cnpj.replace(/^(\d{2})(\d)/, "$1.$2");
    cnpj = cnpj.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
    cnpj = cnpj.replace(/\.(\d{3})(\d)/, ".$1/$2");
    cnpj = cnpj.replace(/(\d{4})(\d)/, "$1-$2");
    return cnpj;
  }

  function mCPF(cpf: string): string {
    cpf = cpf.replace(/\D/g, "");
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
    cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
    return cpf;
  }

  function mCPFCNPJ(valor: string) {
    if (valor.length <= 14) {
      return mCPF(valor);
    } else {
      return mCNPJ(valor);
    }
  }

  function mCEP(cep: string): string {
    cep = cep.replace(/\D/g, "");
    cep = cep.replace(/(\d{5})(\d)/, "$1-$2");
    return cep;
  }
  function mNum(num: string): string {
    num = num.replace(/\D/g, "");
    return num;
  }

  function mUF(uf: string): string {
    return uf.toUpperCase();
  }

  function mCoin(num: string): string {
    var v = num,
      dollar = "0",
      cents = "00";
    if (v.indexOf(".") !== -1) {
      var price = v.split(".");
      dollar = price[0] || "0";
      cents = price[1] || "00";
    }
    if (cents.length === 1) {
      if (dollar) {
        var dollarNumbers = dollar.split("");
        var dollarLength = dollarNumbers.length;
        cents = dollarNumbers[dollarLength - 1] + cents;
        dollar = "";
        for (var i = 0; i < dollarLength - 1; i++) {
          dollar += dollarNumbers[i];
        }
        if (!dollar) {
          dollar = "0";
        }
      }
    }
    if (v.length === 1) {
      cents = "0" + v;
    }
    if (cents.length === 3) {
      var centNumbers = cents.split("");
      dollar = dollar === "0" ? centNumbers[0] : dollar + centNumbers[0];
      cents = centNumbers[1] + centNumbers[2];
    }
    num = dollar + "." + cents;
    return num;
  }

  function mNumeroRomanos(valor: string) {
    valor = valor.toUpperCase();
    valor = valor.replace(/[^IVXLCDM]/g, "");
    while (
      valor.replace(
        /^M{0,4}(CM|CD|D?C{0,3})(XC|XL|L?X{0,3})(IX|IV|V?I{0,3})$/,
        ""
      ) !== ""
    )
      valor = valor.replace(/.$/, "");

    return valor;
  }

  function mSite(valor: string) {
    valor = valor.replace(/^http:\/\/?/, "");
    let dominio = valor;
    let caminho = "";
    if (valor.indexOf("/") > -1) dominio = valor.split("/")[0];
    caminho = valor.replace(/[^\/]*/, "");
    dominio = dominio.replace(/[^\w\.\+-:@]/g, "");
    caminho = caminho.replace(/[^\w\d\+-@:\?&=%\(\)\.]/g, "");
    caminho = caminho.replace(/([\?&])=/, "$1");
    if (caminho !== "") dominio = dominio.replace(/\.+$/, "");
    valor = "http://" + dominio + caminho;

    return valor;
  }

  function mDate(valor: string) {
    valor = valor.replace(/\D/g, "");
    valor = valor.replace(/(\d{2})(\d)/, "$1/$2");
    valor = valor.replace(/(\d{2})(\d)/, "$1/$2");

    return valor;
  }

  function mTime(valor: string) {
    valor = valor.replace(/\D/g, "");
    valor = valor.replace(/(\d{2})(\d)/, "$1:$2");
    valor = valor.replace(/(\d{2})(\d)/, "$1:$2");

    return valor;
  }
  function mDateTime(valor: string) {
    valor = valor.replace(/\D/g, "");
    valor = valor.replace(/(\d{2})(\d)/, "$1/$2");
    valor = valor.replace(/(\d{2})(\d)/, "$1/$2");

    valor = valor.replace(/(\d{4})(\d)/, "$1 $2");
    valor = valor.replace(/(\d{2})(\d{2})(\d)/, "$1:$2:$3");

    return valor;
  }

  function mArea(valor: string) {
    valor = valor.replace(/\D/g, "");
    valor = valor.replace(/(\d)(\d{2})$/, "$1.$2");
    return valor;
  }

  function mPlaca(valor: string) {
    valor = valor.replace(" ", "");
    valor.toUpperCase()

    const regexPlaca = /^[a-zA-Z]{3}[0-9]{4}$/;
    if (regexPlaca.test(valor)) {
      valor = valor.replace(/([a-zA-Z]{3})([0-9]{4})$/, "$1-$2");
    }
    return valor;
  }

  return {
    mNum,
    mCEP,
    mCPF,
    mCNPJ,
    mCPFCNPJ,
    mTel,
    mCoin,
    mUF,
    mNumeroRomanos,
    mSite,
    mDate,
    mTime,
    mDateTime,
    mArea,
    mPlaca,
  };
}
