import styled from "styled-components";
import 'react-datepicker/dist/react-datepicker.css';

interface ContainerProps {
  show: boolean;
}

export const Container = styled.div<ContainerProps>`
  ${(props) => { return props.show ? "" : "display: none;" }}
  margin-top: 2.5rem;
  width: 100%;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const Header = styled.h2`
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
`;

export const TextoHeader = styled.span`
  color: ${({ theme }) => theme.colors.neutral.neutral90};
  font-weight: bold;
  font-size: 1.2rem;  
  padding-top: 6px;
`;

export const Buttons = styled.span`
  display: flex;
`;

export const ButtonContainer = styled.span`
  font-size: 13px;
  margin-right: 6px;
`;

export const Body = styled.div`

`;