import { IRedefinirSenha } from "features/Login/@types/IRedefinirSenha";
import { useRedefinirSenha } from "features/Login/hooks/useRedefinirSenha";
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { ErroCodigo, IconeErro } from "../Codigo2Fatores/style";
import { ContainerForm, CustomButton, EmailIcon, Title } from "../EsqueceuSenha/style";
import { Carregamento, IconeOlho, IconeOlhoCortado, IconeSenha, InputSenha } from "../LoginBox/style";
import { ContainerNovaSenha, ContainerProgressBar, ContainerRegrasSenha, ContainerSucesso, CustomButtonSucesso, FaCheckCircle, FaCheckCircleDireito, FaExclamationCircleDireito, FaTimesCircle, InputConfirmarNovaSenha, InputNovaSenha, Texto, TextoNovaSenha, TextoSucesso, TitleNovaSenha, TitleSucesso } from "./style";
import { Required } from "components/Required";

interface IEsqueceuSenhaProps {
  handleClickVoltarAoLogin(): void;
}

export function RedefinirSenha({ handleClickVoltarAoLogin }: IEsqueceuSenhaProps) {
  const [tamanhoSenha, setTamanhoSenha] = useState(<FaTimesCircle />);
  const [letraMaiculoMinuscula, setLetraMaiculoMinuscula] = useState(<FaTimesCircle />);
  const [numeros, setNumeros] = useState(<FaTimesCircle />);
  const [especiais, setEspeciais] = useState(<FaTimesCircle />);
  const [senha, setSenha] = useState("");
  const [inputType, setInputType] = useState("password");
  const [progresso, setProgresso] = useState<number>(0);
  const [params, serParams] = useSearchParams();
  const [tela, setTela] = useState<"redefinir" | "definir">("redefinir");
  const [token, setToken] = useState<string>("");
  const [erro, setErro] = useState<string>("");
  const [sucesso, setSucesso] = useState<boolean>(false);
  const [esconderValidacaoSenha, setEsconderValidacaoSenha] = useState<boolean>(true);
  const [inputAtivoSenha, setInputAtivoSenha] = useState<boolean>(false);
  const [exibirErro, setExibirErro] = useState<boolean>(true);

  const { validarMinutosRedefinirSenha, validarHorasRedefinirSenha, loading, gravarSenha, definirPrimeiraSenha } =
    useRedefinirSenha();

  const strategy = {
    redefinir: {
      titulo: "Redefinir senha",
      texto: null,
      validar: validarMinutosRedefinirSenha,
      salvar: gravarSenha,
      sucesso: "Senha redefinida com sucesso!",
      btn: "Redefinir senha",
      placeholderSenha: "Insira sua nova senha",
      placeholderConfirmar: "Confirme sua nova senha",
      labelSenha: "Nova senha",
      labelConfirmar: "Confirme a sua nova senha",
    },
    definir: {
      titulo: "Definir senha",
      texto: "Defina sua senha de acesso ao LW Doc.",
      validar: validarHorasRedefinirSenha,
      salvar: definirPrimeiraSenha,
      sucesso: "Senha definida com sucesso!",
      btn: "Gravar",
      placeholderSenha: "Insira sua senha",
      placeholderConfirmar: "Confirme sua senha",
      labelSenha: "Senha",
      labelConfirmar: "Confirme a sua senha",
    },
  };

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<IRedefinirSenha>();

  useEffect(() => {
    async function run() {
      const definirSenha = params.get("senha");

      if (definirSenha == "redefinir" || definirSenha == "definir") {
        setTela(definirSenha);

        const tokenAtual = `${params.get("token")}`;

        const { hasErro } = await strategy[definirSenha].validar({
          token: tokenAtual,
        });

        if (hasErro) {
          window.location.href = "/app/?m=err";
          return;
        }
        setToken(tokenAtual);
      }
    }
    run();
  }, []);

  function handleMostrarSenha() {
    setInputType((prev) => (prev === "password" ? "text" : "password"));
  }

  function contemLetras(string: string) {
    if (!/[a-z]/g.test(string)) {
      return false;
    }
    if (!/[A-Z]/g.test(string)) {
      return false;
    }
    return true;
  }

  function contemNumeros(string: string) {
    return /\d/.test(string);
  }

  function contemCaracteresEspeciais(string: string) {
    return /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(string);
  }

  useEffect(() => {
    setProgresso(0);
    setTamanhoSenha(<FaTimesCircle />);
    setLetraMaiculoMinuscula(<FaTimesCircle />);
    setNumeros(<FaTimesCircle />);
    setEspeciais(<FaTimesCircle />);

    if (senha.length > 7) {
      setProgresso((anterior) => anterior + 25);
      setTamanhoSenha(<FaCheckCircle />);
    }

    if (contemLetras(senha)) {
      setProgresso((anterior) => anterior + 25);
      setLetraMaiculoMinuscula(<FaCheckCircle />);
    }

    if (contemNumeros(senha)) {
      setProgresso((anterior) => anterior + 25);
      setNumeros(<FaCheckCircle />);
    }

    if (contemCaracteresEspeciais(senha)) {
      setProgresso((anterior) => anterior + 25);
      setEspeciais(<FaCheckCircle />);
    }
  }, [senha]);

  async function handleDefinirSenha(params: IRedefinirSenha) {
    setEsconderValidacaoSenha(true);
    setErro("");
    setExibirErro(true);
    if (params.senha !== params.confirmarSenha) {
      setErro("As senhas nos dois campos precisam ser iguais");
      return;
    }

    const { data, hasErro } = await strategy[tela].salvar({
      token: token,
      senha: params.senha,
      confirmeSenha: params.confirmarSenha,
    });

    if (hasErro) {
      setErro(data.message);
      return;
    }

    setSucesso(true);
  }

  const onClickDigitarSenha = () => {
    setEsconderValidacaoSenha(false);
    setInputAtivoSenha(true);
    setExibirErro(false);
  }

  const onClickConfirmarSenha = () => {
    setEsconderValidacaoSenha(true);
    setInputAtivoSenha(false);
    setExibirErro(false);
  }

  const progressoConcluido = 100;

  return (
    <div>
      {!sucesso && (
        <>
          <ContainerNovaSenha>
            <TitleNovaSenha>{strategy[tela].titulo}</TitleNovaSenha>
            {!!strategy[tela].texto && (<TextoNovaSenha>{strategy[tela].texto}</TextoNovaSenha>)}
          </ContainerNovaSenha>
          <ContainerForm onSubmit={handleSubmit(handleDefinirSenha)}>
            <Form.Group>
              <Form.Label>{strategy[tela].labelSenha} <Required /></Form.Label>
              <InputNovaSenha
                onClick={onClickDigitarSenha}
                type={inputType}
                iconeEsquerdo={<IconeSenha style={{ marginLeft: "12px", fontSize: "14px" }} />}
                iconeDireito={inputType === "password" ? <IconeOlho /> : <IconeOlhoCortado />}
                placeholder={strategy[tela].placeholderSenha}
                noBoxShadow
                validar
                paddingIcon
                iconeDireitoInterno={
                  inputAtivoSenha ? (
                    progresso == progressoConcluido
                      ? <FaCheckCircleDireito />
                      : <FaExclamationCircleDireito />
                  ) : null
                }
                onClickMostrarSenha={handleMostrarSenha}
                mensagemErro={errors.senha?.message || (progresso < progressoConcluido && inputAtivoSenha)}
                sucesso={progresso == progressoConcluido && inputAtivoSenha}
                {...register("senha", {
                  onChange: (item) => {
                    setSenha(item.target.value);
                    setErro("");
                  },
                  onBlur: () => { setInputAtivoSenha(false) }
                })}
              />
            </Form.Group>
            <div style={esconderValidacaoSenha ? { display: "none" } : {}}>
              {!!senha && (
                <ContainerProgressBar>
                  <span>Força da senha</span>
                  <ProgressBar now={progresso} variant={progresso == progressoConcluido ? "success" : "danger"} />
                </ContainerProgressBar>
              )}
              <ContainerRegrasSenha>
                <strong>A senha deve conter</strong>
                <div>
                  <div>
                    {tamanhoSenha}
                    Entre 8 e 25 caracteres
                  </div>
                  <div>
                    {letraMaiculoMinuscula}
                    Letras maiúsculas e minúsculas
                  </div>
                  <div>
                    {numeros}
                    Números (0-9)
                  </div>
                  <div>
                    {especiais}
                    Caracteres especiais ($, %, @, #...)
                  </div>
                </div>
              </ContainerRegrasSenha>
            </div>
            <Form.Group style={{ marginTop: "2rem" }}>
              <Form.Label>{strategy[tela].labelConfirmar} <Required /></Form.Label>
              <InputConfirmarNovaSenha
                onClick={onClickConfirmarSenha}
                type={inputType}
                iconeEsquerdo={<IconeSenha style={{ marginLeft: "12px", fontSize: "14px" }} />}
                iconeDireito={inputType === "password" ? <IconeOlho /> : <IconeOlhoCortado />}
                noBoxShadow
                placeholder={strategy[tela].placeholderConfirmar}
                onClickMostrarSenha={handleMostrarSenha}
                {...register("confirmarSenha", {
                  onChange: (item) => {
                    setErro("");
                  },
                })}
                paddingIcon
                mensagemErro={errors.senha?.message}
              />
            </Form.Group>
            {erro && exibirErro && (
              <ErroCodigo>
                <IconeErro />{erro}
              </ErroCodigo>
            )}
            <CustomButton type="submit">{loading ? <Carregamento /> : strategy[tela].btn}</CustomButton>
          </ContainerForm>
        </>
      )
      }

      {
        sucesso && (
          <ContainerSucesso>
            <TitleSucesso>{strategy[tela].sucesso}</TitleSucesso>
            <TextoSucesso>Clique no link abaixo para fazer login</TextoSucesso>
            <CustomButtonSucesso onClick={handleClickVoltarAoLogin}>Fazer login</CustomButtonSucesso>
          </ContainerSucesso>
        )
      }
    </div >
  );
}
