import { yupResolver } from "@hookform/resolvers/yup";
import { ILogar } from "features/Login/@types/ILogar";
import { useLogin } from "features/Login/hooks/useLogin";
import { loginSchema } from "features/Login/validator/loginValidator";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Codigo2Fatores } from "../Codigo2Fatores";
import { ButtonReenviar, Carregamento, ContainerDescricao, ContainerForm, CustomButton, ErroCodigo, IconeErro, NaoRecebido } from "./style";
import { LoginForm } from "../Login";
import { Cookies } from "_utils/Cookies";
import { useSearchParams } from "react-router-dom";
import { LoadingScreen } from "components/LoadingScreen";
import { ModalErroToken } from "./ModalErroToken";

interface ILoginProps {
  handleClickEsqueceuSenha: () => void;
}

export function LoginBox({ handleClickEsqueceuSenha }: ILoginProps) {
  const { logar, validar, loading, crsf, validarToken, validarTokenObterDados } = useLogin();
  const [params] = useSearchParams();

  const [loadingLogin, setLoadingLogin] = useState<boolean>(true);
  const [usuarioBloqueado, setUsuarioBloqueado] = useState<boolean>(false);
  const [idEmail, setIdEmail] = useState<string>("");
  const [etapaAtivarCodigo, setEtapaAtivarCodigo] = useState<boolean>(false);
  const [erro, setErro] = useState<string>("");
  const [erroCodigo, setErroCodigo] = useState<string>("");
  const [codigoDigitado, setCodigoDigitado] = useState<string>("");
  const [reenviar, setReenviar] = useState<boolean>(false);
  const [crsfCodigo, setCrsfCodigo] = useState<string>("");
  const [codigoExpirado, setCodigoExpirado] = useState<boolean>(false);
  const [validarTokenUrl, setValidarTokenUrl] = useState<boolean>(true);
  const [modalErroToken, setModalErroToken] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm<ILogar>({
    resolver: yupResolver<ILogar>(loginSchema),
  });

  useEffect(() => {
    handleUrl();
  }, []);

  async function run() {
    const { data } = await crsf();
    setCrsfCodigo(data.tokenCSRF);

    const { data: dataValidar } = await validarToken();

    if (dataValidar) {
      window.location.href = "/pages/dashboard/Dashboard.php";
      return;
    }

    setLoadingLogin(false);

    const strategyMensagem: { [key: string]: string } = {
      idNull: "Sessão expirada.",
      err: "Não foi possível validar o usuário.",
      segValida: "A validação do usuário não foi possível.",
    };

    const mensagem = params.get("m");

    if (mensagem) {
      if (Object.keys(strategyMensagem).includes(mensagem)) {
        setErro(strategyMensagem[mensagem]);
      }
    }
  }

  async function handleUrl() {
    const tokenLwDocUrl = params.get("token")
    if (tokenLwDocUrl && validarTokenUrl) {
      setValidarTokenUrl(false);

      const resultadoObterDadosToken: any = await validarTokenObterDados(tokenLwDocUrl);

      if (!resultadoObterDadosToken.data) {
        setModalErroToken(true)
        return;
      }
      localStorage.setItem("@link_imagem_perfil", resultadoObterDadosToken.data.imagem);
      localStorage.setItem("@nome_usuario", resultadoObterDadosToken.data.nome);
      localStorage.setItem("@email_usuario", resultadoObterDadosToken.data.email);
      localStorage.setItem("@cargo_usuario", resultadoObterDadosToken.data.cargo);
      localStorage.setItem("@nome_empresa_usuario", resultadoObterDadosToken.data.idEmpresa);
      localStorage.setItem("@id_usuario", resultadoObterDadosToken.data.id);
      localStorage.setItem("@administrador", resultadoObterDadosToken.data.adm);
      localStorage.setItem("@token_keycloak", resultadoObterDadosToken.data.tokenKeycloak);
      localStorage.setItem("@AWDOC_TOKEN", tokenLwDocUrl);

      await validar(tokenLwDocUrl);

      const baseUrlDespachante = process.env.REACT_APP_DESPACHANTE_BASE_URL;
      window.location.assign(`${baseUrlDespachante}/pages/dashboard/Dashboard.php`);

      return;
    }

    run();
  }

  const onClickModalErroToken = () => {
    window.location.assign(`/app/`);
  }

  async function handleLogar(login: ILogar) {
    handleLimparErros();

    if (etapaAtivarCodigo) {
      if (!login.codigo && !reenviar) {
        setErroCodigo("Código é obrigatório");
        return;
      }

      if (login.codigo) {
        login.codigo = login.codigo.replace(/\s/g, "");
      }
    }

    setReenviar(false);

    const { data, hasErro }: any = await logar({ ...login, csrf: crsfCodigo });

    if (hasErro) {
      if (
        data.message ===
        "Código de acesso expirado"
      ) {
        setCodigoExpirado(true);
        setErro("Código expirado. Clique no botão abaixo para reenviar.");
        return;
      }

      setErro(data.message);

      const mensagemUsuarioBloqueado = [
        "Acesso bloqueado. Por favor, Acesse o link abaixo para redefinir sua senha.",
        "Acesso bloqueado. Por favor, utilize os links abaixo para redefinir sua senha ou retornar a tela de login.",
        "Acesso bloqueado por limite de tentativas. Por favor, redefina sua senha para efetuar o login.",
      ];

      if (mensagemUsuarioBloqueado.some((item) => item === data.message)) {
        setUsuarioBloqueado(true);
      }

      return;
    }

    if (data.idEnvioEmail) {
      setIdEmail(data.idEnvioEmail);
      setEtapaAtivarCodigo(true);
      return;
    }

    localStorage.setItem("@link_imagem_perfil", data.usuario.imagem);
    localStorage.setItem("@nome_usuario", data.usuario.nome);
    localStorage.setItem("@email_usuario", data.usuario.email);
    localStorage.setItem("@cargo_usuario", data.usuario.cargo);
    localStorage.setItem("@nome_empresa_usuario", data.empresa);
    localStorage.setItem("@id_usuario", data.usuario.id);
    localStorage.setItem("@administrador", data.usuario.administrador);
    localStorage.setItem("@token_keycloak", data.access_token);
    localStorage.setItem("@AWDOC_TOKEN", data.token);

    await validar(data.token);

    const baseUrlDespachante = process.env.REACT_APP_DESPACHANTE_BASE_URL;
    window.location.assign(`${baseUrlDespachante}/pages/dashboard/Dashboard.php`);
  }

  function handleReenviar() {
    setReenviar(true);
    setValue("codigo", "");
    handleSubmit(logar);
  }

  function handleLimparErros() {
    setErroCodigo("");
    setErro("");
    setCodigoExpirado(false);
  }

  function handleVoltarParaLogin() {
    setValue("codigo", "");
    setEtapaAtivarCodigo(false);
    handleLimparErros();
    setCodigoExpirado(false);
  }

  return (
    <div>
      <LoadingScreen mostrar={loadingLogin} backgroundColor="#FFFFFF" />
      <ContainerForm id="formLogin" onSubmit={handleSubmit(handleLogar)}>
        {!etapaAtivarCodigo ? (
          <LoginForm errors={errors} register={register} />
        ) : (
          <Codigo2Fatores
            idEmail={idEmail}
            handleLimparErros={handleLimparErros}
            control={control}
            setCodigoDigitado={setCodigoDigitado}
            erroCodigo={erroCodigo}
          />
        )}

        {(erro || erroCodigo || errors.email || errors.senha || errors.codigo) && (
          <ErroCodigo>
            <table><tbody>
              <tr>
                <td style={{ verticalAlign: "text-top" }}><IconeErro /></td>
                <td>{erro !== "" ? erro : (erroCodigo !== "" ? erroCodigo : [
                  errors.email?.message,
                  errors.senha?.message,
                  errors.codigo?.message
                ].filter(item => item).join(". "))}
                </td>
              </tr>
            </tbody></table>

          </ErroCodigo>
        )}

        <CustomButton
          style={(codigoExpirado && etapaAtivarCodigo) ? { display: "none" } : {}}
          type={!usuarioBloqueado ? "submit" : "button"}
          onClick={!usuarioBloqueado ? () => { } : handleClickEsqueceuSenha}
        >
          {loading ? <Carregamento /> : !usuarioBloqueado ? "Fazer login" : "Redefinir senha"}
        </CustomButton>

        <ContainerDescricao centralizar={etapaAtivarCodigo}>
          {!usuarioBloqueado && !codigoExpirado && etapaAtivarCodigo && (
            <>
              <div>
                <NaoRecebido>Não recebeu o código de acesso? Verifique o lixo eletrônico ou</NaoRecebido>
              </div>
              <div>
                <ButtonReenviar onClick={handleReenviar} form="formLogin" type="submit">
                  clique para reenviar
                </ButtonReenviar>
                {codigoDigitado && erro && (
                  <><NaoRecebido> ou </NaoRecebido><ButtonReenviar onClick={handleVoltarParaLogin}>voltar para o login</ButtonReenviar></>
                )}
              </div>
            </>
          )}
          <div>
            {usuarioBloqueado && etapaAtivarCodigo && (
              <ButtonReenviar onClick={handleVoltarParaLogin}>Voltar ao login</ButtonReenviar>
            )}
            {!usuarioBloqueado && !etapaAtivarCodigo && (
              <ButtonReenviar onClick={handleClickEsqueceuSenha}>Esqueci minha senha</ButtonReenviar>
            )}
          </div>
        </ContainerDescricao>
      </ContainerForm>
      {!usuarioBloqueado && codigoExpirado && etapaAtivarCodigo && <CustomButton
        form="formLogin"
        type="submit"
        onClick={handleReenviar}
      >
        {loading ? <Carregamento /> : "Reenviar"}
      </CustomButton>}
      <ModalErroToken
        show={modalErroToken}
        onClick={onClickModalErroToken}
      />
    </div>
  );
}

