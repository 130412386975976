import { ReactNode, createContext, useCallback, useEffect, useMemo, useState } from "react";

type TObjetoStringNumber = { [key: string]: number };

export interface IArquivoUpload {
  progressoUpload: number;
  uploadFinalizado: boolean;
  nomeArquivo: string;
  temErro: boolean;
}

interface IAtualizarProgressoUploadProps {
  nomeArquivo: string;
  progressoAtual: number;
  temErro: boolean;
}

interface IErrosUpload {
  [x: string]: {
    erros: any[];
  };
}

interface ISucessoUpload {
  [x: string]: string;
}

export interface IUploadContext {
  showBox: boolean;
  toggleShowBox: () => void;
  arquivos: IArquivoUpload[] | null;
  progresso: TObjetoStringNumber;
  atualizarProgressoUpload: ({ nomeArquivo, progressoAtual }: IAtualizarProgressoUploadProps) => void;
  adicionarArquivo: (arquivo: IArquivoUpload[]) => void;
  limparArquivos: () => void;
  erros: IErrosUpload;
  setErrosUpload: (nomeArquivo: string, erros: any[]) => void;
  urlRedirect: ISucessoUpload;
  setSucessoUpload: (nomeArquivo: string, urlRedirect: string) => void;
  planilhasCorrompidas: { [x: string]: boolean };
  setPlanilhaCorrompida: (nomeArquivo: string) => void;
  setErroUpload: (nomeArquivo: string, erros: string) => void;
  erro: any;
  showModalErros: boolean;
  setShowModalErros: (show: boolean) => void;
  showModalCorrompida: boolean;
  setShowModalCorrompida: (show: boolean) => void;
  showModalErro: boolean;
  setShowModalErro: (show: boolean) => void;
  modalAberto: boolean;
  setModalAberto: (aberto: boolean) => void;
}

export const UploadContext = createContext<IUploadContext>({
  showBox: false,
  toggleShowBox: () => {},
  arquivos: [
    {
      nomeArquivo: "",
      progressoUpload: 0,
      uploadFinalizado: false,
      temErro: false,
    },
  ],
  progresso: {},
  atualizarProgressoUpload: () => {},
  adicionarArquivo: () => {},
  limparArquivos: () => {},
  erros: {},
  setErrosUpload: (nomeArquivo: string, erros: any[]) => {},
  setErroUpload: (nomeArquivo: string, erros: string) => {},
  urlRedirect: {},
  setSucessoUpload: (nomeArquivo: string, urlRedirect: string) => {},
  planilhasCorrompidas: {},
  setPlanilhaCorrompida: (nomeArquivo: string) => {},
  erro: "",
  showModalErros: false,
  setShowModalErros: (show: boolean) => {},
  showModalCorrompida: false,
  setShowModalCorrompida: (show: boolean) => {},
  showModalErro: false,
  setShowModalErro: (show: boolean) => {},
  modalAberto: false,
  setModalAberto: (show: boolean) => {},
});

export const UploadContextProvider = ({ children }: { children: ReactNode }) => {
  const [showBox, setShowBox] = useState(false);
  const [arquivos, setArquivos] = useState<IArquivoUpload[]>([]);
  const [erros, setErros] = useState<IErrosUpload>({});
  const [erro, setErro] = useState();
  const [urlRedirect, setUrlRedirect] = useState<ISucessoUpload>({});
  const [planilhasCorrompidas, setPlanilhasCorrompidas] = useState<any>({});
  const [progresso, setProgresso] = useState<TObjetoStringNumber>({});
  const [showModalErros, setShowModalErros] = useState(false);
  const [showModalCorrompida, setShowModalCorrompida] = useState(false);
  const [showModalErro, setShowModalErro] = useState(false);
  const [modalAberto, setModalAberto] = useState(false);

  useEffect(() => {
    setModalAberto(false);
    if (showModalErro || showModalCorrompida || showModalErros) {
      setModalAberto(true);
    }
  }, [showModalErro, showModalErros, showModalCorrompida]);

  function toggleShowBox() {
    setShowBox((prev) => !prev);
  }

  const finalizarProgressoUpload = useCallback((nomeArquivo: string, temErro = false) => {
    setArquivos((prevArquivos) => {
      const indexArquivo = prevArquivos.findIndex((item) => item.nomeArquivo === nomeArquivo);

      if (indexArquivo !== -1) {
        const finalizado: IArquivoUpload = {
          ...prevArquivos[indexArquivo],
          uploadFinalizado: true,
          temErro,
        };

        const novosArquivos = [...prevArquivos];
        novosArquivos[indexArquivo] = finalizado;

        return novosArquivos;
      }

      return prevArquivos;
    });
  }, []);

  const atualizarProgressoUpload = useCallback(
    ({ nomeArquivo, progressoAtual, temErro }: IAtualizarProgressoUploadProps) => {
      setProgresso((prev) => ({
        ...prev,
        [nomeArquivo]: prev[nomeArquivo] > progressoAtual ? prev[nomeArquivo] : progressoAtual,
      }));

      if (progressoAtual >= 100) {
        finalizarProgressoUpload(nomeArquivo, temErro);
      }
    },
    [finalizarProgressoUpload]
  );

  function handleInitialProgress(arquivos: any[]) {
    arquivos.forEach((arquivo) => {
      setProgresso((prev) => ({ ...prev, [arquivo.nomeArquivo]: arquivo.progressoUpload }));
    });
  }

  const adicionarArquivo = useCallback((novosArquivos: IArquivoUpload[]) => {
    setArquivos((arquivosAnteriores) => [...(arquivosAnteriores ?? []), ...novosArquivos]);
    handleInitialProgress(novosArquivos);
  }, []);

  const limparArquivos = useCallback(() => {
    setArquivos([]);
    setProgresso({});
  }, []);

  const setPlanilhaCorrompida = useCallback((nomeArquivo: string) => {
    setPlanilhasCorrompidas((prev: any) => ({ ...prev, [nomeArquivo]: true }));
  }, []);

  const setErroUpload = useCallback((nomeArquivo: string, erro: string) => {
    setErro((prev: any) => ({ ...prev, [nomeArquivo]: erro }));
  }, []);

  const setErrosUpload = useCallback((nomeArquivo: string, erros: any[]) => {
    setErros((prev: any) => ({ ...prev, [nomeArquivo]: erros }));
  }, []);

  const setSucessoUpload = useCallback((nomeArquivo: string, urlRedirect: string) => {
    setUrlRedirect((prev: any) => ({ ...prev, [nomeArquivo]: urlRedirect }));
  }, []);

  const values = useMemo(
    () => ({
      showBox,
      toggleShowBox,
      arquivos,
      progresso,
      atualizarProgressoUpload,
      adicionarArquivo,
      limparArquivos,
      erros,
      setErrosUpload,
      urlRedirect,
      setSucessoUpload,
      planilhasCorrompidas,
      setErroUpload,
      setPlanilhaCorrompida,
      erro,
      showModalErros,
      setShowModalErros,
      showModalCorrompida,
      setShowModalCorrompida,
      showModalErro,
      setShowModalErro,
      modalAberto,
      setModalAberto,
    }),
    [
      atualizarProgressoUpload,
      showBox,
      adicionarArquivo,
      arquivos,
      progresso,
      limparArquivos,
      erros,
      setErrosUpload,
      urlRedirect,
      setSucessoUpload,
      planilhasCorrompidas,
      setPlanilhaCorrompida,
      setErroUpload,
      erro,
      showModalErros,
      setShowModalErros,
      showModalCorrompida,
      setShowModalCorrompida,
      showModalErro,
      setShowModalErro,
      modalAberto,
      setModalAberto,
    ]
  );

  return <UploadContext.Provider value={values}>{children}</UploadContext.Provider>;
};
