import styled from "styled-components";
import { FaUpload } from "react-icons/fa";

interface ErrorProps {
  comErro: boolean;
  comMaxWidth?: boolean;
}

interface FileUploadContainerProps {
  gap: string;
}

export const FileUploadContainer = styled.div<FileUploadContainerProps>`
  display: grid;
  gap: ${({ gap }) => (gap)};
  width: 100%;
`;

export const FileInput = styled.input`
  display: none;
`;

export const UploadButton = styled.div<ErrorProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.35rem 1rem;
  background-color: transparent;
  color: ${({ theme, comErro }) =>
    comErro ? theme.colors.danger.main : theme.colors.primary.main};
  border: 1px solid
    ${({ theme, comErro }) =>
    comErro ? theme.colors.danger.main : theme.colors.primary.main};
  border-radius: 0rem 0.5rem 0.5rem 0;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    border-color: ${({ theme, comErro }) =>
    comErro ? theme.colors.danger.hover : theme.colors.primary.hover};
    color: ${({ theme, comErro }) =>
    comErro ? theme.colors.danger.hover : theme.colors.primary.hover};
  }

  ${({ comMaxWidth }) =>
    comMaxWidth
      ? `@media(max-width: 1400px) {
    max-width: 70px;
    padding: 0.35rem;
    font-size: 12px;`
      : ""
  }}
`;

export const UploadTextContainer = styled.div<ErrorProps>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  border: 1px solid
    ${({ theme, comErro }) =>
    comErro ? theme.colors.danger.main : theme.colors.neutral.neutral30};
  border-right: none;
  padding: 0.45rem 0.5rem;
  border-radius: 0.5rem 0 0 0.5rem;

  span {
    font-size: 14px;
    width: 100%;
    ${({ comMaxWidth }) => comMaxWidth ? "max-width: 150px;" : ""}
    height: 100%;
    color: ${({ theme }) => theme.colors.neutral.neutral30};
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    scrollbar-width: none;
  }

  ${({ comMaxWidth }) =>
    comMaxWidth
      ?
      `@media(max-width: 1400px) {
    max-width: 90px;
    span {
      font-size: 12px;   
    }
  }`
      : ""
  }}
`;

export const AreaClicavel = styled.label`
  display: flex;
  height: 2.2rem;

  &:hover {
    cursor: pointer;
  }
`;

export const UploadIcon = styled(FaUpload)`
  margin-right: 0.25rem;
`;

export const SpanMensagemErro = styled.span`
  font-size: 0.85rem;
  color: ${({ theme }) => theme.colors.danger.main};
`;

