import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100vw;
  height: 100vh;
  color: ${(props) => props.theme.colors.colorTextMenu};
  background-color: ${(props) => props.theme.colors.colorMenuPrimary};
`;

export const Grid = styled.div`
  display: grid;
  flex: 1;
  grid-template-columns: 100vw;
  height: 80%;
`;

const styleDefaultGrid = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Title = styled(styleDefaultGrid)`
  h1 {
    font-size: 170px;
  }
  h3 {
    font-size: 16px;
  }
`;

export const Description = styled(styleDefaultGrid)``;
