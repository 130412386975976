import { Table } from "containers/Table";
import { Body, Container, Header, TextoHeader } from "./style";
import { IColum } from "containers/Table/interfaces";
import { IServicoModelo } from "features/core/interfaces/IServicoModelo";
import { FaPen, FaPlus, FaTimes } from "react-icons/fa";
import { useCallback } from "react";
import { utils, writeFileXLSX } from "xlsx";

interface IListarProps {
    dados: IServicoModelo[];
    handleLimitChange: (e: number) => void;
    handlePageChange: (e: number) => void;
    totalRows: number;
    handleShowModalEditarServico: (id: string) => void;
    handleShowModalExcluirServico: (id: string) => void;
    handleShowModalEditarEtapas: (id: string) => void;
}
export function Listar({
    dados,
    handleLimitChange,
    handlePageChange,
    totalRows,
    handleShowModalEditarServico,
    handleShowModalExcluirServico,
    handleShowModalEditarEtapas,
}: IListarProps) {

    const columns: IColum<IServicoModelo>[] = [
        {
            name: <span>ID</span>,
            selector: (item: IServicoModelo) => item.id,
            width: "90px",
        },
        {
            name: <span>Serviço</span>,
            selector: (item: IServicoModelo) => item.nome,
        },
        {
            name: <span>UF</span>,
            selector: (item: IServicoModelo) => item.uf,
            width: "200px",
        },
        {
            name: <span>Etapas</span>,
            selector: (item: IServicoModelo) => <span>
                <FaPlus onClick={() => handleShowModalEditarEtapas(item.id)} style={{ cursor: "pointer", color: "#000" }} />
            </span>,
            width: "200px",
        },
        {
            name: <span>Ações</span>,
            selector: (item: IServicoModelo) => <span>
                <FaPen onClick={() => handleShowModalEditarServico(item.id)} style={{ marginRight: "20px", cursor: "pointer", color: "#000" }} />
                <FaTimes onClick={() => handleShowModalExcluirServico(item.id)} style={{ color: "red", cursor: "pointer" }} />
            </span>,
            width: "200px",
        },
    ];

    const dadosPlanilha = dados.map((item: IServicoModelo) => ({
        ID: item.id,
        SERVIÇO: item.nome,
        UF: item.uf,
    }));

    const handleExport = useCallback(() => {
        const planilha = utils.json_to_sheet(dadosPlanilha);
        planilha['!cols'] = [{ wch: 5 }, { wch: 60 }, { wch: 5 }];
        const workBook = utils.book_new();
        utils.book_append_sheet(workBook, planilha, "Serviços");
        writeFileXLSX(workBook, "servicos_modelo.xlsx");
    }, [dadosPlanilha]);

    return (
        <Container show={true}>
            <Header>
                <TextoHeader>Listagem - Serviços</TextoHeader>
            </Header>
            <hr />
            <Body>
                <Table
                    keyField="id"
                    totalRows={totalRows}
                    pagination={true}
                    columns={columns}
                    data={dados}
                    paginationRowsPerPageOptions={[10, 20, 50]}
                    loading={false}
                    onChangeRowsPerPage={handleLimitChange}
                    onChangePage={handlePageChange}
                    exportExcel={true}
                    handleExport={handleExport}
                    buttonExportVariant="secondary"
                />
            </Body>
        </Container >
    )
}