import { HttpError } from "_services/api/HttpError";
import { HttpResponse } from "_services/api/HttpResponse";
import api from "_services/api/api";
import frontPhp from "_services/api/frontphp";
import { IData } from "_services/api/interface/HttpResponse";
import msUsuario from "_services/api/ms_usuario";
import { AxiosRequestConfig } from "axios";
import { toastErro } from "components/Toast";
import { useCallback, useEffect, useState } from "react";

type Service = "usuario" | "frontphp" | "api";

export function useRequest(service: Service = "usuario", mostrarErro: boolean = true) {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const [showErros, setShowErrors] = useState<boolean>(mostrarErro);

  useEffect(() => {
    if (error?.hasErro && Array.isArray(error?.data?.message)) {
      toastErro(error?.data?.message.join(", \n"));
    }

    if (error?.hasErro && typeof error?.data?.message === "string") {
      toastErro(error?.data?.message);
    }

    setError(null);
  }, [error, setError]);

  const axiosStrategy = useCallback((service: Service = "usuario") => {
    const instancias = {
      usuario: msUsuario,
      frontphp: frontPhp,
      api: api,
    };

    return instancias[service];
  }, []);

  const get = useCallback(
    async (url: string, headers: AxiosRequestConfig = {}) => {
      setLoading(true);
      try {
        const { data, status } = await axiosStrategy(service).get(url, headers);
        const response = new HttpResponse(false, status, data);
        if (showErros) {
          setError(response);
        }
        return response;
      } catch (error) {
        const response = new HttpError<IData>(true, error);
        if (showErros) {
          setError(response);
        }
        return response;
      } finally {
        setLoading(false);
      }
    },
    [axiosStrategy, service]
  );

  const post = useCallback(
    async <T, Y>(url: string, body: T, headers: AxiosRequestConfig = {}) => {
      try {
        setLoading(true);
        const { data, status } = await axiosStrategy(service).post(url, body, headers);
        const response = new HttpResponse<Y>(false, status, data);
        if (showErros) {
          setError(response);
        }
        return response;
      } catch (error) {
        const response = new HttpError<IData>(true, error);
        if (showErros) {
          setError(response);
        }
        return response;
      } finally {
        setLoading(false);
      }
    },
    [axiosStrategy, service]
  );

  const patch = useCallback(
    async <T,>(url: string, body: T, headers: AxiosRequestConfig = {}) => {
      try {
        setLoading(true);
        const { data, status } = await axiosStrategy(service).patch(url, body, headers);
        const response = new HttpResponse(false, status, data);
        if (showErros) {
          setError(response);
        }
        return response;
      } catch (error) {
        const response = new HttpError<IData>(true, error);
        if (showErros) {
          setError(response);
        }
        return response;
      } finally {
        setLoading(false);
      }
    },
    [axiosStrategy, service]
  );

  return {
    get,
    post,
    patch,
    setError,
    loading,
    error,
    setShowErrors,
  };
}
