
import { Body, Container, Header, TextoHeader } from "./style";
import { Table } from "containers/Table";
import { IColum } from "containers/Table/interfaces";
import { utils, writeFileXLSX } from "xlsx";
import { useCallback } from "react";
import { FaPen, FaTimes } from "react-icons/fa";
import { IMotivosPendenciaListar } from "features/core/pages/Configuracao/Interfaces/IMotivosPendenciaListar";
import { converterDataBr } from "_utils/Data";

interface IMotivosPendenciaListarProps {
    dados: IMotivosPendenciaListar[];
    loading: boolean;
    totalRows?: number;
    onChangeRowsPerPage?: (limit: number) => void;
    onChangePage?: (page: number) => void;
    show: boolean;
    handleExcluir: (idMotivo: any, processos: any) => void;
    handleEditar: (idMotivo: any, descricao: string) => void
}

export function MotivosPendenciaListar({
    dados,
    loading,
    totalRows,
    onChangeRowsPerPage,
    onChangePage,
    show,
    handleExcluir,
    handleEditar,
}: IMotivosPendenciaListarProps) {

    const columns: IColum<IMotivosPendenciaListar>[] = [
        {
            name: <span>ID</span>,
            selector: (motivo: IMotivosPendenciaListar) => motivo.id,
            width: "90px",
        },
        {
            name: <span>Descrição</span>,
            selector: (motivo: IMotivosPendenciaListar) => motivo.descricao,
        },
        {
            name: <span>Data de Cadastro</span>,
            selector: (motivo: IMotivosPendenciaListar) => converterDataBr(new Date(motivo.dataCadastro)),
        },
        {
            name: <span>Ações</span>,
            selector: (motivo: IMotivosPendenciaListar) => <span>
                <FaPen onClick={() => { handleEditar(motivo.id, motivo.descricao) }} style={{ marginRight: "20px", cursor: "pointer", color: "#000" }} />
                <FaTimes onClick={() => { handleExcluir(motivo.id, motivo.processos) }} style={{ color: "red", cursor: "pointer" }} />
            </span>,
            width: "200px",
        },
    ];

    const dadosPlanilha = dados.map((item: IMotivosPendenciaListar) => ({
        ID: item.id,
        "DESCRIÇÃO": item.descricao,
        "DATA DE CADASTRO": converterDataBr(new Date(item.dataCadastro)),
    }));

    const handleExport = useCallback(() => {
        const planilha = utils.json_to_sheet(dadosPlanilha);
        planilha['!cols'] = [{ wch: 5 }, { wch: 25 }, { wch: 10 }];
        const workBook = utils.book_new();
        utils.book_append_sheet(workBook, planilha, "Motivos");
        writeFileXLSX(workBook, "motivos_pendencias.xlsx");
    }, [dadosPlanilha]);

    return (
        <Container show={show}>
            <Header>
                <TextoHeader>Listagem - Motivos de Pendências</TextoHeader>
            </Header>
            <hr style={{ marginTop: "1.5rem" }} />
            <Body>
                <Table
                    keyField="id"
                    totalRows={totalRows}
                    pagination={true}
                    columns={columns}
                    data={dados}
                    paginationRowsPerPageOptions={[10, 20, 50]}
                    loading={loading}
                    onChangeRowsPerPage={onChangeRowsPerPage}
                    onChangePage={onChangePage}
                    exportExcel={true}
                    handleExport={handleExport}
                    buttonExportVariant="secondary"
                />
            </Body>
        </Container >
    );
}