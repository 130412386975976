import { PlanilhaProcessoLote } from "features/processo/@types/PlanilhaProcessoLote";

export interface IVeiculoProcessoLote {
  placa: NullableString;
  chassi: NullableString;
  renavam: NullableString;
  odometro: NullableNumber;
  uf: NullableString;
  municipio: NullableString;
  marcaModelo: NullableString;
  anoModelo: NullableString;
  anoFabricacao: NullableString;
  cor: NullableString;
  tipo: NullableNumber | NullableString;
  especie: NullableNumber | NullableString;
  potencia: NullableNumber;
  combustivel: NullableString;
  idVeiculo?: NullableNumber;
}

export interface IClienteProcessoLote {
  cpfCnpj?: NullableString;
  nome?: NullableString;
  email?: NullableString;
  cep?: NullableString;
  endereco?: NullableString;
  numero?: NullableNumber;
  complemento?: NullableString;
  bairro?: NullableString;
  cidade?: NullableString;
  ufCliente?: NullableString;
}

export interface IServicoTransferenciaProcessoLote {
  valorDeVenda?: NullableNumber;
  ufVenda?: NullableString;
  municipioDeVenda?: NullableString;
  dataDeVenda?: NullableString;
}

export interface IServicoEmplacamentoProcessoLote {
  escolhaDePlaca?: NullableString;
  localDeEmplacamento?: NullableString;
  placaVermelha?: NullableString;
  alienacao?: NullableString;
  nf?: NullableString;
  emissao?: NullableString;
}

export class CadastrarProcessoEmLoteMapper {
  private constructor(
    public veiculo: IVeiculoProcessoLote,
    public cliente: IClienteProcessoLote,
    public servicoTransferencia: IServicoTransferenciaProcessoLote,
    public servicoEmplacamento: IServicoEmplacamentoProcessoLote
  ) {}

  public static make(dadosPlanilha: PlanilhaProcessoLote) {
    const veiculo: IVeiculoProcessoLote = {
      anoFabricacao: dadosPlanilha.Ano_Fabricacao,
      anoModelo: dadosPlanilha.Ano_Modelo,
      chassi: dadosPlanilha.Chassi,
      combustivel: dadosPlanilha.Combustivel,
      cor: dadosPlanilha.Cor,
      especie: dadosPlanilha.Especie,
      marcaModelo: dadosPlanilha.Marca_Modelo,
      municipio: dadosPlanilha.Municipio,
      odometro: dadosPlanilha.Odometro,
      placa: dadosPlanilha.Placa,
      potencia: dadosPlanilha.Potencia,
      renavam: dadosPlanilha.Renavam,
      tipo: dadosPlanilha.Tipo,
      uf: dadosPlanilha.UF,
      idVeiculo: dadosPlanilha.idVeiculo,
    };

    const cliente: IClienteProcessoLote = {
      bairro: dadosPlanilha.Bairro,
      cep: dadosPlanilha.CEP,
      cidade: dadosPlanilha.Cidade,
      complemento: dadosPlanilha.Complemento,
      cpfCnpj: dadosPlanilha.CPF_CNPJ,
      email: dadosPlanilha.Email,
      endereco: dadosPlanilha.Endereco,
      nome: dadosPlanilha.Nome,
      numero: dadosPlanilha.Numero,
      ufCliente: dadosPlanilha.UF_Cliente,
    };

    const servicoTransferencia: IServicoTransferenciaProcessoLote = {
      dataDeVenda: dadosPlanilha.Data_De_Venda,
      municipioDeVenda: dadosPlanilha.Municipio,
      ufVenda: dadosPlanilha.UF_Venda,
      valorDeVenda: dadosPlanilha.Valor_De_Venda,
    };

    const servicoEmplacamento: IServicoEmplacamentoProcessoLote = {
      alienacao: dadosPlanilha.Alienacao,
      emissao: dadosPlanilha.Emissao,
      escolhaDePlaca: dadosPlanilha.Escolha_De_Placa,
      localDeEmplacamento: dadosPlanilha.Local_De_Emplacamento,
      nf: dadosPlanilha.NF,
      placaVermelha: dadosPlanilha.Placa_Vermelha,
    };

    return new CadastrarProcessoEmLoteMapper(
      veiculo,
      cliente,
      servicoTransferencia,
      servicoEmplacamento
    );
  }

  public static makeObjetoComErro(dadosPlanilha: CadastrarProcessoEmLoteMapper, mensagemErro: string) {
    return {
      "Placa": dadosPlanilha.veiculo.placa,
      "Chassi": dadosPlanilha.veiculo.chassi,
      "Renavam": dadosPlanilha.veiculo.renavam,
      "Odometro": dadosPlanilha.veiculo.odometro,
      "UF": dadosPlanilha.veiculo.uf,
      "Municipio": dadosPlanilha.veiculo.municipio,
      "Marca_Modelo": dadosPlanilha.veiculo.marcaModelo,
      "Ano_Modelo": dadosPlanilha.veiculo.anoModelo,
      "Ano_Fabricacao": dadosPlanilha.veiculo.anoFabricacao,
      "Cor": dadosPlanilha.veiculo.cor,
      "Tipo": dadosPlanilha.veiculo.tipo,
      "Especie": dadosPlanilha.veiculo.especie,
      "Potencia": dadosPlanilha.veiculo.potencia,
      "Combustivel": dadosPlanilha.veiculo.combustivel,
      "CPF_CNPJ": dadosPlanilha.cliente.cpfCnpj,
      "Nome": dadosPlanilha.cliente.nome,
      "Email": dadosPlanilha.cliente.email,
      "CEP": dadosPlanilha.cliente.cep,
      "Endereco": dadosPlanilha.cliente.endereco,
      "Numero": dadosPlanilha.cliente.numero,
      "Complemento": dadosPlanilha.cliente.complemento,
      "Bairro": dadosPlanilha.cliente.bairro,
      "Cidade": dadosPlanilha.cliente.cidade,
      "UF_Cliente": dadosPlanilha.cliente.ufCliente,
      "Valor_De_Venda": dadosPlanilha.servicoTransferencia.valorDeVenda,
      "UF_Venda": dadosPlanilha.servicoTransferencia.ufVenda,
      "Municipio_De_Venda": dadosPlanilha.servicoTransferencia.municipioDeVenda,
      "Data_De_Venda": dadosPlanilha.servicoTransferencia.dataDeVenda,
      "Escolha_De_Placa": dadosPlanilha.servicoEmplacamento.escolhaDePlaca,
      "Local_De_Emplacamento": dadosPlanilha.servicoEmplacamento.localDeEmplacamento,
      "Placa_Vermelha": dadosPlanilha.servicoEmplacamento.placaVermelha,
      "Alienacao": dadosPlanilha.servicoEmplacamento.alienacao,
      "NF": dadosPlanilha.servicoEmplacamento.nf,
      "Emissao": dadosPlanilha.servicoEmplacamento.emissao,
      "Erro": mensagemErro
    }
  }
}
