import { Form, Row } from "react-bootstrap";
import { Button, ButtonsContainer, Container, FormContainer, InputContainer, Title } from "./style";
import { ContainerHeader } from "components/Ibox/style";
import FileUpload from "components/UploadArquivos";
import { useMaskInput } from "hooks/useMaskInput";

interface IFormularioMigrarProps {
    onChangeArquivo: (file: any) => void;
    onClickMigrar: () => void;
}
export function FormularioMigrar({
    onChangeArquivo,
    onClickMigrar,
}: IFormularioMigrarProps) {

    return (
        <Container>
            <ContainerHeader>
                <Title>Migrar serviços Antigos para os Novos Serviços</Title>
            </ContainerHeader>
            <hr />
            <FormContainer>
                <Row>
                    <InputContainer>
                        <Form.Group>
                            <Form.Label style={{ marginBottom: "0.8rem" }}>
                                Arquivo (Excel)
                            </Form.Label>
                            <FileUpload
                                onFileUpload={(file: any) => { onChangeArquivo(file) }}
                                id="fileInputMigrar"
                            />
                        </Form.Group>
                    </InputContainer>


                    <InputContainer>
                        <Form.Group>
                            <ButtonsContainer>
                                <Button onClick={onClickMigrar}>Migrar</Button>
                            </ButtonsContainer>
                        </Form.Group>
                    </InputContainer>
                </Row>
            </FormContainer>
        </Container>
    );
}