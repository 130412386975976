import { useCallback, useEffect, useMemo, useState } from "react";
import { AxiosRequest } from "_services/api";
import { HttpError } from "_services/api/HttpError";
import { HttpResponse } from "_services/api/HttpResponse";
import {
  IData,
  IHttpError,
  IHttpResponse,
} from "_services/api/interface/HttpResponse";

export function useDivisoes(empresa: any) {
  const [divisoes, setDivisoes] = useState<any[]>([]);
  const [divisoesOptions, setDivisoesOptions] = useState<any[]>([]);
  const [error, setError] = useState<IHttpResponse<IData | any>>();

  const api = useMemo(() => AxiosRequest.makeApi(), []);

  const listarTodasDivisoes = useCallback(
    async (empresa: any) => {
      try {
        const { status, data } = await api.get(`/empresa/${empresa}/divisao/`);
        const rersponse = new HttpResponse(false, status, data);
        setError(rersponse);
        setDivisoes(data.dados);
        criarOptionsDivisoes(data.dados);
        return rersponse;
      } catch (error: IHttpError<IData, any> | any) {
        criarOptionsDivisoes([]);
        const response = new HttpError<IData>(true, error);
        setError(response);
        return response;
      }
    },
    [api]
  );

  useEffect(() => {
    if (!empresa) {
      setDivisoes([]);
      setDivisoesOptions([]);
      return;
    }

    listarTodasDivisoes(empresa);
  }, [empresa, listarTodasDivisoes]);

  function criarOptionsDivisoes(divisoes: any[]) {
    const optionsDivisoes = divisoes.map((item) => ({
      value: item.id,
      label: item.nome_fantasia,
    }));

    setDivisoesOptions(optionsDivisoes);
  }

  return {
    divisoes,
    error,
    divisoesOptions,
    listarTodasDivisoes,
  };
}

