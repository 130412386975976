import { ModalCustom } from "components/ModalCustom";
import { FaCheck } from "react-icons/fa";
import { Container } from "./style";
import { AutorizacaoPlacaContext } from "../../context/AutorizacaoPlacaContext";
import { useContext } from "react";

export function ModalConfirmacao() {
  const { showModalSucesso, handleCloseConfirmacao } = useContext(
    AutorizacaoPlacaContext
  );

  return (
    <ModalCustom
      show={showModalSucesso}
      handleClose={handleCloseConfirmacao}
      title="Solicitar autorização"
      centered
    >
      <Container>
        <FaCheck
          style={{
            color: "#2529F4",
            backgroundColor: "#E9E9FE",
            fontSize: "40px",
            padding: "8px",
            borderRadius: "10px",
          }}
        />
      </Container>
      <Container>
        Solicitação efetuada com sucesso! <br/>
        <strong>As autorizações serão processadas.</strong>
      </Container>
    </ModalCustom>
  );
}
