import { ModalCustom } from "components/ModalCustom";
import { Body, Button, ButtonsContainer, FormContainer, InputsContainer, TextoSecundario } from "./style";
import { Col, Form, Row } from "react-bootstrap";
import { Required } from "components/Required";
import { CustomInput } from "components/CustomInput";
import { useEffect, useState } from "react";
import { DropdownSelect } from "components/DropdownSelect";
import { IEtapaServicoModelo } from "features/core/interfaces/IEtapaServicoModelo";
import { IColum } from "containers/Table/interfaces";
import { FaPen, FaTimes } from "react-icons/fa";
import { Table } from "containers/Table";

interface IModalServicoEtapasProps {
    show: boolean;
    handleClose: () => void;
    onConfirm: () => void;
    selected: string;
    setSelected: React.Dispatch<React.SetStateAction<"cadastrar" | "copiar">>;
    servicosModeloOptions: any[];
    onChangeIdServicoModelo: (e: any) => void;
    idServicoModelo: string;
    onChangeOrdem: (e: any) => void;
    onChangeEtapa: (e: any) => void;
    ordem: string;
    etapa: string;
    dadosEtapas: IEtapaServicoModelo[];
    onClickCadastrarEtapa: () => void;
    onClickCopiarEtapa: () => void;
    handleExcluirEtapa: (nome: string, ordemEtapa: string) => void;
    handleEditarEtapa: (nome: string, ordemEtapa: string) => void;
}

export function ModalEditarEtapasServico({
    show,
    handleClose,
    onConfirm,
    selected,
    setSelected,
    servicosModeloOptions,
    onChangeIdServicoModelo,
    idServicoModelo,
    onChangeOrdem,
    onChangeEtapa,
    ordem,
    etapa,
    dadosEtapas,
    onClickCadastrarEtapa,
    onClickCopiarEtapa,
    handleExcluirEtapa,
    handleEditarEtapa,
}: IModalServicoEtapasProps) {

    function handleCheck(event: React.ChangeEvent<HTMLInputElement>) {
        setSelected(event.target.value as "cadastrar" | "copiar");
    }

    const [displayCadastrar, setDisplayCadastrar] = useState<"none" | "block">("block");
    const [displayCopiar, setDisplayCopiar] = useState<"none" | "block">("none");

    useEffect(() => {
        if (selected == "cadastrar") {
            setDisplayCadastrar("block");
            setDisplayCopiar("none");
            return;
        }
        setDisplayCadastrar("none");
        setDisplayCopiar("block");
    }, [selected]);

    const columns: IColum<IEtapaServicoModelo>[] = [
        {
            name: <span>ID</span>,
            selector: (item: IEtapaServicoModelo) => item.id,
            width: "90px",
            omit: true
        },
        {
            name: <span>Ordem</span>,
            selector: (item: IEtapaServicoModelo) => item.ordem,
            width: "90px",
        },
        {
            name: <span>Etapa</span>,
            selector: (item: IEtapaServicoModelo) => item.nome,
        },
        {
            name: <span>Ações</span>,
            selector: (item: IEtapaServicoModelo) => <span>
                <FaPen onClick={() => handleEditarEtapa(item.nome, item.ordem)} style={{ marginRight: "20px", cursor: "pointer", color: "#000" }} />
                <FaTimes onClick={() => handleExcluirEtapa(item.nome, item.ordem)} style={{ color: "red", cursor: "pointer" }} />
            </span>,
            width: "200px",
        },
    ];

    return (
        <ModalCustom
            show={show}
            handleClose={handleClose}
            title="Etapas"
            centered
            footer
            footerConfirmButton
            confirmButtonText="Salvar edição"
            onConfirm={onConfirm}
            size={"lg"}
        >
            <Body>
                <FormContainer>
                    <InputsContainer>
                        <Col lg="5">
                            <input
                                onChange={handleCheck}
                                id="cadastrar"
                                type="radio"
                                className="custom-radio"
                                value="cadastrar"
                                checked={selected === "cadastrar"}
                            />
                            <label htmlFor="cadastrar">Cadastrar novas etapas</label>
                        </Col>
                        <Col lg="5">
                            <input
                                onChange={handleCheck}
                                id="copiar"
                                type="radio"
                                className="custom-radio"
                                value="copiar"
                                checked={selected === "copiar"}
                            />
                            <label htmlFor="copiar">Copiar etapas</label>
                        </Col>
                    </InputsContainer>
                    <div style={{ display: displayCadastrar }}>
                        <TextoSecundario style={{ marginTop: "18px" }}>
                            Preencha os campos abaixo para cadastrar novas etapas
                        </TextoSecundario>
                        <Row style={{ marginTop: "12px" }}>
                            <Col lg="4">
                                <Form.Label style={{ marginBottom: "0.4rem" }}>
                                    Ordem <Required />
                                </Form.Label>
                                <CustomInput
                                    onChange={onChangeOrdem}
                                    id="ordem"
                                    type="text"
                                    placeholder="Informe a ordem da etapa"
                                    value={ordem}
                                />
                            </Col>
                            <Col lg="6">
                                <Form.Label style={{ marginBottom: "0.4rem" }}>
                                    Etapa <Required />
                                </Form.Label>
                                <CustomInput
                                    onChange={onChangeEtapa}
                                    id="etapa"
                                    type="text"
                                    placeholder="Informe o nome da etapa"
                                    value={etapa}
                                />
                            </Col>
                            <Col lg="2">
                                <Form.Group>
                                    <ButtonsContainer>
                                        <Button type="submit" onClick={onClickCadastrarEtapa}>Cadastrar</Button>
                                    </ButtonsContainer>
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                    <div style={{ display: displayCopiar }}>
                        <TextoSecundario style={{ marginTop: "18px" }}>
                            Selecione abaixo o serviço que deseja copiar as etapas.
                        </TextoSecundario>
                        <Row style={{ marginTop: "12px" }}>
                            <Col lg="10">
                                <Form.Label style={{ marginBottom: "0.1rem" }}>
                                    Serviço
                                </Form.Label>
                                <DropdownSelect
                                    options={servicosModeloOptions}
                                    title=""
                                    selectedValue={idServicoModelo}
                                    onSelect={onChangeIdServicoModelo}
                                    searchable
                                />
                            </Col>
                            <Col lg="2">
                                <Form.Group>
                                    <ButtonsContainer>
                                        <Button type="submit" onClick={onClickCopiarEtapa}>Copiar</Button>
                                    </ButtonsContainer>
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                </FormContainer>
                <Table
                    toolsHeader={
                        <TextoSecundario style={{ marginBottom: "0", textAlign: "left" }}>
                            Listagem de etapas
                        </TextoSecundario>
                    }
                    keyField="id"
                    columns={columns}
                    data={dadosEtapas}
                    loading={false}
                />
            </Body>
        </ModalCustom>
    );
}