import {
  IconContainer,
  Checkbox,
  ListItem,
  OptionText,
  OptionsList,
  OptionsText,
  SelectBody,
  SelectButton,
  SelectContainer,
  SelectTitle,
} from "./style";
import { useEffect, useRef, useState } from "react";
import { FaCheck, FaChevronDown } from "react-icons/fa";

export function Multiselect({
  options,
  title,
  onChange,
  value,
  limitPlaceholder = 0,
}: IMultiselectProps) {
  const [listVisible, setListVisible] = useState<boolean>(false);
  const [optionsCheckeds, setOptionsCheckeds] = useState<IMultiselectOptions[]>(options);
  const selectRef = useRef<HTMLDivElement>(null);

  function handleCheckOption(checkedOption: any) {
    const temporarioOptionsChecked = [...optionsCheckeds];
    const optionIndex = temporarioOptionsChecked.findIndex(
      (item) => item.value === checkedOption
    );
    temporarioOptionsChecked[optionIndex].checked =
      !temporarioOptionsChecked[optionIndex].checked;

    setOptionsCheckeds(temporarioOptionsChecked);

    const selectedValues = temporarioOptionsChecked
      .map((option) => (option.checked ? option.value : null))
      .filter((item) => item !== null);
    onChange(selectedValues);
  }

  function handlePlaceholder() {
    const labelsArray = optionsCheckeds
      .map((item) => {
        if (item.checked) {
          return item.label;
        }

        return null;
      })
      .filter((item) => item !== null);

    let placeholder = `${labelsArray.join(", ")}`;
    if (limitPlaceholder && placeholder.length > limitPlaceholder) {
      placeholder = `${placeholder.slice(0, limitPlaceholder)}...`;
    }

    return placeholder;
  }

  function handleClickOutside(event: MouseEvent) {
    if (
      selectRef.current &&
      !selectRef.current.contains(event.target as Node)
    ) {
      setListVisible(false);
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setOptionsCheckeds(
      options.map((option) => ({
        ...option,
        checked: value.includes(option.value as never),
      }))
    );
  }, [value]);

  return (
    <SelectContainer>
      <SelectTitle>{title}</SelectTitle>
      <SelectBody ref={selectRef}>
        <SelectButton onClick={() => setListVisible((prev) => !prev)}>
          {optionsCheckeds.some((item) => item.checked) ? (
            <OptionText>{handlePlaceholder()}</OptionText>
          ) : (
            "Selecione"
          )}
          <IconContainer>
            <FaChevronDown />
          </IconContainer>
        </SelectButton>
        <OptionsList isVisible={listVisible} >
          {options.map((option) => (
            <ListItem
              key={option.value}
              onClick={() => handleCheckOption(option.value)}
            >
              <Checkbox key={option.value}>
                {optionsCheckeds.some(
                  (item) => item.value === option.value && item.checked
                ) ? (
                  <FaCheck />
                ) : (
                  <div></div>
                )}
              </Checkbox>
              <OptionsText>{option.label}</OptionsText>
            </ListItem>
          ))}
        </OptionsList>
      </SelectBody>
    </SelectContainer>
  );
}
