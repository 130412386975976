import axios, { Axios, AxiosRequestHeaders } from "axios";
import { getToken } from "../auth";

export class AxiosRequest {
  public api: Axios;

  private constructor(customBaseUrl?: string, evitarEnvioToken?: boolean) {
    this.api = axios.create({
      baseURL: customBaseUrl ? customBaseUrl : process.env.REACT_APP_API_DESPACHANTE_BASE_URL,
    });

    if (evitarEnvioToken) {
      return;
    }

    this.api.interceptors.request.use(async (config) => {
      const token = getToken();
      if (token) {
        const headers = { Authorization: `Bearer ${token}`, ...config.headers } as unknown as AxiosRequestHeaders;
        config.headers = headers;
      }

      return config;
    });
  }

  public static makeApi(customBaseUrl?: string, evitarEnvioToken?: boolean) {
    return new AxiosRequest(customBaseUrl, evitarEnvioToken).api;
  }
}
