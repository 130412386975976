import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { SearchContainer } from "./style";

interface SearchProps {
  setSearch: (paginationSortSearch: string) => any;
  disabled?: boolean;
}
function Search({ setSearch, disabled }: SearchProps) {
  const [timer, setTimer] = useState<any>(null);

  function onChangeSearch(value: string) {
    setTimer(clearTimeout(timer));
    setTimer(setTimeout(() => setSearch(value), 1000));
  }

  return (
    <SearchContainer>
      <Form.Control
        size="sm"
        type="search"
        placeholder="Procurar"
        disabled={disabled}
        onChange={(e) => onChangeSearch(e.target.value)}
      />
    </SearchContainer>
  );
}
export { Search };

