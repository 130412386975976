import styled from "styled-components";

export const InfoSucessoProcessos = styled.div`
  display: grid;
  place-items: center;
  gap: 1rem;
  padding: 1rem;
`;

export const IconeContainer = styled.div`
  padding: 0.75rem 1rem;
  background-color: ${({ theme }) => theme.colors.tags.info02.bgColor};
  border-radius: 0.5rem;
  svg {
    color: ${({ theme }) => theme.colors.primary.main};
  }
`;

export const ListaChassis = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  background-color: ${({ theme }) => theme.colors.neutral.neutral10};

  p {
    margin: 0;
  }
`;
