import styled from "styled-components";

interface IModalContainerProps {
    width: "sm" | "md" | "lg"
    show: boolean
}

const widthStrategy = {
    sm: 50,
    md: 75,
    lg: 100
}

export const ButtonContainer = styled.div`

`;

export const FooterButtonContainer= styled.span`
    margin-right:8px;
`;

export const ModalContainer = styled.div<IModalContainerProps>`
    position:absolute;
    ${({ show }) => !show ? "display:none;" : ""}
    width: ${({ width }) => widthStrategy[width]}%; 
    background-color: #fff; 
    z-index: 10; 
    top: 67px; 
    right: 0; 
    box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.08);
`;

export const HeaderModalContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const BodyModalContainer = styled.div`
    padding: 6px;
`;

export const FooterModalContainer = styled.div`
    display: flex;
    padding: 6px;
    gap: 5px;
`;

export const TituloModalContainer = styled.span`
    padding: 8px;
`;

export const ButtonModalCustom = styled.button`
    width: 150px;
    height: 32px;
    border: 1px solid ${({ theme }) => theme.colors.neutral.neutral20};
    border-radius: 0.5rem;
    padding: 0.3rem 1rem;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const ButtonTexto = styled.span`
    margin-left: 2px;
`;

interface PlaceholderProps {
    selected: boolean;
}

export const Placeholder = styled.span<PlaceholderProps>`
  color: ${({ selected, theme }) => selected ? theme.colors.neutral.neutral80 : theme.colors.neutral.neutral40};
`;