import { AxiosRequest } from "_services/api";
import { HttpError } from "_services/api/HttpError";
import { HttpResponse } from "_services/api/HttpResponse";
import {
  IData,
  IHttpError,
  IHttpResponse,
} from "_services/api/interface/HttpResponse";
import { getToken } from "_services/auth";
import { alertaErro } from "components/Alerta";
import { useCallback, useEffect, useState } from "react";
import { IFiltro } from "../interfaces/IFiltros";
import { IValidarPlanilha } from "../interfaces/IValidarPlanilha";
import { Data } from "_utils/Data";
import { IBaixaManual } from "../interfaces/IBaixaManual";
import { StatusAtutorizacaoPlaca } from "../enums/StatusAutorizacaoPlacaenum";

export function useAutorizacaoPlaca() {
  const [filtros, setFiltros] = useState<IFiltro>({
    placa: null,
    periodoSolicitacao: null,
    proprietario: null,
    statusSolicitacao: null,
  });
  const [dadosValidar, setDadosValidar] = useState([]);
  const [dadosListar, setDadosListar] = useState<any[]>([]);
  const [totalLinhas, setTotalLinhas] = useState(0);

  const api = AxiosRequest.makeApi(process.env.REACT_APP_MS_AW);
  const token = getToken();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<IHttpResponse<IData | any>>();
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);
  const [showModalSucesso, setShowModalSucesso] = useState<boolean>(false);
  const [showModalSucessoManual, setShowModalSucessoManual] =
    useState<boolean>(false);
  const [showModalManual, setShowModalManual] = useState<boolean>(false);
  const [tab, setTab] = useState<string>("solicitar");

  useEffect(() => {
    listar();
  }, [filtros, page, limit]);

  async function validarPlanilha(dados: IValidarPlanilha) {
    setLoading(true);
    try {
      const { status, data } = await api.post(
        `/autoriza/placa/validar`,
        dados,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const response = new HttpResponse(false, status, data);
      setError(response);
      setDadosValidar(data);
    } catch (error: IHttpError<IData, any> | any) {
      const response = new HttpError<IData>(true, error);
      alertaErro("Ocorreu um erro", response.data.message);
      setDadosValidar([]);
      return response;
    } finally {
      setLoading(false);
    }
  }

  async function enviar() {
    setLoading(true);
    try {
      const { status, data } = await api.post(
        `/autoriza/placa/`,
        {
          dados: dadosValidar,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const response = new HttpResponse(false, status, data);
      setError(response);
      setShowModalSucesso(true);
      setDadosValidar([]);
      listar();
    } catch (error: IHttpError<IData, any> | any) {
      const response = new HttpError<IData>(true, error);
      alertaErro("Ocorreu um erro", response.data.message);
      return response;
    } finally {
      setLoading(false);
    }
  }

  async function listar() {
    setLoading(true);
    try {
      const filtrosParam = [
        filtros.placa ? `placa=${filtros.placa.replace("-", "")}` : null,
        filtros.proprietario ? `proprietario=${filtros.proprietario}` : null,
        filtros.statusSolicitacao
          ? `status=${filtros.statusSolicitacao}`
          : null,
        filtros.periodoSolicitacao &&
        filtros.periodoSolicitacao[0] &&
        filtros.periodoSolicitacao[1]
          ? `dataInicial=${new Data(
              filtros.periodoSolicitacao[0]
            ).getDataEua()}&dataFinal=${new Data(
              filtros.periodoSolicitacao[1]
            ).getDataEua()}`
          : null,
      ]
        .filter((item) => item)
        .join("&");

      const { status, data } = await api.get(
        `/autoriza/placa/?pagina=${page}&limite=${limit}&${filtrosParam}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const response = new HttpResponse(false, status, data);
      setError(response);
      setDadosListar(data.itens);
      setTotalLinhas(data.meta.total);
    } catch (error: IHttpError<IData, any> | any) {
      const response = new HttpError<IData>(true, error);
      if (response.status === 404) {
        setDadosListar([]);
        return response;
      }
      alertaErro("Ocorreu um erro", response.data.message);
      return response;
    } finally {
      setLoading(false);
    }
  }

  async function listarExportar(): Promise<any[]> {
    try {
      setLoading(true);
      const filtrosParam = [
        filtros.placa ? `placa=${filtros.placa.replace("-", "")}` : null,
        filtros.proprietario ? `proprietario=${filtros.proprietario}` : null,
        filtros.statusSolicitacao
          ? `status=${filtros.statusSolicitacao}`
          : null,
        filtros.periodoSolicitacao &&
        filtros.periodoSolicitacao[0] &&
        filtros.periodoSolicitacao[1]
          ? `dataInicial=${new Data(
              filtros.periodoSolicitacao[0]
            ).getDataEua()}&dataFinal=${new Data(
              filtros.periodoSolicitacao[1]
            ).getDataEua()}`
          : null,
      ]
        .filter((item) => item)
        .join("&");

      const { status, data } = await api.get(
        `/autoriza/placa/?limite=${0}&${filtrosParam}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const response = new HttpResponse(false, status, data);
      setError(response);

      return data.itens
    } catch (error: IHttpError<IData, any> | any) {
      const response = new HttpError<IData>(true, error);
      alertaErro("Ocorreu um erro", response.data.message);
      return [];
    } finally {
      setLoading(false);
    }
  }

  async function downloadATPV(id: number, placa: string, extencao: string) {
    setLoading(true);
    try {
      const { status, data } = await api.get<Blob>(
        `/autoriza/placa/${id}/imagem`,
        {
          responseType: "blob",
        }
      );

      const response = new HttpResponse(false, status, data);
      const nomeArquivo = `${placa} AUTORIZAÇÃO.${extencao}`;
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", nomeArquivo);
      document.body.appendChild(link);
      link.click();
      setError(response);
      return response;
    } catch (error: IHttpError<IData, any> | any) {
      const response = new HttpError<IData>(true, error);
      alertaErro("Ocorreu um erro", response.data.message);
      return response;
    } finally {
      setLoading(false);
    }
  }

  function handleCloseConfirmacao() {
    setShowModalSucesso(false);
    setTab("historico");
  }

  async function manual(dados: IBaixaManual) {
    handleCloseManual();
    setLoading(true);
    try {
      const { status, data } = await api.post(
        `/autoriza/placa/manual/${dados.id}`,
        dados,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (data) {
        setShowModalSucessoManual(true);
        setDadosListar(
          dadosListar.map((item: any) =>
            item.id === dados.id
              ? {
                  ...item,
                  status: StatusAtutorizacaoPlaca["Finalização manual"],
                  object_name: true,
                  bucket_name: true,
                  id_autorizacao: dados.idAutorizacao,
                }
              : item
          )
        );
      }

      const response = new HttpResponse(false, status, data);
      setError(response);
    } catch (error: IHttpError<IData, any> | any) {
      const response = new HttpError<IData>(true, error);
      alertaErro("Ocorreu um erro", response.data.message);
      return response;
    } finally {
      setLoading(false);
    }
  }

  async function downloadLote(ids: string[]) {
    window.open(
      `${process.env.REACT_APP_MS_AW}/autoriza/placa/imagens?ids=${ids.join(
        ","
      )}&authorization=Bearer ${token}`
    );
  }

  const handleCloseManualSucesso = useCallback(() => {
    setShowModalSucessoManual(false);
  }, []);

  function handleCloseManual() {
    setShowModalManual(false);
  }

  function handleOpenManual() {
    setShowModalManual(true);
  }

  return {
    error,
    setLoading,
    loading,
    setPage,
    setLimit,
    page,
    limit,
    handleCloseConfirmacao,
    showModalSucesso,
    validarPlanilha,
    dadosValidar,
    enviar,
    dadosListar,
    listar,
    setFiltros,
    totalLinhas,
    downloadATPV,
    showModalManual,
    handleCloseManual,
    handleOpenManual,
    manual,
    showModalSucessoManual,
    handleCloseManualSucesso,
    downloadLote,
    tab,
    setTab,
    listarExportar,
  };
}
