import { ModalCustom } from "components/ModalCustom";
import { Informacao } from "components/Informacao";
import { Body } from "./style";

interface IModalExcluirDespachante {
    show: boolean;
    handleClose: () => void;
    onConfirm: () => void;
}


export function ModalExcluirDespachante({
    show,
    handleClose,
    onConfirm,
}: IModalExcluirDespachante) {

    const MENSAGEM = `Após a confirmação, esta ação não poderá ser revertida.`;

    return (
        <ModalCustom
            show={show}
            handleClose={handleClose}
            title="Exclusão de Despachante"
            centered={true}
            footer={true}
            footerConfirmButton={true}
            onConfirm={onConfirm}
        >
            <Body>
                <div style={{ marginBottom: "24px" }}>Deseja confirmar a exclusão deste despachante?</div>

                <Informacao
                    mensagem={MENSAGEM}
                    type="atencao"
                />
            </Body>
        </ModalCustom>
    );
}