import styled from "styled-components";
import 'react-datepicker/dist/react-datepicker.css';
import { StatusPagamento } from "features/core/pages/Financeiro/Enum/StatusPagamento";

interface TagProps {
  tipo: 1 | 2 | 3 | 4 | 5;
}

interface ContainerProps {
  show: boolean;
}

const tagStyleStrategy = {
  1: { background: "#FFEFCC", color: "#FF9F42" },
  2: { background: "#DCDCDC", color: "#363636" },
  3: { background: "#DBFFF6", color: "#10AC85" },
  4: { background: "#E0FFFF", color: "#00BFFF" },
  5: { background: "#FFB6C1", color: "#DC143C" }
}

export const Container = styled.div<ContainerProps>`
  ${(props) => { return props.show ? "" : "display: none;" }}
  margin-top: 2.5rem;
  width: 100%;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const Header = styled.h2`
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
`;

export const TextoHeader = styled.span`
  color: ${({ theme }) => theme.colors.neutral.neutral90};
  font-weight: bold;
  font-size: 1.2rem;  
  padding-top: 6px;
`;

export const Buttons = styled.span`
  display: flex;
`;

export const ButtonContainer = styled.span`
  font-size: 13px;
  margin-right: 6px;
`;

export const Body = styled.div`
.rdt_TableCell {
  
  div {
    display: flex;
    align-items: center;

    span {
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;
      scrollbar-width: none;
    }

    &:hover {
      span {
        text-overflow: clip;
        white-space: normal;
      }
    } 
  }
}
`;

export const Tag = styled.div<TagProps>`
  ${({ tipo }) => { return tipo == StatusPagamento.Erro ? "cursor:pointer;" : "" }};
  background-color: ${ ({ tipo }) => tagStyleStrategy[tipo].background };
  border: 1px solid ${ ({ tipo }) => tagStyleStrategy[tipo].color };
  border-radius: 50px;
  color: ${ ({ tipo }) => tagStyleStrategy[tipo].color };
  padding: 5px 8px;
  text-align: center;
  width: 120px;
`;