import React, { useEffect } from "react";
import { ExportExcel } from "components/ExportExcel";
import { Search } from "components/Search";
import DataTable, { Alignment, TableColumn } from "react-data-table-component";
import { useSelectedRows } from "./hooks/useSelectedRows";
import { useTablePaginationSortSearch } from "./hooks/useTablePaginationSortSearch";
import {
  ContainerTable,
  NoDataComponentHeaderContainer,
  customStyle,
} from "./style";
import { ToolsAction } from "./ToolsAction";
import { ToolsHeaderTable } from "./ToolsHeaderTable";
import { TableProps } from "./interfaces/ITable";
import { LoadingCirculo } from "components/LoadingCirculo";
import { PaginationComponent } from "components/CustomPagination";

export function Table({
  keyField,
  title,
  columns,
  data,
  loading,
  defaultSortFieldId,
  showHeader = false,
  noSubHeader = false,
  noTableHead = false,
  pagination = false,
  totalRows = 0,
  setPaginationSortSearch,
  selectableRows = false,
  setRowsSelected,
  clearSelectedRows,
  selectableRowsNoSelectAll = false,
  selectableRowsSingle = false,
  tools,
  toolsHeader,
  exportExcel = false,
  rowToExpand,
  expandableRowsComponent,
  handleExport,
  buttonExportVariant,
  paginationRowsPerPageOptions,
  onChangeRowsPerPage,
  onChangePage,
  rowsPerPage,
  customTableStyle,
  handleSortFunction,
  paginationServer = true,
  possuiTooltip = false,
}: TableProps) {
  const { setSort, setOrder, setSearch, paginationSortSearch } =
    useTablePaginationSortSearch();

  const {
    selectedRows,
    setSelectedRows,
    toggledClearRows,
    setToggleClearRows,
  } = useSelectedRows();

  useEffect(() => {
    if (clearSelectedRows == null) return;

    if (clearSelectedRows) return handleClearRows();
  }, [clearSelectedRows]);

  useEffect(() => {
    if (setPaginationSortSearch && pagination)
      setPaginationSortSearch(paginationSortSearch);
  }, [paginationSortSearch]);

  useEffect(() => {
    if (setRowsSelected && selectedRows) setRowsSelected(selectedRows);
  }, [selectedRows]);

  const handleChange = ({ selectedRows }: any) => {
    setSelectedRows(selectedRows);
  };
  const handleClearRows = () => {
    setToggleClearRows(!toggledClearRows);
    setSelectedRows([]);
  };

  const handleSort = handleSortFunction
    ? handleSortFunction
    : async (column: TableColumn<any>, sortDirection: string) => {
      const sort = column.sortField ? column.sortField : "";
      setSort(sort);
      setOrder(sortDirection);
    };

  const paginationComponentOptions = {
    rowsPerPageText: "Linhas por página",
    rangeSeparatorText: "de",
  };

  const actionTools = <ToolsAction>{tools}</ToolsAction>;
  const actionHeader = (
    <ToolsHeaderTable
      tools={toolsHeader}
      exportExcel={
        exportExcel ? (
          <ExportExcel variant={buttonExportVariant} onClick={handleExport} />
        ) : (
          ""
        )
      }
      search={
        pagination ? <Search setSearch={setSearch} disabled={loading} /> : ""
      }
    />
  );

  const noDataComponent = () => {
    return (
      <div style={{ width: "100%", padding: "0", margin: "0" }}>
        <NoDataComponentHeaderContainer>
          <thead>
            <tr>
              {columns
                .filter((item) => !item.omit)
                .map((item) => {
                  return (
                    <th
                      key={item.name?.toString()}
                      style={{ width: item.width, padding: "0 1rem" }}
                    >
                      {item.name}
                    </th>
                  );
                })}
            </tr>
          </thead>
        </NoDataComponentHeaderContainer>
        <div style={{ marginTop: "20px", textAlign: "center" }}>
          Nenhum dado encontrado
        </div>
      </div>
    );
  };

  const style = { ...customStyle, ...customTableStyle };

  return (
    <ContainerTable possuiTooltip={possuiTooltip}>
      <DataTable
        keyField={keyField ? keyField : ""}
        title={title ? title : null}
        fixedHeader
        fixedHeaderScrollHeight="100vh"
        highlightOnHover
        noTableHead={noTableHead}
        noHeader={!showHeader}
        columns={columns}
        data={data}
        customStyles={style}
        progressPending={loading}
        progressComponent={<LoadingCirculo mostrar={loading} />}
        noDataComponent={noDataComponent()}
        striped
        responsive
        subHeader={!noSubHeader} // header de pesquisa
        subHeaderAlign={Alignment.RIGHT}
        subHeaderWrap
        subHeaderComponent={actionHeader}
        //SELECT
        selectableRows={selectableRows}
        selectableRowsHighlight
        selectableRowsNoSelectAll={selectableRowsNoSelectAll} //não mostrar todos
        selectableRowsSingle={selectableRowsSingle} //escolher somente 1
        selectableRowDisabled={(row) => row.selectable === false}
        // selectableRowsVisibleOnly
        // selectableRowsComponent={Checkbox}
        onSelectedRowsChange={handleChange}
        clearSelectedRows={toggledClearRows}
        //EXPANDIR
        expandableRows
        expandableRowExpanded={(row) => row === rowToExpand}
        expandableRowsHideExpander //esconder o icone de abrir
        expandableRowsComponent={expandableRowsComponent}
        // expandOnRowClicked //click em toda a linha
        // expandOnRowDoubleClicked
        // onRowExpandToggled={(state, row) => } //pega row atual
        // onRowClicked={(row, mouse)=>  }
        //PAGINATION
        paginationPerPage={rowsPerPage}
        paginationComponentOptions={paginationComponentOptions}
        paginationComponent={PaginationComponent}
        paginationRowsPerPageOptions={paginationRowsPerPageOptions}
        pagination={pagination}
        // paginationPerPage={10}
        paginationServer={paginationServer}
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onChangePage={onChangePage}
        // SORT
        defaultSortFieldId={defaultSortFieldId ? defaultSortFieldId : 0}
        onSort={handleSort}
        sortServer
        actions={actionTools}
      />
    </ContainerTable>
  );
}
