import { useEffect, useState } from "react";
import { EsqueceuSenha } from "../components/EsqueceuSenha";
import { LoginBox } from "../components/LoginBox";
import { LoginBoxForm, LoginContainer } from "../pages/Login/styles";
import { RedefinirSenha } from "../components/RedefinirSenha";
import { useSearchParams } from "react-router-dom";

type telas = "login" | "esqueceuSenha" | "redefinir";

export function LoginBoxContainer() {
  const [mostrarComponente, setMostrarComponente] = useState<telas>("login");
  const [params, serParams] = useSearchParams();

  useEffect(() => {
    const definirSenha = params.get("senha");

    if (!definirSenha) {
      setMostrarComponente("login");
      return;
    }

    if (["redefinir", "definir"].includes(definirSenha)) {
      setMostrarComponente("redefinir");
    }
  }, []);

  function handleEsqueceuSenha() {
    setMostrarComponente("esqueceuSenha");
  }

  function handleVoltarAoLogin() {
    setMostrarComponente("login");
  }

  const componentes = {
    login: <LoginBox handleClickEsqueceuSenha={handleEsqueceuSenha} />,
    esqueceuSenha: <EsqueceuSenha handleClickVoltarAoLogin={handleVoltarAoLogin} />,
    redefinir: <RedefinirSenha handleClickVoltarAoLogin={handleVoltarAoLogin} />,
  };

  return (
    <LoginBoxForm>
      <LoginContainer>{componentes[mostrarComponente]}</LoginContainer>
    </LoginBoxForm>
  );
}
