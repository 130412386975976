import { ModalCustom } from "components/ModalCustom";
import { Body, InputContainer, TextoLink, TextoSecundario } from "./style";
import { Col, Form, Row } from "react-bootstrap";
import { CustomInput } from "components/CustomInput";
import { Multiselect } from "components/Multiselect";
import { FaPenSquare } from "react-icons/fa";
import { Table } from "containers/Table";
import { IEtapaServicoModelo } from "features/core/interfaces/IEtapaServicoModelo";
import { IColum } from "containers/Table/interfaces";

interface IModalCadastrarServicoProps {
    show: boolean;
    handleClose: () => void;
    descricao: string;
    onConfirm: () => void;
    onChangeDescricaoServico: (descricao: any) => void;
    onChangeUf: (event: any) => void;
    listaUfs: string[];
    onCadastrarEtapas: () => void;
    dadosEtapas: IEtapaServicoModelo[];
}

export function ModalCadastrarServico({
    show,
    handleClose,
    descricao,
    onConfirm,
    onChangeDescricaoServico,
    onChangeUf,
    listaUfs,
    onCadastrarEtapas,
    dadosEtapas,
}: IModalCadastrarServicoProps) {

    const columns: IColum<IEtapaServicoModelo>[] = [
        {
            name: <span>Ordem</span>,
            selector: (item: IEtapaServicoModelo) => item.ordem,
            width: "90px",
        },
        {
            name: <span>Etapa</span>,
            selector: (item: IEtapaServicoModelo) => item.nome,
        },
    ];

    return (
        <ModalCustom
            show={show}
            handleClose={handleClose}
            title="Cadastrar Serviço"
            centered
            footer
            footerConfirmButton
            confirmButtonText="Cadastrar"
            onConfirm={onConfirm}
            size={"lg"}
        >
            <Body>
                <TextoSecundario>
                    Dados do serviço
                </TextoSecundario>
                <Form >
                    <Row style={{ marginTop: "12px" }}>
                        <Col md={8}>
                            <InputContainer>
                                <Form.Group>
                                    <Form.Label style={{ marginBottom: "0.3rem" }}>
                                        Descrição
                                    </Form.Label>
                                    <CustomInput
                                        type="text"
                                        placeholder="Descrição"
                                        style={{ height: "2.2rem" }}
                                        value={descricao}
                                        onChange={onChangeDescricaoServico}
                                    />
                                </Form.Group>
                            </InputContainer>
                        </Col>
                        <Col md={4}>
                            <InputContainer>
                                <Form.Group>
                                    <Form.Label style={{ marginBottom: "0" }}>
                                        UF
                                    </Form.Label>
                                    <Multiselect
                                        options={[
                                            { value: "AC", label: "AC", checked: false },
                                            { value: "AL", label: "AL", checked: false },
                                            { value: "AP", label: "AP", checked: false },
                                            { value: "AM", label: "AM", checked: false },
                                            { value: "BA", label: "BA", checked: false },
                                            { value: "CE", label: "CE", checked: false },
                                            { value: "DF", label: "DF", checked: false },
                                            { value: "ES", label: "ES", checked: false },
                                            { value: "GO", label: "GO", checked: false },
                                            { value: "MA", label: "MA", checked: false },
                                            { value: "MT", label: "MT", checked: false },
                                            { value: "MS", label: "MS", checked: false },
                                            { value: "MG", label: "MG", checked: false },
                                            { value: "PA", label: "PA", checked: false },
                                            { value: "PB", label: "PB", checked: false },
                                            { value: "PR", label: "PR", checked: false },
                                            { value: "PE", label: "PE", checked: false },
                                            { value: "PI", label: "PI", checked: false },
                                            { value: "RJ", label: "RJ", checked: false },
                                            { value: "RN", label: "RN", checked: false },
                                            { value: "RS", label: "RS", checked: false },
                                            { value: "RO", label: "RO", checked: false },
                                            { value: "RR", label: "RR", checked: false },
                                            { value: "SC", label: "SC", checked: false },
                                            { value: "SP", label: "SP", checked: false },
                                            { value: "SE", label: "SE", checked: false },
                                            { value: "TO", label: "TO", checked: false },
                                        ]}
                                        title=""
                                        value={listaUfs}
                                        onChange={onChangeUf}
                                        limitPlaceholder={20}
                                    />
                                </Form.Group>
                            </InputContainer>
                        </Col>
                    </Row>
                </Form>
                <TextoSecundario style={{ marginTop: "18px" }}>
                    Para cadastrar as mesmas etapas deste serviço para todos os estados selecionados, clique na opção abaixo.
                </TextoSecundario>
                <TextoLink style={{ marginTop: "12px" }}>
                    <span style={{ cursor: "pointer" }} onClick={onCadastrarEtapas}>{dadosEtapas.length ? "Editar" : "Cadastrar"} etapas <FaPenSquare /></span>
                </TextoLink>
                <Table
                    keyField="id"
                    columns={columns}
                    data={dadosEtapas}
                    loading={false}
                    noSubHeader
                />
            </Body>
        </ModalCustom>
    );
}