function mCNPJ(cnpj: string): string {
    cnpj = cnpj.replace(/^(\d{2})(\d)/, "$1.$2");
    cnpj = cnpj.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
    cnpj = cnpj.replace(/\.(\d{3})(\d)/, ".$1/$2");
    cnpj = cnpj.replace(/(\d{4})(\d)/, "$1-$2");
    return cnpj;
}

function mCPF(cpf: string): string {
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
    cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
    return cpf;
}

function mCPFCNPJ(valor: string) {
    valor = valor.replace(/\D/g, "");
    if (valor.length <= 11) {
        return mCPF(valor);
    } else {
        return mCNPJ(valor);
    }
}

export function formatarCpfCnpj(valor: string) {

    if (valor.length >= 19) {
        valor = valor.substring(0, valor.length - 1);
    } else {
        valor = mCPFCNPJ(valor);
    }

    return valor;
}