import styled from "styled-components";

export const Container = styled.div`
  color: #FFFFFF;
  background-color: #333333;
  width: auto;
  padding: 8px;
  border-radius: 0.5rem;
  align-items: center;
  margin: 0.5rem;
  box-shadow: 5px 3px 5px 2px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  font-size: 14px;
`;

export const TextoContainer = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.neutral.neutral70};
`;
