import { Informacao } from "components/Informacao";
import { Required } from "components/Required";
import FileUpload from "components/UploadArquivos";
import { Table } from "containers/Table";
import { IColum } from "containers/Table/interfaces";
import { useContext, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { BsCheckCircleFill, BsFillXCircleFill } from "react-icons/bs";
import { AutorizacaoPlacaContext } from "../../context/AutorizacaoPlacaContext";
import { IValidarPlanilha } from "../../interfaces/IValidarPlanilha";
import { Button, ButtonEnviar } from "../Filtros/style";
import { ButtonSolicitar, Header, TextoHeader } from "./style";
import { ModalConfirmacao } from "../ModalConfirmacao";
import temaPadrao from "_config/temas/estilo/base";
import { CompositeContainer } from "components/CompositeContainer";

export function SolicitarAutorizacao() {
  const { dadosValidar, enviar, validarPlanilha } = useContext(
    AutorizacaoPlacaContext
  );

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitted },
  } = useForm<IValidarPlanilha>();

  const columns: IColum<any>[] = [
    {
      name: <span>Proprietario</span>,
      selector: ({ proprietario }) => proprietario,
      sortable: true,
      sortField: "id",
    },
    {
      name: <span>CPF / CNPJ</span>,
      selector: ({ cpfCnpj }) =>
        cpfCnpj
          ? String(cpfCnpj).length > 11
            ? String(cpfCnpj).replace(
                /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
                "$1.$2.$3/$4-$5"
              )
            : String(cpfCnpj).replace(
                /^(\d{3})(\d{3})(\d{3})(\d{2})$/,
                "$1.$2.$3-$4"
              )
          : "-",
      sortable: true,
      sortField: "id",
    },
    {
      name: <span>Placa</span>,
      selector: ({ placa }) =>
        placa ? placa.replace(/^(.{3})(.{4})$/, "$1-$2") : "-",
      sortable: true,
      sortField: "id",
    },
    {
      name: <span>Renavam</span>,
      selector: ({ renavam }) => renavam,
      sortable: true,
      sortField: "id",
    },
    {
      name: <span>Chassi</span>,
      selector: ({ chassi }) => chassi,
      sortable: true,
      sortField: "id",
    },
    {
      name: <span>ID Processo</span>,
      selector: ({ idProcesso }) => idProcesso,
      sortable: true,
      sortField: "id",
    },
    {
      name: <span>Processo Detran</span>,
      selector: ({ numeroProcesso }) => numeroProcesso,
      sortable: true,
      sortField: "id",
    },
    {
      name: <span>Observação</span>,
      selector: ({ observacao }) => observacao.join("\n"),
    },
    {
      name: <span>Status</span>,
      selector: ({ status }) => (
        <div className="text-center">
          {status === 1 ? (
            <BsCheckCircleFill color="green" />
          ) : (
            <BsFillXCircleFill
              style={{
                color: temaPadrao.colors.danger.main,
              }}
            />
          )}
        </div>
      ),
      sortable: true,
      sortField: "id",
      width: "60px",
    },
  ];

  function handleBaixarModelo() {
    const link = document.createElement("a");
    link.download = "Modelo_autorizacao_placa.xlsx";
    link.href = "/app/files/Modelo_autorizacao_placa.xlsx";
    link.click();
  }

  return (
    <div>
      <ModalConfirmacao />
      <div className="bg-white">
        <Row className="p-4">
          <Col lg={6}>
            <p>Faça o upload da planilha para seguir com a solicitação</p>
            <div>
              <form onSubmit={handleSubmit(validarPlanilha)}>
                <Row>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>
                        Arquivo <Required />
                      </Form.Label>

                      <Controller
                        name="arquivo"
                        control={control}
                        render={({ field: { onChange } }) => (
                          <FileUpload
                            onFileUpload={(file) => onChange(file)}
                            mensagemErro={
                              isSubmitted
                                ? (errors.arquivo?.message as any)
                                : null
                            }
                            comMaxWidth={true}
                            gapContainer="0"
                          />
                        )}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <ButtonEnviar>Enviar</ButtonEnviar>
                  </Col>
                  <Col md={4}>
                    <Button
                      outline
                      variante="primary"
                      type="button"
                      onClick={handleBaixarModelo}
                    >
                      Baixar modelo
                    </Button>
                  </Col>
                </Row>
              </form>
            </div>
          </Col>
          <Col lg={6}></Col>
        </Row>
      </div>
      {dadosValidar.length > 0 && (
        <div className="mt-4 bg-white p-3">
          <Header>
            <TextoHeader>Solicitações realizadas</TextoHeader>
          </Header>
          <hr />
          <div className="d-flex flex-row-reverse mb-3">
            <ButtonSolicitar
              variante="primary"
              disabled={!!dadosValidar.find((item: any) => item.status === 2)}
              onClick={enviar}
            >
              Solicitar autorização
            </ButtonSolicitar>
          </div>
          {dadosValidar.find((item: any) => item.status === 2) && (
            <div className="mt-3 mb-3">
              <Informacao
                mensagem="Não foi possível realizar a solicitação devido a informações
          inconsistentes na planilha importada. Faça a correção dos erros
          listados abaixo e importe novamente a planilha para solicitar a(s)
          autorização(ões)."
                type="erro"
                showLabel={false}
                showBotaoFechar={false}
              />
            </div>
          )}
            <Table
              paginationServer={false}
              columns={columns}
              data={dadosValidar}
              loading={false}
              keyField=""
              pagination
              totalRows={dadosValidar.length}
              showHeader={false}
              noSubHeader
            />
        </div>
      )}
    </div>
  );
}
