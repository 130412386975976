let workerInstance: any = null;

export function makeWorker() {
  if (!workerInstance) {
    workerInstance = new Worker(
      new URL(
        "./CadastrarProcessoEmLoteWorker.js",
        import.meta.url
      ),
      {
        type: "module",
      }
    );
  }
  return workerInstance;
}
