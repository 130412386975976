import { Container, Header, Tag, TextoHeader } from "./style";
import { Table } from "containers/Table";
import { IColum } from "containers/Table/interfaces";
import { converterDataHoraBr } from "_utils/Data";
import { RedirectDetalhesVertical } from "components/RedirectDetalhes";
import { DownloadIconFunction } from "components/DownloadLink";
import { StatusSolicitacaoPreencherATPVEnum } from "../../enums/StatusSolicitacaoPreencherATPVenum";
import { Tooltip } from "components/Tooltip";
import { Informacao } from "components/Informacao";

interface IListarSolicitacoesProps {
    dados: IListarSolicitacoes[];
    handleLimitChange: (e: number) => void;
    handlePageChange: (e: number) => void;
    totalRows: number;
    handleDownload: (e: number) => void;
}

export function ListarSolicitacoes({
    dados,
    handleLimitChange,
    handlePageChange,
    totalRows,
    handleDownload,
}: IListarSolicitacoesProps) {

    const statusStrategy = {
        1: "Processando",
        2: "Finalizada",
        3: "Falha",
    }

    const rightTooltipDownload = 225;
    const rightTooltipDetalhes = 140;
    const bottomTooltips = 1.5;
    const widthTooltips = 1;
    const algumaPlacaDisponivel = 1;

    const columns: IColum<IListarSolicitacoes>[] = [
        {
            name: <span>ID Solicitação</span>,
            selector: ({ id }: IListarSolicitacoes) => id,
            width: "150px",
        },
        {
            name: <span>Data / Hora solicitação</span>,
            selector: ({ dataHora }: IListarSolicitacoes) => dataHora ? converterDataHoraBr(dataHora) : "-",
            width: "180px",
        },
        {
            name: <span>Empresa</span>,
            selector: ({ empresaNome }: IListarSolicitacoes) => empresaNome ?? "-",
            width: "250px",
        },
        {
            name: <span>Divisão</span>,
            selector: ({ divisaoNome }: IListarSolicitacoes) => divisaoNome ?? "-",
            width: "250px",
        },
        {
            name: <span>Usuário</span>,
            selector: ({ usuarioNome }: IListarSolicitacoes) => usuarioNome ?? "-",
            width: "150px",
        },
        {
            name: <span>Qtd de placas</span>,
            selector: ({ qtdPlacasSolicitadas }: IListarSolicitacoes) => qtdPlacasSolicitadas,
            width: "150px",
        },
        {
            name: <span>UF</span>,
            selector: ({ uf }: IListarSolicitacoes) => uf ?? "-",
            width: "90px",
        },
        {
            name: <span>Status</span>,
            selector: ({ status }: IListarSolicitacoes) => <Tag tipo={status as 1 | 2 | 3} >{statusStrategy[status as 1 | 2 | 3]}</Tag>,
        },
        {
            name: <span>Ações</span>,
            selector: ({ id, qtdPlacasDisponiveis }: IListarSolicitacoes) => {
                return (
                    <>
                        <Tooltip
                            right={rightTooltipDownload}
                            bottom={bottomTooltips}
                            width={widthTooltips}
                            positionRelative={false}
                            trigger={<DownloadIconFunction disabled={qtdPlacasDisponiveis < algumaPlacaDisponivel} handleDonwload={() => handleDownload(id)} />}>
                            <Informacao
                                type="preto"
                                typeTexto="preto"
                                title={true}
                                showBotaoFechar={false}
                                mensagem={"Download ATPVs"}
                                style={{
                                    width: "120px",
                                    fontSize: "10px",
                                    position: "absolute",
                                    left: "1rem",
                                    top: "1rem",
                                }}
                            />
                        </Tooltip>
                        <Tooltip
                            right={rightTooltipDetalhes}
                            bottom={bottomTooltips}
                            width={widthTooltips}
                            positionRelative={false}
                            trigger={<RedirectDetalhesVertical path={`/backoffice/preenchimentoatpv/detalhes?id=${id}`} />}>
                            <Informacao
                                type="preto"
                                typeTexto="preto"
                                title={true}
                                showBotaoFechar={false}
                                mensagem={"Ver mais"}
                                style={{
                                    width: "80px",
                                    fontSize: "10px",
                                    position: "absolute",
                                    left: "1rem",
                                    top: "1rem",
                                }}
                            />
                        </Tooltip>
                    </>
                )
            },
            width: "90px",
        }
    ];

    return (
        <Container>
            <Header>
                <TextoHeader>Listagem de solicitações</TextoHeader>
            </Header>
            <hr />
            <Table
                keyField="id"
                totalRows={totalRows}
                pagination={true}
                columns={columns}
                data={dados}
                paginationRowsPerPageOptions={[10, 20, 50]}
                loading={false}
                onChangeRowsPerPage={handleLimitChange}
                onChangePage={handlePageChange}
                noSubHeader
                possuiTooltip
            />
        </Container>
    );
}