import { ReactNode } from "react";
import { Container } from "./style";

interface Props {
  children: ReactNode;
  overflowXHidden?: boolean;
}

export function CompositeContainerRoot({ children, overflowXHidden = true }: Props) {
  return <Container overflowXHidden={overflowXHidden}>{children}</Container>;
}

