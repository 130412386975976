import styled from "styled-components";

interface IContainerProps {
  overflowXHidden?: boolean;
}

export const Container = styled.div<IContainerProps>`
  margin-top: 2rem;
  width: 100%;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.white};

  .rdt_TableCol {
    padding: 0 8px;
    margin: 0 6px;
    
    div {
      display: flex;
      align-items: center;
      justify-content: start;
      font-size: 15px;
    }
  }

  .rdt_TableCell {
    padding: 0 8px;
    margin: 0 6px;
    justify-content: start;

    div {
      white-space: nowrap;
      ${({ overflowXHidden }) => overflowXHidden ? "overflow-x: hidden;" : ""}
      text-overflow: ellipsis;
      scrollbar-width: none;

      &:hover {
        text-overflow: clip;
        white-space: normal;
      }
    }
  }
`;
