import { alertaErro } from "components/Alerta";
import { CustomDatePicker } from "components/DatePicker";
import { DropdownSelect } from "components/DropdownSelect";
import { ContainerHeader } from "components/Ibox/style";
import { LoadingScreen } from "components/LoadingScreen";
import { Page } from "containers/Page";
import { ButtonsContainer } from "features/processo/pages/CadastrarEmLote/containers/ImportarExcelProcessos/style";
import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import {
  Container,
  DatePickerContainer,
  FormContainer,
  Title,
} from "../ClonarServicoModelo/containers/Formulario/style";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { scriptGerais } from "../../Validate/validator";
import { CustomInput } from "components/CustomInput";
import { Required } from "components/Required";
import { getToken } from "_services/auth";
import { Data } from "_utils/Data";
import { PrimaryButton } from "components/Button/style";

interface IDadosScriptGeral {
  tipoScript: string;
  periodo: Date | null | undefined;
  idEmpresa: number | null | undefined;
}
function ScriptsGerais() {
  const [loading, setLoading] = useState<boolean>(false);

  const [selectedScript, setSelectedScript] = useState<string>("");
  
  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
  } = useForm<IDadosScriptGeral>({
    resolver: yupResolver(scriptGerais),
  });

  const [dataInicio, setDataInicio] = useState<Date>();
  const [dataFim, setDataFim] = useState<Date>();
  const onChangeData = (dates: any) => {
    const [start, end] = dates;
    setDataInicio(start);
    setDataFim(end);
  };
  const scriptDataObrigatorio = [
    "RegistrosPagamentoAw",
    "RegistrosPagamentoDebitos",
  ];

  const scriptInputIdEmpresa = ['FaturamentoCustoReceberAw'];

  async function formSubmit({ tipoScript, idEmpresa }: IDadosScriptGeral) {
    if (scriptDataObrigatorio.includes(tipoScript) && !dataInicio && !dataFim) {
      alertaErro("Periodo é obrigatorio");
      return;
    }

    const token = getToken();
    const baseUrlMsJob = process.env.REACT_APP_MS_JOB
      ? process.env.REACT_APP_MS_JOB
      : "https://task.lwtecnologia.com.br/ms_jobs";

    let url = `${baseUrlMsJob}/v1/scripts/financeiro/excel?tipoScript=${tipoScript}`;

    url = dataInicio ? `${url}&dataInicio='${new Data(dataInicio).getDataEua()} 00:00:00'` : `${url}`;
    url = dataFim ? `${url}&dataFim='${new Data(dataFim).getDataEua()} 23:59:00'` : `${url}`;
    url = idEmpresa ? `${url}&idEmpresa=${idEmpresa}` : `${url}`;
    url = `${url}&Authorization=${token}`;
    window.open(`${url}`);

  }

  
  return (
    <Page title="Scripts em geral">
      <LoadingScreen mostrar={loading} />
      <Container>
        <ContainerHeader>
          <Title>Execução de Scripts em Geral</Title>
        </ContainerHeader>
        <hr />
        <FormContainer>
          <Form noValidate onSubmit={handleSubmit(formSubmit)}>
            <Row>
              <Col md={3}>
                <Form.Group>
                  <Form.Label style={{ marginBottom: "0.8rem" }}>
                    Selecione o script <Required />
                  </Form.Label>
                  <Controller
                    control={control}
                    name="tipoScript"
                    render={({ field: { onChange, value } }) => (
                      <DropdownSelect
                        options={[
                          {
                            value: "RegistrosPagamentoAw",
                            label: "Registros Pagamento AW",
                          },
                          {
                            value: "RegistrosPagamentoDebitos",
                            label: "Registros Pagamento Debitos",
                          },
                          {
                            value: "FaturamentoCustoReceberAw",
                            label: "Faturamento Custo a Receber AW",
                          },
                        ]}
                        selectedValue={value}
                        onSelect={(selectedValue) => {
                          setSelectedScript(selectedValue);
                          onChange(selectedValue);
                        }}
                        searchable
                      />
                    )}
                  />
                  <Form.Text className="text-danger">
                    {errors.tipoScript?.message}
                  </Form.Text>
                </Form.Group>
              </Col>
              

                {scriptDataObrigatorio.includes(selectedScript) ? (
                    <Col md={3}>
                  <Form.Group>
                    <Form.Label style={{ marginBottom: "0.8rem" }}>
                      Escolha o período <Required />
                    </Form.Label>
                    <Controller
                      control={control}
                      name="periodo"
                      render={({ field: { onChange, value } }) => (
                        <DatePickerContainer>
                          <CustomDatePicker
                            startDate={dataInicio}
                            endDate={dataFim}
                            onChange={onChangeData}
                            rangeSelect={true}
                          />
                        </DatePickerContainer>
                      )}
                    />
                  </Form.Group>
                  </Col>
                ) : ''}

              {scriptInputIdEmpresa.includes(selectedScript) ? (
                <Col md={3}>
                <Form.Group>
                  <Form.Label style={{ marginBottom: "0.8rem" }}>
                    Id Empresa
                  </Form.Label>
                  <CustomInput
                    type="number"
                    defaultValue={0}
                    placeholder="Adicone id empresa"
                    {...register("idEmpresa")}
                    mensagemErro={errors?.idEmpresa?.message}
                  />
                </Form.Group>
              </Col>
              ) : '' }
              <Col md={3}>
                <Form.Group>
                  <ButtonsContainer>
                    <PrimaryButton type="submit" style={{ marginTop: "1.9rem" }} buttonWidth="xs">
                      Executar
                    </PrimaryButton>
                  </ButtonsContainer>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </FormContainer>
      </Container>
    </Page>
  );
}

export { ScriptsGerais };
