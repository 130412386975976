import styled from "styled-components";

interface IChangeRowsButtonProps {
  active?: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

export const SelectPaginationContainter = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  width: 33.33%;
`;

export const TotalRows = styled.div`
  color: ${({ theme }) => theme.colors.neutral.neutral50};
`;

export const SelectPagination = styled.select`
  display: flex;
  width: auto;
  border-radius: 0.6rem;
  padding: 0.5rem 1rem;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.neutral.neutral50};
`;

export const ChangeRows = styled.div`
  display: flex;
  gap: 4px;
  width: 33.33%;
  justify-content: center;
`;

export const ChangeRowsButton = styled.div<IChangeRowsButtonProps>`
  cursor: pointer;
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  color: ${(props) => (props.active ? props.theme.colors.white : "black")};
  background-color: ${(props) =>
    props.active ? props.theme.colors.project.main : ""};

  &:hover {
    background-color: ${(props) =>
      !props.active ? props.theme.colors.project.bgColor : ""};
  }
`;
