import { Route, Routes } from "react-router-dom";
import { CadastrarEmLote } from "../pages/CadastrarEmLote";

function ProcessoRoutes() {
  return (
    <Routes>
      <Route path="/cadastrar/lote" element={<CadastrarEmLote />} />
    </Routes>
  );
}
export { ProcessoRoutes };

