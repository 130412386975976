import { Route, Routes } from "react-router-dom";
import { ClonarServicoModelo } from "../Pages/ClonarServicoModelo";
import { MotivosPendencia } from "../Pages/MotivosPendencia";
import { ScriptsGerais } from "../Pages/ScriptGerais";

function ConfiguracaoRoutes() {
    return (
        <Routes>
            <Route path="/script/clonar_servico_modelo" element={<ClonarServicoModelo />} />
            <Route path="/motivos_pendencia" element={<MotivosPendencia />} />
            <Route path="/script/gerais" element={<ScriptsGerais />} />
        </Routes>
    );
}
export { ConfiguracaoRoutes };