import { useRequest } from "hooks/useRequest";
import { ILogar } from "../@types/ILogar";
import { useState } from "react";

export function useLogin() {
  const [loading, setLoading] = useState<boolean>(false);
  const api = useRequest("api", false);
  const msUsuario = useRequest("usuario", false);
  const frontPhp = useRequest("frontphp");

  async function logar(login: ILogar) {
    try {
      setLoading(true);
      return await msUsuario.post("/usuario/login", login);
    } finally {
      setLoading(false);
    }
  }

  async function validar(token: string) {
    try {
      setLoading(true);
      return await frontPhp.post(
        `/pages/core/login/ValidarUsuario.php`,
        { token },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    } finally {
      setLoading(false);
    }
  }

  async function enviarEmailRedefinirSenha(email: string) {
    try {
      setLoading(true);
      return await api.post(`/usuario/senha/redefine/email`, { email });
    } finally {
      setLoading(false);
    }
  }

  async function crsf() {
    try {
      setLoading(true);
      return await msUsuario.get(`/usuario/csrf`);
    } finally {
      setLoading(false);
    }
  }

  async function logout(manter: boolean = false) {
    const storageManter = [
      "config_buscar_processos",
    ];

    const storageRecriar = storageManter.map((item) => {
      if (localStorage.getItem(item)) {
        return { id: item, valor: localStorage.getItem(item), restaurar: true }
      }
      return { id: item, valor: null, restaurar: false }
    })

    localStorage.clear();
    if (manter) {
      storageRecriar.forEach((item) => {
        if (item.restaurar && item.valor) {
          localStorage.setItem(item.id, item.valor)
        }
      })
    }
    return true;
  }

  async function validarToken() {
    try {
      setLoading(true);
      return await msUsuario.get(`/usuario/token/valido`);
    } finally {
      setLoading(false);
    }
  }

  async function validarTokenObterDados(token: string) {
    try {
      setLoading(true);
      return await msUsuario.post(`/usuario/validade`, { token, loginKeycloak: "sim" });
    } finally {
      setLoading(false);
    }
  }

  return { validarToken, logout, logar, loading, validar, enviarEmailRedefinirSenha, crsf, validarTokenObterDados };
}
