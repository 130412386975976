import { usePaginationSortSearch } from "containers/Table/hooks/usePaginationSortSearch";
import { useCallback, useState } from "react";
import { AxiosRequest } from "_services/api";
import { HttpError } from "_services/api/HttpError";
import { HttpResponse } from "_services/api/HttpResponse";
import { IData, IHttpError, IHttpResponse } from "_services/api/interface/HttpResponse";
import { converterDataEua } from "_utils/Data";
import { IPreencherATPVLoteEnviar } from "../interfaces/IPreencherATPVLoteEnviar";
import { alertaErro } from "components/Alerta";
import { getToken } from "_services/auth";

export function usePreencherATPV() {
    const api = AxiosRequest.makeApi(process.env.REACT_APP_MS_AW);
    const token = getToken();
    const { totalRows, setTotalRows } = usePaginationSortSearch();
    const [loading, setLoading] = useState(false);
    const [dados, setDados] = useState<IListarSolicitacoes[]>([]);
    const [error, setError] = useState<IHttpResponse<IData | any>>();
    const [page, setPage] = useState<number>(1);
    const [limit, setLimit] = useState<number>(10);
    const [queryParams, setQueryParams] = useState<string>("");
    const [idSolicitacao, setIdSolicitacao] = useState<number | null>(null);
    const [showModalSucesso, setShowModalSucesso] = useState<boolean>(false);
    const [showModalPreencherATPV, setShowModalPreencherATPV] = useState<boolean>(false);
    const [forceUpdate, setForceUpdate] = useState(false);
    const statusNotFound = 404;

    const gerarQueryParams = (data: IFiltro) => {
        const placa = data.placa ? `placa=${data.placa}` : null;
        const dataInicial = data.periodoSolicitacao && data.periodoSolicitacao[0] ? `dataInicial=${converterDataEua(data.periodoSolicitacao[0])}` : null;
        const dataFinal = data.periodoSolicitacao && data.periodoSolicitacao[1]
            ? `dataFinal=${converterDataEua(data.periodoSolicitacao[1])}`
            : data.periodoSolicitacao && data.periodoSolicitacao[0]
                ? `dataFinal=${converterDataEua(data.periodoSolicitacao[0])}`
                : null;
        const idSolicitacao = data.idSolicitacao ? `idSolicitacao=${data.idSolicitacao}` : null;
        const empresa = data.empresa ? `empresa=${data.empresa}` : null;
        const status = data.statusSolicitacao ? `status=${data.statusSolicitacao}` : null;

        const params = [placa, dataInicial, dataFinal, idSolicitacao, empresa, status]
            .filter((item) => item !== null)
            .join("&");
        setQueryParams(params);
        setForceUpdate(prevState => !prevState);
    };

    const buscarDados = useCallback(async () => {
        setLoading(true);
        try {
            const { status, data } = await api.get(`/atpv/solicita/?pagina=${page}&limite=${limit}&${queryParams}`);

            setDados(data.itens);
            setTotalRows(data.meta.total);
            const response = new HttpResponse(false, status, data);
            setError(response);
            return response;
        } catch (error: IHttpError<IData, any> | any) {
            const response = new HttpError<IData>(true, error);
            setDados([]);
            setTotalRows(0);
            if (response.data.message != "Nenhuma solicitação encontrada") {
                alertaErro("Aviso", response.data.message);
            }
            return response;
        } finally {
            setLoading(false);
        }
    }, [limit, page, queryParams, setTotalRows]);

    const preencherATPVUnitario = async (dados: IPreencherATPVUnitario) => {
        setLoading(true);
        try {
            const { status, data } = await api.post(`/atpv/solicita/`, {
                ...dados,
                idEmpresa: dados.empresa,
                idDivisao: dados.divisao,
                valorVenda: dados.valorVenda.replace("R$","").replaceAll(".", "").replace(",", ".").trim()
            });
            setShowModalPreencherATPV(false);
            setShowModalSucesso(true);
            setIdSolicitacao(data.id);
            buscarDados();
            const response = new HttpResponse(false, status, data);
            setError(response);
            return response;
        } catch (error: IHttpError<IData, any> | any) {
            const response = new HttpError<IData>(true, error);
            setShowModalPreencherATPV(false);
            setIdSolicitacao(null);
            alertaErro("Ocorreu um erro", response.data.message);
            return response;
        } finally {
            setLoading(false);
        }
    };

    const preencherATPVLote = async (dados: IPreencherATPVLoteEnviar) => {
        setLoading(true);
        try {
            const { status, data } = await api.post(`/atpv/solicita/lote`, {
                ...dados,
                idEmpresa: dados.empresa,
                idDivisao: dados.divisao,
            }, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            buscarDados();
            const response = new HttpResponse(false, status, data);
            setError(response);
            return response;
        } catch (error: IHttpError<IData, any> | any) {
            const response = new HttpError<IData>(true, error);
            alertaErro("Ocorreu um erro", response.data.message);
            return response;
        } finally {
            setLoading(false);
        }
    };

    const downloadSolicitacaoATPV = async (id: number) => {
        window.open(`${process.env.REACT_APP_MS_IMAGEM_BASE_URL}/imagem/atpv/solicita/${id}?authorization=Bearer ${token}`)
    };

    return {
        error,
        setLoading,
        loading,
        setPage,
        setLimit,
        page,
        limit,
        totalRows,
        buscarDados,
        dados,
        queryParams,
        gerarQueryParams,
        preencherATPVUnitario,
        idSolicitacao,
        setShowModalSucesso,
        showModalSucesso,
        setShowModalPreencherATPV,
        showModalPreencherATPV,
        downloadSolicitacaoATPV,
        preencherATPVLote,
        forceUpdate,
    };
}
