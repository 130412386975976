import { useCallback, useEffect, useState } from "react";
import { io, Socket } from "socket.io-client";

export function useSocket() {
  const [socket, setSocket] = useState<Socket | null>(null);

  const connect = useCallback(() => {
    if (socket) {
      return socket;
    }

    const socketIO = io(process.env.REACT_APP_URL_SOCKET!);
    socketIO.on("connect", () => {
      console.log("Conectado");
      setSocket(socketIO);
    });
    socketIO.on("disconnect", () => {
      console.log("desconectou");
    });
    socketIO.on("connect_error", (error) => {
      console.error("Erro ao conectar:", error);
    });

    return socketIO;
  }, [socket]);

  useEffect(() => {
    connect();
  }, [connect]);

  return { socket };
}

