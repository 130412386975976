import { ModalCustom } from "components/ModalCustom";
import { Body } from "./style";
import { Table } from "containers/Table";
import { IColum } from "containers/Table/interfaces";
import { IEtapaServico } from "features/core/interfaces/IEtapaServico";

interface IModalEtapasServicoDespachanteProps {
    show: boolean;
    handleClose: () => void;
    dados: IEtapaServico[];
}

export function ModalEtapasServicoDespachante({
    show,
    handleClose,
    dados
}: IModalEtapasServicoDespachanteProps) {

    const columns: IColum<IEtapaServico>[] = [
        {
            name: <span>Ordem</span>,
            selector: (etapa: IEtapaServico) => etapa.ordem,
            width: "90px",
        },
        {
            name: <span>Etapa</span>,
            selector: (etapa: IEtapaServico) => etapa.nome,
        },
    ];

    return (
        <ModalCustom
            show={show}
            handleClose={handleClose}
            title="Etapas"
            centered
            footer
            footerCloseButton
        >
            <Body>
                <div>Listagem de etapas</div>

                <Table
                    keyField="id"
                    columns={columns}
                    data={dados}
                    loading={false}
                    noSubHeader
                />
            </Body>
        </ModalCustom>
    );
}