import styled from "styled-components";
import 'react-datepicker/dist/react-datepicker.css';
import { PrimaryButton } from "components/Button/style";

export const Container = styled.div`
  margin-top: 1.5rem;
  width: 100%;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const Title = styled.h2`
  color: ${({ theme }) => theme.colors.neutral.neutral90};
  font-weight: bold;
  font-size: 1.2rem;
  padding: 0;
  margin: 0;
`;

export const FormContainer = styled.div`
  margin-top: 2rem;

  select {
    border-radius: 8px;
    height: 38px;
  }

  .row {
    height: 125px;
  }
`;

export const DatePickerContainer = styled.div`
  display: grid;
  gap: 0.3rem;
  width: 100%;
`;

export const Button = styled(PrimaryButton)`
  width: 100px;
`;

export const ButtonsContainer = styled.div`
  position: relative;
  padding-top: 1.4rem;
  display: flex;
  justify-content: space-between;
`;

export const InputContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 20px;
  display: grid;
  height: 60px;
`;