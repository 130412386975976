import styled from "styled-components";

export const ContainerHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    div {
        width: 100%;
    }
`