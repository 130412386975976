import styled from "styled-components";

export const FormContainer = styled.div`
  margin-top: 2rem;

  select {
    border-radius: 8px;
    height: 38px;
  }

  .row {
    height: 125px;
  }
`;

export const Background = styled.div`
    padding: 16px;
    background-color: ${({theme}) => theme.colors.neutral.neutral00};
    margin-bottom: 24px;
`

export const TituloSecao = styled.strong`

`

export const ButtonContainer = styled.div`
    position: relative;
    padding: 0.8rem 0;
    display: flex;
    justify-content: flex-end;
`