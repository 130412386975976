import { useCallback, useEffect, useMemo, useState } from "react";
import { AxiosRequest } from "_services/api";
import { HttpError } from "_services/api/HttpError";
import { HttpResponse } from "_services/api/HttpResponse";
import {
  IData,
  IHttpError,
  IHttpResponse,
} from "_services/api/interface/HttpResponse";

export function useEmpresas() {
  const [empresas, setEmpresas] = useState<any[]>([]);
  const [empresasOptions, setEmpresasOptions] = useState<any[]>([]);
  const [error, setError] = useState<IHttpResponse<IData | any>>();

  const api = useMemo(() => AxiosRequest.makeApi(), []);

  const listarTodasEmpresas = useCallback(async () => {
    try {
      const { status, data } = await api.get("/empresa/");
      const rersponse = new HttpResponse(false, status, data);
      setError(rersponse);
      setEmpresas(data.dados);
      criarOptionsEmpresas(data.dados);
      return rersponse;
    } catch (error: IHttpError<IData, any> | any) {
      const response = new HttpError<IData>(true, error);
      setError(response);
      return response;
    }
  }, [api]);

  useEffect(() => {
    listarTodasEmpresas();
  }, [listarTodasEmpresas]);

  function criarOptionsEmpresas(empresas: any[]) {
    const optionsEmpresas = empresas.map((item) => ({
      value: item.id,
      label: item.razao_social,
    }));

    setEmpresasOptions(optionsEmpresas);
    return optionsEmpresas;
  }

  return {
    empresas,
    error,
    empresasOptions,
    criarOptionsEmpresas,
  };
}

