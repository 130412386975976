import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useMenu from "hooks/UseMenu";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { ContextMenu } from "./ContextMenu";
import { MenuSpan } from "./estilo";
import ItemMenu from "./ItemMenu";
import { Headset } from "react-bootstrap-icons";
import { parseJwt } from "_services/auth";
import { useLogin } from "features/Login/hooks/useLogin";

interface Props {
  alterMiniNavbar(): void;
  activeMiniNavbar: boolean;
}

function Menu({ alterMiniNavbar, activeMiniNavbar }: Props) {
  const { stateMenu } = useContext(ContextMenu);
  const { logout } = useLogin();
  const baseUrlDespachante = process.env.REACT_APP_DESPACHANTE_BASE_URL;
  const [itens] = useMenu();
  const usuario = parseJwt();
  const usuarioSuporte = 1;

  return (
    <>
      {itens?.map((item, indice) => {
        return (
          <li
            key={indice}
            className={indice === stateMenu.indice ? "active" : ""}
          >
            <ItemMenu
              item={item}
              ativo={indice === stateMenu.indice ? stateMenu.ativo : false}
              indice={indice}
              alterMiniNavbar={alterMiniNavbar}
              activeMiniNavbar={activeMiniNavbar}
            />
          </li>
        );
      })}

      <li>
        <Link
          style={{ textDecoration: "none", height: "60px", padding: "22px 20px 22px 25px" }}
          className="dropdown-item" onClick={() => logout(true)} to={`${baseUrlDespachante}/Logout.php`}
        >
          <FontAwesomeIcon icon={faRightFromBracket} />
          <MenuSpan className="nav-label">Sair</MenuSpan>{" "}
        </Link>
      </li>
    </>
  );
}

export default Menu;

