import { AxiosRequestConfig, AxiosResponseHeaders } from "axios";
import { IData, IHttpError, IHttpResponse } from "./interface/HttpResponse";

class HttpError<T = any, D = any> {
  hasErro: boolean;
  status: number;
  data: T | IData;
  statusText?: string;
  headers?: AxiosResponseHeaders;
  config?: AxiosRequestConfig<D>;
  request?: any;
  constructor(
    hasErro: boolean,
    error: IHttpResponse<T, D> | IHttpError<T, D> | any
  ) {
    this.hasErro = hasErro;
    const response = error?.response;
    if (response?.status) {
      this.status = response.status;
      this.data = response.data;
      this.statusText = response.statusText;
      this.headers = response.headers;
      this.request = response.request;
      this.config = response.config;
      return;
    }
    this.status = 500;
    this.data = {
      status: 500,
      message: "Falha na requisição",
      erro: [],
    };

    return;
  }
}

export { HttpError };
