import { usePaginationSortSearch } from "containers/Table/hooks/usePaginationSortSearch";
import { useCallback, useState } from "react";
import { AxiosRequest } from "_services/api";
import { HttpError } from "_services/api/HttpError";
import { HttpResponse } from "_services/api/HttpResponse";
import { IData, IHttpError, IHttpResponse } from "_services/api/interface/HttpResponse";
import { IDadosResumoDetalhesSolicitacaoATPV } from "../interfaces/IDadosResumoDetalhesSolicitacaoATPV";
import { alertaErro } from "components/Alerta";

export function usePreencherDetalhesATPV() {
    const dadosIniciaisResumo = {
        empresa: "",
        divisao: "",
        usuario: "",
        idSolicitacao: 0,
        dataHoraSolicitacao: "",
        qtdPlacas: 0,
        qtdEmitidas: 0
    }

    const api = AxiosRequest.makeApi(process.env.REACT_APP_MS_AW);
    const apiSlim = AxiosRequest.makeApi();
    const msImagem = AxiosRequest.makeApi(process.env.REACT_APP_MS_IMAGEM_BASE_URL);
    const { totalRows, setTotalRows } = usePaginationSortSearch();
    const [loading, setLoading] = useState(false);
    const [dados, setDados] = useState<IListarDetalhesATPV[]>([]);
    const [error, setError] = useState<IHttpResponse<IData | any>>();
    const [page, setPage] = useState<number>(1);
    const [limit, setLimit] = useState<number>(10);
    const [queryParams, setQueryParams] = useState<string>("");
    const [dadosReumo, setDadosResumo] = useState<IDadosResumoDetalhesSolicitacaoATPV>(dadosIniciaisResumo);
    const [showModalConsultarNovamenteSucesso, setShowModalConsultarNovamenteSucesso] = useState<boolean>(false);
    const [id, setId] = useState<string>("");
    const [forceUpdate, setForceUpdate] = useState(false);
    const [dadosExcel, setDadosExcel] = useState<IListarDetalhesATPV[]>([]);

    const gerarQueryParams = (data: IFiltroDetalhes) => {
        const quantidadeCaracteresCPF = 11;
        if (data.cpfCnpj && data.cpfCnpj.length == quantidadeCaracteresCPF) {
            data.cpfCnpj = data.cpfCnpj.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4');
        }

        if (data.cpfCnpj && data.cpfCnpj.length > quantidadeCaracteresCPF) {
            data.cpfCnpj = data.cpfCnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5');
        }

        const placa = data.placa ? `placa=${data.placa}` : null;
        const renavam = data.renavam ? `renavam=${data.renavam}` : null;
        const cpfCnpj = data.cpfCnpj ? `cpfCnpj=${data.cpfCnpj.replace(/[-./]/g, "")}` : null;
        const idProcesso = data.idProcesso ? `idProcesso=${data.idProcesso}` : null;
        const status = data.statusATPV ? `status=${data.statusATPV}` : null;

        const params = [placa, renavam, cpfCnpj, idProcesso, status]
            .filter((item) => item !== null)
            .join("&");
        setQueryParams(params);
        setForceUpdate(prevState => !prevState);
    };

    const buscarDados = useCallback(async () => {
        setLoading(true);
        try {
            const { status, data } = await api.get(`/atpv/detalhes/solicitacao/${id}?pagina=${page}&limite=${limit}&${queryParams}`);
            setDados(data.itens);
            setTotalRows(data.meta.total);
            const response = new HttpResponse(false, status, data);
            setError(response);
            return response;
        } catch (error: IHttpError<IData, any> | any) {
            const response = new HttpError<IData>(true, error);
            setDados([]);
            setTotalRows(0);
            if (response.data.message != "ATPVs não encontrados") {
                alertaErro("Aviso", response.data.message);
            }
            return response;
        } finally {
            setLoading(false);
        }
    }, [id, limit, page, queryParams, setTotalRows]);

    const buscarDadosResumo = useCallback(async () => {
        setLoading(true);
        try {
            const { status, data } = await api.get(`/atpv/resumo/solicitacao/${id}?${queryParams}`);
            setDadosResumo(data);
            const response = new HttpResponse(false, status, data);
            setError(response);
            return response;
        } catch (error: IHttpError<IData, any> | any) {
            const response = new HttpError<IData>(true, error);
            setDadosResumo(dadosIniciaisResumo);
            return response;
        } finally {
            setLoading(false);
        }
    }, [id, queryParams]);

    const downloadATPV = async (id: number | string, placa: string) => {
        setLoading(true);
        try {
            const { status, data } = await msImagem.get(`/imagem/atpv/${id}`, {
                responseType: "blob",
            });
            const response = new HttpResponse(false, status, data);
            const nomeArquivo = `${placa}-ATPV-e.pdf`;
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", nomeArquivo);
            document.body.appendChild(link);
            link.click();
            setError(response);
            return response;
        } catch (error: IHttpError<IData, any> | any) {
            const response = new HttpError<IData>(true, error);
            alertaErro("Ocorreu um erro", response.data.message);
            return response;
        } finally {
            setLoading(false);
        }
    };

    const consultarNovamente = async (id: number | string) => {
        setLoading(true);
        try {
            const { status, data } = await api.post(`/atpv/solicita/reconsulta/${id}`);
            const response = new HttpResponse(false, status, data);
            setError(response);
            return response;
        } catch (error: IHttpError<IData, any> | any) {
            const response = new HttpError<IData>(true, error);
            return response;
        } finally {
            setLoading(false);
        }
    };

    const onClickProcesso = async (id: number | string) => {
        setLoading(true);
        try {
            const { status, data } = await apiSlim.get(`/processos/param/${id}`);
            window.open(`${process.env.REACT_APP_DESPACHANTE_BASE_URL}/pages/processo/Processo.php?param=${data}`, "_blank")
            const response = new HttpResponse(false, status, data);
            setError(response);
            return response;
        } catch (error: IHttpError<IData, any> | any) {
            const response = new HttpError<IData>(true, error);
            return response;
        } finally {
            setLoading(false);
        }
    };

    const buscarDadosExcel = async () => {
        setLoading(true);
        try {
            const { status, data } = await api.get(`/atpv/detalhes/solicitacao/${id}?${queryParams}`);
            setDadosExcel(data.itens);
            const response = new HttpResponse(false, status, data);
            return response;
        } catch (error: IHttpError<IData, any> | any) {
            const response = new HttpError<IData>(true, error);
            setDadosExcel([]);
            if (response.data.message != "ATPVs não encontrados") {
                alertaErro("Aviso", response.data.message);
            }
            return response;
        } finally {
            setLoading(false);
        }
    };

    return {
        error,
        setLoading,
        loading,
        setPage,
        setLimit,
        page,
        limit,
        totalRows,
        buscarDados,
        dados,
        queryParams,
        gerarQueryParams,
        downloadATPV,
        buscarDadosResumo,
        dadosReumo,
        consultarNovamente,
        showModalConsultarNovamenteSucesso,
        setShowModalConsultarNovamenteSucesso,
        onClickProcesso,
        setId,
        id,
        forceUpdate,
        buscarDadosExcel,
        dadosExcel,
    };
}
