import { useRequest } from "hooks/useRequest";
import { useState } from "react";
import { IValidarToken } from "../@types/IValidarToken";
import { IAlterarSenha } from "../@types/IAlterarSenha";

export function useRedefinirSenha() {
  const [loading, setLoading] = useState<boolean>(false);

  const api = useRequest("api");

  async function gravarSenha(dados: IAlterarSenha) {
    return await api.patch(`/usuario/senha/redefine`, dados);
  }

  async function validarMinutosRedefinirSenha(token: IValidarToken) {
    try {
      setLoading(true);
      return await api.post(`/usuario/senha/minutos`, token);
    } finally {
      setLoading(false);
    }
  }

  async function validarHorasRedefinirSenha(token: IValidarToken) {
    try {
      setLoading(true);
      return await api.post(`/usuario/senha/horas`, token);
    } finally {
      setLoading(false);
    }
  }

  async function definirPrimeiraSenha(dados: IAlterarSenha) {
    return await api.post(`/usuario/senha/define`, dados);
  }

  return {
    gravarSenha,
    validarMinutosRedefinirSenha,
    validarHorasRedefinirSenha,
    definirPrimeiraSenha,
    loading,
  };
}
