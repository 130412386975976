import { FaQuestion } from "react-icons/fa";
import styled from "styled-components";

export const CustomQuestion = styled(FaQuestion)`
  width: 7px;
  color: ${({theme}) => theme.colors.project.main};
`;

export const Circle = styled.div`
  background-color: #FBF1F1;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  position: absolute;
  top: -13px;
`;