import { ILogar } from "features/Login/@types/ILogar";
import { OverlayTrigger } from "react-bootstrap";
import { Control, Controller } from "react-hook-form";
import { Dica, FormGroup, IconeInterrogacao, IconeSenha, InputCodigo, TextoContainer, TitleLogin } from "../LoginBox/style";
import { Id, TextoCodigo, TextoEtapa } from "./style";

interface Props {
  idEmail: string;
  handleLimparErros(): void;
  control: Control<ILogar, any>;
  setCodigoDigitado(codigo: string): void;
  erroCodigo: string;
}

export function Codigo2Fatores({ idEmail, handleLimparErros, control, setCodigoDigitado, erroCodigo }: Props) {
  return (
    <>
      <TextoContainer>
        <TitleLogin>Login</TitleLogin>
        <TextoEtapa>Para ajudar a manter seus dados mais seguros, complete a etapa abaixo.</TextoEtapa>
      </TextoContainer>
      <TextoCodigo>Digite o código de acesso que enviamos para o seu e-mail.</TextoCodigo>
      <Id>
        ID: {idEmail}
        <OverlayTrigger
          placement="right"
          overlay={
            <Dica>
              Utilize o identificador ao lado para localizar o código de acesso
              <br /> correto na sua caixa de entrada
            </Dica>
          }
        >
          <span>
            <IconeInterrogacao />
          </span>
        </OverlayTrigger>
      </Id>
      <FormGroup>
        <Controller
          control={control}
          name="codigo"
          render={({ field: { onChange } }) => (
            <InputCodigo
              noBoxShadow
              validar
              paddingIcon
              styleIconeEsquerdo={{ padding: "18px 12px 18px 12px" }}
              iconeEsquerdo={<IconeSenha style={{ marginLeft: "12px", fontSize: "14px" }} />}
              type="text"
              placeholder="Insira seu código"
              size="lg"
              id="codigo"
              mensagemErro={erroCodigo}
              onChange={(e) => {
                handleLimparErros();
                setCodigoDigitado(e.target.value);
                onChange(e);
              }}
            />
          )}
        />
      </FormGroup>
    </>
  );
}
