import { ReactNode } from "react";
import { Divider, HeaderContainer } from "./style";

interface Props {
  children: ReactNode;
}

export function CompositeContainerHeader({ children }: Props) {
  return (
    <>
      <HeaderContainer>{children}</HeaderContainer>
      <Divider />
    </>
  );
}

