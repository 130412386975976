import { AxiosRequestConfig, AxiosResponseHeaders } from "axios";
import { IData } from "./interface/HttpResponse";

class HttpResponse<T = any, D = any> {
  hasErro: boolean;
  status: number;
  data: T | IData;
  statusText?: string;
  headers?: AxiosResponseHeaders;
  config?: AxiosRequestConfig<D>;
  request?: any;
  constructor(
    hasErro: boolean,
    status: number,
    data: T,
    statusText?: string,
    headers?: AxiosResponseHeaders,
    config?: AxiosRequestConfig<D>,
    request?: any
  ) {
    this.hasErro = hasErro;
    this.status = status;
    this.data = data;
    this.statusText = statusText;
    this.headers = headers;
    this.request = request;
    this.config = config;
  }
}

export { HttpResponse };

