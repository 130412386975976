import { Link } from "react-router-dom";
import styled from "styled-components";

interface UsuarioMenu {
  aberto: boolean;
}

export const UsuarioMenuContainer = styled.div<UsuarioMenu>`
  color: ${({ theme }) => theme.colors.neutral.neutral70};
  position: absolute;
  right: 0;
  background-color: #ffffff;
  box-shadow: -4px 4px 4px rgb(0,0,0,25%);
  margin-top: 355px;
  padding: 24px 30px 26px 30px;
  border-radius: 5px;
  font-size: 16px;
  z-index: 100;
  font-weight: 400;
  display: ${({ aberto }) => (aberto ? "block" : "none")};
`;

export const UsuarioMenuLink = styled.a`
  display: block;
  padding: 5px;
  color: ${({ theme }) => theme.colors.neutral.neutral70};
  text-decoration: none;
  background-color: #fff;
  font-size: 16px;

  &:hover{
    color: ${({ theme }) => theme.colors.neutral.neutral70} !important;
  }
`;
