import styled from "styled-components";

export const Body = styled.div`
  padding: 0px 24px;
`

export const InputContainer = styled.div`
  width: 100%;
`;

export const TextoSecundario = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.neutral.neutral40};
`;

export const TextoLink = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.primary.main};
`;