import styled from 'styled-components';

interface ITooltipContent {
  bottom: number,
  right: number,
  width: number,
}

interface ITooltipWrapper {
  positionRelative: boolean
}

export const TooltipWrapper = styled.div<ITooltipWrapper>`
  ${({ positionRelative }) => positionRelative ? "position: relative;" : ""}
  display: inline-block;
`;

export const TooltipContent = styled.div<ITooltipContent>`
  visibility: hidden;
  width: ${({ width }) => width}px;
  border-radius: 4px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: ${({ bottom }) => bottom}rem;
  right: ${({ right }) => right}%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
`;

export const TooltipTrigger = styled.span`
  &:hover + ${TooltipContent} {
    visibility: visible;
    opacity: 1;
  }
`;