import styled from "styled-components";

export const Body = styled.div`
    padding: 24px 24px 0 24px;
`

export const InputContainer = styled.div`
    margin-bottom: 60px;
`

export const ButtonContainer = styled.div`
`

export const ListaContainer = styled.div`
    margin-top: 20px;
`