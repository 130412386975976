import { ModalCustom } from "components/ModalCustom";
import { FiRotateCcw } from "react-icons/fi";
import { Icone, ModalBody } from "./style";
import { FaPlus, FaTimes } from "react-icons/fa";

interface IModalTentarNovamente {
    show: boolean;
    handleClose: () => void;
    onConfirm: () => void;
    motivoErro: string;
}


export function ModalTentarNovamente({
    show,
    handleClose,
    onConfirm,
    motivoErro
}: IModalTentarNovamente) {

    return (
        <ModalCustom
            show={show}
            handleClose={handleClose}
            title="Erro!"
            centered={true}
            footer={true}
            footerConfirmButtonInvert={true}
            onConfirm={onConfirm}
            confirmButtonText="Tentar novamente"
            ConfirmButtonIcone={<FiRotateCcw></FiRotateCcw>}
        >
            <ModalBody>
                <Icone><FaTimes></FaTimes></Icone>
                {motivoErro}
            </ModalBody>

        </ModalCustom>
    );
}