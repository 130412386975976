import { useState } from "react";
import { AxiosRequest } from "_services/api";
import { HttpError } from "_services/api/HttpError";
import { HttpResponse } from "_services/api/HttpResponse";
import {
    IData,
    IHttpError,
    IHttpResponse,
} from "_services/api/interface/HttpResponse";
import { IMotivosPendenciaListar } from "../Interfaces/IMotivosPendenciaListar";
import { usePaginationSortSearch } from "containers/Table/hooks/usePaginationSortSearch";

interface ICadastrar {
    descricao: string;
}

interface IEditar {
    descricao: string;
}

export default function useMotivoPendencia() {
    const api = AxiosRequest.makeApi();
    const [dados, setDados] = useState<IMotivosPendenciaListar[]>([]);
    const { totalRows, setTotalRows } = usePaginationSortSearch();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<IHttpResponse<IData | any>>();
    const [page, setPage] = useState<number>(1);
    const [limit, setLimit] = useState<number>(10);

    async function listar() {
        try {
            setLoading(true);
            const { status, data } = await api.get(`/processos/motivo_pendencia/?page=${page}&limit=${limit}`);
            setDados(data.dados);
            setTotalRows(data.totalRegistros);
            const response = new HttpResponse(false, status, data);
            setError(response);
            return response;
        } catch (error: IHttpError<IData, any> | any) {
            const response = new HttpError<IData>(true, error);
            setDados([]);
            setTotalRows(0);
            setError(response);
            return response;
        } finally {
            setLoading(false);
        }
    }

    async function cadastrar(dados: ICadastrar) {
        try {
            setLoading(true);
            const { status, data } = await api.post(`/processos/motivo_pendencia/`, {
                descricao: dados.descricao
            });
            const response = new HttpResponse(false, status, data);
            setError(response);
            return response;
        } catch (error: IHttpError<IData, any> | any) {
            const response = new HttpError<IData>(true, error);
            setError(response);
            return response;
        } finally {
            setLoading(false);
        }
    }

    async function editar(dados: IEditar, idMotivo: string) {
        try {
            setLoading(true);
            const { status, data } = await api.put(`/processos/motivo_pendencia/${idMotivo}`, {
                descricao: dados.descricao
            });
            const response = new HttpResponse(false, status, data);
            setError(response);
            return response;
        } catch (error: IHttpError<IData, any> | any) {
            const response = new HttpError<IData>(true, error);
            setError(response);
            return response;
        } finally {
            setLoading(false);
        }
    }

    async function excluir(idMotivo: string) {
        try {
            setLoading(true);
            const { status, data } = await api.patch(`/processos/motivo_pendencia/${idMotivo}/exclusao`);
            const response = new HttpResponse(false, status, data);
            setError(response);
            return response;
        } catch (error: IHttpError<IData, any> | any) {
            const response = new HttpError<IData>(true, error);
            setError(response);
            return response;
        } finally {
            setLoading(false);
        }
    }

    return {
        listar,
        cadastrar,
        editar,
        excluir,
        dados,
        error,
        setLoading,
        loading,
        setPage,
        setLimit,
        page,
        limit,
        totalRows,
    };
}
