import { FaBars, FaBell } from "react-icons/fa";
import {
  Arrow,
  ButtonSideBar,
  Container,
  ContainerAbrirMenu,
  ContainerNomeUsuario,
  NavImage,
  NavItem,
} from "./estilo";
import { UsuarioMenu } from "./UsuarioMenu";
import Notificacoes from "./Notificacoes";
import { faChevronDown, faChevronUp, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getNome } from "_services/auth";
import imgNav from "_assets/images/logo_branco_navbar.png";

interface Props {
  alterMiniNavbar(): void;
  activeMiniNavbar: boolean;
  aberto: boolean;
  setAberto(aberto: boolean): void;
}

function Navbar({
  alterMiniNavbar,
  activeMiniNavbar,
  aberto,
  setAberto,
}: Props) {
  return (
    <Container>
      <nav className="navbar navbar-static-top" role="navigation">
        <div className="navbar-header">
          <ButtonSideBar onClick={alterMiniNavbar}>
            <FaBars style={{ cursor: "pointer" }} size={"20px"} />
            <NavImage alt="imagePerfil" src={imgNav} />
          </ButtonSideBar>
        </div>
        <ul className="nav navbar-top-links navbar-right d-flex justify-content-between">
          <NavItem>
            <a href="/pages/processo/Notificacao_Processo.php" style={{ padding: 0, color: "#fff" }}>
              <FaBell />
            </a>
          </NavItem>
          <NavItem>
            <FontAwesomeIcon icon={faUserCircle} />
          </NavItem>
          <NavItem onClick={() => setAberto(!aberto)}>
            <ContainerNomeUsuario>
              {getNome()}
            </ContainerNomeUsuario>
            <ContainerAbrirMenu>
              {aberto ? (
                <Arrow icon={faChevronUp} />
              ) : (
                <Arrow icon={faChevronDown} />
              )}
            </ContainerAbrirMenu>
          </NavItem>
        </ul>
        <UsuarioMenu aberto={aberto} />
      </nav>
    </Container>
  );
}
export default Navbar;

