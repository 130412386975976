import { createContext } from "react";
import { useAutorizacaoPlaca } from "../hooks/useAutorizacaoPlaca";
import { IValidarPlanilha } from "../interfaces/IValidarPlanilha";
import { IBaixaManual } from "../interfaces/IBaixaManual";

interface IContext {
  validarPlanilha(dados: IValidarPlanilha): void;
  dadosValidar: any[];
  dadosListar: any[];
  enviar(): void;
  listar(): void;
  setFiltros(filtro: IFiltro): void;
  totalLinhas: number;
  setPage(page: number): void;
  setLimit(limit: number): void;
  downloadATPV(id: number, placa: string, extencao: string): void;
  showModalSucesso: boolean;
  handleCloseConfirmacao(): void;
  showModalManual: boolean;
  handleCloseManual(): void;
  handleOpenManual(): void;
  manual(dados: IBaixaManual): void;
  showModalSucessoManual: boolean;
  handleCloseManualSucesso(): void;
  loading: boolean;
  downloadLote(ids: string[]): void;
  tab: string;
  setTab(tab: string): void;
  listarExportar(): Promise<any[]>;
}

export const AutorizacaoPlacaContext = createContext<IContext>({
  dadosListar: [],
  dadosValidar: [],
  showModalSucesso: false,
  enviar: () => {},
  validarPlanilha: (dados: IValidarPlanilha) => {},
  listar: () => {},
  setFiltros: (filtro: IFiltro) => {},
  totalLinhas: 0,
  setPage: (page: number) => {},
  setLimit: (limit: number) => {},
  downloadATPV: (id: number, placa: string, extencao: string) => {},
  handleCloseConfirmacao: () => {},
  showModalManual: false,
  handleCloseManual: () => {},
  handleOpenManual: () => {},
  manual: (dados: IBaixaManual) => {},
  handleCloseManualSucesso: () => {},
  showModalSucessoManual: false,
  loading: false,
  downloadLote: (ids: string[]) => {},
  tab: "",
  setTab: (tab: string) => {},
  listarExportar: async () => [],
});

interface Props {
  children: React.ReactNode;
}

export function AutorizacaoPlacaPage({ children }: Props) {
  const {
    validarPlanilha,
    dadosValidar,
    enviar,
    dadosListar,
    listar,
    setFiltros,
    totalLinhas,
    setPage,
    setLimit,
    downloadATPV,
    showModalSucesso,
    handleCloseConfirmacao,
    handleCloseManual,
    showModalManual,
    handleOpenManual,
    manual,
    handleCloseManualSucesso,
    showModalSucessoManual,
    loading,
    downloadLote,
    tab,
    setTab,
    listarExportar,
  } = useAutorizacaoPlaca();

  return (
    <AutorizacaoPlacaContext.Provider
      value={{
        validarPlanilha,
        dadosValidar,
        enviar,
        dadosListar,
        listar,
        setFiltros,
        totalLinhas,
        setPage,
        setLimit,
        downloadATPV,
        showModalSucesso,
        handleCloseConfirmacao,
        handleCloseManual,
        showModalManual,
        handleOpenManual,
        manual,
        handleCloseManualSucesso,
        showModalSucessoManual,
        loading,
        downloadLote,
        tab,
        setTab,
        listarExportar,
      }}
    >
      {children}
    </AutorizacaoPlacaContext.Provider>
  );
}
