import { ModalCustom } from "components/ModalCustom";
import { Body, TextoContainer } from "./style";
import { Informacao } from "components/Informacao";

interface IModalEditarServicoProps {
    show: boolean;
    handleClose: () => void;
    onConfirm: () => void;
}

export function ModalExcluirServico({
    show,
    handleClose,
    onConfirm,
}: IModalEditarServicoProps) {

    const mensagem = "Esta ação afetará todos os despachantes e processos vinculados a este serviço, mantendo apenas as informações já cadastradas em custo e valor."

    return (
        <ModalCustom
            show={show}
            handleClose={handleClose}
            title="Excluir serviço"
            centered
            footer
            footerConfirmButton
            onConfirm={onConfirm}
            size={"lg"}
        >
            <Body>
                <TextoContainer>
                    Deseja confirmar a exclusão deste serviço?
                </TextoContainer>
                <Informacao
                    mensagem={mensagem}
                    type="atencao"
                />
            </Body>
        </ModalCustom>
    );
}