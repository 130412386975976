import {
  faBuilding,
  faKey,
  faRightFromBracket,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getEmail, getEmpresa, getNome, parseJwt, removeToken } from "_services/auth";
import { UsuarioMenuContainer, UsuarioMenuLink } from "./estilo";

interface Props {
  aberto: boolean;
}

export function UsuarioMenu({ aberto }: Props) {

  return (
    <UsuarioMenuContainer aberto={aberto}>
      <p>
        <strong>{getNome()}</strong>
      </p>
      <p>{getEmpresa()}</p>
      <p>{getEmail()}</p>
      <hr />
      <UsuarioMenuLink href={`${process.env.REACT_APP_DESPACHANTE_BASE_URL}/pages/usuario/Perfil.php`}>
        <FontAwesomeIcon icon={faKey} /> Segurança
      </UsuarioMenuLink>
      <UsuarioMenuLink href={`${process.env.REACT_APP_DESPACHANTE_BASE_URL}/pages/usuario/AlterarEmpresa.php`}>
        <FontAwesomeIcon icon={faBuilding} /> Alterar Empresa
      </UsuarioMenuLink>
      <UsuarioMenuLink onClick={() => removeToken(true)}>
        <FontAwesomeIcon icon={faRightFromBracket} /> Sair
      </UsuarioMenuLink>
    </UsuarioMenuContainer>
  );
}
