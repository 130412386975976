import React from "react";
import { Link } from "react-router-dom";
import { Container, Description, Grid, Title } from "./estilo";

const Page404 = () => {
  const baseUrlDespachante = process.env.REACT_APP_DESPACHANTE_BASE_URL;
  return (
    <Container>
      <Grid>
        <Title>
          <h1>404</h1>
          <h3>Página não encontrada</h3>
        </Title>
        <Description>
          Desculpe, mas a página que você está procurando não foi encontrada.
          <h2>
            {" "}
            <Link to={`${baseUrlDespachante}/pages/dashboard/Dashboard.php`}>
              Acesse a página inicial
            </Link>{" "}
          </h2>
        </Description>
      </Grid>
    </Container>
  );
};

export default Page404;

