import { ModalCustom } from "components/ModalCustom";
import { FaCheck, FaPlus, FaTimes } from "react-icons/fa";
import { Container } from "./style";

interface IModalSucessoConsultarNovamenteProps {
    show: boolean;
    handleClose: () => void;
    nenhumaConsultaComFalha: boolean;
}

export function ModalSucessoConsultarNovamente({
    show,
    handleClose,
    nenhumaConsultaComFalha,
}: IModalSucessoConsultarNovamenteProps) {

    return (
        <ModalCustom
            show={show}
            handleClose={handleClose}
            title="Preenchimento ATPV"
            centered
        >
            {nenhumaConsultaComFalha
                ? (
                    <>
                        <Container><FaTimes style={{
                            color: "#EE5353",
                            backgroundColor: "#FFE4E4",
                            fontSize: "40px",
                            padding: "8px",
                            borderRadius: "10px",
                        }} /></Container>
                        <Container>Nenhuma consulta com falha encontrada!</Container>
                    </>)
                : (
                    <>
                        <Container><FaCheck style={{
                            color: "#2529F4",
                            backgroundColor: "#E9E9FE",
                            fontSize: "40px",
                            padding: "8px",
                            borderRadius: "10px",
                        }} /></Container>
                        <Container>Solicitação efetuada com sucesso!</Container>
                        <Container><strong>As solicitações com status de falha serão reprocessadas.</strong></Container>
                    </>)
            }
        </ModalCustom>
    );
}