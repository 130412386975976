import { ModalCustom } from "components/ModalCustom";
import { FaCheck } from "react-icons/fa";
import { Container } from "./style";
import { AutorizacaoPlacaContext } from "../../context/AutorizacaoPlacaContext";
import { useContext } from "react";

export function ModalConfirmacaoManual() {
  const { showModalSucessoManual, handleCloseManualSucesso } = useContext(
    AutorizacaoPlacaContext
  );

  return (
    <ModalCustom
      show={showModalSucessoManual}
      handleClose={handleCloseManualSucesso}
      title="Informar autorização manual"
      centered
    >
      <Container>
        <FaCheck
          style={{
            color: "#2529F4",
            backgroundColor: "#E9E9FE",
            fontSize: "40px",
            padding: "8px",
            borderRadius: "10px",
          }}
        />
      </Container>
      <Container>
          Autorização manual confirmada com sucesso!
      </Container>
    </ModalCustom>
  );
}
