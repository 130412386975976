import { Page } from "containers/Page";
import { LoadingScreen } from "components/LoadingScreen";
import { FormularioMotivosPendencia } from "./containers/Formulario";
import { MotivosPendenciaListar } from "./containers/Listagem";
import { ModalConfirmarExcluir } from "./containers/ModalConfirmacao";
import { useEffect, useState } from "react";
import useMotivoPendencia from "../../Hooks/useMotivoPendencia";
import { alertaErro } from "components/Alerta";

export function MotivosPendencia() {
    const {
        totalRows,
        listar,
        dados,
        excluir,
        editar,
        cadastrar,
        loading,
        setPage,
        setLimit,
        page,
        limit,
        error,
    } = useMotivoPendencia();

    useEffect(() => {
        listar();
    }, [, page, limit]);

    const submitForm = async () => {
        if (idMotivo) {
            const { hasErro, data } = await editar({ descricao }, idMotivo);
            if (hasErro) {
                alertaErro("Editar motivo de pendência", data.message);
                return;
            }
            listar();
            limparFormulario();
            return;
        }

        const { hasErro, data } = await cadastrar({ descricao });
        if (hasErro) {
            alertaErro("Cadastrar motivo de pendência", data.message);
            return;
        }
        limparFormulario();
        listar();
    }

    const [showBotaoCadastrar, setShowBotaoCadastrar] = useState<boolean>(true);
    const [idMotivo, setIdMotivo] = useState<NullableString>(null);
    const handleEditar = (idMotivo: string, descricao: string) => {
        setShowBotaoCadastrar(false);
        setIdMotivo(idMotivo);
        setDescricao(descricao);
    }

    const limparFormulario = () => {
        setIdMotivo(null);
        setDescricao("");
        setShowBotaoCadastrar(true);
    }

    const onClickCancelarEditar = () => {
        limparFormulario()
    }

    const [descricao, setDescricao] = useState<string>("");
    const onChangeDescricao = (descricao: string) => {
        setDescricao(descricao);
    }

    const [showModalExcluir, setShowModalExcluir] = useState<boolean>(false);
    const handleClose = () => {
        setShowModalExcluir(false)
    }
    const handleExcluir = async (idMotivo: string, processos: number) => {
        if (processos) {
            alertaErro("Excluir motivo de pendência", "Não foi possível realizar a exclusão. Existem processos vinculados a esta pendência.");
            return;
        }
        setIdMotivo(idMotivo)
        setShowModalExcluir(true)
    }
    const onConfirmModalExcluir = async () => {
        if (idMotivo) {
            setShowModalExcluir(false)
            const { hasErro, data } = await excluir(idMotivo);
            if (hasErro) {
                alertaErro("Excluir motivo de pendência", data.message);
                return;
            }
            setIdMotivo(null)
            listar();
        }
    }

    function handleLimitChange(limit: number) {
        setLimit(limit);
    }

    function handlePageChange(page: number) {
        setPage(page);
    }

    return (
        <Page title="Motivos de Pendências">
            <LoadingScreen mostrar={loading} />
            <FormularioMotivosPendencia
                submitForm={submitForm}
                onChangeDescricao={onChangeDescricao}
                descricao={descricao}
                showBotaoCadastrar={showBotaoCadastrar}
                onClickCancelarEditar={onClickCancelarEditar}
            />
            <MotivosPendenciaListar
                dados={dados}
                loading={false}
                totalRows={totalRows}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handleLimitChange}
                show={true}
                handleEditar={handleEditar}
                handleExcluir={handleExcluir}
            />
            <ModalConfirmarExcluir
                show={showModalExcluir}
                handleClose={handleClose}
                onConfirm={onConfirmModalExcluir}
            />
        </Page>
    );
}