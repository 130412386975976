import { ConsultarContainer, Container, Header, Tag, TextoHeader } from "./style";
import { Table } from "containers/Table";
import { IColum } from "containers/Table/interfaces";
import { LoadingScreen } from "components/LoadingScreen";
import { DownloadIconFunction } from "components/DownloadLink";
import { StatusDetalhesATPVEnum } from "../../enums/StatusDetalhesATPVEnum";
import { formatarDinheiro } from "_utils/Money";
import { CompositeContainer } from "components/CompositeContainer";
import { PrimaryButton } from "components/Button/style";
import { ResumoDetalhesSolicitacaoATPV } from "../ResumoDetalhesSolicitacaoATPV";
import { FaRedo } from "react-icons/fa";
import { IDadosResumoDetalhesSolicitacaoATPV } from "../../interfaces/IDadosResumoDetalhesSolicitacaoATPV";
import { utils, writeFileXLSX } from "xlsx";
import { Tooltip } from "components/Tooltip";
import { Informacao } from "components/Informacao";
import { formatarCpfCnpj } from "_utils/CpfCnpj";
import { adicionarSeparadorMilhar } from "_utils/Numero";
import { useEffect } from "react";

interface IListarDetalhesProps {
    loading: boolean;
    dados: IListarDetalhesATPV[];
    dadosResumo: IDadosResumoDetalhesSolicitacaoATPV;
    handleLimitChange: (e: number) => void;
    handlePageChange: (e: number) => void;
    totalRows: number;
    handleDownload: (id: string | number, placa: string) => void;
    handleConsultarNovamente: () => void;
    idSolicitacao: string | undefined;
    onClickProcesso: (id: number) => void;
    handleExport: () => void;
    dadosExcel: IListarDetalhesATPV[];
}

export function ListarDetalhes({
    loading,
    dados,
    dadosResumo,
    handleLimitChange,
    handlePageChange,
    totalRows,
    handleDownload,
    handleConsultarNovamente,
    idSolicitacao,
    onClickProcesso,
    handleExport,
    dadosExcel
}: IListarDetalhesProps) {
    const rightTooltipDownload = -25;
    const bottomTooltips = 1.5;
    const widthTooltips = 150;
    const statusStrategy = {
        1: "Processando",
        2: "Finalizado",
        3: "Dados inconsistentes",
        4: "Impedido",
        5: "Falha",
    }

    const arrumarObservacao = (texto: string) => {

        const tabelaDePara: { [key: string]: string } = {
            '3341': 'Proprietário informado divergente do cadastro de veículos',
            '2015': 'CNPJ/CPF Inválido',
            '4321': 'Intenção de venda para o proprietário atual',
            'o encontrado os dados deste ve': 'Não encontrados os dados deste veículo',
            '4320': 'Já existe intenção de venda para o veículo'
        };

        const arrayTexto = texto.split("\n")
        for (const chave in tabelaDePara) {
            arrayTexto.map((item, index) => {
                if (item.includes(chave)) {
                    arrayTexto[index] = tabelaDePara[chave];
                }
            })
        }

        return arrayTexto.join(". ");
    }

    const columns: IColum<IListarDetalhesATPV>[] = [
        {
            name: <span>Placa</span>,
            selector: ({ Atpv_placa }: IListarDetalhesATPV) => Atpv_placa ?? "-",
            width: "75px",
        },
        {
            name: <span>Renavam</span>,
            selector: ({ Atpv_renavam }: IListarDetalhesATPV) => Atpv_renavam ?? "-",
            width: "150px",
        },
        {
            name: <span>Modelo</span>,
            selector: ({ Atpv_marcaModeloVersao }: IListarDetalhesATPV) => Atpv_marcaModeloVersao ?? "-",
            width: "200px",
        },
        {
            name: <span>Odômetro</span>,
            selector: ({ Atpv_odometro }: IListarDetalhesATPV) => Atpv_odometro ? adicionarSeparadorMilhar(Atpv_odometro) : "-",
        },
        {
            name: <span>Valor de venda</span>,
            selector: ({ Atpv_valorVenda }: IListarDetalhesATPV) => Atpv_valorVenda ? formatarDinheiro(Number(Atpv_valorVenda)) : "-",
            width: "150px",
        },
        {
            name: <span>CPF / CNPJ vendedor</span>,
            selector: ({ Atpv_cpfCnpjVendedor }: IListarDetalhesATPV) => Atpv_cpfCnpjVendedor ? formatarCpfCnpj(Atpv_cpfCnpjVendedor) : "-",
            width: "150px",
        },
        {
            name: <span>CPF / CNPJ comprador</span>,
            selector: ({ Atpv_cpfCnpjComprador }: IListarDetalhesATPV) => Atpv_cpfCnpjComprador ? formatarCpfCnpj(Atpv_cpfCnpjComprador) : "-",
            width: "200px",
        },
        {
            name: <span>ID Processo</span>,
            selector: ({ Atpv_idProcesso }: IListarDetalhesATPV) => Atpv_idProcesso ? <div className="link-primary" style={{ cursor: "pointer" }} onClick={() => { onClickProcesso(Atpv_idProcesso) }}>{Atpv_idProcesso}</div> : "-",
            width: "150px",
        },
        {
            name: <span>Observação</span>,
            selector: ({ Atpv_observacao }: IListarDetalhesATPV) => Atpv_observacao && Atpv_observacao !== "" ? arrumarObservacao(Atpv_observacao) : "-",
            width: "250px",
        },
        {
            name: <span>Status</span>,
            selector: ({ Atpv_status }: IListarDetalhesATPV) => Atpv_status ? <Tag tipo={Atpv_status as 1 | 2 | 3 | 4 | 5} >{statusStrategy[Atpv_status as 1 | 2 | 3 | 4 | 5]}</Tag> : "-",
            width: "175px",
        },
        {
            name: <span>Ações</span>,
            selector: ({ Atpv_status, Atpv_id, Atpv_placa }: IListarDetalhesATPV) => {
                return (
                    <Tooltip
                        right={rightTooltipDownload}
                        bottom={bottomTooltips}
                        width={widthTooltips}
                        positionRelative={false}
                        trigger={<DownloadIconFunction disabled={Atpv_status !== StatusDetalhesATPVEnum.FINALIZADO} handleDonwload={() => handleDownload(Atpv_id, Atpv_placa ?? "")} />}>
                        <Informacao
                            type="preto"
                            typeTexto="preto"
                            title={true}
                            showBotaoFechar={false}
                            mensagem={"Download ATPV"}
                            style={{
                                width: "115px",
                                fontSize: "10px",
                                position: "absolute",
                                left: "1rem",
                                top: "1rem",
                            }}
                        />
                    </Tooltip>
                )
            },
            width: "80px",
        }
    ];

    useEffect(() => {
        if (dadosExcel.length) {
            const dadosPlanilha = dadosExcel.map((item: IListarDetalhesATPV) => ({
                Placa: item.Atpv_placa ?? "-",
                Renavam: item.Atpv_renavam ?? "-",
                Modelo: item.Atpv_marcaModeloVersao ?? "-",
                "Odômetro": item.Atpv_odometro ?? "-",
                "Valor de venda": item.Atpv_valorVenda ? formatarDinheiro(Number(item.Atpv_valorVenda)) : "-",
                "CPF/CNPJ vendedor": item.Atpv_cpfCnpjVendedor ? formatarCpfCnpj(item.Atpv_cpfCnpjVendedor) : "-",
                "CPF/CNPJ comprador": item.Atpv_cpfCnpjComprador ? formatarCpfCnpj(item.Atpv_cpfCnpjComprador) : "-",
                "ID Processo": item.Atpv_idProcesso ?? "-",
                "Observação": item.Atpv_observacao && item.Atpv_observacao !== "" ? arrumarObservacao(item.Atpv_observacao) : "-",
                "Status da Consulta": item.Atpv_status ? statusStrategy[item.Atpv_status as 1 | 2 | 3 | 4 | 5] : "-",
            }));

            const planilha = utils.json_to_sheet(dadosPlanilha);
            planilha["!cols"] = [
                { wch: 12 },
                { wch: 15 },
                { wch: 15 },
                { wch: 15 },
                { wch: 15 },
                { wch: 25 },
                { wch: 25 },
                { wch: 15 },
                { wch: 25 },
                { wch: 20 },
            ];
            const workBook = utils.book_new();
            utils.book_append_sheet(workBook, planilha, "Detalhes");
            writeFileXLSX(workBook, `Solicitacao_ATPV_${idSolicitacao}.xlsx`);
        }
    }, [dadosExcel]);


    return (
        <Container>
            <LoadingScreen mostrar={loading} />
            <Header>
                <TextoHeader>Detalhes Preenchimento ATPV</TextoHeader>
            </Header>
            <hr />
            <ResumoDetalhesSolicitacaoATPV
                dadosResumo={dadosResumo}
            ></ResumoDetalhesSolicitacaoATPV>
            <CompositeContainer.Root overflowXHidden={false}>
                <Table
                    keyField="id"
                    totalRows={totalRows}
                    pagination={true}
                    columns={columns}
                    data={dados}
                    paginationRowsPerPageOptions={[10, 20, 50]}
                    loading={loading}
                    onChangeRowsPerPage={handleLimitChange}
                    onChangePage={handlePageChange}
                    exportExcel
                    handleExport={handleExport}
                    buttonExportVariant="secondary"
                    toolsHeader={
                        <ConsultarContainer>
                            <PrimaryButton
                                onClick={handleConsultarNovamente}
                                outline
                                variante="primary"
                            >
                                <FaRedo style={{ transform: "scaleX(-1)", marginRight: "10px" }} /> Consultar novamente
                            </PrimaryButton>
                        </ConsultarContainer>
                    }
                />
            </CompositeContainer.Root>
        </Container>
    );
}