import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";

import { SWRConfig } from "swr";

import { AxiosRequest } from "_services/api";
import temaPadrao from "_config/temas/estilo/base";
import { AppRouters } from "routers";
import ToastContainer from "components/Toast";
import ContextPermisaoProvider from "hooks/ContextPermissao";
import { ThemeProvider } from "styled-components";
import { UploadContextProvider } from "contexts/UploadContext";
import { ModalUpload } from "components/ModalUpload";

function App() {
  const api = AxiosRequest.makeApi();

  return (
    <SWRConfig
      value={{
        fetcher: (url: string) => api.get(url).then((r) => r.data),
      }}
    >
      <ThemeProvider theme={temaPadrao}>
        <ToastContainer />
        <UploadContextProvider>
          <AppRouters />
          <ModalUpload />
        </UploadContextProvider>
      </ThemeProvider>
    </SWRConfig>
  );
}

export default App;
