import { ReactNode } from "react";

interface Props {
  children: ReactNode
}

function Wrapper({ children }: Props) {
  return <div id="wrapper">{children}</div>;
};

export default Wrapper;
