import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.neutral.neutral80}
  }

  svg {
    font-size: 1.5rem;
  }
`;

export const ContainerVertical = styled.span`
  height: 100%;
  margin: 0 10px;

  &:hover {
    cursor: pointer;
  }

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.neutral.neutral80}
  }

  svg {
    font-size: 1.5rem;
  }
`;
