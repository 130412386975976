import * as yup from "yup";
import { portugues } from "_utils/validador";

yup.setLocale(portugues);

export const despachanteFiltrosSchema = yup.object().shape({
    despachante: yup.string().optional(),
    cnpj: yup.string().optional(),
    uf: yup.string().optional().max(2, "UF inválido").min(2, "UF inválido"),
    municipio: yup.string().optional(),
});

export const honorarioServicoDespachanteSchema = yup.object().shape({
    valor: yup.string(),
});

export const servicosDespachantesFiltrosSchema = yup.object().shape({
    servico: yup.string().optional(),
    uf: yup.string().optional().max(2, "UF inválido").min(2, "UF inválido"),
});
