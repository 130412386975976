import * as yup from "yup";
import { portugues } from "_utils/validador";

yup.setLocale(portugues);

export const filtrosPagarIpvaLote = yup.object().shape({
    empresa: yup.number().optional().nullable(),
    divisao: yup.number().optional().nullable(),
    dataVencimento: yup.date().optional().nullable(),
    dataCadastroProcesso: yup.date().optional().nullable(),
    statusPagamento: yup.number().optional().nullable(),
})