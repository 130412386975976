import Page404 from "features/core/pages/404";
import { CoreRoutes } from "features/core/routes";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PrivateRoute from "./PrivateRouter";
import { FinanceiroRoutes } from "features/core/pages/Financeiro/routes";
import { ProcessoRoutes } from "features/processo/routes";
import { CadastroRoutes } from "features/core/pages/Cadastro/routes";
import { ConfiguracaoRoutes } from "features/core/pages/Configuracao/routes";
import { Login } from "features/Login/pages/Login";
import { BackofficeRoutes } from "features/core/pages/Backoffice/routes";

export function AppRouters() {
  return (
    <BrowserRouter basename="/app">
      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          path="/core/*"
          element={<PrivateRoute component={CoreRoutes} />}
        />
        <Route path="/404" element={<Page404 />} />
        <Route path="/*" element={<Page404 />} />
        <Route
          path="/financeiro/*"
          element={<PrivateRoute component={FinanceiroRoutes} />}
        />
        <Route
          path="/processo/*"
          element={<PrivateRoute component={ProcessoRoutes} />}
        />
        <Route
          path="/cadastro/*"
          element={<PrivateRoute component={CadastroRoutes} />}
        />
        <Route
          path="/configuracao/*"
          element={<PrivateRoute component={ConfiguracaoRoutes} />}
        />
        <Route
          path="/backoffice/*"
          element={<PrivateRoute component={BackofficeRoutes} />}
        />
      </Routes>
    </BrowserRouter>
  );
}
