import { Data } from "_utils/Data";
import { Table } from "containers/Table";
import { IColum } from "containers/Table/interfaces";
import { Filtros } from "../Filtros";
import { FaDownloadImagem, Header, Tag, TextoHeader } from "./style";
import { useContext, useEffect, useState } from "react";
import { AutorizacaoPlacaContext } from "../../context/AutorizacaoPlacaContext";
import { StatusAtutorizacaoPlaca } from "../../enums/StatusAutorizacaoPlacaenum";
import { PrimaryButton } from "components/Button/style";
import { FaDownload, FaFileSignature } from "react-icons/fa";
import { utils, writeFileXLSX } from "xlsx";
import { ModalManual } from "../ModalManual";
import { ModalConfirmacaoManual } from "../ModalConfirmacaoManual";
import { TooltipBootstrap } from "components/TooltipBootstrap";
import { TruncateString } from "components/TruncateString";

export function Historico() {
  const {
    dadosListar,
    totalLinhas,
    setPage,
    setLimit,
    downloadATPV,
    handleOpenManual,
    downloadLote,
    listarExportar,
  } = useContext(AutorizacaoPlacaContext);

  const [selectedRow, setSelectedRows] = useState<any[]>([]);
  const [disabledDownload, setDisabledDownload] = useState(true);
  const [disabledManual, setDisabledManual] = useState(true);
  const [idManual, setIdManual] = useState<string>("");

  useEffect(() => {
    setDisabledDownload(true);
    setDisabledManual(true);

    const qtdErro = selectedRow.filter(
      (item) => item.status === StatusAtutorizacaoPlaca.Erro
    ).length;

    const qtdFinalizado = selectedRow.filter(
      (item) =>
        item.status !== StatusAtutorizacaoPlaca.Erro &&
        item.status !== StatusAtutorizacaoPlaca.Processando
    ).length;

    if (selectedRow.length === 0) {
      return;
    }

    if (selectedRow.length === 1 && qtdErro === 1) {
      setDisabledManual(false);
      setDisabledDownload(true);
      return;
    }

    if (selectedRow.length > 0 && qtdFinalizado > 0) {
      setDisabledDownload(false);
    }
  }, [selectedRow]);

  const handleExport = async () => {
    const itens = await listarExportar();

    const dadosPlanilha = itens.map((item: any) => ({
      "Data / Hora solicitação": new Data(item.data_hora).getDataHoraBr(),
      Proprietário: item.proprietario,
      "CPF / CNPJ": item.cpf_cnpj,
      Placa: item.placa.toUpperCase(),
      Renavam: item.renavam,
      Chassi: item.chassi,
      "ID Processo": item.id_processo,
      "Processo Detran": item.processo_detran,
      "ID Autorização": item.id_autorizacao,
      Status: StatusAtutorizacaoPlaca[item.status],
      Observação: item.observacao,
    }));

    const planilha = utils.json_to_sheet(dadosPlanilha);
    planilha["!cols"] = [
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
    ];
    const workBook = utils.book_new();
    utils.book_append_sheet(workBook, planilha, "Detalhes");
    writeFileXLSX(workBook, "autorizacao_placa.xlsx");
  };

  const handleDownload = async (id: number, placa: string, extensao: string) => {
    downloadATPV(id, placa, extensao);
  };

  const handleDownloadLote = async () => {
    const selecionados = selectedRow.filter(
      (item) =>
        item.status === StatusAtutorizacaoPlaca.Finalizado ||
        item.status === StatusAtutorizacaoPlaca["Finalização manual"]
    );

    const ids = selecionados.map((item) => item.id);

    return downloadLote(ids);
  };

  const columns: IColum<any>[] = [
    {
      name: <span>Data / Hora solicitação</span>,
      selector: ({ data_hora }) => new Data(data_hora).getDataHoraBr(),
      sortable: true,
      width: "139px",
    },
    {
      name: <span>Proprietário</span>,
      selector: ({ proprietario }) => <TruncateString text={proprietario} maxLength={53}/>,
      sortable: true,
      width: "250px",
    },
    {
      name: <span>CPF / CNPJ</span>,
      selector: ({ cpf_cnpj }) =>
        cpf_cnpj
          ? String(cpf_cnpj).length > 11
            ? String(cpf_cnpj).replace(
                /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
                "$1.$2.$3/$4-$5"
              )
            : String(cpf_cnpj).replace(
                /^(\d{3})(\d{3})(\d{3})(\d{2})$/,
                "$1.$2.$3-$4"
              )
          : "-",
      sortable: true,
      width: '134px'
    },
    {
      name: <span>Placa</span>,
      selector: ({ placa }) =>
        placa ? /^(.{3})(\d)([a-zA-Z])(\d{2})$/.test(placa) ? placa  : placa.replace(/^(.{3})(.{4})$/, "$1-$2") : "-",
      sortable: true,
      width: "74px",
    },
    {
      name: <span>Renavam</span>,
      selector: ({ renavam }) => renavam,
      sortable: true,
      width: '97px'
    },
    {
      name: <span>Chassi</span>,
      selector: ({ chassi }) => chassi,
      sortable: true,
      width: "148px",
    },
    {
      name: <span>ID Processo</span>,
      selector: ({ id_processo }) => id_processo ?? "-",
      sortable: true,
      width: "100px",
    },
    {
      name: <span>Processo Detran</span>,
      selector: ({ processo_detran }) => processo_detran,
      sortable: true,
      width: "110px",
    },
    {
      name: <span>ID Autorização</span>,
      selector: ({ id_autorizacao }) => id_autorizacao ?? "-",
      width: "126px",
    },
    {
      name: <span>Status</span>,
      selector: ({ status }) => (
        <Tag tipo={status}>{StatusAtutorizacaoPlaca[status]}</Tag>
      ),
      width: "150px",
    },
    {
      name: <span>Observação</span>,
      selector: ({ observacao }) => observacao ?? "-",
      width: "300px",
    },
    {
      name: (
        <span className="d-flex justify-content-center text-center">
          <text>Ações</text>
        </span>
      ),
      selector: ({ id, object_name, bucket_name, placa }) => (
        <TooltipBootstrap
          childrenToolTip={"Download autorização"}
          placement="left"
          show
        >
          <div>
            <FaDownloadImagem
              disabled={!(object_name && bucket_name)}
              onClick={() => handleDownload(id, placa, object_name.split('.').pop())}
            />
          </div>
        </TooltipBootstrap>
      ),
      sortable: true,
      style: { textAlign: "center" },
      width: "60px",
    },
  ];

  return (
    <div>
      <ModalManual id={idManual} />
      <ModalConfirmacaoManual />
      <div className="bg-white">
        <Filtros />
      </div>

      <div className="mt-4 bg-white p-3">
        <Header>
          <TextoHeader>Solicitações realizadas</TextoHeader>
        </Header>
        <hr />
        <div className="d-flex justify-content-end gap-3 mb-3 mt-5">
          <PrimaryButton
            style={{ width: "15rem" }}
            className="gap-1"
            disabled={disabledDownload}
            onClick={handleDownloadLote}
          >
            <FaDownload /> Download autorização
          </PrimaryButton>
          <TooltipBootstrap
            childrenToolTip={
              "Selecione apenas um item da listagem para informar a autorização manual"
            }
            show={selectedRow.length > 0 && disabledManual}
            placement="top"
          >
            <PrimaryButton
              style={{ width: "12rem" }}
              className="gap-1"
              outline
              variante="primary"
              onClick={() => {
                setIdManual(selectedRow[0].id);
                handleOpenManual();
              }}
              disabled={disabledManual}
            >
              <FaFileSignature /> Autorização manual
            </PrimaryButton>
          </TooltipBootstrap>

          <PrimaryButton
            style={{ width: "8rem" }}
            className="gap-1 text-center"
            outline
            variante="primary"
            onClick={handleExport}
            disabled={dadosListar.length === 0}
          >
            <FaDownload /> Exportar
          </PrimaryButton>
        </div>
        <Table
          paginationServer={true}
          columns={columns}
          data={dadosListar}
          loading={false}
          keyField=""
          pagination
          totalRows={totalLinhas}
          showHeader={false}
          onChangePage={setPage}
          onChangeRowsPerPage={setLimit}
          noSubHeader
          selectableRows
          setRowsSelected={(row) => setSelectedRows(row)}
        />
      </div>
    </div>
  );
}
