import { ModalCustom } from "components/ModalCustom";
import { useContext, useEffect } from "react";
import { AutorizacaoPlacaContext } from "../../context/AutorizacaoPlacaContext";
import { Col, Form, Row } from "react-bootstrap";
import { Required } from "components/Required";
import { Controller, useForm } from "react-hook-form";
import FileUpload from "components/UploadArquivos";
import { CustomInput } from "components/CustomInput";
import temaPadrao from "_config/temas/estilo/base";
import { PrimaryButton } from "components/Button/style";
import { yupResolver } from "@hookform/resolvers/yup";
import { preenchimentoModalManual } from "../../Validate/validator";

interface Props {
  id: string;
}

export function ModalManual({ id }: Props) {
  const { handleCloseManual, showModalManual, manual } = useContext(
    AutorizacaoPlacaContext
  );

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isSubmitted },
  } = useForm({
    resolver: yupResolver(preenchimentoModalManual),
  });

  useEffect(() => {
    reset({
      arquivo: undefined,
      idAutorizacao: "",
    });
  }, [showModalManual]);

  return (
    <ModalCustom
      show={showModalManual}
      handleClose={handleCloseManual}
      title="Informar autorização manual"
      centered
      size="lg"
    >
      <span style={{ color: temaPadrao.colors.neutral.neutral50 }}>
        Dados da autorização
      </span>
      <form
        onSubmit={handleSubmit((itens) => {
          manual({ ...itens, id });
        })}
        className="mt-3"
      >
        <Row>
          <Col md={5}>
            <Form.Group>
              <Form.Label
                style={{ color: temaPadrao.colors.neutral.neutral70 }}
              >
                ID autorização
              </Form.Label>

              <Controller
                name="idAutorizacao"
                control={control}
                render={({ field }) => (
                  <CustomInput
                    mensagemErro={""}
                    type="text"
                    placeholder="Informe o ID autorização"
                    style={{ height: "2.2rem" }}
                    {...field}
                  />
                )}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label
                style={{ color: temaPadrao.colors.neutral.neutral70 }}
              >
                Arquivo (PDF autorização)
                <Required />
              </Form.Label>

              <Controller
                name="arquivo"
                control={control}
                render={({ field: { onChange } }) => (
                  <FileUpload
                    onFileUpload={(file) => onChange(file)}
                    mensagemErro={
                      isSubmitted ? (errors.arquivo?.message as any) : null
                    }
                    comMaxWidth={false}
                    gapContainer="0"
                    id="baixaManualInput"
                    inputAccept=".png, .jpg, .jpeg, .pdf"
                  />
                )}
              />
            </Form.Group>
          </Col>
        </Row>
        <div className="d-flex justify-content-end gap-4 mt-4">
          <PrimaryButton
            style={{ width: "8rem" }}
            outline
            variante="primary"
            onClick={handleCloseManual}
          >
            Cancelar
          </PrimaryButton>
          <PrimaryButton style={{ width: "13rem" }} type="submit">
            Confirmar autorização
          </PrimaryButton>
        </div>
      </form>
    </ModalCustom>
  );
}
