import { Route, Routes } from "react-router-dom";
import { PagarIpvaLote } from "../Pages/Ipva/Lote";

function FinanceiroRoutes() {
  return (
    <Routes>
      <Route path="/ipva/lote" element={<PagarIpvaLote />} />
    </Routes>
  );
}
export { FinanceiroRoutes };