import { ModalCustom } from "components/ModalCustom";
import { Body, ButtonContainer, InputContainer, ListaContainer } from "./style";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Form, Row } from "react-bootstrap";
import { CustomInput } from "components/CustomInput";
import { honorarioServicoDespachanteSchema } from "features/core/pages/Cadastro/Validate/validator";
import { useMaskInput } from "hooks/useMaskInput";
import { DropdownSelect } from "components/DropdownSelect";
import { Required } from "components/Required";
import { PrimaryButton } from "components/Button/style";
import { ListaHorizontal } from "components/ListaHorizontal";
import { useEffect, useState } from "react";
import { alertaErro, alertaWarnig } from "components/Alerta";

interface IModalAreaAtuacaoProps {
    show: boolean;
    handleClose: () => void;
    uf: string;
    onChangeUf: (uf: string) => void;
    optionsMunicipios: any[];
    municipio: string;
    onChangeMunicipio: (municipio: string) => void;
    onConfirm: () => void;
    setListaAreas: (e: any) => void;
    listaAreas: any[];
    disabledBtn: boolean;
    disabledMunicipio: boolean;
}

export function ModalAreaAtuacao({
    show,
    handleClose,
    uf,
    onChangeUf,
    optionsMunicipios,
    municipio,
    onChangeMunicipio,
    onConfirm,
    setListaAreas,
    listaAreas,
    disabledBtn,
    disabledMunicipio,
}: IModalAreaAtuacaoProps) {


    const onAdd = () => {
        if (!listaAreas.find(obj => obj.texto == municipio)) {
            setListaAreas((oldArray: any) => [...oldArray, { uf, texto: municipio }]);
            return;
        }
        alertaWarnig("Atenção", "Município já adicionado à lista.")
    }

    const onClickExcluir = (nomeMunicipio: string) => {
        const novaListaAreas = listaAreas.filter(obj => obj.texto !== nomeMunicipio);
        setListaAreas(novaListaAreas);
    }

    const [disabled, setDisabled] = useState<boolean>(false);
    useEffect(() => {
        if (listaAreas.filter(obj => obj.texto != "").length) {
            setDisabled(true);
            return;
        }
        setDisabled(false);
    }, [listaAreas]);

    return (
        <ModalCustom
            show={show}
            handleClose={handleClose}
            title="Adicionar Região"
            centered
            footer
            footerConfirmButton
            confirmButtonText="Salvar"
            onConfirm={onConfirm}
            size={"lg"}
        >
            <Body>
                <Row>
                    <InputContainer>
                        <Form.Group>
                            <Form.Label style={{ marginBottom: "0rem" }}>
                                Estado <Required />
                            </Form.Label>
                            <DropdownSelect
                                options={[
                                    { value: "Acre", label: "Acre" },
                                    { value: "Alagoas", label: "Alagoas" },
                                    { value: "Amazonas", label: "Amazonas" },
                                    { value: "Amapá", label: "Amapá" },
                                    { value: "Bahia", label: "Bahia" },
                                    { value: "Ceará", label: "Ceará" },
                                    { value: "Distrito Federal", label: "Distrito Federal" },
                                    { value: "Espírito Santo", label: "Espírito Santo" },
                                    { value: "Goiás", label: "Goiás" },
                                    { value: "Maranhão", label: "Maranhão" },
                                    { value: "Mato Grosso", label: "Mato Grosso" },
                                    { value: "Mato Grosso do Sul", label: "Mato Grosso do Sul" },
                                    { value: "Minas Gerais", label: "Minas Gerais" },
                                    { value: "Pará", label: "Pará" },
                                    { value: "Paraíba", label: "Paraíba" },
                                    { value: "Paraná", label: "Paraná" },
                                    { value: "Pernambuco", label: "Pernambuco" },
                                    { value: "Piauí", label: "Piauí" },
                                    { value: "Rio de Janeiro", label: "Rio de Janeiro" },
                                    { value: "Rio Grande do Norte", label: "Rio Grande do Norte" },
                                    { value: "Rio Grande do Sul", label: "Rio Grande do Sul" },
                                    { value: "Rondônia", label: "Rondônia" },
                                    { value: "Roraima", label: "Roraima" },
                                    { value: "Santa Catarina", label: "Santa Catarina" },
                                    { value: "São Paulo", label: "São Paulo" },
                                    { value: "Sergipe", label: "Sergipe" },
                                    { value: "Tocantins", label: "Tocantins" }
                                ]}
                                title=""
                                selectedValue={uf}
                                onSelect={onChangeUf}
                                searchable
                                disabled={disabled}
                            />
                        </Form.Group>
                    </InputContainer>
                    <InputContainer>
                        <Form.Group>
                            <Form.Label style={{ marginBottom: "0rem" }}>
                                Cidade
                            </Form.Label>
                            <DropdownSelect
                                options={optionsMunicipios}
                                title=""
                                selectedValue={municipio}
                                onSelect={onChangeMunicipio}
                                searchable
                                disabled={disabledMunicipio}
                            />
                        </Form.Group>
                    </InputContainer>
                    <ButtonContainer>
                        <PrimaryButton onClick={onAdd} buttonWidth="xl" disabled={disabledBtn} buttonWidthTipo="px">Adicionar</PrimaryButton>
                    </ButtonContainer>
                    <ListaContainer>
                        <ListaHorizontal
                            itens={listaAreas}
                            onClickExcluir={onClickExcluir}
                        ></ListaHorizontal>
                    </ListaContainer>
                </Row>
            </Body>
        </ModalCustom>
    );
}