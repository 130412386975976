import { Container, ContainerBotao, Icone, Titulo, TituloAreaAtuacao } from "./style";
import { Col, Form, Row } from "react-bootstrap";
import { CustomInput } from "components/CustomInput";
import { Required } from "components/Required";
import { FaPen, FaPlus, FaTimes } from "react-icons/fa";
import { Table } from "containers/Table";
import { IColum } from "containers/Table/interfaces";
import { IAreaAtuacao } from "features/core/pages/Cadastro/Interfaces/IAreaAtuacao";
import { useEffect, useState } from "react";
import { PrimaryButton } from "components/Button/style";
import { useCep } from "hooks/useCep";
import { useMaskInput } from "hooks/useMaskInput";
import { LoadingScreen } from "components/LoadingScreen";
import { ModalAreaAtuacao } from "../ModalAreaAtuacao";
import { useMunicipios } from "hooks/useMunicipios";
import { IFormularioDespachante } from "features/core/pages/Cadastro/Interfaces/IFormularioDespachante";
import { HttpError } from "_services/api/HttpError";
import { IData } from "_services/api/interface/HttpResponse";
import { HttpResponse } from "_services/api/HttpResponse";
import { alertaErro, alertaSucesso } from "components/Alerta";

interface IFormularioDespachanteProps {
    id: string;
    buscarDespachante: (id: string) => Promise<HttpError<IData, any> | HttpResponse<any, any>>;
    dadosFormulario: IFormularioDespachante;
    dadosTabelaAreasAtuacao: IAreaAtuacao[];
    setDadosFormulario: (dados: IFormularioDespachante) => void;
    setDadosTabelaAreasAtuacao: (e: any) => void;
    cadastrarDespachante: (despachante: IFormularioDespachante, atuacao: IAreaAtuacao[]) => Promise<HttpError<IData, any> | HttpResponse<any, any>>;
    atualizarDespachante: (despachante: IFormularioDespachante, atuacao: IAreaAtuacao[], idDespachante: string) => Promise<HttpError<IData, any> | HttpResponse<any, any>>;
    setLoading: (e: boolean) => void;
    loading: boolean;
    setListaUfs: (lista: string[]) => void;
    setId: (e: string) => void;
}

export function FormularioDespachante({
    id,
    buscarDespachante,
    dadosFormulario,
    dadosTabelaAreasAtuacao,
    setDadosFormulario,
    setDadosTabelaAreasAtuacao,
    cadastrarDespachante,
    atualizarDespachante,
    setLoading,
    loading,
    setListaUfs,
    setId,
}: IFormularioDespachanteProps) {

    useEffect(() => {
        (async () => {
            if (id) {
                const { hasErro } = await buscarDespachante(id);
                if (hasErro) {
                    alertaErro("Erro ao buscar despachante", "O despachante não foi encontrado.")
                }
            }
        })();
    }, []);

    const submitForm = async () => {
        if (!dadosTabelaAreasAtuacao.filter((item) => item.exclusao == "0").length) {
            alertaErro("Erro", "Selecione ao menos uma área de atuação para cadastrar o despachante.");
            return;
        }

        if (id) {
            const { hasErro, data } = await atualizarDespachante(dadosFormulario, dadosTabelaAreasAtuacao, id);
            if (hasErro) {
                alertaErro(
                    "Erro ao atualizar despachante",
                    Object.values(data.erro).length ?
                        Object.values(data.erro).map((error) => `${error}`).join(", ") :
                        data.message
                )
                return;
            }

            alertaSucesso("Sucesso", "Despachante atualizado com sucesso!");
            return;
        }

        const { hasErro, data } = await cadastrarDespachante(dadosFormulario, dadosTabelaAreasAtuacao)
        if (hasErro) {
            alertaErro(
                "Erro ao cadastrar despachante",
                Object.values(data.erro).length ?
                    Object.values(data.erro).map((error) => `${error}`).join(", ") :
                    data.message
            )
            return;
        }

        alertaSucesso("Sucesso", "Despachante salvo com sucesso!");
        setId(data.id);
    }

    const { mInputCEP, mInputUF, mInputTel, mInputCNPJ, mInputNum } = useMaskInput();
    const { obterDadosCep, dadosLocal } = useCep();
    const onChangeCep = async (event: any) => {
        mInputCEP(event)
        let cep = event.target.value
        const qtdCarateresCep = 9;
        setDadosFormulario({ ...dadosFormulario, cep })
        if (cep.length < qtdCarateresCep) {
            return;
        }
        setLoading(true)
        await obterDadosCep(cep);
        setLoading(false)
    }

    useEffect(() => {
        if (!dadosLocal) {
            return;
        }
        setDadosFormulario({
            ...dadosFormulario,
            logradouro: dadosLocal.logradouro,
            bairro: dadosLocal.bairro,
            cidade: dadosLocal.localidade,
            estado: dadosLocal.uf,
        });
    }, [dadosLocal]);

    const columns: IColum<IAreaAtuacao>[] = [
        {
            name: <span>Estado</span>,
            selector: (area: IAreaAtuacao) => area.estado,
            width: "220px",
        },
        {
            name: <span>Cidades</span>,
            selector: (area: IAreaAtuacao) => area.cidades,
        },
    ];

    const disabled = id == "0";
    if (!disabled) {
        columns.push({
            name: <span>Ações</span>,
            selector: (area: IAreaAtuacao) => <span>
                <FaPen onClick={() => onClickEditarAreaAtuacao(area.estado, area.cidades)} style={{ marginRight: "20px", cursor: "pointer" }} />
                <FaTimes onClick={() => onClickExcluirAreaAtuacao(area.estado)} style={{ color: "red", cursor: "pointer" }} />
            </span>,
            width: "200px",
        },)
    }

    const [showModalAreaAtuacao, setShowModalAreaAtuacao] = useState<boolean>(false);
    const handleCloseModalAreaAtuacao = () => {
        limparModalAreaAtuacao();
    }

    const [disabledMunicipio, setDisabledMunicipio] = useState<boolean>(false);
    const { municipios, listarMunicipios, criarOptionsMunicipios, optionsMunicipios } = useMunicipios();
    const [uf, setUf] = useState<string>("");
    const onChangeUf = (uf: string) => {
        setUf(uf);
    }
    useEffect(() => {
        if (uf) {
            setDisabledMunicipio(false);
            listarMunicipios(uf);
            return;
        }
        setDisabledMunicipio(true);
        listarMunicipios(uf);
    }, [uf]);

    useEffect(() => {
        criarOptionsMunicipios(municipios)
    }, [municipios]);

    const [disabledBtnAreaAtuacao, setDisabledBtnAreaAtuacao] = useState<boolean>(true);
    const [municipio, setMunicipio] = useState<string>("");
    const onChangeMunicipio = (municipio: string) => {
        setMunicipio(municipio);
        if (municipio && uf) {
            setDisabledBtnAreaAtuacao(false);
            return;
        }
        setDisabledBtnAreaAtuacao(true);
    }

    const [listaAreas, setListaAreas] = useState<any[]>([])
    const onConfirm = () => {
        if (dadosTabelaAreasAtuacao.map(obj => obj.estado).includes(uf)) {
            if (editarEstadoAntigo) {
                const novosDados = dadosTabelaAreasAtuacao.map(obj => {
                    if (obj.estado == uf) {
                        const novasCidades = listaAreas.map(obj => obj.texto).filter(obj => obj != "").join(", ")
                        obj.cidades = `${novasCidades ? `${novasCidades}` : ""}`;
                        obj.exclusao = "0";
                    }

                    return obj;
                })
                setDadosTabelaAreasAtuacao(novosDados);
                limparModalAreaAtuacao();
                return;
            }

            const novosDados = dadosTabelaAreasAtuacao.map(obj => {
                if (obj.estado == uf) {
                    const novasCidades = listaAreas.map(obj => obj.texto).filter(cidade => !obj.cidades.includes(cidade)).join(", ")
                    obj.cidades = `${obj.cidades ? `${obj.cidades}, ` : ""}${novasCidades ?? ""}`;
                    obj.exclusao = "0";
                }
                return obj;
            })
            setDadosTabelaAreasAtuacao(novosDados);
            limparModalAreaAtuacao();
            return;
        }

        const novosDados = [...dadosTabelaAreasAtuacao, { estado: uf, cidades: listaAreas.map(obj => obj.texto).filter(obj => obj != "").join(", "), exclusao: "0" }];
        if (editarEstadoAntigo != uf) {
            novosDados.forEach(obj => {
                if (obj.estado == editarEstadoAntigo) {
                    const excluido = "1";
                    obj.exclusao = excluido;
                }
            })
        }
        setDadosTabelaAreasAtuacao(novosDados);
        limparModalAreaAtuacao();

    }

    function limparModalAreaAtuacao() {
        setShowModalAreaAtuacao(false);
        setUf("");
        setMunicipio("");
        setListaAreas([]);
        setEditarEstadoAntigo("");
    }

    const [editarEstadoAntigo, setEditarEstadoAntigo] = useState<string>("");
    const onClickEditarAreaAtuacao = (uf: string, cidades: string) => {
        setUf(uf);
        setListaAreas(cidades.split(", ").map((item) => { return { uf, texto: item } }))
        setShowModalAreaAtuacao(true);
        setEditarEstadoAntigo(uf);
    }

    const onClickExcluirAreaAtuacao = (uf: string) => {
        const novosDados = dadosTabelaAreasAtuacao.map((obj) => {
            if (obj.estado == uf) {
                const excluido = "1";
                obj.exclusao = excluido;
                obj.cidades = "";
            }
            return obj;
        });
        setDadosTabelaAreasAtuacao(novosDados);
    }

    useEffect(() => {
        setListaUfs(dadosTabelaAreasAtuacao.map(obj => obj.estado));
    }, [dadosTabelaAreasAtuacao]);

    return (
        <Container>
            <LoadingScreen mostrar={loading} />
            <Titulo>Dados do Despachante</Titulo>
            <Row style={{ marginTop: "20px" }}>
                <Col>
                    <Form.Label style={{ marginBottom: "0.3rem" }}>
                        Razão Social <Required />
                    </Form.Label>
                    <CustomInput
                        type="text"
                        placeholder="Informe a Razão Social"
                        onChange={(event: any) => {
                            setDadosFormulario({
                                ...dadosFormulario,
                                razao_social: event.target.value,
                            });
                        }}
                        style={{ height: "2.2rem" }}
                        value={dadosFormulario?.razao_social}
                        disabled={disabled}
                    />
                </Col>
                <Col>
                    <Form.Label style={{ marginBottom: "0.3rem" }}>
                        Nome Fantasia <Required />
                    </Form.Label>
                    <CustomInput
                        type="text"
                        placeholder="Informe o Nome Fantasia"
                        onChange={(event: any) => {
                            setDadosFormulario({
                                ...dadosFormulario,
                                nome_fantasia: event.target.value,
                            });
                        }}
                        style={{ height: "2.2rem" }}
                        value={dadosFormulario?.nome_fantasia}
                        disabled={disabled}
                    />
                </Col>
            </Row>
            <Row style={{ marginTop: "20px" }}>
                <Col>
                    <Form.Label style={{ marginBottom: "0.3rem" }}>
                        CNPJ <Required />
                    </Form.Label>
                    <CustomInput
                        type="text"
                        placeholder="Informe o CNPJ"
                        onChange={(event: any) => {
                            setDadosFormulario({
                                ...dadosFormulario,
                                cnpj: mInputCNPJ(event),
                            });
                        }}
                        style={{ height: "2.2rem" }}
                        value={dadosFormulario?.cnpj}
                        disabled={disabled}
                    />
                </Col>
                <Col>
                    <Form.Label style={{ marginBottom: "0.3rem" }}>
                        E-mail
                    </Form.Label>
                    <CustomInput
                        type="text"
                        placeholder="Informe o E-mail"
                        onChange={(event: any) => {
                            setDadosFormulario({
                                ...dadosFormulario,
                                email: event.target.value,
                            });
                        }}
                        style={{ height: "2.2rem" }}
                        value={dadosFormulario?.email}
                        disabled={disabled}
                    />
                </Col>
            </Row>
            <Row style={{ marginTop: "20px" }}>
                <Col>
                    <Form.Label style={{ marginBottom: "0.3rem" }}>
                        Celular
                    </Form.Label>
                    <CustomInput
                        type="text"
                        placeholder="Informe o celular com DDD"
                        onChange={(event: any) => {
                            setDadosFormulario({
                                ...dadosFormulario,
                                celular: mInputTel(event),
                            });
                        }}
                        style={{ height: "2.2rem" }}
                        value={dadosFormulario?.celular}
                        disabled={disabled}
                    />
                </Col>
                <Col>
                    <Form.Label style={{ marginBottom: "0.3rem" }}>
                        Telefone
                    </Form.Label>
                    <CustomInput
                        type="text"
                        placeholder="Informe o telefone com DDD"
                        onChange={(event: any) => {
                            setDadosFormulario({
                                ...dadosFormulario,
                                telefone: mInputTel(event),
                            });
                        }}
                        style={{ height: "2.2rem" }}
                        value={dadosFormulario?.telefone}
                        disabled={disabled}
                    />
                </Col>
            </Row>
            <Titulo>Endereço</Titulo>
            <Row style={{ marginTop: "20px" }}>
                <Col md={2}>
                    <Form.Label style={{ marginBottom: "0.3rem" }}>
                        CEP <Required />
                    </Form.Label>
                    <CustomInput
                        type="text"
                        placeholder="Informe o CEP"
                        onChange={onChangeCep}
                        style={{ height: "2.2rem" }}
                        value={dadosFormulario?.cep}
                        disabled={disabled}
                    />
                </Col>
                <Col md={7}>
                    <Form.Label style={{ marginBottom: "0.3rem" }}>
                        Logradouro <Required />
                    </Form.Label>
                    <CustomInput
                        type="text"
                        placeholder="Informe o logradouro"
                        onChange={(event: any) => {
                            setDadosFormulario({
                                ...dadosFormulario,
                                logradouro: event.target.value,
                            });
                        }}
                        style={{ height: "2.2rem" }}
                        value={dadosFormulario?.logradouro}
                        disabled={disabled}
                    />
                </Col>
                <Col md={3}>
                    <Form.Label style={{ marginBottom: "0.3rem" }}>
                        Número <Required />
                    </Form.Label>
                    <CustomInput
                        type="text"
                        placeholder="Informe o número"
                        onChange={(event: any) => {
                            setDadosFormulario({
                                ...dadosFormulario,
                                numero: mInputNum(event),
                            });
                        }}
                        style={{ height: "2.2rem" }}
                        value={dadosFormulario?.numero}
                        disabled={disabled}
                    />
                </Col>
            </Row>
            <Row style={{ marginTop: "20px" }}>
                <Col>
                    <Form.Label style={{ marginBottom: "0.3rem" }}>
                        Bairro <Required />
                    </Form.Label>
                    <CustomInput
                        type="text"
                        placeholder="Informe o bairro"
                        onChange={(event: any) => {
                            setDadosFormulario({
                                ...dadosFormulario,
                                bairro: event.target.value,
                            });
                        }}
                        style={{ height: "2.2rem" }}
                        value={dadosFormulario?.bairro}
                        disabled={disabled}
                    />
                </Col>
                <Col>
                    <Form.Label style={{ marginBottom: "0.3rem" }}>
                        Cidade <Required />
                    </Form.Label>
                    <CustomInput
                        type="text"
                        placeholder="Informe a cidade"
                        onChange={(event: any) => {
                            setDadosFormulario({
                                ...dadosFormulario,
                                cidade: event.target.value,
                            });
                        }}
                        style={{ height: "2.2rem" }}
                        value={dadosFormulario?.cidade}
                        disabled={disabled}
                    />
                </Col>
                <Col>
                    <Form.Label style={{ marginBottom: "0.3rem" }}>
                        Estado <Required />
                    </Form.Label>
                    <CustomInput
                        type="text"
                        placeholder="Informe o estado"
                        onChange={(event: any) => {
                            setDadosFormulario({
                                ...dadosFormulario,
                                estado: mInputUF(event),
                            });
                        }}
                        style={{ height: "2.2rem" }}
                        value={dadosFormulario?.estado}
                        disabled={disabled}
                    />
                </Col>
                <Col>
                    <Form.Label style={{ marginBottom: "0.3rem" }}>
                        Complemento
                    </Form.Label>
                    <CustomInput
                        type="text"
                        placeholder="Informe o complemento"
                        onChange={(event: any) => {
                            setDadosFormulario({
                                ...dadosFormulario,
                                complemento: event.target.value,
                            });
                        }}
                        style={{ height: "2.2rem" }}
                        value={dadosFormulario?.complemento}
                        disabled={disabled}
                    />
                </Col>
            </Row>
            <TituloAreaAtuacao style={{ marginTop: "60px" }}>Área de Atuação {!disabled ? <Icone><FaPlus onClick={() => { setShowModalAreaAtuacao(true) }} /></Icone> : ""}</TituloAreaAtuacao>
            <Table
                noSubHeader
                keyField="id"
                columns={columns}
                data={dadosTabelaAreasAtuacao.filter(obj => obj.exclusao == "0")}
                loading={false}
                customTableStyle={{ paddingTop: "0" }}
            />
            <ContainerBotao>
                <Row className="justify-content-end pt-4">
                    <PrimaryButton disabled={disabled} onClick={submitForm} style={{ width: "220px" }}>Salvar</PrimaryButton>
                </Row>
            </ContainerBotao>
            <ModalAreaAtuacao
                show={showModalAreaAtuacao}
                handleClose={handleCloseModalAreaAtuacao}
                uf={uf}
                onChangeUf={onChangeUf}
                optionsMunicipios={optionsMunicipios}
                municipio={municipio}
                onChangeMunicipio={onChangeMunicipio}
                onConfirm={onConfirm}
                setListaAreas={setListaAreas}
                listaAreas={listaAreas}
                disabledBtn={disabledBtnAreaAtuacao}
                disabledMunicipio={disabledMunicipio}
            ></ModalAreaAtuacao>
        </Container >
    );
}