import { useState } from "react";
import { AxiosRequest } from "_services/api";
import { HttpError } from "_services/api/HttpError";
import { HttpResponse } from "_services/api/HttpResponse";
import {
    IData,
    IHttpError,
    IHttpResponse,
} from "_services/api/interface/HttpResponse";

export function useCep() {
    const [dadosLocal, setDadosLocal] = useState<any>();
    const [error, setError] = useState<IHttpResponse<IData | any>>();

    const api = AxiosRequest.makeApi();

    async function obterDadosCep(cep: string) {
        try {
            const { status, data } = await api.get(`/utils/pesquisarCep/${cep}`);
            const rersponse = new HttpResponse(false, status, data);
            setError(rersponse);
            setDadosLocal(data);
            return rersponse;
        } catch (error: IHttpError<IData, any> | any) {
            const response = new HttpError<IData>(true, error);
            setError(response);
            return response;
        }
    }

    return {
        obterDadosCep,
        dadosLocal
    };
}
