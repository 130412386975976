import { FaTimes } from "react-icons/fa";
import { Item } from "./style";

interface IItem {
    texto: string;
}

interface IListaHorizontalProps {
    itens: IItem[];
    onClickExcluir: (e: any) => void;
}

export function ListaHorizontal({
    itens,
    onClickExcluir,
}: IListaHorizontalProps) {
    const lista = itens.filter(obj => obj.texto != "").map((e: IItem) => {
        return (<Item onClick={() => onClickExcluir(e.texto)}>
            {e.texto}
            <FaTimes style={{ fontSize: "11px", marginBottom: "3px", marginLeft: "5px", cursor: "pointer" }} />
        </Item>)
    });

    return (
        <div style={{ lineHeight: "27px" }}>{lista}</div>
    );
}