import { converterDataBr } from "_utils/Data";
import { CompositeContainer } from "components/CompositeContainer";
import { Table } from "containers/Table";
import { makeWorker } from "features/processo/_workers";
import { useCadastrarProcesso } from "features/processo/hooks/useCadastrarProcesso";
import { CadastrarProcessoEmLoteMapper } from "features/processo/utils/mappers/CadastrarProcessoEmLoteMapper";
import { useMemo, useState } from "react";
import { ModalProcessosCadastrados } from "../ModalProcessosCadastrados";
import { handleExportProcessos } from "../ModalProcessosCadastrados/utils/ExportarPlanilhaErro";

const columns = [
  {
    name: <span>Placa</span>,
    selector: ({ veiculo }: CadastrarProcessoEmLoteMapper) => (
      <span>{veiculo?.placa ?? "- -"}</span>
    ),
  },
  {
    name: <span>Chassi</span>,
    selector: ({ veiculo }: CadastrarProcessoEmLoteMapper) => (
      <span>{veiculo?.chassi ?? "- -"}</span>
    ),
    width: "170px",
  },
  {
    name: <span>Renavam</span>,
    selector: ({ veiculo }: CadastrarProcessoEmLoteMapper) => (
      <span>{veiculo?.renavam ?? "- -"}</span>
    ),
  },
  {
    name: <span>Odômetro</span>,
    selector: ({ veiculo }: CadastrarProcessoEmLoteMapper) => (
      <span>{veiculo?.odometro ?? "- -"}</span>
    ),
  },
  {
    name: <span>UF</span>,
    selector: ({ veiculo }: CadastrarProcessoEmLoteMapper) => (
      <span>{veiculo?.uf ?? "- -"}</span>
    ),
  },
  {
    name: <span>Município</span>,
    selector: ({ veiculo }: CadastrarProcessoEmLoteMapper) => (
      <span>{veiculo?.municipio ?? "- -"}</span>
    ),
  },
  {
    name: <span>Marca/Modelo</span>,
    selector: ({ veiculo }: CadastrarProcessoEmLoteMapper) => (
      <span>{veiculo?.marcaModelo ?? "- -"}</span>
    ),
    width: "200px",
  },
  {
    name: <span>Ano Modelo</span>,
    selector: ({ veiculo }: CadastrarProcessoEmLoteMapper) => (
      <span>{veiculo?.anoModelo ?? "- -"}</span>
    ),
    width: "100px",
  },
  {
    name: <span>Ano Fabricação</span>,
    selector: ({ veiculo }: CadastrarProcessoEmLoteMapper) => (
      <span>{veiculo?.anoFabricacao ?? "- -"}</span>
    ),
    width: "100px",
  },
  {
    name: <span>Cor</span>,
    selector: ({ veiculo }: CadastrarProcessoEmLoteMapper) => (
      <span>{veiculo?.cor ?? "- -"}</span>
    ),
  },
  {
    name: <span>Tipo</span>,
    selector: ({ veiculo }: CadastrarProcessoEmLoteMapper) => (
      <span>{veiculo?.tipo ?? "- -"}</span>
    ),
  },
  {
    name: <span>Espécie</span>,
    selector: ({ veiculo }: CadastrarProcessoEmLoteMapper) => (
      <span>{veiculo?.especie ?? "- -"}</span>
    ),
  },
  {
    name: <span>Potência</span>,
    selector: ({ veiculo }: CadastrarProcessoEmLoteMapper) => (
      <span>{veiculo?.potencia ?? "- -"}</span>
    ),
  },
  {
    name: <span>Combustível</span>,
    selector: ({ veiculo }: CadastrarProcessoEmLoteMapper) => (
      <span>{veiculo?.combustivel ?? "- -"}</span>
    ),
  },
  {
    name: <span>CPF/CNPJ</span>,
    selector: ({ cliente }: CadastrarProcessoEmLoteMapper) => (
      <span>{cliente?.cpfCnpj ?? "- -"}</span>
    ),
  },
  {
    name: <span>Nome</span>,
    selector: ({ cliente }: CadastrarProcessoEmLoteMapper) => (
      <span>{cliente?.nome ?? "- -"}</span>
    ),
  },
  {
    name: <span>E-mail</span>,
    selector: ({ cliente }: CadastrarProcessoEmLoteMapper) => (
      <span>{cliente?.email ?? "- -"}</span>
    ),
  },
  {
    name: <span>CEP</span>,
    selector: ({ cliente }: CadastrarProcessoEmLoteMapper) => (
      <span>{cliente?.cep ?? "- -"}</span>
    ),
  },
  {
    name: <span>Endereço</span>,
    selector: ({ cliente }: CadastrarProcessoEmLoteMapper) => (
      <span>{cliente?.endereco ?? "- -"}</span>
    ),
  },
  {
    name: <span>Número</span>,
    selector: ({ cliente }: CadastrarProcessoEmLoteMapper) => (
      <span>{cliente?.numero ?? "- -"}</span>
    ),
  },
  {
    name: <span>Complemento</span>,
    selector: ({ cliente }: CadastrarProcessoEmLoteMapper) => (
      <span>{cliente?.complemento ?? "- -"}</span>
    ),
  },
  {
    name: <span>Bairro</span>,
    selector: ({ cliente }: CadastrarProcessoEmLoteMapper) => (
      <span>{cliente?.bairro ?? "- -"}</span>
    ),
  },
  {
    name: <span>UF</span>,
    selector: ({ cliente }: CadastrarProcessoEmLoteMapper) => (
      <span>{cliente?.ufCliente ?? "- -"}</span>
    ),
  },
  {
    name: <span>Valor de Venda</span>,
    selector: ({ servicoTransferencia }: CadastrarProcessoEmLoteMapper) => (
      <span>{servicoTransferencia?.valorDeVenda ?? "- -"}</span>
    ),
  },
  {
    name: <span>UF Venda</span>,
    selector: ({ servicoTransferencia }: CadastrarProcessoEmLoteMapper) => (
      <span>{servicoTransferencia?.ufVenda ?? "- -"}</span>
    ),
  },
  {
    name: <span>Município de Venda</span>,
    selector: ({ servicoTransferencia }: CadastrarProcessoEmLoteMapper) => (
      <span>{servicoTransferencia?.municipioDeVenda ?? "- -"}</span>
    ),
  },
  {
    name: <span>Data de Venda</span>,
    selector: ({ servicoTransferencia }: CadastrarProcessoEmLoteMapper) => (
      <span>
        {servicoTransferencia?.dataDeVenda
          ? converterDataBr(new Date(servicoTransferencia?.dataDeVenda))
          : "- -"}
      </span>
    ),
  },
  {
    name: <span>Escolha de Placa</span>,
    selector: ({ servicoEmplacamento }: CadastrarProcessoEmLoteMapper) => (
      <span>{servicoEmplacamento?.escolhaDePlaca ?? "- -"}</span>
    ),
  },
  {
    name: <span>Local de Emplacamento</span>,
    selector: ({ servicoEmplacamento }: CadastrarProcessoEmLoteMapper) => (
      <span>{servicoEmplacamento?.localDeEmplacamento ?? "- -"}</span>
    ),
    width: "225px",
  },
  {
    name: <span>Placa Vermelha</span>,
    selector: ({ servicoEmplacamento }: CadastrarProcessoEmLoteMapper) => (
      <span>{servicoEmplacamento?.placaVermelha ?? "- -"}</span>
    ),
  },
  {
    name: <span>Alienação</span>,
    selector: ({ servicoEmplacamento }: CadastrarProcessoEmLoteMapper) => (
      <span>{servicoEmplacamento?.alienacao ?? "- -"}</span>
    ),
  },
  {
    name: <span>NF</span>,
    selector: ({ servicoEmplacamento }: CadastrarProcessoEmLoteMapper) => (
      <span>{servicoEmplacamento?.nf?.toString() ?? "- -"}</span>
    ),
    width: "225px",
  },
  {
    name: <span>Emissão</span>,
    selector: ({ servicoEmplacamento }: CadastrarProcessoEmLoteMapper) => (
      <span>
        {servicoEmplacamento?.emissao
          ? converterDataBr(new Date(servicoEmplacamento?.emissao))
          : "- -"}
      </span>
    ),
  },
];

export function ListarProcessosPlanilha({ setLoadingPage }: any) {
  const [dadosTabela, setDadosTabela] = useState<any[]>([]);
  const [dadosServico, setDadosServico] = useState<any>({});
  const [showModal, setShowModal] = useState(false);

  const { cadastrarProcessos, processosComErro, handleLimparProcessosComErro } = useCadastrarProcesso();
  const worker = useMemo(makeWorker, []);

  worker.onmessage = async ({ data }: any) => {
    const { type, dados, dadosServico } = data;

    if (type === "dadosExcel" && dados && dadosServico) {
      setDadosServico(dadosServico);
      setDadosTabela(dados);
      setLoadingPage(false);
    }
  };

  async function handleSalvarExcel() {
    setLoadingPage(true);
    await cadastrarProcessos(dadosTabela, dadosServico);
    setLoadingPage(false);
    setShowModal(true);
  }

  return (
    <CompositeContainer.Root>
      <CompositeContainer.Header>
        <CompositeContainer.Titulo text="Resultado" />
        <CompositeContainer.ActionButton
          text="Salvar Excel"
          onClick={handleSalvarExcel}
          disabled={dadosTabela.length === 0}
        />
      </CompositeContainer.Header>
      <CompositeContainer.Body>
        <Table
          columns={columns}
          data={dadosTabela}
          keyField={`id${dadosTabela.length}`}
          loading={false}
          noSubHeader={true}
          totalRows={dadosTabela.length}
        />
      </CompositeContainer.Body>
      <ModalProcessosCadastrados
        handleClose={() => {
          handleLimparProcessosComErro()
          setShowModal(false)
        }}
        soTeveErro={processosComErro.length === dadosTabela.length}
        processosComErro={processosComErro}
        show={showModal}
        onConfirm={() => {
          handleExportProcessos(processosComErro, setShowModal);
        }}
      />
    </CompositeContainer.Root>
  );
}

