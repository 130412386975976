import { useEffect, useState } from "react";
import { AxiosRequest } from "_services/api";
import { HttpError } from "_services/api/HttpError";
import { HttpResponse } from "_services/api/HttpResponse";
import {
    IData,
    IHttpError,
    IHttpResponse,
} from "_services/api/interface/HttpResponse";
import { IOptions } from "interfaces/IOptions";

export function useMunicipios() {
    const [municipios, setMunicipios] = useState<any[]>([]);
    const [optionsMunicipios, setOptionsMunicipios] = useState<IOptions[]>([]);
    const [errorMunicipios, setErrorMunicipios] = useState<IHttpResponse<IData | any>>();

    const api = AxiosRequest.makeApi("https://servicodados.ibge.gov.br/api/v1", true);

    async function listarMunicipios(uf: string) {
        try {
            const limiteCaracteresUf = 2;
            if (uf.length > limiteCaracteresUf) {
                uf = obterSiglaEstado(uf)
            }
            const { status, data } = await api.get(`/localidades/estados/${uf}/municipios`);
            const rersponse = new HttpResponse(false, status, data);
            setErrorMunicipios(rersponse);
            setMunicipios(data);
            return rersponse;
        } catch (error: IHttpError<IData, any> | any) {
            const response = new HttpError<IData>(true, error);
            setErrorMunicipios(response);
            return response;
        }
    }

    function obterSiglaEstado(estado: string) {
        const estadoSiglaStrategy: { [key: string]: string } = {
            "Acre": "AC",
            "Alagoas": "AL",
            "Amazonas": "AM",
            "Amapá": "AP",
            "Bahia": "BA",
            "Ceará": "CE",
            "Distrito Federal": "DF",
            "Espírito Santo": "ES",
            "Goiás": "GO",
            "Maranhão": "MA",
            "Mato Grosso": "MT",
            "Mato Grosso do Sul": "MS",
            "Minas Gerais": "MG",
            "Pará": "PA",
            "Paraíba": "PB",
            "Paraná": "PR",
            "Pernambuco": "PE",
            "Piauí": "PI",
            "Rio de Janeiro": "RJ",
            "Rio Grande do Norte": "RN",
            "Rio Grande do Sul": "RS",
            "Rondônia": "RO",
            "Roraima": "RR",
            "Santa Catarina": "SC",
            "São Paulo": "SP",
            "Sergipe": "SE",
            "Tocantins": "TO"
        };

        if (estado in estadoSiglaStrategy) {
            return estadoSiglaStrategy[estado];
        } else {
            return "";
        }
    }

    async function criarOptionsMunicipios(listaMunicipios: any[]) {
        setOptionsMunicipios(listaMunicipios.map((item) => { return { value: item.nome, label: item.nome } }))
    }

    return {
        municipios,
        errorMunicipios,
        listarMunicipios,
        criarOptionsMunicipios,
        optionsMunicipios,
    };
}
