import { useCallback, useEffect, useMemo, useState } from "react";
import { AxiosRequest } from "_services/api";
import { HttpError } from "_services/api/HttpError";
import { HttpResponse } from "_services/api/HttpResponse";
import {
  IData,
  IHttpError,
  IHttpResponse,
} from "_services/api/interface/HttpResponse";

export function useServicos(empresa: any, despachante: any) {
  const [servicos, setServicos] = useState<any[]>([]);
  const [servicosOptions, setServicosOptions] = useState<any[]>([]);
  const [error, setError] = useState<IHttpResponse<IData | any>>();

  const api = useMemo(() => AxiosRequest.makeApi(), []);

  const listarTodosServicos = useCallback(
    async (empresa: any, despachante: any) => {
      try {
        const { status, data } = await api.get(`/empresa/${empresa}/despachantes/${despachante}/servicos?ignorarExcluidos=sim`);
        const response = new HttpResponse(false, status, data);
        setError(response);
        setServicos(data.dados ?? data);
        criarOptionsServicos(data.dados ?? data);
        return response;
      } catch (error: IHttpError<IData, any> | any) {
        criarOptionsServicos([]);
        const response = new HttpError<IData>(true, error);
        setError(response);
        return response;
      }
    },
    [api]
  );

  useEffect(() => {
    if (!despachante || !empresa) {
      setServicos([]);
      setServicosOptions([]);
      return;
    }

    listarTodosServicos(empresa, despachante);
  }, [despachante, listarTodosServicos, empresa]);

  function criarOptionsServicos(divisoes: any[]) {
    const optionsDivisoes = divisoes.map((item) => ({
      value: item.id_servico_modelo,
      label: item.servico,
    }));

    setServicosOptions(optionsDivisoes);
  }

  return {
    servicos,
    error,
    servicosOptions,
    listarTodosServicos,
  };
}

