import styled from "styled-components";

const ToolsActionContainer = styled.div`
  display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    width: 100%;
`;

export { ToolsActionContainer };
