import Swal, { SweetAlertOptions } from "sweetalert2";

async function alerta<T = any>(options: SweetAlertOptions<T>) {
  return Swal.fire({
    confirmButtonColor: "#8CD4F5",
    cancelButtonText: "Cancelar",
    ...options,
  });
}

async function alertaSucesso<T = any>(
  titulo: string,
  texto?: string,
  options?: SweetAlertOptions<T>
) {
  const { title, text, ...restante } = options
    ? options
    : { title: "", text: "" };

  return Swal.fire({
    title: `${titulo}`,
    text: `${texto ? texto : ""}`,
    icon: "success",
    confirmButtonColor: "#8CD4F5",
    cancelButtonText: "Cancelar",
    ...restante,
  });
}

async function alertaErro<T = any>(
  titulo: string,
  texto?: string,
  options?: SweetAlertOptions<T>
) {
  const { title, text, ...restante } = options
    ? options
    : { title: "", text: "" };

  return Swal.fire({
    title: `${titulo}`,
    text: `${texto ? texto : ""}`,
    icon: "error",
    confirmButtonColor: "#8CD4F5",
    cancelButtonText: "Cancelar",
    ...restante,
  });
}

async function alertaWarnig<T = any>(
  titulo: string,
  texto?: string,
  options?: SweetAlertOptions<T>
) {
  const { title, text, ...restante } = options
    ? options
    : { title: "", text: "" };

  return Swal.fire({
    title: `${titulo}`,
    text: `${texto ? texto : ""}`,
    icon: "warning",
    confirmButtonColor: "#8CD4F5",
    cancelButtonText: "Cancelar",
    ...restante,
  });
}

async function alertaInfo<T = any>(
  titulo: string,
  texto?: string,
  options?: SweetAlertOptions<T>
) {
  const { title, text, ...restante } = options
    ? options
    : { title: "", text: "" };

  return Swal.fire({
    title: `${titulo}`,
    text: `${texto ? texto : ""}`,
    icon: "info",
    confirmButtonColor: "#8CD4F5",
    cancelButtonText: "Cancelar",
    ...restante,
  });
}
export { alerta, alertaSucesso, alertaErro, alertaWarnig, alertaInfo };

