import { CustomInput } from "components/CustomInput";
import { CustomDatePicker } from "components/DatePicker";
import { DropdownSelect } from "components/DropdownSelect";
import { ContainerHeader } from "components/Ibox/style";
import { useMaskInput } from "hooks/useMaskInput";
import { useContext, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { FaFilter } from "react-icons/fa";
import { AutorizacaoPlacaContext } from "../../context/AutorizacaoPlacaContext";
import { Button, Container, FormContainer, Title } from "./style";

export function Filtros() {
  const { setFiltros } = useContext(AutorizacaoPlacaContext);
  const { register, handleSubmit, reset, control } = useForm();
  const [dataInicio, setDataInicio] = useState<Date>();
  const [dataFim, setDataFim] = useState<Date>();
  const onChangeData = (dates: any) => {
    const [start, end] = dates;
    setDataInicio(start);
    setDataFim(end);
  };

  const { mInputPlaca } = useMaskInput();

  return (
    <Container>
      <ContainerHeader>
        <Title>Filtros</Title>
      </ContainerHeader>
      <hr />
      <FormContainer>
        <Form
          noValidate
          onSubmit={handleSubmit((dados: IFiltro) => setFiltros(dados))}
        >
          <Row>
            <Col md={2}>
              <Form.Group>
                <Form.Label>Placa</Form.Label>
                <Controller
                  control={control}
                  name="placa"
                  render={({ field: { onChange } }) => (
                    <CustomInput
                      type="text"
                      placeholder="Busque por placa"
                      style={{ height: "2.2rem" }}
                      onChange={(e) => {
                        mInputPlaca(e);
                        onChange(e);
                      }}
                    />
                  )}
                />
              </Form.Group>
            </Col>
            <Col md={2}>
              <Form.Group>
                <Form.Label>Período de solicitação</Form.Label>
                <Controller
                  control={control}
                  name="periodoSolicitacao"
                  render={({ field: { onChange } }) => (
                    <CustomDatePicker
                      onChange={(update) => {
                        onChange(update);
                        onChangeData(update);
                      }}
                      rangeSelect={true}
                      startDate={dataInicio}
                      endDate={dataFim}
                    />
                  )}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Proprietário</Form.Label>
                <Controller
                  control={control}
                  name="proprietario"
                  render={({ field }) => (
                    <CustomInput
                      type="text"
                      placeholder="Busque por proprietário"
                      style={{ height: "2.2rem" }}
                      {...field}
                    />
                  )}
                />
              </Form.Group>
            </Col>
            <Col md={2}>
              <Form.Group>
                <Form.Label>Status da solicitação</Form.Label>
                <Controller
                  control={control}
                  name="statusSolicitacao"
                  render={({ field: { onChange, value } }) => (
                    <DropdownSelect
                      options={[
                        { value: "1", label: "Processando" },
                        { value: "2", label: "Finalizado" },
                        { value: "3", label: "Erro" },
                        { value: "4", label: "Finalizado manual" },
                      ]}
                      title=""
                      selectedValue={value}
                      onSelect={onChange}
                      searchable
                    />
                  )}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <div className="d-flex gap-3">
                  <Button type="submit">
                    <FaFilter
                      style={{
                        fontSize: "12px",
                        marginBottom: "3px",
                        marginRight: "5px",
                      }}
                    />{" "}
                    Filtrar
                  </Button>
                  <Button
                    type="reset"
                    outline
                    variante="primary"
                    onClick={() => {
                      onChangeData([null, null]);
                      reset({
                        idSolicitacao: null,
                        placa: null,
                        empresa: null,
                        statusSolicitacao: null,
                      });
                    }}
                  >
                    Limpar
                  </Button>
                </div>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </FormContainer>
    </Container>
  );
}
