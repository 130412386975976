import styled from "styled-components";

export const ModalBody = styled.div`
    text-align:center;
`;

export const Icone = styled.div`
    margin:auto;
    font-size: 30px;

    svg {
        background-color: #FFE4E4;
        color: #EE5353;
        border-radius: 10px;
        padding: 4px;
    }
`;