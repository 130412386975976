import { Page } from "containers/Page";
import { ImportarExcelProcessos } from "./containers/ImportarExcelProcessos";
import { ListarProcessosPlanilha } from "./containers/ListarProcessosPlanilha";
import { useState } from "react";

export function CadastrarEmLote() {
  const [loadingPage, setLoadingPage] = useState<boolean>(false);

  return (
    <Page title="Cadastrar Processo em Lote" loading={loadingPage}>
      <ImportarExcelProcessos setLoadingPage={setLoadingPage} />
      <ListarProcessosPlanilha setLoadingPage={setLoadingPage} />
    </Page>
  )
}
