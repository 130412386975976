import { Controller, useForm } from "react-hook-form";
import { Button, ButtonContainer, FormContainer } from "./style";
import { Col, Form, Row } from "react-bootstrap";
import { DropdownSelect } from "components/DropdownSelect";
import { yupResolver } from "@hookform/resolvers/yup";
import { modalPreencherATPVFormularioLoteSchema } from "../../Validate/validator";
import { Required } from "components/Required";
import FileUpload from "components/UploadArquivos";
import { useEmpresas } from "hooks/useEmpresas";
import { useState } from "react";
import { useDivisoes } from "hooks/useDivisoes";
import { Tooltip } from "components/Tooltip";
import { Informacao } from "components/Informacao";
import { Question } from "components/Icons/Question";
import { IPreencherATPVLote } from "../../interfaces/IPreencherATPVLote";
import { PrimaryButton } from "components/Button/style";

interface IModalPreencherATPVFormularioLoteProps {
    submitForm: (dados: IPreencherATPVLote) => void;
    setNomeEmpresa: (e: string) => void;
    setNomeDivisao: (e: string) => void;
}

export function ModalPreencherATPVFormularioLote({
    submitForm,
    setNomeEmpresa,
    setNomeDivisao,
}: IModalPreencherATPVFormularioLoteProps) {
    const {
        handleSubmit,
        control,
        formState: { errors, isSubmitted },
    } = useForm<IPreencherATPVLote>({
        resolver: yupResolver(modalPreencherATPVFormularioLoteSchema),
    });

    const { empresasOptions } = useEmpresas();
    const [idEmpresaEscolhida, setIdEmpresaEscolhida] = useState<number>(0);
    const [desabilitarDivisao, setDesabilitarDivisao] = useState<boolean>(true);
    const { divisoesOptions } = useDivisoes(idEmpresaEscolhida);
    function buscarNomeEmpresa(idEmpresa: NullableString | number) {
        setNomeEmpresa(empresasOptions.find((item) => item.value === Number(idEmpresa))?.label);
    }
    function buscarNomeDivisao(idDivisao: NullableString | number) {
        setNomeDivisao(divisoesOptions.find((item) => item.value === Number(idDivisao))?.label);
    }
    const onChangeEmpresaEscolhida = (empresa: number | null) => {
        if (empresa) {
            buscarNomeEmpresa(empresa);
            setIdEmpresaEscolhida(empresa);
            setDesabilitarDivisao(false);
            return;
        }
        setDesabilitarDivisao(true);
    }
    const onChangeDivisao = (divisao: NullableString | number) => {
        if (divisao) {
            buscarNomeDivisao(divisao);
        }
    }
    function handleBaixarModelo() {
        const link = document.createElement("a");
        link.download = "Modelo_preenchimento_atpv.xlsx";
        link.href = "/app/files/Modelo_preenchimento_atpv.xlsx";
        link.click();
    }

    return (
        <FormContainer>
            <Form noValidate onSubmit={handleSubmit((dados) => submitForm({
                uf: dados.uf,
                empresa: dados.empresa,
                divisao: dados.divisao,
                arquivo: dados.arquivo,
            }))}>
                <Row style={{ height: "5.5rem" }}>
                    <Col>
                        <Form.Group>
                            <Form.Label>
                                Empresa <Required />
                            </Form.Label>
                            <Controller
                                control={control}
                                name="empresa"
                                render={({ field: { onChange, value } }) => (
                                    <DropdownSelect
                                        options={empresasOptions}
                                        title=""
                                        selectedValue={value}
                                        onSelect={(value) => {
                                            onChange(value)
                                            onChangeEmpresaEscolhida(value)
                                        }}
                                        searchable
                                        mensagemErro={
                                            isSubmitted ? (errors.empresa?.message as any) : null
                                        }
                                        gapDropdownContainer="0"
                                    />
                                )}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label>
                                Divisão <Required />
                            </Form.Label>
                            <Controller
                                control={control}
                                name="divisao"
                                render={({ field: { onChange, value } }) => (
                                    <DropdownSelect
                                        options={divisoesOptions}
                                        title=""
                                        selectedValue={value}
                                        onSelect={(value) => {
                                            onChange(value)
                                            onChangeDivisao(value)
                                        }}
                                        searchable
                                        disabled={desabilitarDivisao}
                                        mensagemErro={
                                            isSubmitted ? (errors.divisao?.message as any) : null
                                        }
                                        gapDropdownContainer="0"
                                    />
                                )}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg="2">
                        <Form.Group>
                            <Form.Label>
                                UF <Required />{" "}
                                <Tooltip trigger={<Question />}>
                                    <Informacao
                                        type="preto"
                                        typeTexto="preto"
                                        title={true}
                                        showBotaoFechar={false}
                                        mensagem={"UF de emplacamento"}
                                        style={{
                                            width: "150px",
                                            fontSize: "10px",
                                            position: "absolute",
                                            left: "34rem",
                                            top: "-2rem",
                                        }}
                                    />
                                </Tooltip>
                            </Form.Label>
                            <Controller
                                control={control}
                                name="uf"
                                render={({ field: { onChange, value } }) => (
                                    <DropdownSelect
                                        options={[
                                            { value: "PR", label: "PR" },
                                        ]}
                                        title=""
                                        selectedValue={value}
                                        onSelect={onChange}
                                        searchable
                                        mensagemErro={
                                            isSubmitted ? (errors.uf?.message as any) : null
                                        }
                                        gapDropdownContainer="0"
                                    />
                                )}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row style={{ height: "5.5rem" }}>
                    <Col>
                        <Form.Group>
                            <Form.Label>
                                Arquivo <Required />
                            </Form.Label>
                            <Controller
                                name="arquivo"
                                control={control}
                                render={({ field: { onChange } }) => (
                                    <FileUpload
                                        onFileUpload={(file) => onChange(file)}
                                        mensagemErro={
                                            isSubmitted ? (errors.arquivo?.message as any) : null
                                        }
                                        comMaxWidth={false}
                                        gapContainer="0"
                                    />
                                )}
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <ButtonContainer>
                    <Button
                        outline
                        variante="primary"
                        onClick={handleBaixarModelo}
                        type="button"
                    >Baixar Modelo</Button>
                    <PrimaryButton style={{ width: "200px" }} type="submit">Preencher ATPV</PrimaryButton>
                </ButtonContainer>
            </Form>
        </FormContainer>
    )
}