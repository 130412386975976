import { useState } from "react";
import { AxiosRequest } from "_services/api";
import { HttpError } from "_services/api/HttpError";
import { HttpResponse } from "_services/api/HttpResponse";
import { IData, IHttpError } from "_services/api/interface/HttpResponse";
import { IReciboEmpresa } from "../Interfaces/IReciboEmpresa";

enum ProcessoComIpva {
    SIM = 1,
    NAO = 0,
}

export function useRecibos() {
    const api = AxiosRequest.makeApi();
    const [reciboEmpresaLista, setReciboEmpresaLista] = useState<IReciboEmpresa[]>([]);
    const [processosComIpva, setProcessosComIpva] = useState<number[]>([]);
    const [processosSemIpva, setProcessosSemIpva] = useState<number[]>([]);
    const listarRecibosDaEmpresa = async (empresa: number | undefined) => {
        try {
            if (empresa) {
                const { status, data } = await api.get(`/pagamento/recibos/empresa/${empresa}`);
                setReciboEmpresaLista(data.items.map((item: any) => {
                    return { value: item.id, label: `${item.id} - ${item.descricao}` }
                }));
                const response = new HttpResponse(false, status, data);
                return response;
            }
        } catch (error: IHttpError<IData, any> | any) {
            const response = new HttpError<IData>(true, error);
            return response;
        }
    };

    const baixarManualmente = async (idsProcessos: any[]) => {
        try {
            const { status, data } = await api.post(`/processos/detran/custo/ipva`, { idsProcessos });

            const listaProcessosComIpva = data.processos
                .filter((item: any) => { return item.comIpva == ProcessoComIpva.SIM })
                .map((item: any) => { return item.processo });
            setProcessosComIpva(listaProcessosComIpva);
            const listaProcessosSemIpva = data.processos
                .filter((item: any) => { return item.comIpva == ProcessoComIpva.NAO })
                .map((item: any) => { return item.processo });
            setProcessosSemIpva(listaProcessosSemIpva);

            const response = new HttpResponse(false, status, data);
            return response;
        } catch (error: IHttpError<IData, any> | any) {
            const response = new HttpError<IData>(true, error);
            return response;
        }
    };

    const realizarPagamento = async (recibo: any, idsProcessos: any[]) => {
        try {
            const { status, data } = await api.post(`/processos/detran/custo/ipva/${recibo}/pagamento`, { idsProcessos });
            const response = new HttpResponse(false, status, data);
            return response;
        } catch (error: IHttpError<IData, any> | any) {
            const response = new HttpError<IData>(true, error);
            return response;
        }
    };

    const baixarManualmenteProcessosComIpva = async (recibo: any, idsProcessos: any[]) => {
        try {
            const { status, data } = await api.post(`/processos/detran/custo/ipva/${recibo}`, { idsProcessos });
            const response = new HttpResponse(false, status, data);
            return response;
        } catch (error: IHttpError<IData, any> | any) {
            const response = new HttpError<IData>(true, error);
            return response;
        }
    };

    return {
        processosComIpva,
        processosSemIpva,
        setReciboEmpresaLista,
        listarRecibosDaEmpresa,
        baixarManualmente,
        reciboEmpresaLista,
        baixarManualmenteProcessosComIpva,
        realizarPagamento
    };
}
