import React, { useEffect, useState } from "react";
import { Dropdown, FormControl } from "react-bootstrap";
import { RiArrowDropDownLine } from "react-icons/ri";
import {
  Container,
  DropdownButton,
  DropdownContainer,
  DropdownMenu,
  SpanMensagemErro,
  Title,
} from "./style";
import { Required } from "components/Required";

interface IDropdownSelect {
  options: IOptions[];
  title?: string;
  searchable?: boolean;
  onSelect: (e: any) => void;
  selectedValue: any;
  disabled?: boolean;
  mensagemErro?: NullableString;
  required?: boolean;
  gapDropdownContainer?: string;
}

export interface IOptions {
  value: string | number;
  label: string;
}

interface ICustomToggleProps {
  children?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {};
  selected?: boolean;
  comErro: boolean;
  disabled?: boolean;
}

interface ICustomMenuProps {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  labeledBy?: string;
  searchable: boolean;
}

const CustomToggle = React.forwardRef(
  (props: ICustomToggleProps, ref: React.Ref<HTMLButtonElement>) => (
    <DropdownButton
      comErro={props.comErro}
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        props.onClick!(e);
      }}
      selected={props.selected!}
      disabled={props.disabled}
    >
      {props.children}
      <RiArrowDropDownLine />
    </DropdownButton>
  )
);

const CustomMenu = React.forwardRef(
  (props: ICustomMenuProps, ref: React.Ref<HTMLDivElement>) => {
    const [value, setValue] = useState("");

    return (
      <DropdownMenu
        ref={ref}
        style={props.style}
        className={props.className}
        aria-labelledby={props.labeledBy}
        searchable={props.searchable}
      >
        <FormControl
          autoFocus
          placeholder={`Pesquise`}
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
        <ul className="list-unstyled">
          {React.Children.toArray(props.children).filter(
            (child: any) =>
              !value ||
              child.props.children.toLowerCase().includes(value.toLowerCase())
          )}
        </ul>
      </DropdownMenu>
    );
  }
);

export function DropdownSelect({
  options,
  title = '',
  searchable = false,
  onSelect,
  selectedValue,
  disabled = false,
  mensagemErro = null,
  required = false,
  gapDropdownContainer = "0.5rem"
}: IDropdownSelect) {
  const [selected, setSelected] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState<string>("Selecione");

  useEffect(() => {
    if (disabled && !selected) {
      setSelected(false);
      setSelectedLabel("Selecione");
      onSelect(null);
      return;
    }

    setSelected(!!selectedValue);
    const selectedOption =
      options.find((option) => String(option.value) === String(selectedValue))?.label ??
      "Selecione";
    setSelectedLabel(selectedOption);
  }, [onSelect, selectedValue, options, disabled]);

  return (
    <Container>
      {title && (
        <Title>
        {title} {required && <Required />}
        </Title>
      )}
      
      <DropdownContainer onSelect={onSelect} gapDropdownContainer={gapDropdownContainer}>
        <Dropdown.Toggle
          selected={selected}
          comErro={!!mensagemErro}
          disabled={disabled}
          as={CustomToggle}
        >
          {selectedLabel}
        </Dropdown.Toggle>

        {!disabled && (
          <Dropdown.Menu searchable={searchable} as={CustomMenu}>
            <Dropdown.Item key="Selecione">Selecione</Dropdown.Item>
            {options.map((option) => (
              <Dropdown.Item key={option.value} eventKey={option.value}>
                {option.label}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        )}
        {mensagemErro && <SpanMensagemErro>{mensagemErro}</SpanMensagemErro>}
      </DropdownContainer>
    </Container>
  );
}

