import * as yup from "yup";
import { portugues } from "_utils/validador";

yup.setLocale(portugues);

export const preenchimentoModalManual = yup.object().shape({
  idAutorizacao: yup.string().optional(),
  arquivo: yup
    .mixed()
    .test("file-selected", "Selecione um arquivo", (value) => {
      return value instanceof File && value.size > 0;
    })
    .required("Selecione um arquivo")
    .nonNullable(),
});
