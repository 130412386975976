import { ModalCustom } from "components/ModalCustom";
import { Container, IconeContainer, TextoContainer } from "./style";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  show: boolean;
  handleClose: () => void;
}

export function ModalPlanilhaCorrompida({ handleClose, show }: Props) {
  return (
    <ModalCustom size="lg" centered handleClose={handleClose} show={show} title="Planilha corrompida">
      <Container>
        <IconeContainer>
        <FontAwesomeIcon icon={faXmark} />
        </IconeContainer>
        <TextoContainer>
          <span>A planilha está corrompida, não foi possível realizar o processamento.</span>
        </TextoContainer>
      </Container>
    </ModalCustom>
  );
}

