import { usePaginationSortSearch } from "containers/Table/hooks/usePaginationSortSearch";
import { useCallback, useState } from "react";
import { AxiosRequest } from "_services/api";
import { HttpError } from "_services/api/HttpError";
import { HttpResponse } from "_services/api/HttpResponse";
import { IData, IHttpError, IHttpResponse } from "_services/api/interface/HttpResponse";
import { IDespachanteListar } from "../Interfaces/IDespachanteListar";
import { IFormularioDespachante } from "../Interfaces/IFormularioDespachante";
import { IAreaAtuacao } from "../Interfaces/IAreaAtuacao";
import { IServico } from "features/core/interfaces/IServico";
import { IEtapaServico } from "features/core/interfaces/IEtapaServico";

interface IListarDespachantes {
    despachante?: string;
    cnpj?: string;
    uf?: string;
    municipio?: string;
    pageDespachante?: number;
    limitDespachante?: number;
}

export function useDespachantes() {
    const api = AxiosRequest.makeApi();
    const { totalRows, setTotalRows } = usePaginationSortSearch();
    const [loading, setLoading] = useState(false);
    const [listaDespachantes, setListaDespachantes] = useState<IDespachanteListar[]>([]);
    const [error, setError] = useState<IHttpResponse<IData | any>>();
    const [page, setPage] = useState<number>(1);
    const [limit, setLimit] = useState<number>(10);
    const [pageServicos, setPageServicos] = useState<number>(1);
    const [limitServicos, setLimitServicos] = useState<number>(10);
    const [dadosFormulario, setDadosFormulario] = useState<IFormularioDespachante>({
        id: "",
        razao_social: "",
        nome_fantasia: "",
        cnpj: "",
        email: "",
        celular: "",
        telefone: "",
        cep: "",
        logradouro: "",
        numero: "",
        bairro: "",
        cidade: "",
        estado: "",
        complemento: "",
    })
    const [dadosTabelaAreasAtuacao, setDadosTabelaAreasAtuacao] = useState<IAreaAtuacao[]>([])
    const [servicosDespachante, setServicosDespachante] = useState<IServico[]>([])
    const [etapasServicoDespachante, setEtapasServicoDespachante] = useState<IEtapaServico[]>([])
    const [honorarioServicoDespachante, setHonorarioServicoDespachante] = useState<string>("")
    const [listaUfs, setListaUfs] = useState<string[]>([]);
    const [ultimoFiltro, setUltimoFiltro] = useState<IListarDespachantes>();

    const listarDespachantes = useCallback(async ({
        despachante,
        cnpj,
        uf,
        municipio,
        pageDespachante,
        limitDespachante,
    }: IListarDespachantes) => {
        setLoading(true);
        try {
            let params = "";
            params += despachante ? `&despachante=${despachante}` : "";
            params += cnpj ? `&cnpj=${cnpj}` : "";
            params += uf ? `&uf=${uf}` : "";
            params += municipio ? `&municipio=${municipio}` : "";
            const { status, data } = await api.get(`/empresa/despachante/?page=${pageDespachante}&limit=${limitDespachante}${params}`);
            setListaDespachantes(data.dados);
            setTotalRows(data.totalRegistros);
            setUltimoFiltro({ despachante, cnpj, uf, municipio, limitDespachante: limit, pageDespachante: page })
            const response = new HttpResponse(false, status, data);
            setError(response);
            return response;
        } catch (error: IHttpError<IData, any> | any) {
            const response = new HttpError<IData>(true, error);
            setListaDespachantes([]);
            setTotalRows(0);
            setError(response);
            return response;
        } finally {
            setLoading(false);
        }
    }, [page, limit, setTotalRows]);

    const excluirDespachante = async (idDespachante: string) => {
        setLoading(true);
        try {
            const { status, data } = await api.patch(`/empresa/despachante/${idDespachante}/exclusao`);
            const response = new HttpResponse(false, status, data);
            setError(response);
            return response;
        } catch (error: IHttpError<IData, any> | any) {
            const response = new HttpError<IData>(true, error);
            setError(response);
            return response;
        } finally {
            setLoading(false);
        }
    };

    const buscarDespachante = async (id: string) => {
        setLoading(true);
        try {
            const { status, data } = await api.get(`/empresa/despachante/${id}`);
            const response = new HttpResponse(false, status, data);
            setDadosFormulario(data.despachante);
            setDadosTabelaAreasAtuacao(data.areasAtuacao);
            setError(response);
            return response;
        } catch (error: IHttpError<IData, any> | any) {
            const response = new HttpError<IData>(true, error);
            setError(response);
            return response;
        } finally {
            setLoading(false);
        }
    };

    const buscarServicosDespachante = useCallback(async (idDespachante: string, setTotalRowsServicos: React.Dispatch<React.SetStateAction<number>>, page: number, limit: number) => {
        setLoading(true);
        try {
            const { status, data } = await api.get(`/empresa/servico/despachante/${idDespachante}?page=${page}&limit=${limit}`);
            const response = new HttpResponse(false, status, data);
            setServicosDespachante(data.dados);
            setTotalRowsServicos(data.totalRegistros);
            setError(response);
            return response;
        } catch (error: IHttpError<IData, any> | any) {
            const response = new HttpError<IData>(true, error);
            setServicosDespachante([]);
            setTotalRowsServicos(0);
            setError(response);
            return response;
        } finally {
            setLoading(false);
        }
    }, [pageServicos, limitServicos]);

    const buscarEtapasServicoDespachante = async (idServico: string, idDespachante: string, idEmpresa: string) => {
        setLoading(true);
        try {
            const { status, data } = await api.get(`/empresa/servico/${idServico}/despachante/${idDespachante}/empresa/${idEmpresa}`);
            const response = new HttpResponse(false, status, data);
            setEtapasServicoDespachante(data);
            setError(response);
            return response;
        } catch (error: IHttpError<IData, any> | any) {
            const response = new HttpError<IData>(true, error);
            setError(response);
            return response;
        } finally {
            setLoading(false);
        }
    };

    const atualizarHonorarioServicoDespachante = async (idServico: string, idDespachante: string, valor: string) => {
        setLoading(true);
        try {
            const { status, data } = await api.patch(`/empresa/servico/${idServico}/honorario`,
                { valor, idDespachante });
            const response = new HttpResponse(false, status, data);
            setHonorarioServicoDespachante("");
            setError(response);
            return response;
        } catch (error: IHttpError<IData, any> | any) {
            const response = new HttpError<IData>(true, error);
            setError(response);
            return response;
        } finally {
            setLoading(false);
        }
    };

    const cadastrarDespachante = async (despachante: IFormularioDespachante, atuacao: IAreaAtuacao[]) => {
        setLoading(true);
        try {
            const { status, data } = await api.post(`/empresa/despachante/`, {
                despachante,
                atuacao
            });
            const response = new HttpResponse(false, status, data);
            setError(response);
            return response;
        } catch (error: IHttpError<IData, any> | any) {
            const response = new HttpError<IData>(true, error);
            setError(response);
            return response;
        } finally {
            setLoading(false);
        }
    };

    const atualizarDespachante = async (despachante: IFormularioDespachante, atuacao: IAreaAtuacao[], idDespachante: string) => {
        setLoading(true);
        try {
            const { status, data } = await api.put(`/empresa/despachante/${idDespachante}`, {
                despachante,
                atuacao
            });
            const response = new HttpResponse(false, status, data);
            setError(response);
            return response;
        } catch (error: IHttpError<IData, any> | any) {
            const response = new HttpError<IData>(true, error);
            setError(response);
            return response;
        } finally {
            setLoading(false);
        }
    };

    return {
        error,
        setLoading,
        loading,
        setPage,
        setLimit,
        page,
        limit,
        setPageServicos,
        setLimitServicos,
        pageServicos,
        limitServicos,
        totalRows,
        listarDespachantes,
        listaDespachantes,
        excluirDespachante,
        buscarDespachante,
        dadosFormulario,
        setDadosFormulario,
        dadosTabelaAreasAtuacao,
        setDadosTabelaAreasAtuacao,
        buscarServicosDespachante,
        servicosDespachante,
        buscarEtapasServicoDespachante,
        etapasServicoDespachante,
        setHonorarioServicoDespachante,
        honorarioServicoDespachante,
        atualizarHonorarioServicoDespachante,
        cadastrarDespachante,
        atualizarDespachante,
        setListaUfs,
        listaUfs,
        ultimoFiltro,
    };
}
