import { PrimaryButton } from "components/Button/style";
import { ButtonContainer } from "./style";
import { ButtonHTMLAttributes, ElementType } from "react";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  onClick: () => void;
  text: string;
  iconeBotao?: ElementType;
}

export function CompositeContainerActionButton({
  onClick,
  text,
  iconeBotao: IconeBotao,
  disabled,
}: Props) {
  return (
    <ButtonContainer>
      <PrimaryButton buttonWidth="sm" disabled={disabled} onClick={onClick}>
        {text} {IconeBotao && <IconeBotao />}
      </PrimaryButton>
    </ButtonContainer>
  );
}

