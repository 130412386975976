import styled from "styled-components";
import { FaCheckCircle as FaCheckCircleBase, FaExclamationCircle, FaTimesCircle as FaTimesCircleBase } from "react-icons/fa";
import { CustomInput } from "components/CustomInput";
import { PrimaryButton } from "components/Button/style";

export const ContainerRegrasSenha = styled.div`
  margin-top: 0.5rem;
  font-size: 13px;
  color: #676a6c;
`;

export const FaCheckCircle = styled(FaCheckCircleBase)`
  color: green;
  margin-right: 1rem;
`

export const FaTimesCircle = styled(FaTimesCircleBase)`
  color: red;
  margin-right: 1rem;
`

export const ContainerSucesso = styled.div`
`;

export const Texto = styled.p`
  padding: 0;
  color: ${({ theme }) => theme.colors.neutral.neutral50};
`;

export const ContainerNovaSenha = styled.div`
  display: grid;
  gap: 16px;
  margin-bottom: 2.5rem;
`;

export const TitleNovaSenha = styled.h1`
  color: ${(props) => props.color};
  font-size: 32px;
  font-weight: 700;
  padding:0;
  margin: 0;
`;

export const TextoNovaSenha = styled.p`
  padding: 0;
  margin: 0;
  color: ${({ theme }) => theme.colors.neutral.neutral50};
`;

export const FaCheckCircleDireito = styled(FaCheckCircleBase)`
  font-size: 14px;
  color: #10AC85;
`;

export const FaExclamationCircleDireito = styled(FaExclamationCircle)`
  font-size: 14px;
  color: #FF3129;
`;

export const InputNovaSenha = styled(CustomInput).attrs((props) => ({
  type: props.type,
  placeholder: props.placeholder,
  size: "lg",
}))`
  border-left: 0;
  border-right: 0;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  &::placeholder {
    font-size: 14px;
  }
`;

export const InputConfirmarNovaSenha = styled(CustomInput).attrs((props) => ({
  type: props.type,
  placeholder: props.placeholder,
  size: "lg",
}))`
  border-left: 0;
  border-right: 0;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  &::placeholder {
    font-size: 14px;
  }
`;

export const ContainerProgressBar = styled.div`
  margin-top: 0.5rem;
  margin-bottom: 1rem;
`;

export const TitleSucesso = styled.h1`
  color: ${(props) => props.color};
  font-size: 32px;
  font-weight: 700;
  padding:0;
  margin: 0;
  margin-bottom: 2.5rem;
`;

export const TextoSucesso = styled.p`
  padding: 0;
  color: ${({ theme }) => theme.colors.neutral.neutral50};
`;

export const CustomButtonSucesso = styled(PrimaryButton)`
  width: 100%;
  height: 50px;
  margin-top: 4rem;
`;