const temaPadrao = {
  spinner: {
    speed: {
      low: 3,
      medium: 2.5,
      high: 2,
    },
    sizeContainer: {
      small: "3.5rem",
      medium: "4rem",
      large: "4.5rem",
    },
    sizeItem: {
      small: "0.5rem",
      medium: "0.75rem",
      large: "1rem",
    },
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fonts: {
    body: "'Roboto', sans-serif",
    heading: "Roboto",
    monospace: "Roboto",
  },
  fontSizes: [
    "0.75rem ", //12,
    "0.875rem", //14,
    "1rem", //16
    "1.25rem", //20,
    "1.5rem", //24
    "2rem", //32,
    "3rem", //48,
    "4rem", //64,
    "6rem", //96
  ],
  fontWeights: {
    body: 400,
    heading: 700,
    semiBold: 600,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  colors: {
    text: "#000",
    textLight: "#FFF",
    background: "#f3f3f4",
    border: "#DEE2E6",
    ternary: {
      main: "#FFCB00",
      bgColor: "#FFFAE5",
    },
    layout: {
      p00: "#009933",
      p01: "rgb(0,0,255)",
      p02: "#e30012",
      p03: "#ff6d00",
      p04: "#125B9E",
      p05: "#ff6d00",
      p06: "rgb(0,0,255)",
      p07: "#541570",
      p08: "#ff6d00",
      bg00: "#FFCB00",
      bg01: 'rgb(0,0,255)',
      bg02: "#e30012",
      bg03: "#ff6d00",
      bg04: "#125B9E",
      bg05: "#ff6d00",
      bg06: "rgb(0,0,255)",
      bg07: "#541570",
      bg08: "#ff6d00",
    },
    primary: {
      main: "#333333",
      bgColor: "#F5F5F5",
      pressed: "#1A1A1A",
      hover: "#4D4D4D",
      focus: "#333333"
    },
    secondary: {
      main: "#FFCB00",
      bgColor: "#FFFAE5",
      pressed: "#CCA200",
      hover: "#FFE066",
      focus: "#FFEA99"
    },
    success: {
      main: "#10AC85",
      bgColor: "#DBFFF6",
      pressed: "#085642",
      hover: "#0D8A6A",
      focus: "#ABE9D9"
    },
    info: {
      main: "#0ABDE2",
      bgColor: "#EEFCFF",
      pressed: "#055E71",
      hover: "#0897B5",
      focus: "#D2F7FF"
    },
    danger: {
      main: "#EE5353",
      bgColor: "#FFE4E4",
      pressed: "#772929",
      hover: "#BE4242",
      focus: "#FFCCCC"
    },
    warning: {
      main: "#FF9F42",
      bgColor: "#FFEFCC",
      pressed: "#7F4F21",
      hover: "#CC7F35",
      focus: "#FFEFCC"
    },
    neutral: {
      neutral00: "#F2F2F2",
      neutral05: "#F5F5F5",
      neutral10: "#E6E6E6",
      neutral20: "#CCCCCC",
      neutral30: "#B3B3B3",
      neutral40: "#999999",
      neutral50: "#7F7F7F",
      neutral60: "#666666",
      neutral70: "#4D4D4D",
      neutral80: "#333333",
      neutral90: "#1A1A1A",
    },
    project: {
      main: "#990000",
      bgColor: "#F4E6E6",
      pressed: "#580703",
      hover: "#750D06",
      focus: "#990000",
      bgColor20: "#D19B9A"
    },
    tags: {
      warning: {
        bgColor: "#FFEFCC",
        textColor: "#FF9F42",
      },
      warning02: {
        bgColor: "#FDE5F8",
        textColor: "#EC04B9",
      },
      danger: {
        bgColor: "#FFE4E4",
        textColor: "#EE5353",
      },
      info: {
        bgColor: "#EEFCFF",
        textColor: "#0ABDE2",
      },
      info02: {
        bgColor: "#E5D9FE",
        textColor: "#5F27CC",
      },
      info03: {
        bgColor: "#E9E9FE",
        textColor: "#2529F4",
      },
      success: {
        bgColor: "#DBFFF6",
        textColor: "#10AC85",
      },
      disabled: {
        bgColor: "#F5F5F5",
        textColor: "#7F7F7F",
      },
    },
    white: "#FFF",
    light: "#f8f9fa",
    dark: "#212529",
    colorNavBar: "#f8f9fa",
    colorMenuPrimary: "#2f4050",
    colorMenuSecondary: "#293846",
    colorTextMenu: "#fff",
  },
  styles: {
    root: {
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",
    },
    h1: {
      color: "text",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 5,
    },
    h2: {
      color: "text",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 4,
    },
    h3: {
      color: "text",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 3,
    },
    h4: {
      color: "text",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 2,
    },
    h5: {
      color: "text",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 1,
    },
    h6: {
      color: "text",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 0,
    },
    p: {
      color: "text",
      fontFamily: "body",
      fontWeight: "body",
      lineHeight: "body",
    },
    a: {
      color: "primary",
    },
    pre: {
      fontFamily: "monospace",
      overflowX: "auto",
      code: {
        color: "inherit",
      },
    },
    code: {
      fontFamily: "monospace",
      fontSize: "inherit",
    },
    table: {
      width: "100%",
      borderCollapse: "separate",
      borderSpacing: 0,
    },
    th: {
      textAlign: "left",
      borderBottomStyle: "solid",
    },
    td: {
      textAlign: "left",
      borderBottomStyle: "solid",
    },
    img: {
      maxWidth: "100%",
    },
  },
};

export default temaPadrao;
