import { Container } from "./style";
import { FaPlus } from "react-icons/fa";
import { Table } from "containers/Table";
import { IColum } from "containers/Table/interfaces";
import { useCallback, useEffect, useState } from "react";
import { ModalEtapasServicoDespachante } from "../ModalEtapasServicoDespachante";
import { ModalHonorarioServicoDespachante } from "../ModalHonorarioServicoDespachante";
import { useMaskInput } from "hooks/useMaskInput";
import { alertaErro, alertaSucesso } from "components/Alerta";
import { LoadingScreen } from "components/LoadingScreen";
import { HttpError } from "_services/api/HttpError";
import { IData } from "_services/api/interface/HttpResponse";
import { HttpResponse } from "_services/api/HttpResponse";
import { utils, writeFileXLSX } from "xlsx";
import { usePaginationSortSearch } from "containers/Table/hooks/usePaginationSortSearch";
import { IServico } from "features/core/interfaces/IServico";
import { IEtapaServico } from "features/core/interfaces/IEtapaServico";

interface IListarServicosProps {
    id: string;
    buscarServicosDespachante: (idDespachante: string, setTotalRowsServicos: React.Dispatch<React.SetStateAction<number>>, pag: number, limit: number) => Promise<HttpError<IData, any> | HttpResponse<any, any>>;
    buscarEtapasServicoDespachante: (idServico: string, idDespachante: string, idEmpresa: string) => Promise<HttpError<IData, any> | HttpResponse<any, any>>;
    atualizarHonorarioServicoDespachante: (idServico: string, idDespachante: string, valor: string) => Promise<HttpError<IData, any> | HttpResponse<any, any>>;
    loading: boolean;
    honorarioServicoDespachante: string;
    etapasServicoDespachante: IEtapaServico[];
    servicosDespachante: IServico[];
    setHonorarioServicoDespachante: (e: string) => void;
    listaUfs: string[];
    handleLimitChange: (e: number) => void;
    handlePageChange: (e: number) => void;
    limit: number;
    page: number;
}

export function ListaServicos({
    id,
    buscarServicosDespachante,
    buscarEtapasServicoDespachante,
    atualizarHonorarioServicoDespachante,
    loading,
    honorarioServicoDespachante,
    etapasServicoDespachante,
    servicosDespachante,
    setHonorarioServicoDespachante,
    listaUfs,
    handleLimitChange,
    handlePageChange,
    limit,
    page,
}: IListarServicosProps) {

    const { totalRows, setTotalRows } = usePaginationSortSearch();
    useEffect(() => {
        if (id) {
            buscarServicosDespachante(
                id,
                setTotalRows, page, limit
            );
        }
    }, [listaUfs, page, limit, id]);

    const [showModalEtapasServicoDespachante, setShowModalEtapasServicoDespachante] = useState<boolean>(false);
    const handleCloseModalEtapasServicoDespachante = () => {
        setShowModalEtapasServicoDespachante(false);
    }
    const handleShowModalEtapasServicoDespachante = async (idServico: string, idEmpresa: string) => {
        const { hasErro, data } = await buscarEtapasServicoDespachante(idServico, id, idEmpresa);
        if (hasErro) {
            alertaErro("Erro", data.message);
            return;
        }
        setShowModalEtapasServicoDespachante(true);
    }

    const [disabledInput, setDisabledInput] = useState<boolean>(true);
    const [showModalHonorarioServicoDespachante, setShowModalHonorarioServicoDespachante] = useState<boolean>(false);
    const [idServicoEscolhido, setServicoEscolhido] = useState<string>("");
    const handleCloseModalHonorarioServicoDespachante = () => {
        setShowModalHonorarioServicoDespachante(false);
    }
    const handleShowModalHonorarioServicoDespachante = (idServico: string, honorario: string) => {
        setServicoEscolhido(idServico)
        setShowModalHonorarioServicoDespachante(true);
        if (id == "0") {
            setHonorarioServicoDespachante(honorario);
            setDisabledInput(true);
            return;
        }

        setDisabledInput(false);
        setHonorarioServicoDespachante(honorario);
    }

    const { mInputCoin } = useMaskInput();
    const onChangeValor = (event: any) => {
        setHonorarioServicoDespachante(mInputCoin(event));
    }
    const submitForm = async () => {
        const { hasErro } = await atualizarHonorarioServicoDespachante(
            idServicoEscolhido,
            id,
            honorarioServicoDespachante
        )
        setShowModalHonorarioServicoDespachante(false);

        if (hasErro) {
            alertaErro("Ocorreu um erro", "Erro ao tentar atualizar honorário.");
            return;
        }

        await buscarServicosDespachante(
            id,
            setTotalRows, page, limit
        );
        alertaSucesso("Sucesso", "Honorário atualizado com sucesso!");
    }

    const columns: IColum<IServico>[] = [
        {
            name: <span>ID</span>,
            selector: (servico: IServico) => servico.id,
            width: "90px",
        },
        {
            name: <span>Serviço</span>,
            selector: (servico: IServico) => servico.nome,
        },
        {
            name: <span>UF</span>,
            selector: (servico: IServico) => servico.uf,
            width: "90px",
        },
        {
            name: <span>Etapas</span>,
            selector: (servico: IServico) => <FaPlus onClick={() => handleShowModalEtapasServicoDespachante(servico.id, servico.id_empresa as string)} style={{ cursor: "pointer" }} />,
            width: "150px",
        },
        {
            name: <span>Honorários</span>,
            selector: (servico: IServico) => <FaPlus onClick={() => handleShowModalHonorarioServicoDespachante(servico.id, servico.honorario ?? null)} style={{ cursor: "pointer" }} />,
            width: "150px",
        }
    ];

    const dadosPlanilha = servicosDespachante.map((item: IServico) => ({
        ID: item.id,
        SERVIÇO: item.nome,
        UF: item.uf,
    }));

    const handleExport = useCallback(() => {
        const planilha = utils.json_to_sheet(dadosPlanilha);
        planilha['!cols'] = [{ wch: 5 }, { wch: 60 }, { wch: 5 }];
        const workBook = utils.book_new();
        utils.book_append_sheet(workBook, planilha, "Serviços");
        writeFileXLSX(workBook, "servicos.xlsx");
    }, [dadosPlanilha]);

    return (
        <Container>
            <LoadingScreen mostrar={loading} />
            <Table
                keyField="id"
                totalRows={totalRows}
                pagination={true}
                columns={columns}
                data={servicosDespachante}
                paginationRowsPerPageOptions={[10, 20, 50]}
                loading={false}
                onChangeRowsPerPage={handleLimitChange}
                onChangePage={handlePageChange}
                exportExcel={true}
                handleExport={handleExport}
                buttonExportVariant="secondary"
            />
            <ModalEtapasServicoDespachante
                show={showModalEtapasServicoDespachante}
                handleClose={handleCloseModalEtapasServicoDespachante}
                dados={etapasServicoDespachante}
            ></ModalEtapasServicoDespachante>
            <ModalHonorarioServicoDespachante
                show={showModalHonorarioServicoDespachante}
                handleClose={handleCloseModalHonorarioServicoDespachante}
                valorHonorarioServico={honorarioServicoDespachante}
                submitForm={submitForm}
                onChangeValor={onChangeValor}
                disabledInput={disabledInput}
            ></ModalHonorarioServicoDespachante>
        </Container>
    );
}