import { FunnelFill } from "react-bootstrap-icons";
import {
    ButtonContainer,
    ButtonTexto,
    ModalContainer,
    TituloModalContainer,
    HeaderModalContainer,
    BodyModalContainer,
    FooterModalContainer,
} from "./style";
import { PrimaryButton } from "components/Button/style";
import { Button } from "react-bootstrap";
import { ReactNode, useState } from "react";

interface ButtonModalProps {
    children: ReactNode;
    handleClickAplicarFiltros: () => void;
    limparTudoOnClick: () => void;
    widthModal?: "sm" | "md" | "lg";
    showMaisFiltrosDefault?: boolean;
    cancelarOnClick: () => void;
}

export function MaisFiltros({
    children,
    limparTudoOnClick,
    widthModal = "md",
    showMaisFiltrosDefault = false,
    cancelarOnClick,
    handleClickAplicarFiltros,
}: ButtonModalProps) {
    const [aberto, setAberto] = useState(showMaisFiltrosDefault);

    function handleAplicarFiltros() {
        setAberto(false);
        handleClickAplicarFiltros();
    }

    function handleCancelar() {
        setAberto(false);
        cancelarOnClick();
    }

    function toggleModal() {
        setAberto((state) => !state);
    }

    return (
        <ButtonContainer>
            <PrimaryButton
                outline={true}
                variante="black"
                onClick={toggleModal}
                type="button"
                buttonWidth="lg"
            >
                <FunnelFill />
                <ButtonTexto>Mais filtros</ButtonTexto>
            </PrimaryButton>
            <ModalContainer width={widthModal} show={aberto}>
                <HeaderModalContainer>
                    <TituloModalContainer>
                        <strong>Filtros</strong>
                    </TituloModalContainer>
                    <Button
                        variant="link"
                        onClick={limparTudoOnClick}
                        style={{ textDecoration: "none", fontSize: "13px", color: "#990000" }}
                    >
                        Limpar tudo
                    </Button>
                </HeaderModalContainer>
                <BodyModalContainer>{children}</BodyModalContainer>
                <FooterModalContainer>
                    <PrimaryButton
                        outline={true}
                        variante="primary"
                        style={{ width: "100%" }}
                        onClick={handleCancelar}
                        type="button"
                    >
                        Cancelar
                    </PrimaryButton>
                    <PrimaryButton
                        type="button"
                        style={{ width: "100%" }}
                        onClick={handleAplicarFiltros}
                    >
                        Aplicar
                    </PrimaryButton>
                </FooterModalContainer>
            </ModalContainer>
        </ButtonContainer>
    );
}

