import styled from "styled-components";

interface IContainerProps {
  mostrar?: boolean;
  backgroundColor?: string;
}

export const Container = styled.div<IContainerProps>`
  background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : "rgba(00, 00, 00, 0.4)")};
  position: fixed;
  z-index: 999;
  height: 100vh;
  width: 100vw;
  display: ${({ mostrar }) => mostrar ? 'flex' : 'none'};
  align-items: center;
  justify-content: center;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`