
import { Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ContainerHeader } from "components/Ibox/style";
import { CustomInput } from "components/CustomInput";
import { Button, ButtonsContainer, Container, FormContainer, InputContainer, Title } from "./style";
import { motivoPendenciaSchema } from "features/core/pages/Configuracao/Validate/validator";
import { PrimaryButton } from "components/Button/style";

interface IFormularioProps {
    submitForm: (e: any) => void;
    onChangeDescricao: (descricao: string) => void;
    descricao: string;
    showBotaoCadastrar: boolean;
    onClickCancelarEditar: () => void;
}

export function FormularioMotivosPendencia({
    submitForm,
    onChangeDescricao,
    descricao,
    showBotaoCadastrar,
    onClickCancelarEditar,
}: IFormularioProps) {

    const {
        register,
        handleSubmit,
    } = useForm({
        resolver: yupResolver(motivoPendenciaSchema),
    });



    return (
        <Container>
            <ContainerHeader>
                <Title>{showBotaoCadastrar ? <span>Cadastrar</span> : <span>Editar</span>}</Title>
            </ContainerHeader>
            <hr />
            <FormContainer>
                <Form noValidate onSubmit={handleSubmit(submitForm)}>
                    <Row>
                        <InputContainer>
                            <Form.Group>
                                <Form.Label style={{ marginBottom: "0.8rem" }}>
                                    Descrição
                                </Form.Label>
                                <CustomInput
                                    type="text"
                                    {...register("descricao")}
                                    style={{ height: "2.2rem" }}
                                    onChange={(event) => onChangeDescricao(event.target.value)}
                                    value={descricao}
                                />
                            </Form.Group>
                        </InputContainer>
                        <InputContainer>
                            <Form.Group>
                                <ButtonsContainer>
                                    <Button type="submit" style={{ width: "120px" }}>{showBotaoCadastrar ? <span>+ Cadastrar</span> : <span>Atualizar</span>}</Button>
                                    <PrimaryButton
                                        onClick={onClickCancelarEditar}
                                        outline={true}
                                        variante="primary"
                                        type="button"
                                        style={showBotaoCadastrar ? { display: "none" } : { width: "120px", marginLeft: "20px" }}
                                    >Cancelar
                                    </PrimaryButton>
                                </ButtonsContainer>
                            </Form.Group>
                        </InputContainer>
                    </Row>
                </Form>
            </FormContainer>
        </Container>
    );
}