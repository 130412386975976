import TemaInspinia from "_config/temas/Padrao";
import ContextPermisaoProvider from "hooks/ContextPermissao";

const PrivateRoute = ({ component: Component }: any) => {
  return (
    <ContextPermisaoProvider>
      <TemaInspinia>
        <Component />
      </TemaInspinia>
    </ContextPermisaoProvider>
  );
};

export default PrivateRoute;
