import { useEffect, useState } from "react";

export function usePaginationSortSearch(startPaginationSortSearch = "") {
  const [totalRows, setTotalRows] = useState<number>(0);
  const [paginationSortSearch, setPaginationSortSearch] = useState<string>(
    startPaginationSortSearch
  );

  return {
    totalRows,
    setTotalRows,
    paginationSortSearch,
    setPaginationSortSearch,
  };
}
