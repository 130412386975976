
import { Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { DropdownSelect } from "components/DropdownSelect";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, ButtonsContainer, Container, FormContainer, InputContainer, Title } from "./style";
import { ContainerHeader } from "components/Ibox/style";
import { FaFilter } from "react-icons/fa";
import { CustomInput } from "components/CustomInput";
import { preenchimentoAtpvDetalhesFiltrosSchema } from "../../Validate/validator";
import { useMaskInput } from "hooks/useMaskInput";

interface IFiltrosProps {
    submitForm: (e: IFiltroDetalhes) => void;
}

export function Filtros({
    submitForm,
}: IFiltrosProps) {

    const {
        register,
        handleSubmit,
        reset,
        control,
    } = useForm({
        resolver: yupResolver(preenchimentoAtpvDetalhesFiltrosSchema),
    });

    const {
        mInputPlaca,
        mInputCPFCNPJ,
        mInputRenavam,
        mInputNum,
    } = useMaskInput();

    return (
        <Container>
            <ContainerHeader>
                <Title>Filtros</Title>
            </ContainerHeader>
            <hr />
            <FormContainer>
                <Form noValidate onSubmit={handleSubmit((dados: IFiltroDetalhes) =>
                    submitForm(dados)
                )}>
                    <Row>
                        <InputContainer>
                            <Form.Group>
                                <Form.Label>
                                    Placa
                                </Form.Label>
                                <CustomInput
                                    type="text"
                                    placeholder="Busque por placa"
                                    {...register("placa")}
                                    style={{ height: "2.2rem" }}
                                    onChange={mInputPlaca}
                                />
                            </Form.Group>
                        </InputContainer>
                        <InputContainer>
                            <Form.Group>
                                <Form.Label>
                                    Renavam
                                </Form.Label>
                                <CustomInput
                                    type="text"
                                    placeholder="Busque por renavam"
                                    {...register("renavam")}
                                    style={{ height: "2.2rem" }}
                                    onChange={mInputRenavam}
                                />
                            </Form.Group>
                        </InputContainer>
                        <InputContainer>
                            <Form.Group>
                                <Form.Label>
                                    CPF / CNPJ
                                </Form.Label>
                                <CustomInput
                                    type="text"
                                    placeholder="Busque por CPF ou CNPJ"
                                    {...register("cpfCnpj")}
                                    style={{ height: "2.2rem" }}
                                    onChange={mInputCPFCNPJ}
                                />
                            </Form.Group>
                        </InputContainer>
                        <InputContainer>
                            <Form.Group>
                                <Form.Label>
                                    ID Processo
                                </Form.Label>
                                <CustomInput
                                    type="text"
                                    placeholder="Busque por ID"
                                    {...register("idProcesso")}
                                    style={{ height: "2.2rem" }}
                                    onChange={mInputNum}
                                />
                            </Form.Group>
                        </InputContainer>
                        <InputContainer>
                            <Form.Group>
                                <Form.Label>
                                    Status
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name="statusATPV"
                                    render={({ field: { onChange, value } }) => (
                                        <DropdownSelect
                                            options={[
                                                { value: "1", label: "Processando" },
                                                { value: "2", label: "Finalizado" },
                                                { value: "3", label: "Dados inconsistentes" },
                                                { value: "4", label: "Impedido" },
                                                { value: "5", label: "Falha" },
                                            ]}
                                            title=""
                                            selectedValue={value}
                                            onSelect={onChange}
                                            searchable
                                        />
                                    )}
                                />
                            </Form.Group>
                        </InputContainer>
                        <InputContainer>
                            <Form.Group>
                                <ButtonsContainer>
                                    <Button type="submit"><FaFilter style={{ fontSize: "12px", marginBottom: "3px", marginRight: "5px" }} /> Filtrar</Button>
                                    <Button
                                        type="reset"
                                        outline
                                        variante="primary"
                                        onClick={() => {
                                            reset({
                                                idProcesso: null,
                                                placa: null,
                                                renavam: null,
                                                cpfCnpj: null,
                                                statusATPV: null,
                                            });
                                        }}
                                    >Limpar</Button>
                                </ButtonsContainer>
                            </Form.Group>
                        </InputContainer>
                    </Row>
                </Form>
            </FormContainer>
        </Container>
    );
}