import React from "react";
import DatePicker from "react-datepicker";
import { FaCalendar } from "react-icons/fa";
import { Container, CustomButton, Placeholder } from "./style";
import br from "date-fns/locale/pt-BR"

interface IDatePickerProps {
  onChange: (
    date: any,
    event: React.SyntheticEvent<any, Event> | undefined
  ) => void;
  selected?: Date | null | undefined;
  rangeSelect?: boolean
  startDate?: Date;
  endDate?: Date;
  placeholder?: string;
}

interface ICustomInput {
  value?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {};
  children?: React.ReactNode;
  className?: string;
  placeholder?: string;
}

const InputIcon = React.forwardRef<HTMLButtonElement>(
  (props: ICustomInput, ref: React.Ref<HTMLButtonElement>) => (
    <CustomButton
      type="button"
      className="example-custom-input"
      onClick={props.onClick}
      ref={ref}
    >
      <Placeholder selected={!!props.value}>
        {props.value ? props.value : (props.placeholder ? props.placeholder : "Selecione")}
      </Placeholder>
      <FaCalendar />
    </CustomButton>
  )
);

export function CustomDatePicker({ onChange, selected, rangeSelect = false, endDate, startDate, placeholder }: IDatePickerProps) {
  return (
    <Container>
      <DatePicker
        isClearable
        locale={br}
        dateFormat="dd/MM/yyyy"
        selected={selected}
        onChange={onChange}
        customInput={<InputIcon />}
        selectsRange={rangeSelect}
        endDate={endDate}
        startDate={startDate}
        placeholderText={placeholder}
      />
    </Container>
  );
}

