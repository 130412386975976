interface Props {
  text: string;
  maxLength: number;
}

export function TruncateString({ maxLength, text }: Props) {
  if (text.length < maxLength) {
    return text;
  }

  const truncatedText = text.substring(0, maxLength) + "...";

  return <span title={text}>{truncatedText}</span>;
}
