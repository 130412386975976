import { ModalCustom } from "components/ModalCustom";
import { Col } from "react-bootstrap";
import { RadioContainer } from "./style";
import { useEffect, useState } from "react";
import { ModalPreencherATPVFormularioUnitario } from "../ModalPreencherATPVFormularioUnitario";
import { ModalPreencherATPVFormularioLote } from "../ModalPreencherATPVFormularioLote";
import { IPreencherATPVLote } from "../../interfaces/IPreencherATPVLote";

interface IModalPreencherATPVProps {
    show: boolean;
    handleClose: () => void;
    submitFormUnitario: (dados: IPreencherATPVUnitario) => void;
    submitFormLote: (dados: IPreencherATPVLote) => void;
    setNomeEmpresa: (e: string) => void;
    setNomeDivisao: (e: string) => void;
}

export function ModalPreencherATPV({
    show,
    handleClose,
    submitFormUnitario,
    submitFormLote,
    setNomeEmpresa,
    setNomeDivisao,
}: IModalPreencherATPVProps) {

    const [preencherAtpvLoteSelected, setPreencherAtpvLoteSelected] = useState<boolean>(true);
    const handleCheck = (tipo: "lote" | "unitario") => {
        setPreencherAtpvLoteSelected(tipo === "lote");
    }

    useEffect(() => {
        setPreencherAtpvLoteSelected(true)
    }, [show]);

    return (
        <ModalCustom
            show={show}
            handleClose={handleClose}
            title="Preenchimento ATPV"
            centered
            size="xl"
        >
            <RadioContainer>
                <Col lg="3">
                    <div onClick={() => handleCheck("lote")}>
                        <input
                            id="radioPreencherAtpvLote"
                            type="radio"
                            className="custom-radio"
                            value="lote"
                            checked={preencherAtpvLoteSelected}
                        />
                        <label htmlFor="cadastrar" >Preenchimento em lote</label>
                    </div>

                </Col>
                <Col lg="3">
                    <div onClick={() => handleCheck("unitario")}>
                        <input
                            id="radioPreencherAtpvIndividual"
                            type="radio"
                            className="custom-radio"
                            value="unitario"
                            checked={!preencherAtpvLoteSelected}
                        />
                        <label htmlFor="copiar">Preenchimento unitário</label>
                    </div>
                </Col>
            </RadioContainer>
            <div style={{ margin: "16px 0" }}>Preencha as informações abaixo para registrar a intenção de venda.</div>
            {preencherAtpvLoteSelected
                ? <ModalPreencherATPVFormularioLote
                    setNomeEmpresa={setNomeEmpresa}
                    setNomeDivisao={setNomeDivisao}
                    submitForm={submitFormLote}
                ></ModalPreencherATPVFormularioLote>
                : <ModalPreencherATPVFormularioUnitario
                    submitForm={submitFormUnitario}
                ></ModalPreencherATPVFormularioUnitario>
            }
        </ModalCustom>
    );
}