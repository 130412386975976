import { Link } from "react-router-dom";
import { Button, ButtonsContainer, Container, ContainerHeader, FormContainer, InputContainer, Title } from "./style";
import { PrimaryButton } from "components/Button/style";
import { FaPlus } from "react-icons/fa";
import { Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { DropdownSelect } from "components/DropdownSelect";
import { yupResolver } from "@hookform/resolvers/yup";
import { servicosDespachantesFiltrosSchema } from "features/core/pages/Cadastro/Validate/validator";

interface IFiltrosProps {
    servicosModeloOptions: any[];
    onChangeUf: (e: any) => void;
    uf: string;
    onChangeIdServicoModelo: (e: any) => void;
    idServicoModelo: string;
    onClickBuscar: () => void;
    onClickCadastrar: () => void;
}
export function Filtros({
    servicosModeloOptions,
    onChangeUf,
    uf,
    onChangeIdServicoModelo,
    idServicoModelo,
    onClickBuscar,
    onClickCadastrar,
}: IFiltrosProps) {

    const {
        register,
        handleSubmit,
        reset,
        setError,
        control,
        formState: { errors, isSubmitted },
    } = useForm({
        resolver: yupResolver(servicosDespachantesFiltrosSchema),
    });

    return (
        <Container>
            <ContainerHeader>
                <Title>Filtros</Title>
                <PrimaryButton onClick={onClickCadastrar} buttonWidth="xl" buttonWidthTipo="px"><FaPlus></FaPlus> <span style={{ marginLeft: "2px", marginTop: "2px" }}>Cadastrar Serviço</span></PrimaryButton>
            </ContainerHeader>
            <hr />
            <FormContainer>
                <Form noValidate onSubmit={handleSubmit(onClickBuscar)}>
                    <Row>
                        <Col md={3}>
                            <InputContainer>
                                <Form.Group>
                                    <Form.Label>
                                        Serviço
                                    </Form.Label>
                                    <Controller
                                        control={control}
                                        name="servico"
                                        render={() => (
                                            <DropdownSelect
                                                options={servicosModeloOptions}
                                                title=""
                                                selectedValue={idServicoModelo}
                                                onSelect={onChangeIdServicoModelo}
                                                searchable
                                            />
                                        )}
                                    />
                                </Form.Group>
                            </InputContainer>
                        </Col>
                        <Col md={2}>
                            <InputContainer>
                                <Form.Group>
                                    <Form.Label>
                                        UF
                                    </Form.Label>
                                    <Controller
                                        control={control}
                                        name="uf"
                                        render={() => (
                                            <DropdownSelect
                                                options={[
                                                    { value: "AC", label: "AC" },
                                                    { value: "AL", label: "AL" },
                                                    { value: "AP", label: "AP" },
                                                    { value: "AM", label: "AM" },
                                                    { value: "BA", label: "BA" },
                                                    { value: "CE", label: "CE" },
                                                    { value: "DF", label: "DF" },
                                                    { value: "ES", label: "ES" },
                                                    { value: "GO", label: "GO" },
                                                    { value: "MA", label: "MA" },
                                                    { value: "MT", label: "MT" },
                                                    { value: "MS", label: "MS" },
                                                    { value: "MG", label: "MG" },
                                                    { value: "PA", label: "PA" },
                                                    { value: "PB", label: "PB" },
                                                    { value: "PR", label: "PR" },
                                                    { value: "PE", label: "PE" },
                                                    { value: "PI", label: "PI" },
                                                    { value: "RJ", label: "RJ" },
                                                    { value: "RN", label: "RN" },
                                                    { value: "RS", label: "RS" },
                                                    { value: "RO", label: "RO" },
                                                    { value: "RR", label: "RR" },
                                                    { value: "SC", label: "SC" },
                                                    { value: "SP", label: "SP" },
                                                    { value: "SE", label: "SE" },
                                                    { value: "TO", label: "TO" }
                                                ]}
                                                title=""
                                                selectedValue={uf}
                                                onSelect={onChangeUf}
                                                searchable
                                            />
                                        )}
                                    />
                                </Form.Group>
                            </InputContainer>
                        </Col>
                        <Col md={2}>
                            <InputContainer>
                                <Form.Group>
                                    <ButtonsContainer>
                                        <Button type="submit">Buscar</Button>
                                    </ButtonsContainer>
                                </Form.Group>
                            </InputContainer>
                        </Col>
                    </Row>
                </Form>
            </FormContainer>
        </Container>
    )
}